/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * The FilterSideBrand.js file defines a component for filtering by brand in a sidebar.
 * 
 * @author Yang Ming
 * @version January 17th, 2024
 */

import React from 'react'
import { setNewStoreFilter } from '@reducers/contentReducer'
import { useSelector, useDispatch } from 'react-redux'

export default function StoreFilterCategory() {
  const dispatch = useDispatch()
  const storeFilter = useSelector(state => state.content.newStoreFilter)
  const newCategoryList = useSelector(state => state.content.newCategoryList)

  if (newCategoryList.length === 0) return null

  return (
    <div className='filter-item'>
      <div className='filter-item-header'>
        <div className='filter-item-header-title'>
          <h4 className='cursor-pointer text-black'>Category</h4>
        </div>
      </div>

      <div className={`flex flex-col gap-2 smsize:gap-2 smsize:text-[16px]`}>
        {newCategoryList.map((item, index) => (
          <div key={index} className='filter-checkbox-item'>
            <label className='text-black'>
              <input
                value={item?._id}
                type='checkbox'
                className={`custom-checkbox`}
                checked={storeFilter?.categories.includes(item._id)}
                onChange={e => {

                  if (e.target.checked) {
                    dispatch(setNewStoreFilter({
                      ...storeFilter,
                      categories: [...storeFilter.categories, item?._id],
                    }))
                  } else {
                    dispatch(
                      setNewStoreFilter({
                        ...storeFilter,
                        categories: storeFilter.categories.filter(i => i !== item?._id),
                      }),
                    )
                  }
                }}
              />
              <span className={`truncate`}>{item?.title}</span>
            </label>
          </div>
        ))}
      </div>
    </div>
  )
}
