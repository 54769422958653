import React from 'react'
import InviteForm from './InviteForm'
export default function InviteSection({
    userinfo
}) {

    const [isOpen, setIsOpen] = React.useState(false)

    return (
        <>
            <InviteForm opened={isOpen} setOpened={setIsOpen} userinfo={userinfo} />
            <div className='w-full bg-[#202020] rounded-[33px] flex justify-between items-center py-5 px-8 text-white smsize:flex-col smsize:text-left smsize:items-start smsize:gap-4 smsize:px-5'>
                <div className='flex gap-5 items-center smsize:flex-col smsize:text-left smsize:items-start smsize:gap-1'>
                    <h4 className='text-[#DBE366] text-[40px] smsize:text-[24px] uppercase smsize:whitespace-nowrap'>WIN A $500 SHOPPING SPREE</h4>
                    <p className='text-[24px] smsize:text-[15px] smsize:text-left'>when you invite a friend to join Fitzba</p>
                </div>
                <button
                    onClick={() => {
                        setIsOpen(true)
                    }}
                    className='bg-[#DBE366] rounded-full py-1 px-10 uppercase text-[#202020] text-[18px] smsize:text-[13px]'>
                    Invite
                </button>
            </div>
        </>
    )
}
