/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * The OutsideFitzba component renders a message about Fitzba's expansion and two buttons.
 * One button allows the user to join a waitlist and the other navigates to the retail page.
 * The component uses the useNavigate hook from the react-router-dom library to handle navigation.
 * It also receives a setProg function as a prop to manage the progress state.
 *
 * @author James Nguyen
 * @version May 09, 2023 
 */

import React from 'react'
import { useNavigate } from 'react-router-dom'

function OutsideFitzba({ setProg }) {
  const navigate = useNavigate()
  return (
    <>
      <div className='my-[50px] w-full px-8 lg:px-24 flex m-auto items-center text-[22px] sm:text-[36px] text-[#202020]'>
        fitzba is working hard to come to your community!
      </div>
      <div className='flex h-[42px] w-full flex-col sm:flex-row items-center justify-around gap-5'>
        <div
          className='flex w-[230px] cursor-pointer items-center justify-around rounded-full  border-2 border-fitzba-bright-gold-static bg-fitzba-bright-gold-static font-lexend text-[16px] text-fitzba-white-static hover:bg-fitzba-white-static hover:text-fitzba-bright-gold-static'
          onClick={() => {
            setProg(1)
          }}
        >
          JOIN THE WAITLIST
        </div>
        <div
          className='flex w-[230px] cursor-pointer items-center justify-around rounded-full  border-2 border-black bg-fitzba-white-static font-lexend text-[16px] text-black hover:border-fitzba-bright-gold-static hover:text-fitzba-bright-gold-static'
          onClick={() => {
            navigate('/retail')
          }}
        >
          I OWN A RETAIL STORE
        </div>
      </div>
    </>
  )
}

export default OutsideFitzba
