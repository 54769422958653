/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * EmployeeVerify is a functional React component that handles the employee verification process.
 * It fetches the employee data from the server and displays either the EmployeeVerifyForm or the EmployeeVerifyResult component based on the verification status.
 * It also handles the state of the verification status.
 *
 * @author Yang Ming, James Nguyen
 * @version December 12th, 2023
 */

import React from 'react'
import { useSearchParams } from 'react-router-dom'
import EmployeeVerifyForm from './components/EmployeeVerifyForm'
import EmployeeVerifyResult from './components/EmployeeVerifyResult'
import { useQuery } from '@tanstack/react-query'
import Loading from '@components/Loading'
import { getEmployeeVerify } from '@services/api.business.service'

export default function EmployeeVerify() {
    const [searchParams] = useSearchParams()
    const employee_id = searchParams.get('employee_id')
    const business_id = searchParams.get('business_id')
    const [isVerified, setIsVerified] = React.useState(false)
    const {
        isPending,
        error,
        data: employee,
    } = useQuery({
        queryKey: ['employeeVerify'],
        queryFn: () => {
            return getEmployeeVerify(employee_id, business_id)
        },
    })

    if (isPending) return <Loading />
    console.log('employee', employee)
    if (error) return 'Employee Verify Page: An error has occurred: ' + error.message

    if (employee.data?.isVerified || isVerified) {
        return <EmployeeVerifyResult employee={employee.data} />
    }

    return (
        <EmployeeVerifyForm employee={employee.data} setIsVerified={setIsVerified} />
    )
}
