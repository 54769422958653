
import React from 'react'
import BenefitItem from './BenefitItem'
import MyImage from 'components/MyImage'

const customerBenefits = [
  'Discover local shops',
  'Support your community',
  'Compare nearby products',
  'Shop local conveniently',
  'Follow your favourite stores'
]

const retailerBenefits = [
  'Showcase your products, brands and departments',
  'Reach more shoppers & boost sales',
  'Offer convenient item reservation options',
  'Special features on the Fitzba homepage and social media',
  'Reports & insights'
]

export default function WhyFitzbaSectionNew({
  isMobile
}) {
  return (

    <section className='bg-white my-2 smsize:my-3 smsize:mb-0'>
      <div className='m-auto w-[1200px] smsize:w-full text-left flex gap-3 items-center smsize:p-5 smsize:flex-col'>
        <div className='w-full flex flex-col gap-3'>
          <h2 className='text-[48px] font-semibold uppercase smsize:text-center smsize:text-[35px]'>Why{' '}
            <span className='text-[#E4B456]'>Fitzba</span>
          </h2>
          <div className='flex gap-7 smsize:flex-col smsize:w-full'>
            <BenefitItem title='As a consumer' benefits={customerBenefits} />
            <BenefitItem title='As a retailer' benefits={retailerBenefits} />
          </div>
        </div>
        <div className='pt-[20px] smsize:pt-0 text-center smsize:hidden'>
          <MyImage src={`/images/benefit-phone.webp`} alt='Why Fitzba?' />
        </div>
      </div>
    </section>
  )
}
