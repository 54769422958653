/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * userReducer.js - reducer for user info
 * @author Yang Ming, James Nguyen
 * @version March 7th, 2023
 */
import { createSlice } from '@reduxjs/toolkit'
const ObjectId = require('bson-objectid')

export const initialLocation = {
    city: 'Cochrane',
    province: 'AB',
    country: 'CA',
    lat: 51.19206635900946,
    lng: -114.46633603302544,
    postalCode: 'T4C2B2',
    isLoaded: false
}

const initFilterState = {
    location: false,
    category: false,
    store: false,
    brand: false,
    payment: false,
    price: false,
    condition: false
}

export const initialState = {
    setting: null,
    userinfo: null,
    isRefreshUserinfo: false,
    business_id: null,
    isBusiness: false,
    background: {
        isOpen: true,
        image: ''
    },
    tier: null,
    location: initialLocation,
    itemsFilter: {
        title: '',
        perPage: 5,
        currentPage: 1,
    },
    itemsList: {
        status: 'success',
        total: 1,
        totalPage: 1,
        currentPage: 1,
        perPage: 10,
        data: [],
    },
    isLoadWishlist: true,
    wishlist: [],
    wishlistIds: [],
    cartList: null,
    shoppingCart: {
        isRefresh: true,
        data: null,
        tmp_id: null,
        isShowSideBar: false
    },
    subscription: null,
    filterState: initFilterState,
    searchHistory: [],
}

const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        logout: (state) => {
            state.userinfo = null
            state.isBusiness = false
            // remove shopping cart
            state.shoppingCart = {
                isRefresh: true,
                data: null
            }
            localStorage.removeItem('token')
            localStorage.removeItem('userinfo')
        },
        setSetting: (state, action) => {
            state.setting = action.payload
        },
        //sets whether the user is a business or not
        setBusiness: (state, action) => {
            state.isBusiness = action.payload
        },
        setBusinessId: (state, action) => {
            state.business_id = action.payload
            state.isBusiness = true
        },
        //Sets the business users tier level
        setTier: (state, action) => {
            state.tier = action.payload
        },
        setUserinfo: (state, action) => {
            // console.log(action.payload)
            state.userinfo = action.payload
            state.isBusiness = action.payload?.isBusiness || false
            localStorage.setItem('token', action.payload.token)
            localStorage.setItem('userinfo', JSON.stringify(action.payload))
            if (action.payload?.searchHistory) {
                state.searchHistory = action.payload.searchHistory
            }
            state.shoppingCart = {
                isRefresh: true,
            }
            state.isRefreshUserinfo = false
        },
        setBackground: (state, action) => {
            state.background = action.payload
            if (!action.payload.isOpen)
                state.background.image = ''
            localStorage.setItem('background', JSON.stringify(state.background))
        },
        setLocation: (state, action) => {
            // console.log('setLocation is run')
            state.location = {
                ...action.payload,
                isLoaded: true
            }
            localStorage.setItem('location', JSON.stringify(state.location))
        },
        setItemsFilter: (state, action) => {
            state.itemsFilter = {
                ...state.itemsFilter,
                ...action.payload
            }
        },
        setItemsList: (state, action) => {
            state.itemsList = action.payload
        },
        setWishlistIds: (state, action) => {
            state.wishlistIds = action.payload
            state.isLoadWishlist = false
        },
        setWishlist: (state, action) => {
            state.wishlist = action.payload
        },
        setIsLoadWishlist: (state, action) => {
            state.isLoadWishlist = action.payload
        },
        setCartData: (state, action) => {
            state.shoppingCart.data = action.payload
            // console.log('setCartData', action.payload)
            if (action.payload?.totalItems > 0) {
                state.shoppingCart.isShowSideBar = true
            }
        },
        setCartRefresh: (state, action) => {
            state.shoppingCart.isRefresh = action.payload
        },
        setCartTmpItems: (state, action) => {
            // save into local storage
            const tmpId = localStorage.getItem('tmp_id')
            if (tmpId) {
                state.shoppingCart.tmp_id = tmpId
            } else {
                // generate a new tmpId
                const tmpId = ObjectId().toHexString()
                localStorage.setItem('tmp_id', tmpId)
                state.shoppingCart.tmp_id = tmpId
            }
        },
        setShowSideBar: (state, action) => {
            state.shoppingCart.isShowSideBar = action.payload
        },
        setSubscription: (state, action) => {
            state.subscription = action.payload
        },
        setFilterState: (state, action) => {
            if (action.payload === null) {
                state.filterState = initFilterState
                return
            }
            state.filterState[action.payload.type] = action.payload.value
        },
        setSearchHistory: (state, action) => {
            if (action.payload === null) {
                state.searchHistory = []
                localStorage.removeItem('searchHistory')
                return
            }
            // load an array
            if (typeof action.payload === 'string') {
                const arr = action.payload.split(',')
                // remove empty string and duplicates
                state.searchHistory = [...new Set(arr.filter(item => item !== ''))]
                // state.searchHistory = action.payload.split(',')
                return
            }
            if (action.payload?.type === 'add') {
                if (!state.searchHistory.includes(action.payload.value))
                    state.searchHistory.push(action.payload.value)
            } else if (action.payload?.type === 'remove') {
                if (state.searchHistory.includes(action.payload.value))
                    state.searchHistory = state.searchHistory.filter(
                        item => item !== action.payload.value,
                    )
            }
            console.log('setSearchHistory', state.searchHistory)
            // save the first 10 keywords to local storage
            localStorage.setItem('searchHistory', state.searchHistory?.slice(0, 10)?.join(','))
        },
        setIsRefreshUserinfo: (state, action) => {
            state.isRefreshUserinfo = action.payload
        }
    }
})

export const {
    logout, setUserinfo, setBackground, setLocation,
    setItemsFilter, setItemsList, setFavList, setBusiness, setCartData,
    setCartRefresh, setTier,
    setCartTmpItems, setWishlistIds, setWishlist, setIsLoadWishlist,
    setShowSideBar, setSubscription, setFilterState, setBusinessId, setSetting,
    setSearchHistory, setIsRefreshUserinfo
} = userSlice.actions

export default userSlice.reducer