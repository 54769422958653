/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * StoreOpenHours is a functional React component that displays the opening hours of a store.
 * The opening hours are passed as a prop to the component.
 * Each opening hour includes the start day, end day (if any), opening time, closing time, and whether the store is closed.
 *
 * @author Yang Ming, James Nguyen
 * @version January 26th, 2024
 */

import React from 'react'

export default function StoreOpenHours({
    openHours
}) {
    // get current day of week
    const currentDay = new Date().getDay()
    // get short day name
    const shortDay = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    const shortDayName = shortDay[currentDay]
    console.log('currentDay', currentDay)
    const dayWidth = openHours?.find(hour => hour.dayEnd) ? 'w-[80px]' : 'w-[40px]'
    return (
        <span>
            {openHours?.filter(hour => hour?.isActivated)?.map((hour, index) => (
                <div className={`text-[14px] font-light text-left flex ${shortDayName === hour?.dayStart ? 'font-bold' : 'font-medium'}`} key={index}>
                    <label className={`block ${dayWidth} font-courier uppercase`}>{hour.dayStart}{hour.dayEnd ? '-' + hour.dayEnd : ''}:</label>{' '}
                    {hour.isClosed ? 'Closed' : hour.openAt?.slice(0, 5) + ' - ' + hour.closeAt.slice(0, 5)}
                </div>
            ))}
        </span>
    )
}
