/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * Navigation is a component that displays a navigation bar with links.
 * It uses the Link component from 'react-router-dom' for navigation.
 * Props include title, titleLink, and subTitle. The title is a link to titleLink.
 * If subTitle is provided, it is displayed after the title.
 *
 * @author Dean Yang
 * @version November 15th, 2023 
 */

import React from 'react'
import { Link } from 'react-router-dom'

export default function Navigation({
    title,
    titleLink,
    subTitle
}) {
    return (
        <div className='w-full'>
            <div className='mx-auto my-0 w-full p-4 flex items-center gap-2 list-none phone:w-[1280px]'>
                <li><Link to='/'>Home</Link></li>
                <li>/</li>
                <li><Link to={titleLink}>{title}</Link></li>
                {subTitle && <li className='hidden phone:block'> / {subTitle}</li>}
            </div>
        </div>
    )
}
