/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * StoreProfileBrand defines the brand section of the store profile. It is a sub-component of ProfileView and handles retreiving, displaying and interactions with the brands of a store.
 * Displays Brands as buttons within a container 
 * @author Yang Ming, James Nguyen
 * @version January 10th, 2024 
 */

import React from 'react'
import { setFilterBrand } from '@reducers/contentReducer'
import { Modal } from 'antd'

/**
 * A component for the brand section of a store profile.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.dispatch - The dispatch function from the Redux store.
 * @param {Function} props.setFilterBrandProfile - The function to set the brand filter.
 * @param {Object} props.itemsFilter - The current state of the items filter.
 * @param {Array} props.brands - The list of brands.
 */

export default function StoreProfileBrand({
  isOpen,
  setIsOpen,
  dispatch,
  onClickBrandItem,
  itemsFilter,
  brands,
}) {

  if (!brands || brands.length === 0) return null
  // sort by first letter ignore cases
  brands.sort((a, b) => {
    if (a.label?.toLowerCase() < b.label?.toLowerCase()) {
      return -1
    }
    if (a.label?.toLowerCase() > b.label?.toLowerCase()) {
      return 1
    }
    return 0
  })

  function onClick() {
    setIsOpen(!isOpen)
  }

  function onClickClear() {
    dispatch(setFilterBrand(null))
  }

  return (
    <Modal
      title="Brands"
      open={isOpen}
      onCancel={onClick}
      maskClosable={true}
      destroyOnClose={true}
      footer={null}
      width={800}
    >
      <div className='max-h-[600px] show-scroll grid grid-cols-2 gap-4 overflow-y-scroll rounded-b-xl p-[10px] smsize:grid-cols-1'>
        {brands.map((brand, index) => (
          <div
            key={index}
            className={`flex h-[31px] w-full items-center truncate rounded-md border cursor-pointer 
                ${itemsFilter.brands.find(id => id === brand._id) ? 'border-[#E4B456]' : 'border-[#D9D9D9]'} 
                px-2 text-[13px] hover:bg-[#D9D9D9]`}
            onClick={() => {
              onClickBrandItem(brand._id)
            }}
          >
            <span className='w-full truncate'>{brand.label}</span>
          </div>
        ))}
      </div>
    </Modal>
  )
}
