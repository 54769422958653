/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * The DemoBooking component renders a booking form for a demo.
 * It includes a form (RetailRegisterStep5) to collect user information for booking a demo.
 * It also includes a "prev" function to navigate back to the retail page.
 * The component also displays a Fitzba logo and a contact link for users who have questions.
 *
 * @author Yang Ming, James Nguyen
 * @version May 09, 2023 
 */
import React, { useState } from 'react'
// import './Registerv2.css'
import { Link, useNavigate } from 'react-router-dom'
import RetailRegisterStep5 from '../user/components/retail/RetailRegisterStep5'

// import RetailRegisterStep5 from '../../../user/components/retail/RetailRegisterStep5'
// import RetailRegisterStep5 from './components/retail/RetailRegisterStep5'

/**
 * DemoBooking is a functional React component that renders a booking form for a demo.
 * It includes a form (RetailRegisterStep5) to collect user information for booking a demo.
 * It also includes a "prev" function to navigate back to the retail page.
 * 
 * @returns {JSX.Element} The JSX code for the DemoBooking component.
 */

export default function DemoBooking() {
  // Initialize registration steps
  const navigate = useNavigate()
  const [formData, setFormData] = useState({})
  // prev is a function that navigates back to the retail page when called.
  const prev = () => {
    navigate('/retail')
  }


  // Handle form submission

  return (
    <div className=' flex h-fit scale-90 lg:h-[850px] xl:scale-100'>
      <div className='hidden flex-1 flex-col items-center justify-center bg-fitzba-off-black-static xl:flex'>
        <img
          src='/fitzba-ICON-WORD-WHITE.png'
          onClick={() => {navigate('/')}}
          alt='Fitzba Logo'
          className='md:w-[200px] lg:w-[400px]'
        />
        <div className='mt-[122px] w-[276px] text-center text-[20px] font-light text-white'>
          Have questions?{' '}
          <Link
            to='/contact'
            className=' text-fitzba-bright-gold-static underline'
          >
            Contact us
          </Link>{' '}
          and we will get back to you with answers
        </div>
      </div>
      <div className='register-wrapper flex-1'>
        <div className='my-[70px] flex h-full w-full flex-col items-center justify-center p-2 lg:my-0 xl:flex-row'>
          <div className='block xl:hidden'>
            <img src='/FITBIZ-WORD-ICON.png' width={400} alt='' />
          </div>
          <div className='register-main-form'>
            <>
              <h3 className='text-[38px] font-medium'>
                HAVE SOME CONCERNS BEFORE JOINING?{' '}
              </h3>

              <h4 className='text-[30px] font-light'>Book a demo</h4>
              <RetailRegisterStep5
                formData={formData}
                setFormData={setFormData}
                prev={prev}
              />
            </>
          </div>
        </div>
      </div>
    </div>
  )
}
