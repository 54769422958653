//TODO: Discuss with yang
import React, { useState, useEffect } from 'react'
// import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai'
// import { BiSearchAlt2, BiXCircle } from 'react-icons/bi'
import { debounce } from 'lodash'
import FilterSideSearchBar from './FilterSideSearchBar'
import FilterSideSectionHeader from './FilterSideSectionHeader'
import { setFilterState } from '@reducers/userReducer'
import { useSelector } from 'react-redux'

const DEFAULT_SHOW_NUMBER = 10

const collator = new Intl.Collator("en", {
  numeric: true,
  sensitivity: "base",
})

export default function FilterSideStore({
  itemsFilter,
  dispatch,
  filterList,
  setFilterStore,
  // isMobile,
}) {

  const isOpen = useSelector(state => state.user.filterState.store)
  const setIsOpen = (value) => {
    dispatch(setFilterState({ type: 'store', value }))
  }

  const [input, setInput] = useState('')
  // const [isOpen, setIsOpen] = useState(false)
  const [showNumber, setShowNumber] = useState(filterList?.stores.length)
  const [stores, setStores] = useState(filterList?.stores)
  const [allCheckbox, setAllCheckbox] = useState(false)

  useEffect(() => {
    setStores(filterList?.stores)
    setShowNumber(filterList?.stores.length)
  }, [filterList?.stores])

  function onClickTitle() {
    setIsOpen(!isOpen)
    defaultSetting()
  }

  function defaultSetting() {
    setInput('')
    setShowNumber(filterList?.stores.length)
    setStores(filterList?.stores)
  }

  const handleChange = debounce((value) => {
    // console.log('onSearch', value)
    //search function
    const findList = filterList?.stores.filter(item => {
      // startswith or each words startswith if the name is contain splace
      if (!item.name)
        return false
      const words = item.name.indexOf(' ') !== -1 ? item.name.toLowerCase().split(' ') : [item.name.toLowerCase()]
      const inputLower = value.toLowerCase()
      const result = words.find(item => item.startsWith(inputLower))
      // console.log('result', result)
      return result
    }
    )
    setStores(findList)
  }, 500)

  const onSearch = (e) => {
    const value = e.target.value
    setInput(value)
    if (!value) {
      defaultSetting()
      return
    }
    handleChange(value)
  }

  function onClickAllCheckbox(checked) {
    console.log('onClickAllCheckbox', checked)
    // const checked = e.target.checked
    if (checked) {
      // select all
      const ids = filterList?.stores.map(item => item.id)
      dispatch(setFilterStore({ id: ids, checked: true }))
    } else {
      // deselect all
      // const ids = filterList?.stores.map(item => item.id)
      dispatch(setFilterStore(null))
    }
    setAllCheckbox(checked)
  }

  if (filterList?.stores?.length === 0) return null

  // sort by first letter
  let storesList = stores?.filter(item => item.name).sort((a, b) => collator.compare(a.name, b.name))
  // console.log('storesList', storesList)

  return (
    <div className='filter-item'>
      <FilterSideSectionHeader
        title='Store'
        isOpen={isOpen}
        onClickTitle={onClickTitle}
        isHighlight={itemsFilter?.stores?.length > 0}
      />
      <FilterSideSearchBar
        input={input}
        isOpen={isOpen}
        onSearch={onSearch}
        onClear={defaultSetting}
        allCheckbox={allCheckbox}
        onSelectAll={() => onClickAllCheckbox(!allCheckbox)}
        onClearAll={() => onClickAllCheckbox(false)}
      />
      <div className='filter-item-list smsize:gap-2 smsize:text-[16px]' style={{ display: isOpen ? 'flex' : 'none' }}>
        {storesList?.slice(0, showNumber).map((store, index) => (
          <div key={index} className='filter-checkbox-item'>
            <label>
              <input
                value={store?.id}
                type='checkbox'
                className='custom-checkbox'
                checked={itemsFilter?.stores.includes(store?.id)}
                onChange={e => {
                  dispatch(
                    setFilterStore({
                      id: store?.id,
                      checked: e.target.checked,
                    }),
                  )
                }}
              />
              <span className='truncate'>{store?.name}</span>
            </label>
          </div>
        ))}
      </div>
      {((input !== '' && storesList.length > DEFAULT_SHOW_NUMBER) || (input === '' && filterList?.stores.length > DEFAULT_SHOW_NUMBER)) &&
        <div className='filter-checkbox-item underline' style={{ display: isOpen ? 'flex' : 'none' }}>
          <span onClick={() => setShowNumber(showNumber === DEFAULT_SHOW_NUMBER ? stores.length : DEFAULT_SHOW_NUMBER)}>
            Show {showNumber === DEFAULT_SHOW_NUMBER ? 'More' : 'Less'} Stores ({stores.length})
          </span>
        </div>
      }
    </div>
  )
}
