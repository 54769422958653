/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * This file contains the LoadingSpin component, which is responsible for displaying a loading spinner.
 * The component uses Ant Design's Spin component to display the spinner.
 * The size and text of the spinner can be customized through the component's props.
 * 
 * @param {string} size - The size of the spinner. Defaults to 'large' if not provided.
 * @param {string} text - The text to display below the spinner. Defaults to 'Loading' if not provided.
 * @returns {JSX.Element} The LoadingSpin component.
 *
 * @author Dean Yang
 * @version January 4th, 2024
 */


import React from 'react'
import { Spin } from 'antd'

export default function LoadingSpin({ size, text }) {
    return (
        <div className='flex items-center justify-center flex-col'>
            <Spin size={size || 'large'} />
            <span className="loading-content">{text || 'Loading'}</span>
        </div>
    )
}
