import React, { useState } from 'react'

export default function ItemVariants({
    setPrices,
    options,
    variants,
    imageIndex,
    setImageIndex,
    setSelectedVariant
}) {

    const [selectOption, setSelectOption] = useState({
        0: 0,
        1: 0,
        2: 0,
    })

    const getVariant = (mainIndex, optionName, optionValue, subIndex) => {
        // console.log('name: ', optionName)
        // console.log('value: ', optionValue)
        // console.log('index: ', subIndex)
        const option1 = options[0]
        const option2 = options[1]
        const option3 = options[2]
        let findTitle = ''
        if (option1) {
            findTitle = option1?.values[mainIndex === 0 ? subIndex : selectOption[0]]
        }
        if (option2) {
            findTitle += ` / ${option2?.values[mainIndex === 1 ? subIndex : selectOption[1]]}`
        }
        if (option3) {
            findTitle += ` / ${option3?.values[mainIndex === 2 ? subIndex : selectOption[2]]}`
        }
        const variant = variants?.find(variant => variant?.title === findTitle)
        return variant
    }

    const onClickOption = (mainIndex, optionName, optionValue, subIndex) => {
        const variant = getVariant(mainIndex, optionName, optionValue, subIndex)
        console.log('variant: ', variant)
        if (!variant || variant?.quantity === 0)
            return
        setPrices({
            price: variant?.price,
            compare_at_price: variant?.compare_at_price,
        })
        setSelectedVariant(variant)
        setImageIndex(variant.image_id)
        setSelectOption({
            ...selectOption,
            [mainIndex]: subIndex
        })
    }

    return (
        <div className='pb-[30px] flex flex-col gap-3'>
            {options?.map((option, index1) => (
                <div className='flex flex-col gap-1' key={index1}>
                    <h3 className='text-[18px] uppercase font-[400]'>
                        {option?.name}
                    </h3>
                    <div className='flex gap-3 flex-wrap'>
                        {option?.values?.map((value, index2) => {
                            const isSelected = selectOption[index1] === index2
                            const variant = getVariant(index1, option?.name, value, index2)
                            const isDisabled = !variant || variant?.quantity === 0
                            return (
                                <button
                                    key={index2}
                                    className={`${isSelected && 'border-[#000]'} 
                      ${isDisabled && 'bg-[#eee] text-[#999] line-through hover:border-[#eee]'}
                      border-[2px] rounded-[5px] px-4 py-1 font-[300] cursor-pointer hover:border-[#000] whitespace-nowrap`}
                                    onClick={() => {
                                        onClickOption(index1, option?.name, value, index2)
                                    }}
                                    disabled={isDisabled}
                                >
                                    {value}
                                </button>
                            )
                        })}
                    </div>
                </div>
            ))}
        </div>
    )
}
