import React from 'react'

export default function CartButton({
  onClick,
  disabled = false,
  text,
  bgColor,
  textColor,
  borderColor,
}) {
  console.log('CartButton', disabled)
  let hoverBgColor = textColor
  let hoverTextColor = bgColor
  if (disabled) {
    bgColor = 'gray-300'
    textColor = 'white'
    hoverBgColor = 'gray-300'
    hoverTextColor = 'white'
    borderColor = 'gray-300'
  }
  return (
    <button
      enabled={(!disabled).toString()}
      onClick={onClick}
      className={`flex h-[35px] w-[140px] cursor-pointer items-center justify-center rounded-full border-2 border-${borderColor} bg-${bgColor} uppercase text-${textColor} hover:bg-${hoverBgColor} hover:text-${hoverTextColor}`}
    >
      {text}
    </button>
  )
}
