/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * StoreGoogleMap is a functional React component that displays a Google Map with a marker at the location of a store.
 * The map is displayed in a popover that opens when the user clicks on the provided content.
 * The popover also includes a link to get directions to the store on Google Maps.
 * The map and the link use the latitude and longitude of the store provided in the business prop.
 * The popover is initially open if the pickup query parameter is present in the URL.
 *
 * @author Yang Ming, James Nguyen
 * @version January 22nd, 2024
 */

import React from 'react'
import './StoreGoogleMap.css'
import { Popover } from 'antd'
import { GoogleMap, Marker } from "@react-google-maps/api"
import { useSearchParams } from 'react-router-dom'

export default function StoreGoogleMap({
    business,
    content
}) {

    const [searchParams] = useSearchParams()
    const isPickup = searchParams.get('pickup') === 'true' ? true : false
    // console.log('isPickup', isPickup)
    const [isOpen, setIsOpen] = React.useState(isPickup)
    const location = {
        lat: business?.lat,
        lng: business?.lng
    }
    return (
        <Popover
            placement='right'
            // title='Location'
            open={isOpen}
            onOpenChange={setIsOpen}
            overlayClassName={'store-google-map-popover'}
            overlayInnerStyle={{
                padding: '0px',
                borderRadius: '22px',
                zIndex: 10
            }}
            content={
                <div className='flex flex-col items-center justify-center gap-2' >
                    <GoogleMap
                        mapContainerStyle={{
                            width: "300px",
                            height: "200px",
                            borderRadius: '22px 22px 0px 0px',
                            borderBottom: '2px solid #CDCDCD'
                        }}
                        center={location}
                        zoom={15}
                    // onClick={() => { }}
                    >
                        {location && <Marker position={location} label={{ text: `${business?.name}`, className: 'marker-label' }} />}
                    </GoogleMap>
                    <a href={business?.mapUrl ? business.mapUrl : `https://maps.google.com/?q=${business?.lat},${business?.lng}`} alt={business?.name} target='_blank' rel="noreferrer"
                        className='text-[#0B6186] font-light underline text-center mb-3'>
                        Get Directions
                    </a>
                </div>
            }
            trigger='click'
        >
            {content}
        </Popover>
    )
}
