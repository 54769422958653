import React from 'react'
import { getDistance2 } from '@utils/Helper'
import CartItemCard from './CartItemCard'

export default function CartItemStoreReserve({
  item,
  location,
  isMobile,
  handleDeleteCart,
  handleUpdateCart,
}) {
  const selectedItems = item?.carts?.filter(item => item?.isSelected)
  console.log('selectedItems', selectedItems)

  return (
    <div className='cart-item'>
      <div className='flex w-full flex-row items-center justify-between bg-[#f5f5f5] p-[10px]'>
        <div className='cart-item-store'>
          <h3>{item?.business?.name}</h3>
          <p>
            {item?.business?.address}, {item?.business?.city},{' '}
            {item?.business?.province} {item?.business?.postalCode},{' '}
            {getDistance2(location, {
              lat: item?.business?.lat,
              lng: item?.business?.lng,
            })}
          </p>
        </div>
      </div>
      <div className='cart-item-list'>
        {item?.carts?.map((item, index) => (
          <CartItemCard
            key={index}
            item={item}
            handleUpdateCart={handleUpdateCart}
            handleDeleteCart={handleDeleteCart}
          />
        ))}
      </div>
    </div>
  )
}
