import React from 'react'
import { formatCurrency } from '@utils/Helper'

export default function ReserveItemList({
    itemList,
}) {
    return (
        <div className='w-full show-scroll max-h-[200px] overflow-y-scroll flex flex-col gap-3'>
            {itemList?.map((item, index) => {
                return <div
                    key={index}
                    className='flex w-full grid-cols-4 justify-center gap-3 text-[16px]'
                >
                    <div className='rounded-lg border border-fitzba-darkcoal-static'>
                        <img
                            alt={item?.name}
                            src={item?.image}
                            style={{
                                width: '100%',
                                maxHeight: '100px',
                                objectFit: 'contain',
                                borderRadius: '10px',
                            }}
                        />
                    </div>
                    <div className='w-full flex h-full flex-col justify-between gap-1'>
                        <h3 className='text-left py-0 my-0'>{item?.name}</h3>
                        {item?.brand && <span>{item.brand}</span>}
                        {item?.variant_title && <span className='text-[12px] text-[#666]'>Option: {item?.variant_title}</span>}
                        <div className='w-full flex justify-between items-center'>
                            <span>Qty: {item?.quantity}</span>
                            <span>{formatCurrency(item?.price)}/ea</span>
                        </div>
                    </div>
                </div>
            })}
        </div>
    )
}
