/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * This file defines a component for a section header in a sidebar filter.
 * 
 * @author Yang Ming
 * @version February 12th, 2024 
 */

import React from 'react'
import { SlArrowDown, SlArrowUp } from 'react-icons/sl'

/**
 * A component for a section header in a sidebar filter.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.title - The title of the section.
 * @param {boolean} props.isOpen - Whether the section is open.
 * @param {Function} props.onClickTitle - The function to call when the title is clicked.
 * @param {boolean} props.isHighlight - Whether the title is highlighted.
 */
export default function FilterSideSectionHeader({
    title,
    isOpen,
    onClickTitle,
    isHighlight,
    color = '#FFF',
    highlightColor = '#e4b456'
}) {
    return (
        <div className='filter-item-header'>
            <div className='filter-item-header-title'>
                <h4 onClick={onClickTitle} style={{ color: isHighlight ? highlightColor : color }} className='cursor-pointer'>{title}</h4>
            </div>
            {isOpen ? <SlArrowUp size={16} onClick={onClickTitle} className='cursor-pointer' style={{ color: isHighlight ? highlightColor : color }}/>
                : <SlArrowDown size={16} onClick={onClickTitle} className='cursor-pointer' style={{ color: isHighlight ? highlightColor : color }}/>
            }
        </div>
    )
}
