/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * FilterSideSelectedMobile.js implements functionality for displaying selected filter options at the top of the screen
 * For mobile devices 
 * 
 * @author Yang Ming
 * @version February 12th, 2024 
 */

import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
// import { AiOutlineClose } from 'react-icons/ai'
import {
  initItemsFilter,
  setFilterPrice,
  setFilterDistance,
  setFilterBrand,
  setFilterStore,
  setFilterCategory,
  setFilterCategoryList,
  setSearchTitle,
  setSearchInput,
  setFilterDeliveryTypes,
  setFilterCondition,
  setFilterCode
} from '@reducers/contentReducer'
import { getDistance } from '@utils/Helper'
import {
  getGlobalCategorySelected,
  getGlobalCategoryRemoveSelected,
} from '@services/api.globalCategory.service'

import FilterSideSelectedItem from './FilterSideSelectedItem'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'

/**
 * FilterSideSelectedMobile component. This component is responsible for displaying selected items in the menu on mobile devices.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.itemsFilter - The current state of the items filter.
 * @param {Function} props.dispatch - The dispatch function from the Redux store.
 * @param {Object} props.filterList - The list of available filters.
 * @param {boolean} props.isMobile - A flag indicating whether the device is a mobile device.
 */
export default function FilterSideSelectedMobile({
  itemsFilter,
  dispatch,
  filterList,
  isMobile
}) {
  // const categoryList = useSelector(state => state.content.categoryList)
  const [displayCategorySelected, setDisplayCategorySelected] = useState([])
  const navigate = useNavigate()
  const selectedListRef = React.useRef(null)
  const location = useSelector(state => state.user.location)

  /**
   * Scrolls the selected items list by a given step.
   *
   * @param {number} step - The amount to scroll by.
   */
  const onClickScrollBtn = step => {
    console.log('onClickScrollBtn', step)
    if (!selectedListRef.current) return
    console.log('onClickScrollBtn 111', step)
    let scrollAmount = 0
    const slideTimer = setInterval(() => {
      selectedListRef.current.scrollLeft += step > 0 ? 100 : -100
      scrollAmount += 100
      if (scrollAmount >= Math.abs(step)) {
        clearInterval(slideTimer)
      }
    }, 50)
  }

  useEffect(() => {
    if (itemsFilter.categories.length === 1 && itemsFilter.categories[0] === 'All Categories') {
      return
    }
    const fetchSelectedCategory = async () => {
      const res = await getGlobalCategorySelected({
        categories: itemsFilter.categories,
      })
      setDisplayCategorySelected(res.data)
    }
    fetchSelectedCategory()
  }, [itemsFilter.categories])

  const onClearAll = () => {
    dispatch(setSearchInput(''))
    dispatch(initItemsFilter(null))
    navigate('/search')
  }

  const onClearPrice = () => {
    dispatch(
      setFilterPrice({
        priceMin: -1,
        priceMax: -1,
      }),
    )
  }

  const onClearDistance = () => {
    dispatch(setFilterDistance(-1))
  }

  const onClearBrand = id => {
    dispatch(
      setFilterBrand({
        id: id,
        checked: false,
      }),
    )
  }

  const getBrandById = id => {
    console.log('getBrandById', id)
    console.log('filterList.brands', filterList.brands)
    const item = filterList.brands.find(item => item.id === id)
    if (!item) return
    return (
      <FilterSideSelectedItem
        key={item.id}
        label={item.name}
        value={item.id}
        onClickRemove={() => onClearBrand(item.id)}
      />
    )
  }

  const onClearStore = id => {
    dispatch(
      setFilterStore({
        id: id,
        checked: false,
      }),
    )
  }

  const getStoreById = id => {
    const item = filterList.stores.find(item => item.id === id)
    if (!item) return null
    return (
      <FilterSideSelectedItem
        key={item.id}
        label={item.name}
        value={item.id}
        onClickRemove={() => onClearStore(item.id)}
      />
    )
  }

  const getCategoryById = id => {
    const item = filterList.stores.find(item => item.id === id)
    if (!item) return null
    return (
      <FilterSideSelectedItem
        key={id}
        label={item.name}
        value={id}
        onClickRemove={() => onClearCategory(id)}
      />
    )
  }

  // const onClearCategoryNew = async id => {
  //   if (id === 'All Categories') {
  //     dispatch(setFilterCategoryList([]))
  //     return
  //   }
  //   dispatch(
  //     setFilterCategory({
  //       id: id,
  //       checked: false,
  //     }),
  //   )
  // }

  const onClearCategory = async id => {
    if (id === 'All Categories') {
      dispatch(setFilterCategoryList([]))
      return
    }
    const result = await getGlobalCategoryRemoveSelected({
      remove_id: id,
      categories: itemsFilter.categories,
    })
    console.log('onClearCategory', result)
    dispatch(setFilterCategoryList(result.data))
  }

  if (
    itemsFilter.title === '' &&
    itemsFilter.code === '' &&
    itemsFilter.priceMin === -1 &&
    itemsFilter.priceMax === -1 &&
    itemsFilter.stores.length === 0 &&
    itemsFilter.brands.length === 0 &&
    itemsFilter.distance === -1 &&
    itemsFilter.categories.length === 0 &&
    itemsFilter.deliveryTypes.length === 0 &&
    itemsFilter.condition.length === 0
  )
    return null

  // get item-content width by id items-content
  const itemContent = document.getElementById('items-content')
  const itemContentWidth = itemContent?.offsetWidth
  // console.log('itemContentWidth', itemContentWidth)

  return (
    <>
      <div className={` relative filter-side-selected-mobile flex`}
        style={{
          width: itemContentWidth || '100%'
        }}
      >
        {!isMobile && (
          <>
            <span
              className='scroll-left top-0'
              onClick={() => onClickScrollBtn(-500)}
            >
              <AiOutlineLeft />
            </span>
            <span
              className='scroll-right top-0'
              onClick={() => onClickScrollBtn(500)}
            >
              <AiOutlineRight />
            </span>
          </>
        )}
        <div className={`filter-selected-clear-all ${!isMobile && 'ml-3'}`} onClick={onClearAll}>
          Clear All
        </div>
        <div className='w-full flex gap-2 flex-nowrap overflow-x-auto' ref={selectedListRef}>
          {itemsFilter.title !== '' && (
            <FilterSideSelectedItem
              label={itemsFilter.title}
              value={itemsFilter.title}
              onClickRemove={() => dispatch(setSearchTitle(''))}
            />
          )}
          {/* on-sale */}
          {itemsFilter.code !== '' && (
            <FilterSideSelectedItem
              label={itemsFilter.code}
              value={itemsFilter.code}
              onClickRemove={() => dispatch(setFilterCode(''))}
            />
          )}
          {displayCategorySelected &&
            (itemsFilter.categories.length === 1 && itemsFilter.categories[0] === 'All Categories')
            ? <FilterSideSelectedItem
              key={'All Categories'}
              label={'All Categories'}
              value={'All Categories'}
              onClickRemove={() => onClearCategory('All Categories')}
            />
            : displayCategorySelected.map(item => (
              <FilterSideSelectedItem
                key={item.id}
                label={item.name}
                value={item.id}
                onClickRemove={() => onClearCategory(item.id)}
              />
            ))}
          {itemsFilter.priceMin !== -1 && itemsFilter.priceMax !== -1 && (
            <FilterSideSelectedItem
              label={`$${itemsFilter.priceMin} - $${itemsFilter.priceMax}`}
              value={''}
              onClickRemove={onClearPrice}
            />
          )}
          {itemsFilter.priceMin !== -1 && itemsFilter.priceMax === -1 && (
            <FilterSideSelectedItem
              label={`Min $${itemsFilter.priceMin}`}
              value={itemsFilter.priceMin}
              onClickRemove={onClearPrice}
            />
          )}
          {itemsFilter.priceMin === -1 && itemsFilter.priceMax !== -1 && (
            <FilterSideSelectedItem
              label={`Max $${itemsFilter.priceMax}`}
              value={itemsFilter.priceMax}
              onClickRemove={onClearPrice}
            />
          )}
          {/* {itemsFilter.categories.length > 0 &&
            filterList.categories?.length > 0 &&
            itemsFilter.categories.map(id => (
              getCategoryById(id)
            ))} */}
          {itemsFilter.stores.length > 0 &&
            filterList.stores?.length > 0 &&
            itemsFilter.stores.map(id => (
              getStoreById(id)
            ))}
          {itemsFilter.brands.length > 0 &&
            filterList.brands?.length > 0 &&
            itemsFilter.brands.map(id => (
              getBrandById(id)
            ))}
          {itemsFilter.distance !== -1 && (
            <FilterSideSelectedItem
              label={`Up to ${getDistance(itemsFilter.distance, location?.country === 'US')}`}
              value={itemsFilter.distance}
              onClickRemove={onClearDistance}
            />
          )}
          {itemsFilter.condition.length > 0 &&
            itemsFilter.condition.map((type, index) => (
              <FilterSideSelectedItem
                key={index}
                label={type}
                value={type}
                onClickRemove={() =>
                  dispatch(setFilterCondition({ name: type, checked: false }))
                }
              />
            ))}
          {itemsFilter.deliveryTypes.length > 0 &&
            itemsFilter.deliveryTypes.map((type, index) => (
              <FilterSideSelectedItem
                key={index}
                label={type}
                value={type}
                onClickRemove={() =>
                  dispatch(setFilterDeliveryTypes({ name: type, checked: false }))
                }
              />
            ))}
        </div>
      </div>
    </>
  )
}
