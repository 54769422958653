/*
 * Copyright Fitzba Technologies Inc. 2023

 * RegisterStep1 is a functional React component that renders the first step of the registration process.
 * It uses Ant Design components and provides validation for email, phone number, and password formats.
 * The checkUsernameExist function validates if the email is unique and the onFinish function proceeds to the next step.
 * 
 * @author Yang Ming
 * @version May 09, 2023
 */

import React, { useEffect } from 'react'
import { Form, Input } from 'antd'
// import InputSuffixTip from '../../user/components/InputSuffixTip'
import { VscAccount, VscEye } from 'react-icons/vsc'
import { BiLockAlt } from 'react-icons/bi'
import { RxEyeClosed } from 'react-icons/rx'
// import '../Registerv2.css'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

/**
 * @param formData - Form data object
 * @param setFormData - Function to set form data
 * @param next - Function to proceed to the next step
 * @returns Renders a form for users to enter their email, phone number, password, first name, and last name
 */
export default function RegisterConsumerNew({
  handleSubmit,
}) {
  // Using the  hook from Ant Design's Form component to create a form instance
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const tempUser = useSelector(state => state.temp.userinfo)

  return (
    <Form
      forceRender
      layout='vertical'
      onFinish={async (values) => {
        await handleSubmit(values)
      }}
      scrollToFirstError
      form={form}
      className='w-[340px] text-left'
    >
      <Form.Item
        name='username'
        initialValue={tempUser?.username}
        rules={[
          {
            required: true,
            message: 'Please input your email',
          },
          { min: 10, max: 50 },
        ]}
        style={{
          color: '#fff',
        }}
      >
        <Input
          className='rounded-full border border-black text-base'
          size='large'
          prefix={<VscAccount size={22} />}
          // suffix={<InputSuffixTip text='Must be a valid email' />}
          placeholder='Email'
        />
      </Form.Item>
      <div className='flex justify-between items-center gap-3'>
        <Form.Item
          name='firstName'
          rules={[
            {
              required: true,
              message: 'Input your first name',
              whitespace: true,
            },
            {
              min: 2,
              message: 'At least 2 chars.',
            },
          ]}
        >
          <Input
            className='rounded-full border border-black text-base'
            size='large'
            // suffix={<InputSuffixTip text='First name is required' />}
            placeholder='First name'
          />
        </Form.Item>
        <Form.Item
          name='lastName'
          rules={[
            {
              required: true,
              message: 'Input your last name',
              whitespace: true,
            },
            {
              min: 2,
              message: 'At least 2 chars.',
            },
          ]}
        >
          <Input
            className='rounded-full border border-black text-base'
            size='large'
            // suffix={<InputSuffixTip text='Last name is required' />}
            placeholder='Last name'
          />
        </Form.Item>
      </div>
      <Form.Item
        name='phone'
        rules={[
          {
            required: true,
            message: 'Input your phone number',
            whitespace: true,
          }
        ]}
      >
        <Input
          className='rounded-full border border-black text-base'
          size='large'
          // suffix={<InputSuffixTip text='Last name is required' />}
          placeholder='Phone number'
        />
      </Form.Item>

      <Form.Item
        name='password'
        rules={[
          {
            required: true,
            message: 'Please input your password',
          },
          {
            max: 20,
            min: 6,
            message: 'Password: 6-20 chars.',
          },
        ]}
      >
        <Input.Password
          className='rounded-full border border-black text-base'
          size='large'
          prefix={<BiLockAlt size={22} />}
          iconRender={visible => (visible ? <VscEye /> : <RxEyeClosed />)}
          placeholder='Password'
        />
      </Form.Item>
      <Form.Item
        name='confirmPassword'
        dependencies={['password']}
        // hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve()
              }
              return Promise.reject(new Error('Passwords do not match'))
            },
          }),
        ]}
      >
        <Input.Password
          className='rounded-full border border-black text-base'
          size='large'
          prefix={<BiLockAlt size={22} />}
          iconRender={visible => (visible ? <VscEye /> : <RxEyeClosed />)}
          placeholder='Confirm password'
        />
      </Form.Item>
      <div
        className='mx-auto w-full flex items-center justify-center gap-3'
      >
        <button
          type='button'
          className='flex flex-[1] w-full cursor-pointer justify-center rounded-full border border-black bg-white p-[6px] text-black hover:bg-slate-200'
          onClick={() => { navigate('/login') }}
        >
          BACK
        </button>
        <button
          type='submit'
          className=' flex flex-[2] w-full cursor-pointer justify-center rounded-full border-2 border-fitzba-bright-gold-static bg-fitzba-bright-gold-static p-[6px] text-white hover:bg-white hover:text-fitzba-bright-gold-static'
        >
          SIGN UP
        </button>
      </div>
    </Form>
  )
}
