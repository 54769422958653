import React from 'react'
import { getStoresList } from '@services/api.business.service'
import { useQuery } from '@tanstack/react-query'
import Loading from '@components/Loading'
import StoreListCard from './components/StoreListCard'
import {
    setNewStoreFilter,
    setNewStoreList,
} from '@reducers/contentReducer'
import ShowMore from './components/ShowMore'
import { useDispatch, useSelector } from 'react-redux'
import StoreListFilterSelected from './StoreListFilterSelected'

export default function StoreList({
    location,
}) {
    const dispatch = useDispatch()
    const storeList = useSelector(state => state.content.newStoreList)
    const storeFilter = useSelector(state => state.content.newStoreFilter)

    const {
        isPending,
        // error,
        // data,
    } = useQuery({
        queryKey: ['getStoresList', location?.lat, location?.lng, storeFilter],
        queryFn: async () => {
            const res = await getStoresList({
                lat: location?.lat,
                lng: location?.lng,
                ...storeFilter
            })
            dispatch(setNewStoreList(res))
            return res
        },
    })

    if (isPending)
        return <Loading />

    const onClickStore = (storeid) => {
    }

    const onClickSeemore = () => {
        dispatch(setNewStoreFilter({
            ...storeFilter,
            currentPage: storeFilter.currentPage + 1
        }))
    }

    return (
        <div className='w-full flex flex-col'>
            <StoreListFilterSelected />
            <div className='flex px-10 py-8 gap-7 flex-col smsize:px-5 smsize:py-5'>
                <h1 className='text-[18px] font-normal uppercase'>Stores</h1>
                <div className='flex gap-3 flex-wrap px-5 smsize:px-0 smsize:justify-center smsize:items-center smsize:gap-10'>
                    {storeList?.data?.map((item, index) => {
                        return <div key={index} className='pb-7'>
                            <StoreListCard key={index} item={item} onClickStore={onClickStore} location={location} />
                        </div>
                    })}
                </div>
                {storeList?.data?.length > 0 && storeList.currentPage < storeList.totalPage &&
                    <div className='text-center'>
                        <ShowMore onClick={onClickSeemore} />
                    </div>
                }
            </div>
        </div>
    )
}
