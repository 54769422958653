import React from 'react'
import { actionFavoriteItems } from '@services/api.user.service'
import { setIsRefreshUserinfo } from '@reducers/userReducer'
import { useDispatch } from 'react-redux'
import { DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import { Modal } from 'antd'
const { confirm } = Modal

export default function FavoriteRemoveButton({
    item_id
}) {

    const dispatch = useDispatch()
    const onClick = async () => {
        try {
            await actionFavoriteItems({
                item_id: item_id,
                action: 'remove'
            })
            dispatch(setIsRefreshUserinfo(true))
        } catch (error) {
            console.log(error)
        }
    }

    const showDeleteConfirm = () => {
        confirm({
            title: 'Are you sure delete this favorite item?',
            icon: <ExclamationCircleFilled />,
            content: 'The favorite item will be deleted permanently',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                console.log('OK')
                onClick()
            },
            onCancel() {
                console.log('Cancel')
            },
        })
    }

    return (
        <div className='absolute top-3 right-3 z-[999] cursor-pointer'>
            <DeleteOutlined className='text-red-600' onClick={showDeleteConfirm} size={24} />
        </div>
    )
}
