/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * This module exports a React component named 'ItemDesc'. This component displays the description of a specific item.
 * It includes a 'See More'/'See Less' button that expands/collapses the description. The number of lines displayed when the description is collapsed is customizable.
 * 
 * The component takes three props: 'description', 'className', and 'defaultLines'. 'description' is the description of the item. 'className' is a string of class names to apply to the description.
 * 'defaultLines' is the number of lines to display when the description is collapsed.
 * 
 * @author Yang Ming, James Nguyen
 * @version January 26th, 2024
 */


import React, {useEffect} from 'react'
import { getContentRow } from '@utils/Helper'
import './ItemDesc.css'

/**
 * ItemDesc is a component that displays the description of a specific item.
 * 
 * @param {Object} props - The props object.
 * @param {string} props.description - The description of the item.
 * @param {string} props.className - A string of class names to apply to the description.
 * @param {number} props.defaultLines - The number of lines to display when the description is collapsed.
 * @returns {JSX.Element} The 'ItemDesc' component.
 */
export default function ItemDesc({
    description = 'No Description yet',
    className,
    defaultLines = 10
}) {
    const [rowLines, setRowLines] = React.useState(0)
    const [descExpanded, setDescExpanded] = React.useState(false)
    useEffect(() => {
        // delay 0.5s to get the content row
        setTimeout(() => {
            const content = document.getElementById('content')
            const rowLines = getContentRow(content)
            setRowLines(rowLines)
        }, 500)
    },[])
    return (
        <>
            <p id='content'
                className={`detail-content text-left text-[13px] font-medium text-white lg:text-left ${className}`}
                style={{
                    whiteSpace: descExpanded ? 'normal' : 'none',
                    lineClamp: descExpanded ? 'unset' : defaultLines,
                    WebkitLineClamp: descExpanded ? 'unset' : defaultLines,
                }}
                dangerouslySetInnerHTML={{ __html: description }}>
            </p>
            {
                rowLines >= defaultLines && (
                    <div className='text-right underline text-[13px] cursor-pointer text-[#E4B456]'
                        onClick={() => {
                            setDescExpanded(!descExpanded)
                        }}>
                        {descExpanded ? 'See Less' : 'See More'}
                    </div>
                )

            }
        </>
    )
}
