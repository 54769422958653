/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * This module exports a React component named 'ItemRating'. This component displays the average rating and total count of reviews for a specific item.
 * It includes a row of stars that visually represent the average rating, with full stars representing whole numbers, half stars representing decimals, 
 * and empty stars representing the remainder. The average rating is also displayed as a number.
 * 
 * The component takes three props: 'review', 'isMobile', and 'isShowCount'. 'review' is an object containing the average rating and total count of reviews.
 * 'isMobile' is a boolean indicating whether the device is mobile or not. 'isShowCount' is a boolean indicating whether to display the average rating as a number.
 * 
 * @author Yang Ming, James Nguyen
 * @version January 19th, 2024
 */

import React from 'react'
import { BsStarFill, BsStar, BsStarHalf } from "react-icons/bs"

/**
 * A component that displays the average rating and total count of reviews for a specific item.
 * 
 * @param {Object} props - The props object.
 * @param {Object} props.review - An object containing the average rating and total count of reviews.
 * @param {boolean} props.isMobile - A boolean indicating whether the device is mobile or not.
 * @param {boolean} props.isShowCount - A boolean indicating whether to display the average rating as a number.
 * @returns {JSX.Element} The 'ItemRating' component.
 */
export default function ItemRating({
    review = {
        averageRating: 0,
        totalCount: 0
    },
    isMobile,
    isShowCount = true
}) {
    // if (!review?.totalCount)
    //     return null
    // console.log('review', review)
    let isHafStar = review.totalCount === 0
    return (
        <div className={`flex items-center gap-2 ${isMobile && 'px-5'}`}>
            <div className='flex flex-row items-center gap-[2px] text-[19px] text-[#000]'>
                {[...new Array(5)].map((_, index) => (
                    review?.averageRating >= index + 1 ?
                        <BsStarFill key={index} /> :
                        Math.abs(review?.averageRating - index) >= 0.5 && !isHafStar ?
                            isHafStar = true && <BsStarHalf key={index} /> : <BsStar key={index} />
                ))}
            </div>
            {isShowCount && <h4>{review?.averageRating}</h4>}
        </div>
    )
}
