/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * The last step of the multi-step registeration
 *
 * @author Yang Ming
 * @version May 09, 2023
 */

import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { sendVerificationEmail } from '@services/api.service'
import { useSelector } from 'react-redux'

/**
 * @param username - Username of the user who registered
 * @returns Renders a success screen with the username and a link to the login page
 */
export default function RefusterStep3({ user }) {
  const isBusiness = useSelector(state => state.user.isBusiness)

  useEffect(() => {
    const sendVerify = async () => {
      try {
        await sendVerificationEmail({ username: user.username })
      } catch (err) {
        console.log(err)
      }
    }
    sendVerify()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className='gap-20px rounded-10px p-30 mx-auto flex w-full max-w-[400px] flex-col items-center justify-center gap-10 text-black'>
      <h3 className='text-black text-[38px] font-medium'>THANK YOU FOR {isBusiness ? 'SUBSCRIBING' : 'REGISTERING'}!</h3>
      <p>Check your email to verify your account</p>
      {!isBusiness ? (
        <Link
          to='/search'
          className='flex w-full cursor-pointer justify-center rounded-full border-2 border-fitzba-bright-gold-static bg-fitzba-bright-gold-static p-[6px] text-white hover:bg-white hover:text-fitzba-bright-gold-static'
        >
          START SEARCHING
        </Link>
      ) : (
        <div className='flex w-full items-center flex-col gap-5'>
          <a
            href={`${process.env.REACT_APP_APP_HOST}business`}
            target='_blank'
            rel='noreferrer'
            className='flex w-[300px] cursor-pointer justify-center rounded-full border-2 border-fitzba-bright-gold-static bg-fitzba-bright-gold-static p-[6px] text-white hover:bg-white hover:text-fitzba-bright-gold-static'
          >
            SET UP YOUR PROFILE
          </a>
          <Link
            to='/'
            className='flex w-[300px] cursor-pointer justify-center rounded-full border-2 border-fitzba-bright-gold-static bg-fitzba-bright-gold-static p-[6px] text-white hover:bg-white hover:text-fitzba-bright-gold-static'
          >
            RETURN TO HOME
          </Link>
        </div>
      )}
    </div>
  )
}
