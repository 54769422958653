/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * FilterSideCategory defines a component for filtering by condition in a sidebar.
 * 
 * @author Yang Ming
 * @version February 7th, 2024 
 */

import React from 'react'
// import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai'
import { conditionList } from '@data/filter.data'
import FilterSideSectionHeader from './FilterSideSectionHeader'
import { setFilterState } from '@reducers/userReducer'
import { useSelector } from 'react-redux'


/**
 * A component for filtering by condition in a sidebar.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.itemsFilter - The current filter settings.
 * @param {Function} props.setFilterCondition - A function to set the condition filter.
 * @param {Function} props.dispatch - The Redux dispatch function.
 */
export default function FilterSideGiftGuide({
  itemsFilter,
  setFilterGiftGuide,
  dispatch,
  // isMobile
}) {


  // const [isOpen, setIsOpen] = useState(!isMobile ? (false) : !isMobile)

  // Get the current state of the condition filter
  const isOpen = useSelector(state => state.user.filterState.gift_guide)

  // Function to set the state of the condition filter
  const setIsOpen = (value) => {
    dispatch(setFilterState({ type: 'gift_guide', value }))
  }

  return (
    <div className='filter-item' style={{
      overflow: 'hidden',
      // marginBottom: '100px'
    }}>
      <FilterSideSectionHeader
        title='Gift Guide'
        isOpen={isOpen}
        onClickTitle={() => setIsOpen(!isOpen)}
        isHighlight={itemsFilter?.is_gift_guide}
      />
      <div className='filter-item-list' style={{ display: isOpen ? 'flex' : 'none' }}>
        <div className='filter-checkbox-item'>
          <label>
            <input
              value={'is_gift_guide'}
              type='checkbox'
              className='custom-checkbox'
              checked={itemsFilter?.is_gift_guide}
              onChange={e => {
                dispatch(setFilterGiftGuide(e.target.checked))
              }}
            />
            <span className='truncate'>Show Gifts</span>
          </label>
        </div>
      </div>
    </div>
  )
}
