import React from 'react'
import { MdCheckCircle } from 'react-icons/md'
export default function TaskStepItem({
    text,
    isCompleted,
    desc
}) {
    return (
        <div className={`flex items-start gap-2 ${isCompleted ? 'text-[#A6A6A6]' : 'text-black'}`}>
            {isCompleted ? <MdCheckCircle className='text-[#E4B456]' size={24} /> :
                <div className=' ml-[2px] w-[20px] h-[20px] border-2 border-[#A6A6A6] rounded-full text-transparent'>1</div>
            }
            <div>
                <h4 className='text-[14px] whitespace-nowrap smsize:text-[12px]'>
                    {text}
                </h4>
                {desc && <p className='text-[12px] text-[#A6A6A6] whitespace-nowrap'>{desc}</p>}
            </div>
        </div>
    )
}
