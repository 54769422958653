/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * EmployeeVerifyResult is a functional React component that displays the result of the employee verification process.
 * It shows a success message if the employee has accepted the invitation from the business.
 * It also provides a link to the business dashboard.
 *
 * @author Yang Ming
 * @version May 09, 2023
 */

import React from 'react'
import { Result } from 'antd'

export default function EmployeeVerifyResult({
    employee
}) {
    return (
        <Result
            status="success"
            title="Accept invitation successfully"
            subTitle={`You have accepted the invitation from ${employee?.businessName}`}
            extra={[
                <div className='my-5 flex w-full justify-center' key='success'>
                    <a
                        href='https://www.fitzba.com/business'
                        className=' flex w-fit cursor-pointer justify-center rounded-full border-2 border-fitzba-bright-gold-static bg-fitzba-bright-gold-static px-10 py-[6px] text-white hover:bg-white hover:text-fitzba-bright-gold-static'

                    >
                        Business Dashboard
                    </a>
                </div>
            ]}
        />
    )
}
