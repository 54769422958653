/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * A service to get the location of the user
 * 
 * @author Yang Ming, James Nguyen
 * @version January 30th, 2024
 */

//TODO: Discuss this file with yang 
export async function getGeocoderData(params) {
    const geocoder = new window.google.maps.Geocoder()
    if (!geocoder) {
        console.log('Geocoder not found')
        throw new Error('Geocoder not found')
    }
    return new Promise((resolve, reject) => {
        geocoder.geocode(params, (results, status) => {
            if (status === "OK" && results.length > 0) {
                const location = results[0].geometry.location
                const addressComponents = results[0].address_components
                console.log('getLatLngAndAddressFromLatLng', addressComponents)
                let city = null
                let province = null
                let postalCode = null
                let country = null
                let neighborhood = null
                let route = null
                let street_number = null
                for (let i = 0; i < addressComponents.length; i++) {
                    const addressType = addressComponents[i].types[0]
                    if (addressType === "locality") {
                        city = addressComponents[i].long_name
                    } else if (addressType === "administrative_area_level_1") {
                        province = addressComponents[i].short_name
                    } else if (addressType === "postal_code") {
                        postalCode = addressComponents[i].long_name
                    } else if (addressType === "country") {
                        country = addressComponents[i].short_name
                    } else if (addressType === "neighborhood") {
                        neighborhood = addressComponents[i].long_name
                    } else if (addressType === "route") {
                        route = addressComponents[i].long_name
                    } else if (addressType === "street_number") {
                        street_number = addressComponents[i].long_name
                    }
                    // country, short_name
                    // neighborhood, long_name
                    // route, long_name
                    // street_number, long_name
                }
                resolve({
                    lat: location?.lat(),
                    lng: location?.lng(),
                    city,
                    province,
                    postalCode,
                    country,
                    neighborhood,
                    route,
                    street_number
                })
            } else {
                reject(new Error("getGeocoderData: Unable to get data."))
            }
        })
    })
}

/**
 * @param lat         a float that contain the latitude value
 * @param lng         a float that contain the longitude value
 * @returns a promise object that contain the location got from 
 *          the latitude and longitude
 */
export const getLatLngAndAddressFromLatLng = async (lat, lng) => {
    // const geocoder = new window.google.maps.Geocoder()
    const latlng = new window.google.maps.LatLng(lat, lng)
    return await getGeocoderData({ location: latlng })
    // return new Promise((resolve, reject) => {
    //     geocoder.geocode({ location: latlng }, (results, status) => {
    //         if (status === "OK" && results.length > 0) {
    //             const addressComponents = results[0].address_components
    //             console.log('getLatLngAndAddressFromLatLng', addressComponents)
    //             let city = null
    //             let province = null
    //             let postalCode = null
    //             let country = null
    //             let neighborhood = null
    //             let route = null
    //             let street_number = null
    //             for (let i = 0; i < addressComponents.length; i++) {
    //                 const addressType = addressComponents[i].types[0]
    //                 if (addressType === "locality") {
    //                     city = addressComponents[i].long_name
    //                 } else if (addressType === "administrative_area_level_1") {
    //                     province = addressComponents[i].short_name
    //                 } else if (addressType === "postal_code") {
    //                     postalCode = addressComponents[i].long_name
    //                 } else if (addressType === "country") {
    //                     country = addressComponents[i].short_name
    //                 } else if (addressType === "neighborhood") {
    //                     neighborhood = addressComponents[i].long_name
    //                 } else if (addressType === "route") {
    //                     route = addressComponents[i].long_name
    //                 } else if (addressType === "street_number") {
    //                     street_number = addressComponents[i].long_name
    //                 }
    //                 // country, short_name
    //                 // neighborhood, long_name
    //                 // route, long_name
    //                 // street_number, long_name
    //             }
    //             resolve({ city, province, postalCode, country, neighborhood, route, street_number })
    //         } else {
    //             reject(new Error("Unable to geocode latlng."))
    //         }
    //     })
    // })
}

/**
 * 
 * @param postalCode    A string value that hold the user 
 *                      input of their postal code
 * @returns a promise object that contain the location got from 
 *          the postal code
 */
export const getLatLngAndAddressFromPostalCode = async (postalCode) => {
    // const geocoder = new window.google.maps.Geocoder()
    // return new Promise((resolve, reject) => {
    //     geocoder.geocode({ address: postalCode }, (results, status) => {
    //         if (status === "OK" && results.length > 0) {
    //             const location = results[0].geometry.location
    //             const addressComponents = results[0].address_components
    //             const city = addressComponents.find((component) =>
    //                 component.types.includes("locality")
    //             ).long_name
    //             const province = addressComponents.find((component) =>
    //                 component.types.includes("administrative_area_level_1")
    //             ).short_name
    //             resolve({ lat: location.lat(), lng: location.lng(), city, province })
    //         } else {
    //             reject(new Error(`Geocode was not successful for the following reason: ${status}`))
    //         }
    //     })
    // })
    return await getGeocoderData({ address: postalCode })
}

/**
 * 
 * @returns a promise object that contain the current location
 */
export const getCurrentPositionLatLng = async () => {
    // check if geolocation is supported
    if (!navigator.geolocation) {
        console.log("Geolocation is not supported by this browser.")
        throw new Error("Geolocation is not supported by this browser.")
    }
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            position => {
                const { latitude, longitude } = position.coords
                resolve({ lat: latitude, lng: longitude })
            },
            error => reject(error)
        )
    })
}

export const getCurrentPosition = async (lat2lng) => {
    try {
        const latLng = lat2lng ? lat2lng : await getCurrentPositionLatLng()
        console.log('getCurrentPosition', latLng)
        const position = await getLatLngAndAddressFromLatLng(latLng?.lat, latLng?.lng,)
        return {
            ...position,
            lat: latLng?.lat,
            lng: latLng?.lng,
        }
    } catch (error) {
        console.log('getCurrentPosition', error)
        return null
    }
}