import React from 'react'

export default function FavouriteButton({
    text,
    onClick = () => { },
    isHightlighted,
    extraStyles
}) {
    return (
        <button
            onClick={onClick}
            className={`${extraStyles} border  ${isHightlighted ? 'bg-[#E4B456] border-[#E4B456] text-white' : 'bg-white border-black text-black'} rounded-full px-5 text-[16px] smsize:text-[13px]`}
        >
            {text}
        </button>
    )
}
