import React from 'react'
import { MdCheckCircle } from 'react-icons/md'

export default function CartReserveCompleted() {
    return (
        <div className='flex min-h-[345px] w-full flex-col items-center justify-center gap-[30px] px-[10px]'>
            <div className='text-center text-[24px] text-[#202020]'>
                Thank you for reserving! <br />
                You will be receiving an email soon
            </div>
            <MdCheckCircle size={80} color='#E4B465' />
        </div>
    )
}
