/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * This module exports a React component named 'Review'. This component displays a list of reviews for a specific item.
 * It includes a form for submitting a new review, and a list of existing reviews. Each review is displayed using the 'ReviewCard' component.
 * 
 * The component takes two props: 'item' and 'isMobile'. 'item' is the item for which the reviews are being displayed.
 * 'isMobile' is a boolean indicating whether the device is mobile or not.
 * 
 * The component uses several hooks for state and side effects. It uses 'useSelector' to access the redux store, 'useDispatch' to dispatch actions,
 * 'useState' to manage local state, and 'useEffect' to handle side effects.
 * 
 * @author Yang Ming, James Nguyen
 * @version January 18nd, 2024
 */

import { message } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ReviewCard from './ReviewCard'
import { createReview, getReviews } from '@services/api.service'
import { setReviewList } from '@reducers/contentReducer'
import { setBackToRoute } from '@reducers/tempReducer'
import ItemRating from './ItemRating'
import ReviewForm from './ReviewForm'


/**
 * A component that displays a list of reviews for a specific item.
 * 
 * @param {Object} props - The props object.
 * @param {Object} props.item - The item for which the reviews are being displayed.
 * @param {boolean} props.isMobile - A boolean indicating whether the device is mobile or not.
 * @returns {JSX.Element} The 'Review' component.
 */
function Review({
  item,
  // reviewCount,
  // setReviewCount,
  // handleReviewLogin
  isMobile
}) {
  const currentRoute = useLocation()
  const dispatch = useDispatch()
  const formRef = useRef(null)
  const reviews = useSelector(state => state.content.reviewContent)
  const userinfo = useSelector(state => state.user.userinfo)
  const [showWriteComment, setWriteShowComment] = useState(false)
  const [rating, setRating] = useState(0)
  const [isLoadReviews, setIsLoadReviews] = useState(true)
  const [reviewCount, setReviewCount] = useState(0)

  useEffect(() => {
    if (!isLoadReviews || item?._id === null) return
    const fetchReviews = async () => {
      try {
        const res = await getReviews(item._id, reviewCount)
        dispatch(setReviewList(res))
      } catch (error) {
        console.log('get reviews', error)
      }
      setIsLoadReviews(false)
    }
    fetchReviews()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, dispatch, isLoadReviews])

  const handleReviewLogin = () => {
    dispatch(setBackToRoute(currentRoute.pathname))
    // navigate('/login')
  }

  const handleSubmit = async values => {
    try {
      await createReview({
        ...values,
        user_id: userinfo._id,
        item_id: item._id,
        rating,
      })
      setWriteShowComment(false)
      setIsLoadReviews(true)
      if (formRef.current) formRef.current.resetFields()
      message.success('Review sent!')
    } catch (err) {
      message.error('Review failed to send')
    }
  }
  // console.log('review', reviews)

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldsValue({
        user_id: userinfo?._id,
        item_id: item?._id,
        title: '',
        comment: '',
        rating: 0,
      })
    }
  }, [formRef, userinfo, item])

  return (
    <div className='flex-[3]'>
      <div className='w-full text-left'>
        <h2 name='review-count' className='pt-5 text-[20px] font-semibold tracking-[1px] text-fitzba-black-static'>
          Reviews ({reviews.total}){' '}
          {/*<== To be replace by atual number of reviews */}
        </h2>
        <ItemRating review={item.review} />
        {!userinfo ? (
          <div>
            <Link
              to={'/login'}
              name='loginButton'
              onClick={handleReviewLogin}
              className='mt-3 text-fitzba-bright-gold-static hover:text-fitzba-dark-gold-static'
            >
              Log in
            </Link>{' '}
            to leave a comment
          </div>
        ) : (
          <button
            className='mt-5 text-[15px] w-full cursor-pointer rounded-full border border-black py-3 px-3 text-center sm:w-[200px]'
            onClick={() => {
              setWriteShowComment(!showWriteComment)
            }}
          >
            WRITE A REVIEW
          </button>
        )}
        {showWriteComment && (
          <ReviewForm
            formRef={formRef}
            setShowComment={setWriteShowComment}
            handleSubmit={handleSubmit}
            setRating={setRating}
            isMobile={isMobile}
          />
        )}
        <div className='py-5'>
          {reviews?.data?.map((item, index) => (
            <ReviewCard info={item} key={index} />
          ))}
        </div>
      </div>
      {reviews.total > 3 && (
        <button
          type='button'
          onClick={() => {
            setReviewCount(reviewCount + 5)
          }}
          className='text-[16px]'
        >
          More Reviews
        </button>
      )}
    </div>
  )
}

export default Review
