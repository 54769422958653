import React from 'react'
import { Modal } from 'antd'

export default function CommonModal({
    isOpen,
    setIsOpen,
    zIndex = 99999,
    width = 400,
    children
}) {
    return (
        <Modal
            open={isOpen}
            onCancel={() => setIsOpen(false)}
            footer={null}
            forceRender
            destroyOnClose={false}
            width={width}
            centered={true}
            style={{
                background: "transparent",
                pointerEvents: "auto"
            }}
            zIndex={zIndex}
            modalRender={() => children}
        >
        </Modal>
    )
}
