/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * Item is a functional React component that renders a single item
 * in the list view, with an image, title, rating, price, and other details.
 *
 * @author Yang Ming
 * @version June 07, 2023
 */
import React from 'react'
import { getShortString, getDistance } from '@utils/Helper'
import { Link, useNavigate } from 'react-router-dom'
import ItemPrice from '../../zearch/components/ItemPrice'
import ItemRating from '../../zearch/components/ItemRating'
import { getItemLink, getStoreLink } from '@utils/Helper'
import MyImage from '@components/MyImage'
import { useDispatch, useSelector } from 'react-redux'
import { setFilterStoreOnlyOne } from '@reducers/contentReducer'
import { setFilterBrand } from '@reducers/contentReducer'
import FavoriteRemoveButton from './FavoriteRemoveButton'

export default function FavoriteItemCard({
  item,
  handleAddToCart,
  keywordsList,
}) {
  const location = useSelector((state) => state.user.location)
  // console.log(item)
  // highlight keywords in title
  const getTitle = () => {
    let title = getShortString(item?.name, 50)
    if (keywordsList && keywordsList.length > 0) {
      title = title.replace(
        new RegExp(`(${keywordsList.join('|')})`, 'gi'),
        '<font color="#E4B456">$1</font>',
      )
    }
    return title
  }

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const itemLink = getItemLink(item._id, item.name)
  const storeLink = getStoreLink(item?.business?.shortLink, item?.business?.city, item?.business?.province)

  return (
    <div className='flex w-full justify-center p-0 font-lexend md:w-fit'>
      <div className='relative overflow-hidden rounded-lg'>
        <div className='relative w-[130px] xs:w-[150px] xl:w-[180px] 2xl:w-full'>
          <FavoriteRemoveButton item_id={item?._id} />
          <button
            className=' absolute top-0 left-0 z-10 flex h-[30px] w-[30px] cursor-pointer items-center justify-center rounded-br-lg rounded-tl-lg border border-solid border-fitzba-bright-gold-static bg-fitzba-bright-gold-static pb-1 text-[25px] font-light leading-[30px] text-white hover:bg-white hover:text-fitzba-bright-gold-static'
            onClick={() => handleAddToCart(item._id)}
          >
            +
          </button>
          <div className='h-[130px] w-[130px] overflow-hidden rounded-lg border-2 border-black border-opacity-10 xs:h-[150px] xs:w-[150px] xl:h-[180px] xl:w-[180px] '>
            <Link to={itemLink}>
              <MyImage
                src={
                  item?.images && item.images.length > 0
                    ? item.images[0]
                    : '/item-default.png'
                }
                alt={item?.name}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  mixBlendMode: 'multiply',
                }}
              />
            </Link>
          </div>
        </div>
        <div className='w-[130px] py-2 xs:w-[150px] xl:w-[180px]'>
          <h4 className=' relative mb-[3px] h-[30px] cursor-pointer text-[14px] font-lexend'>
            <Link
              to={itemLink}
              title={item?.name}
              className='truncate-multiline'
            >
              <p className='leading-5 pr-3' dangerouslySetInnerHTML={{ __html: getTitle() }} />
            </Link>
          </h4>
          {item?.brand ? (
            <p
              className='mt-4 flex w-full cursor-pointer justify-between truncate text-[11px] text-[#727272]'
              onClick={() => {
                dispatch(
                  setFilterBrand({
                    id: item?.brand?._id,
                    checked: true,
                  }),
                )
                navigate('/search')
              }}
            >
              <span className=' uppercase'>{item?.brand?.title}</span>
            </p>
          ) : (
            <p className='my-3 h-0' />
          )}

          <div className='mb-1 max-w-full cursor-pointer truncate text-[10px] text-[#727272]'>
            <div
              onClick={() => {
                dispatch(setFilterStoreOnlyOne(item?.business?._id))
                navigate(storeLink)
              }}
              title={item?.name}
              className=' uppercase'
            >
              {item?.business?.name}
              {/* {item?.business?.isVerified && (
                <BsPatchCheckFill className='ml-1 inline-block text-black' />
              )} */}
            </div>
          </div>
          <p className='mb-1 truncate text-xs text-black'>
            <span className='text-[10px] font-light'>
              {getDistance(item?.distance, location?.country === 'US')?.toUpperCase()}
            </span>
          </p>
          <div className='product-item-info-price gap-1'>
            <ItemPrice
              originalPrice={item?.originalPrice}
              salePrice={item?.salePrice}
            />
          </div>
          <div className='flex gap-1 mt-2 text-[10px] font-light text-[#0B6186] whitespace-nowrap'>
            <Link
              to={itemLink + '&pickup=true'}
              className='bg-[#E4F2F3] rounded-sm p-1 cursor-pointer'
            >
              pick-up
            </Link>
            {item?.business?.isReserve && (
              <Link
                to={itemLink + '&reserve=true'}
                className='bg-[#E4F2F3] rounded-sm p-1 cursor-pointer'
              >
                reservation
              </Link>
            )}
          </div>
          {item?.review?.totalCount > 0 && (
            <ItemRating
              review={item?.review}
            />
          )}
        </div>
      </div>
    </div>
  )
}
