/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * RetailRegisterStep4 is a functional React component that handles the fourth step in the registration process for retail users.
 * It renders a form for users to enter their website URL and select their POS system from a list of options.
 * If the user selects 'Others (Specify)', they are given an input field to enter their custom POS system.
 *
 * @author James Nguyen, Yang Ming
 * @version October 13th, 2023
 */

import React, { useEffect } from 'react'
import { Form, Input, Select } from 'antd'
import { useState } from 'react'

/**
 * Handles the registration steps for retail users.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.prev - Function to go to the previous step.
 * @param {Object} props.formData - The data from the form.
 * @param {Function} props.setFormData - Function to update the form data.
 * @param {Function} props.handleSubmit - Function to handle form submission.
 *
 * @returns {JSX.Element} The rendered component.
 */
function RetailRegisterStep4({ prev, formData, setFormData, handleSubmit }) {
  // Using the  hook from Ant Design's Form component to create a form instance
  const [form] = Form.useForm()

  const posList = [
    {
      title: 'Square',
      logo: '/images/Square.png',
    },
    {
      title: 'Shopify',
      logo: '/images/Shopify.png',
    },
    {
      title: 'Zenoti',
      logo: '/images/Zenoti.png',
    },
    {
      title: 'Lightspeed',
      logo: '/images/Lightspeed.png',
    },
    {
      title: 'Others (Specify)',
    },
  ]
  const [isOtherSelected, setIsOtherSelected] = useState(false)
  const [customPos, setCustomPos] = useState(formData.pos)

  useEffect(() => {
    if (form !== null) {
      form.setFieldsValue(formData)
    }
  }, [form, formData])

  const handleSelectChange = value => {
    if (value === 'Others (Specify)') {
      setIsOtherSelected(true)
      setCustomPos('')
    } else {
      setIsOtherSelected(false)
      setCustomPos('')
      form.setFieldValue('pos', value)
    }
  }

  const handleInputChange = e => {
    setCustomPos(e.target.value)
  }

  const handleClearInput = () => {
    setIsOtherSelected(false)
    setCustomPos('')
    form.setFieldValue('pos', '') // Clear the selected value
  }

  // Defining the onFinish function to handle form submission

  const onFinish = async values => {
    setFormData({
      ...formData,
      ...values,
    })
    handleSubmit(values)
  }

  // Using the useEffect hook to set the initial form values when the form instance is available
  useEffect(() => {
    if (form !== null) {
      form.setFieldsValue(formData)
    }
  }, [form, formData])

  // Defining the checkUsernameExist function to validate if the entered email is unique

  const onPrev = () => {
    setFormData({
      ...formData,
      ...form.getFieldsValue(),
    })
    prev()
  }

  return (
    <Form
      forceRender
      layout='vertical'
      onFinish={onFinish}
      scrollToFirstError
      form={form}
      style={{
        textAlign: 'left',
      }}
      className='retail-form m-auto w-[300px]'
    >
      <div className='flex w-full flex-col '>
        <Form.Item name='website' className='register-form-item'>
          <Input
            className='rounded-full border-2 border-black text-base'
            size='large'
            // suffix={<InputSuffixTip text='First name is required' />}
            placeholder='Website URL'
          />
        </Form.Item>
        <Form.Item
          name='pos'
          initialValue={formData.pos}
          className='register-form-item'
        >
          {isOtherSelected ? (
            <Input
              size='large'
              placeholder='Other'
              className='rounded-full border-2 border-black text-base'
              value={customPos}
              onChange={handleInputChange}
              autoFocus
              suffix={<span onClick={handleClearInput}>x</span>}
            />
          ) : (
            <div className='rounded-full border-2 border-black'>
              <Select
                // bordered={false}
                variant="borderless"
                onChange={value => {
                  form.setFieldValue('pos', value)
                  handleSelectChange(value)
                }}
                value={formData.pos}
                size='large'
                placeholder='POS System'
                options={posList.map(item => ({
                  value: item.title,
                  label: item.title,
                }))}
              />
            </div>
          )}
        </Form.Item>
      </div>
      <div className='flex gap-2 items-center w-full'>
        <input type='checkbox' className=' accent-fitzba-bright-gold-static' />
        <span>The business doesn’t have a website and/or POS system</span>
      </div>
      <div
        style={{
          marginTop: 24,
        }}
        className='mx-auto flex w-[300px] items-center justify-center gap-2'
      >
        <div
          className='flex w-full max-w-[100px] cursor-pointer justify-center rounded-full border-2 border-black bg-white p-[6px] text-black hover:bg-slate-200'
          onClick={onPrev}
        >
          BACK
        </div>
        <button
          type='submit'
          className=' flex w-full cursor-pointer justify-center rounded-full border-2 border-fitzba-bright-gold-static bg-fitzba-bright-gold-static p-[6px] text-white hover:bg-white hover:text-fitzba-bright-gold-static'
        >
          CONTINUE
        </button>
      </div>
    </Form>
  )
}

export default RetailRegisterStep4
