/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * 
 *  A collection of utility functions for Fitzba that assist with various tasks such as 
 *  string manipulation, validation, formatting, and other miscellaneous tasks.
 * 
 *  
 * @author Yang Ming, James Nguyen
 * @version January 30th, 2024
 */

/**
 * Checks if the given email is valid.
 * @param {string} email - The email to check.
 * @returns {boolean} True if the email is valid, false otherwise.
 */
export function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
}

/**
 * Converts the first letter of each word in the given string to uppercase.
 * @param {string} str - The string to convert.
 * @returns {string} The converted string.
 */
export function upperCaseFirstLetter(str) {
    let words = str.split(" ") // split the string into an array of words
    for (let i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase()
        // capitalize the first letter of each word and convert the rest of the word to lowercase
    }
    str = words.join(" ") // join the words back together into a string
    return str
}

/**
 * Retrieves the address from a business object.
 * @param {Object} business - The business object.
 * @returns {string} The address of the business.
 */
export function getAddress(business) {
    if (business) {
        //Check address
        let address = business.address.trim()
        if (address.charAt(address.length - 1) === ',') {
            // Remove the comma at the end
            address = address.slice(0, -1)
        }

        // Check city
        let city = business.city.trim()
        if (city.charAt(city.length - 1) === ',') {
            // Remove the comma at the end
            city = city.slice(0, -1)
        }

        // Check province
        let province = business.province.trim()
        if (province.charAt(province.length - 1) === ',') {
            // Remove the comma at the end
            province = province.slice(0, -1)
        }

        // Check postalCode
        let postalCode = business.postalCode.trim()
        if (postalCode.charAt(postalCode.length - 1) === ',') {
            // Remove the comma at the end
            postalCode = postalCode.slice(0, -1)
        }

        return address + ', ' + city + ', ' + province + ' ' + postalCode
    }
}

/**
 * Truncates a string to a specified length and appends '...' if the string is longer than the specified length.
 * @param {string} string - The string to truncate.
 * @param {number} len - The length to truncate the string to.
 * @returns {string} The truncated string.
 */
export function getShortString(string, len) {
    if (!string)
        return ''
    if (string.length > len)
        return string.slice(0, len) + '...'
    return string
}

/**
 * Checks if a postal code is valid for Canada and the US.
 * @param {string} postalCode - The postal code to check.
 * @returns {boolean} True if the postal code is valid, false otherwise.
 */
export function isValidPostalCodeCAandUS(postalCode) {
    if (!postalCode || postalCode.length < 5) {
        return false
    }
    const regex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/
    if (!regex.test(postalCode)) {
        // Define the regular expression pattern for US postal codes
        const regex = /(^\d{5}(?:[\s]?[-\s][\s]?\d{4})?$)/
        return regex.test(postalCode)
    }
    return true
}

/**
 * Checks if a postal code is valid for a specified country.
 * @param {string} postalCode - The postal code to check.
 * @param {boolean} isUS - True if the country is the US, false otherwise.
 * @returns {boolean} True if the postal code is valid, false otherwise.
 */
export function isValidPostalCode(postalCode, isUS) {
    // Define the regular expression pattern for Canadian postal codes
    const regex = isUS ? /(^\d{5}(?:[\s]?[-\s][\s]?\d{4})?$)/ : /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/
    return regex.test(postalCode)
}

/**
 * Formats a number as a currency string.
 * @param {number} amount - The amount to format.
 * @returns {string} The formatted currency string.
 */
export function formatCurrency(amount) {
    if (!amount || isNaN(amount)) {
        return '$0.00'
    }
    return '$' + amount?.toFixed(2)?.replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

/**
 * Formats a number as a currency string without a currency symbol.
 * @param {number} amount - The amount to format.
 * @returns {string} The formatted currency string.
 */
export function formatCurrency2(amount) {
    if (!amount || isNaN(amount)) {
        return '0.00'
    }
    return amount?.toFixed(2)?.replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

/**
 * Generates a random number between a minimum and maximum value.
 * @param {number} min - The minimum value.
 * @param {number} max - The maximum value.
 * @returns {number} The generated random number.
 */
export function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
}

/**
 * Calculates the distance between two locations.
 * @param {Object} location1 - The first location.
 * @param {Object} location2 - The second location.
 * @returns {number} The calculated distance.
 */
export function calculateDistance(location1, location2) {
    // console.log(location1, location2)

    function toRadians(degrees) {
        return degrees * (Math.PI / 180)
    }

    const earthRadius = 6371 // km
    const lat1 = toRadians(location1.lat)
    const lat2 = toRadians(location2.lat)
    const latDiff = toRadians(location2.lat - location1.lat)
    const lonDiff = toRadians(location2.lng - location1.lng)

    const a = Math.sin(latDiff / 2) * Math.sin(latDiff / 2) +
        Math.cos(lat1) * Math.cos(lat2) *
        Math.sin(lonDiff / 2) * Math.sin(lonDiff / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    const distance = earthRadius * c

    return distance ? Number(Number(distance).toFixed(2)) : 0
}

/**
 * Formats a distance as a string.
 * @param {number} distance - The distance to format.
 * @returns {string} The formatted distance string.
 */
export function getDistance(distance, isMiles = false) {
    if (isNaN(distance))
        return ''
    if (distance === 0) {
        return '0 m'
    }
    if (distance < 1) {
        distance = distance * 1000
        return Number(distance).toFixed(0) + ' m'
    }
    if (isMiles) {
        const milesPerKm = 0.621371
        return Number(distance * milesPerKm).toFixed(1) + ' mi'
    }
    return Number(distance).toFixed(1) + ' km'
}

/**
 * Calculates the distance between two locations and formats it as a string.
 * @param {Object} location1 - The first location.
 * @param {Object} location2 - The second location.
 * @returns {string} The calculated and formatted distance string.
 */
export function getDistance2(location1, location2, isMiles = false) {
    return getDistance(calculateDistance(location1, location2), isMiles)
}

/**
 * Checks if a credit card number is valid.
 * @param {string} cardNumber - The credit card number to check.
 * @returns {boolean} True if the credit card number is valid, false otherwise.
 */
export function isCreditCardValid(cardNumber) {
    // Remove spaces and non-digit characters
    cardNumber = cardNumber.replace(/\D/g, '')

    // Check if the card number is the right length for different card types
    if (
        cardNumber.length !== 13 &&
        cardNumber.length !== 15 &&
        cardNumber.length !== 16
    ) {
        return false
    }

    // Perform the Luhn algorithm check
    let sum = 0
    let doubleUp = false

    for (let i = cardNumber.length - 1; i >= 0; i--) {
        let digit = parseInt(cardNumber.charAt(i))

        if (doubleUp) {
            digit *= 2

            if (digit > 9) {
                digit -= 9
            }
        }

        sum += digit
        doubleUp = !doubleUp
    }

    return sum % 10 === 0
}

/**
 * Checks if a credit card expiry date is valid.
 * @param {string} expiryDate - The expiry date to check.
 * @returns {boolean} True if the expiry date is valid, false otherwise.
 */
export function isExpiryDateValid(expiryDate) {
    // Remove non-digit characters and remove /
    expiryDate = expiryDate.replace(/\D/g, '').replace('/', '')

    // Validate that the input is exactly four digits
    if (expiryDate.length !== 4) {
        return false
    }

    // Extract the month and year from the input
    const expiryMonth = Number(expiryDate.substring(0, 2))
    const expiryYear = Number(expiryDate.substring(2))

    console.log(expiryMonth, expiryYear)

    if (expiryMonth < 1 || expiryMonth > 12) {
        return false
    }

    // Get the current date
    const currentDate = new Date()

    // Calculate the current month and year
    const currentMonth = currentDate.getMonth() + 1 // JavaScript months are zero-based
    const currentYear = currentDate.getFullYear() % 100 // Get the last two digits of the current year

    // Check if the expiry year is in the future or if it's the current year and the month is in the future
    if (expiryYear < currentYear || (expiryYear === currentYear && expiryMonth < currentMonth)) {
        return false
    }

    return true
}

/**
 * Checks if a credit card CVC is valid.
 * @param {string} cvc - The CVC to check.
 * @returns {boolean} True if the CVC is valid, false otherwise.
 */
export function isCVCValid(cvc) {
    if (cvc.length !== 3 && cvc.length !== 4) {
        return false
    }
    // Remove non-digit characters
    cvc = cvc.replace(/\D/g, '')

    // Check if the CVC length is either 3 or 4 digits
    return /^[0-9]{3,4}$/.test(cvc)
}

/**
 * Replaces spaces in a string with dashes.
 * @param {string} str - The string to replace spaces in.
 * @returns {string} The string with spaces replaced by dashes.
 */
export function replaceSpaceWithDash(str) {
    return str.toLowerCase().replace(/\s/g, '-')
}

/**
 * Formats a title for use in a URL.
 * @param {string} title - The title to format.
 * @returns {string} The formatted title.
 */
export function formatTitleForLink(title) {
    if (!title)
        return ''
    return title
        .trim()
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, "-")
        .replace(/^-+|-+$/g, "")
}

/**
 * Generates a link for an item.
 * @param {string} id - The id of the item.
 * @param {string} name - The name of the item.
 * @returns {string} The generated link.
 */
export function getItemLink(id, name) {
    return `/item/${id}?title=${formatTitleForLink(name)}`
}

/**
 * Retrieves filters from a URL's search parameters.
 * @param {Object} itemsFilter - The current items filter.
 * @param {URLSearchParams} searchParams - The search parameters of the URL.
 * @returns {Object} The retrieved filters and a flag indicating if the filters have changed.
 */
export function getFiltersFromLink(itemsFilter, searchParams) {
    let filters = {}
    let isChanged = false

    const keywords = searchParams.get('keywords')
    if (keywords && keywords !== itemsFilter.title) {
        filters.title = keywords
        isChanged = true
    }
    const sortby = searchParams.get('sortby')
    if (sortby && Number(sortby) !== itemsFilter.sortBy) {
        filters.sortBy = Number(sortby)
        isChanged = true
    }
    const qrcode = searchParams.get('qrcode')
    if (qrcode && Number(qrcode) !== itemsFilter.qrcode) {
        filters.qrcode = Number(qrcode)
        isChanged = true
    }
    const searchinstore = searchParams.get('searchinstore')
    if (searchinstore && Number(searchinstore) !== itemsFilter.qrcode) {
        filters.searchInStore = Number(searchinstore)
        isChanged = true
    }
    const stores = searchParams.get('stores')
    if (stores && stores !== itemsFilter.stores.join(',')) {
        const list = stores.split(',')
        if (list.length === 1) {
            filters.onlyOneStore = 1
        }
        filters.stores = list
        isChanged = true
    }
    const code = searchParams.get('code')
    if (code && code !== itemsFilter.code) {
        filters.code = code
        isChanged = true
    }
    const brands = searchParams.get('brands')
    if (brands && brands !== itemsFilter.brands.join(',')) {
        filters.brands = brands.split(',')
        isChanged = true
    }
    const page = searchParams.get('page')
    if (page && Number(page) !== itemsFilter.currentPage) {
        filters.currentPage = Number(page)
        isChanged = true
    }
    const distance = searchParams.get('distance')
    if (distance && Number(distance) !== itemsFilter.distance) {
        filters.distance = Number(distance)
        isChanged = true
    }
    const priceMin = searchParams.get('min')
    if (priceMin && Number(priceMin) !== itemsFilter.priceMin) {
        filters.priceMin = Number(priceMin)
        isChanged = true
    }
    const priceMax = searchParams.get('max')
    if (priceMax && Number(priceMax) !== itemsFilter.priceMax) {
        filters.priceMax = Number(priceMax)
        isChanged = true
    }

    const deliveryTypes = searchParams.get('deliverytypes')
    if (deliveryTypes && deliveryTypes !== itemsFilter.deliveryTypes.join(',')) {
        filters.deliveryTypes = deliveryTypes.split(',')
        isChanged = true
    }
    const categories = searchParams.get('categories')
    if (categories && categories !== itemsFilter.categories.join(',')) {
        filters.categories = categories.split(',')
        isChanged = true
    }
    const condition = searchParams.get('condition')
    if (condition && condition !== itemsFilter.condition.join(',')) {
        filters.condition = condition.split(',')
        isChanged = true
    }
    const store = searchParams.get('store')
    if (store) {
        filters.onlyOneStore = 1
        filters.stores = [store]
        isChanged = true
    }
    return { filters, isChanged }
}

/**
 * Updates the URL's search parameters based on the items filter.
 * @param {Object} itemsFilter - The items filter.
 * @param {string} ref - The reference string.
 * @returns {boolean} True if the URL was changed, false otherwise.
 */
export function refreshUrlParmas(itemsFilter, ref = 'fitzba') {
    const currentURL = window.location.pathname
    // Add parameters to the URL
    const baseUrl = `${currentURL}?ref=${ref}`
    let newURL = baseUrl
    if (itemsFilter?.title)
        newURL += `&keywords=${itemsFilter?.title}`
    if (itemsFilter?.stores?.length > 0)
        newURL += `&stores=${itemsFilter?.stores.join(',')}`
    if (itemsFilter?.brands?.length > 0)
        newURL += `&brands=${itemsFilter?.brands.join(',')}`
    if (itemsFilter?.sortBy > -1)
        newURL += `&sortby=${itemsFilter?.sortBy}`
    if (itemsFilter?.priceMin > -1)
        newURL += `&min=${itemsFilter?.priceMin}`
    if (itemsFilter?.priceMax > -1)
        newURL += `&max=${itemsFilter?.priceMax}`
    if (itemsFilter?.distance > -1)
        newURL += `&distance=${itemsFilter?.distance}`
    if (itemsFilter?.code)
        newURL += `&code=${itemsFilter?.code}`
    if (itemsFilter?.deliveryTypes?.length > 0)
        newURL += `&deliverytypes=${itemsFilter?.deliveryTypes.join(',')}`
    if (itemsFilter?.currentPage > 1)
        newURL += `&page=${itemsFilter?.currentPage}`
    if (itemsFilter?.onlyOneStore)
        newURL += `&onlyonestore=${itemsFilter?.onlyOneStore}`
    if (itemsFilter?.searchInStore)
        newURL += `&searchinstore=${itemsFilter?.searchInStore}`
    if (itemsFilter?.categories?.length > 0)
        newURL += `&categories=${itemsFilter?.categories.join(',')}`
    // if (itemsFilter?.condition?.length > 0)
    //     newURL += `&condition=${itemsFilter?.condition.join(',')}`

    // Update the URL without refreshing the page
    window.history.pushState({}, '', newURL)
    let isChanged = baseUrl !== newURL
    return isChanged
}

/**
 * Retrieves the price of an item.
 * @param {number} originalPrice - The original price of the item.
 * @param {number} salePrice - The sale price of the item.
 * @returns {number} The price of the item.
 */
export function getPrice(originalPrice, salePrice) {
    if (!originalPrice && !salePrice) return 'N/A'
    return salePrice || 'N/A'
}

/**
 * Formats the updated time of an item.
 * @param {string} updatedAt - The updated time of the item.
 * @returns {string} The formatted updated time.
 */
export function getItemUpdatedTime(updatedAt) {
    const formattedDate = new Date(updatedAt).toLocaleDateString('en-US', {
        weekday: 'short',
        month: 'long',
        day: 'numeric',
    })
    const formattedTime = new Date(updatedAt).toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit',
    })
    return formattedDate?.toUpperCase() + ', ' + formattedTime
}

/**
 * Retrieves the number of content rows in an element.
 * @param {Element} element - The element to retrieve the content rows from.
 * @returns {number} The number of content rows.
 */
export function getContentRow(element) {
    if (!element)
        return 0
    // get style
    const style = window.getComputedStyle(element)
    // get line height
    const lineHeight = style.lineHeight === 'normal' ?
        parseFloat(style.fontSize) * 1.14 :
        parseFloat(style.lineHeight)

    // get height
    const height = parseFloat(style.height)

    // get lines
    const lines = Math.ceil(height / lineHeight)
    return lines
}

/**
 * Removes duplicates from an array based on a specified field.
 * @param {Array} arr - The array to remove duplicates from.
 * @param {string} field - The field to check for duplicates.
 * @returns {Array} The array with duplicates removed.
 */
export function removeDuplicatesByField(arr, field) {
    if (!arr || !field) return []
    return Array.from(new Set(arr.map(obj => obj[field]))).map(id => {
        return arr.find(obj => obj[field] === id)
    })
}

export function getStoreLink(shortlink, city, province) {
    let link = `/search?storeName=${shortlink}`
    if (shortlink && city && province)
        link = `/${city.toLowerCase()}-${province.toLowerCase()}/${shortlink}`
    return link
}

export function isRunningInWebView() {
    // Check if 'ReactNative' is present in the user agent
    return /ReactNative/i.test(navigator.userAgent)
}

export function getOSName() {
    let osName = 'Unknown OS'
    const userAgent = window?.navigator?.userAgent?.toLowerCase() || ''
    const safari = /safari/.test(userAgent)
    const ios = /iphone|ipod|ipad/.test(userAgent)
    const android = /android/.test(userAgent)

    if (ios) {
        if (safari) {
            //browser
            osName = 'IOS Safari'
        } else if (!safari) {
            //webview
            osName = 'IOS Webview'
        };
    }
    if (android) {
        if (safari) {
            //browser
            osName = 'Android Browser'
        } else if (!safari) {
            //webview
            osName = 'Android Webview'
        };
    }
    return osName
}

export function generateUUID() {
    const ObjectId = require('bson-objectid')
    return ObjectId().toHexString()
}