/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * This module exports a React component named 'ReviewCard'. This component displays a single review card.
 * It includes the user's avatar, name, review title, rating, review date, and comment.
 * 
 * The component takes one prop: 'info'. 'info' is an object that contains the review data.
 * 
 * @author Yang Ming, James Nguyen
 * @version January 22nd, 2024
 */
import { Avatar } from 'antd'
import React from 'react'
import ItemRating from './ItemRating'

/**
 * A component that displays a single review card.
 * 
 * @param {Object} props - The props object.
 * @param {Object} props.info - The review data.
 * @returns {JSX.Element} The 'ReviewCard' component.
 */
export default function ReviewCard({ info }) {
  const formatedDate = new Date(info?.createdAt).toLocaleString('en-CA', {
    timeZone: 'America/Edmonton',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })

  // console.log('ReviewCard', info)

  return (
    <div className='pb-5'>
      <div className='flex flex-col gap-1'>
        <div className='flex justify-start items-center gap-2'>
          <Avatar
            // className='font-sbold mx-auto mb-5 cursor-pointer break-words bg-fitzba-off-white-static align-middle font-anke text-fitzba-off-black-static '
            size={50}
          >
            {info?.user?.firstName?.substring(0, 1)}
          </Avatar>
          <h4 className='font-light'>{info?.user?.firstName} {info?.user?.lastName}</h4>
        </div>
        <h3 className='text-[16px] font-[400] tracking-[1px] text-fitzba-black-static'>
          {info.title}
        </h3>
        <div className='flex gap-3 items-center'>
          <ItemRating
            review={
              {
                averageRating: info.rating,
                totalCount: 0
              }
            }
            isShowCount={false}
          />
          {'  '}
          <label className='text-[13px] font-light'>{formatedDate}</label>
        </div>
        <div className='mt-3 text-[13px] font-light sm:max-w-[600px]'>{info?.comment}</div>
      </div>
    </div>
  )
}
