import React from 'react'

export default function BenefitsCard({
    title,
    description,
    image
}) {
    return (
        <div className='w-[310px] h-[250px] border-2 border-[#000] rounded-2xl p-6 flex gap-1 flex-col'>
            <h4 className='text-[24px] font-semibold text-left'>{title}</h4>
            <p className='text-[12px] font-light text-left'>{description}</p>
            <div className='flex items-center justify-center pt-2'>
                <img src={image} alt={title} />
            </div>
        </div>
    )
}
