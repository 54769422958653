import React from 'react'
import { IoCheckmarkCircleSharp } from "react-icons/io5"

export default function BenefitItemLine({
    text
}) {
    return (
        <li className='flex gap-3 items-center text-[16px]'>
            <IoCheckmarkCircleSharp className='w-[20px] h-[20px]' />
            <span className='flex-1'>{text}</span>
        </li>
    )
}
