/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * The QuestionForm component renders a form for users to submit their questions.
 * The form includes fields for user type, name, city, province, email, phone, and message.
 * The component uses the useForm hook from the antd library to manage the form state.
 * It also defines a handleSubmit function to handle form submission, which sends the form data to a specified sheet and then resets the form.
 *
 * @author James Nguyen
 * @version June 10th, 2023 
 */

import React from 'react'
import InputBar from './InputBar'
import { submitForm } from '../services/api.service'
import { Button, Checkbox, Form, message } from 'antd'

function QuestionForm() {
  const [form] = Form.useForm()

    /**
   * handleSubmit is a function that handles form submission.
   * It sends the form data to a specified sheet and then resets the form.
   * 
   * @param {Object} values - The form values.
   */
  const handleSubmit = async values => {
    console.log(values)
    await submitForm({
      sheet: 'Questions',
      ...values,
      date: new Date().toLocaleDateString(),
    })
    // Clear the form data
    form.resetFields()
    message.success('Form submitted successfully!')
  }

  return (
    <div className='flex flex-col items-center justify-center'>
      <h3 className='text-[12px] font-[330] text-fitzba-black-static'>
        Got questions?
      </h3>
      <Form
        forceRender
        form={form}
        onFinish={handleSubmit}
        className='flex w-3/4 flex-col gap-2'
      >
        <span className='text-left text-[12px] font-[330] text-fitzba-black-static'>
          I am a:
        </span>

        <Form.Item
          style={{ marginBottom: 0 }}
          name='userType'
          rules={[
            {
              required: true,
              message: 'Please select at least one user type',
            },
          ]}
        >
          <Checkbox.Group className='flex w-full flex-row flex-wrap justify-evenly xs:flex-nowrap'>
            <Checkbox value='Consumer'>
              <p className='text-left text-[11px] font-[330] text-fitzba-black-static'>
                Consumer
              </p>
            </Checkbox>
            <Checkbox value='Store'>
              <p className='text-left text-[11px] font-[330] text-fitzba-black-static'>
                Store
              </p>
            </Checkbox>
            <Checkbox value='Brand'>
              <p className='text-left text-[11px] font-[330] text-fitzba-black-static'>
                Brand
              </p>
            </Checkbox>
            <Checkbox value='Investor'>
              <p className='text-left text-[11px] font-[330] text-fitzba-black-static'>
                Investor
              </p>
            </Checkbox>
            <Checkbox value='Media'>
              <p className='text-left text-[11px] font-[330] text-fitzba-black-static'>
                Media
              </p>
            </Checkbox>
          </Checkbox.Group>
        </Form.Item>

        <Form.Item
          style={{ marginBottom: 0 }}
          name='name'
          rules={[
            {
              required: true,
              message: 'Please enter your name',
            },
          ]}
        >
          <InputBar label='Name' />
        </Form.Item>

        <div className='mb-0 flex flex-row gap-1'>
          <Form.Item
            name='city'
            rules={[
              {
                required: true,
                message: 'Please enter your city/town',
              },
            ]}
            style={{ flex: '1', marginBottom: 0 }}
          >
            <InputBar label='City/Town' />
          </Form.Item>

          <Form.Item
            name='province'
            rules={[
              {
                required: true,
                message: 'Please enter your province',
              },
            ]}
            style={{ flex: '1', marginBottom: 0 }}
          >
            <InputBar label='Province' />
          </Form.Item>
        </div>

        <div className='flex flex-row gap-1'>
          <Form.Item
            name='email'
            rules={[
              {
                required: true,
                message: 'Please enter your email',
              },
              {
                type: 'email',
                message: 'Please enter a valid email',
              },
            ]}
            style={{ flex: '1', marginBottom: 0 }}
          >
            <InputBar label='Email' />
          </Form.Item>

          <Form.Item
            name='phone'
            rules={[
              {
                required: true,
                message: 'Please enter your phone number',
              },
            ]}
            style={{ flex: '1', marginBottom: 0 }}
          >
            <InputBar label='Phone' />
          </Form.Item>
        </div>

        <Form.Item
          name='message'
          rules={[
            {
              required: true,
              message: 'Please enter a message',
            },
          ]}
          style={{ marginBottom: 0 }}
        >
          <InputBar label='Message' type='box' />
        </Form.Item>

        <div className='flex justify-center'>
          <Button
            type='primary'
            htmlType='submit'
            className='w-48 rounded border border-fitzba-bright-gold-static bg-fitzba-bright-gold-static py-[5px] font-lexend text-[12px] text-fitzba-white-static xs:mt-5'
          >
            GET IN TOUCH
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default QuestionForm
