/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * The Verify component is a React component that verifies a user token.
 * It uses the useEffect hook to perform the verification when the component mounts.
 * The useLocation and useNavigate hooks from react-router-dom are used to access the location object and navigate the user.
 * The useSelector hook from react-redux is used to access the user information from the state.
 * The verifyUserToken function from the api.service is used to verify the user token.
 *
 * @author James Nguyen, Yang Ming
 * @version June 7th, 2023
 */

import React from 'react'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { verifyUserToken } from '../services/api.service'
import { useSelector } from 'react-redux'
import { message } from 'antd'



function Verify() {
  const location = useLocation()
  const navigate = useNavigate()
  const userinfo = useSelector((state) => state.user.userinfo)
  const searchParams = new URLSearchParams(location.search)
  
  // Using useEffect hook to perform side effects (token verification) on component mount
  useEffect(() => {
    const token = searchParams.get('token')
    const verify = async () => {
      try {
        if (token) {
        // Store the token in localStorage for later use
        localStorage.setItem('token', token)
        // Call the verifyUserToken service function with the extracted token
        const res = await verifyUserToken(token )
        // Display a message to the user based on the response
        message(res.message)
        // Navigate to home page if user info exists, otherwise to login page
        if (res) {
          navigate(userinfo ? '/' : '/login')
        }
      }
      } catch (error) {
        console.log(error)
      }
      
    }
    verify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  // Render a message to the user while verification is in progress
  return <div>Please wait one moment</div>
}

export default Verify
