/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * The Contact component renders a contact form for users to send messages.
 *
 * It includes fields for the user's name, email, phone number, and message.
 *
 * It also displays fitzbas phone number and email for users who want to contact directly.
 *
 * Once the form is submitted it sends a message to the server and displays a success or error message.
 *
 * @author Yang Ming, James Nguyen
 * @version January 26th, 2024
 */

import React, { useState } from 'react'
import './Contact.css'
import { addMessage } from '@services/api.service'
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai'
import { FiMail } from 'react-icons/fi'

export default function Contact() {
  // initial state for the form
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    subject: '',
    message: '',
  })
  const [result, setResult] = useState('')

  /**
   * getData retrieves a value from the form data.
   *
   * @param {string} key - The key of the value to retrieve.
   * @returns {string} The value associated with the key.
   */

  const getData = key => {
    return formData.hasOwnProperty(key) ? formData[key] : ''
  }

  /**
   * setData is a function that sets a value in the form data.
   *
   * @param {string} key - The key of the value to set.
   * @param {string} value - The value to set.
   */

  const setData = (key, value) => {
    return setFormData({ ...formData, [key]: value })
  }

  /**
   * handleSubmit is an asynchronous function that handles form submission.
   * It sends the form data to the server and resets the form.
   * It also sets the result state to 'success' if the submission was successful, or to the error message if there was an error.
   *
   * @param {Event} e - The form submission event.
   */

  const handleSubmit = async e => {
    e.preventDefault()
    setResult('')

    try {
      await addMessage({
        ...formData,
        type: 'contact-page',
      })
      setFormData({})
      setResult('success')
    } catch (err) {
      setResult(err.data?.message)
    }
  }

  return (
    <div className='relative min-h-[80vh]'>
      <div className='contact-wrapper'>
        <div className='contact-section'>
          <div className='max-lg:flex max-w-[305px] flex-col items-center'>
            <h1 className='w-fit'>CONTACT US</h1>
            <div className='py-5 text-left'>
              Got questions, suggestions, or just want to say hi? <br /> <br />{' '}
              We'd love to hear from you!{' '}
            </div>
            <div className='flex flex-col gap-2'>
              <span className='flex flex-row items-center gap-3'>
                <FiMail /> hello@fitzba.com
              </span>
            </div>
          </div>

          <div className='contact-form'>
            <form onSubmit={handleSubmit}>
              <div className='contact-form-input'>
                <label>Name:</label>
                <input
                  type='text'
                  name='name'
                  required
                  value={getData('name')}
                  onChange={e => setData('name', e.target.value)}
                />
              </div>
              {/* <div className='contact-form-two-columns'> */}
              <div className='contact-form-input'>
                <label>Email:</label>
                <input
                  type='email'
                  name='email'
                  required
                  pattern='^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$'
                  value={getData('email')}
                  onChange={e => setData('email', e.target.value)}
                />
              </div>
              <div className='contact-form-input'>
                <label>Phone:</label>
                <input
                  type='tel'
                  name='phoneNumber'
                  placeholder='123-456-7890'
                  pattern='^\d{3}-\d{3}-\d{4}$'
                  value={getData('phoneNumber')}
                  onChange={e => setData('phoneNumber', e.target.value)}
                />
              </div>
              {/* </div> */}
              <div className='contact-form-textarea'>
                <label>Message:</label>
                <textarea
                  name='message'
                  rows={3}
                  required
                  placeholder='Enter message here'
                  value={getData('message')}
                  onChange={e => setData('message', e.target.value)}
                />
              </div>
              <button id='contact' type='submit'>
                Get in touch
              </button>
              {result && (
                <div
                  className={
                    result === 'success' ? 'contact-success' : 'contact-error'
                  }
                >
                  <label className='contact-result'>
                    {result === 'success' ? (
                      <AiOutlineCheckCircle size={32} />
                    ) : (
                      <AiOutlineCloseCircle size={32} />
                    )}
                    {result === 'success'
                      ? `Success! We will contact you as soon as possible!`
                      : result}
                  </label>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
      <div className='absolute bottom-0 hidden h-[65px] w-full bg-fitzba-bright-gold-static phone:block' />
    </div>
  )
}
