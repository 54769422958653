import React from 'react'

export default function PlanIcon({
    type,
    size = 30
}) {
    return (
        <div className='flex items-center justify-center'>
            <img width={size} height={size} src={`images/retail/${type}.png`} alt='' />
        </div>
    )
}
