/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * This file contains the ResetPass component, which is responsible for handling user password reset.
 * The component uses the useLocation hook from 'react-router-dom' to get the token from the URL search parameters.
 * The verifyUser function is used to verify the user and reset the password.
 * The component renders a form with fields for the new password and password confirmation.
 * The form has validation rules to ensure the password is between 6 and 20 characters and that the password and password confirmation match.
 *
 * @author James Nguyen
 * @version January 31st, 2024 
 */

import { Form, Input } from "antd";
import React from "react";
import { AiOutlineLeft } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import { verifyUser } from "../../services/api.service";

const ResetPass = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);


  const handleSubmit = async (value) => {
    const token = searchParams.get("token");
    try {
        const res = await verifyUser({
            token: token,
            password: value.password,
        })
        console.log(res)
    } catch (error) {
        console.log(error)
    }
  };

  return (
    <div className="login-wrapper">
      <div className="login-top">
        <AiOutlineLeft />
        <Link to="/">Return</Link>
      </div>
      <div className="login-main">
        <div className="login-main-logo">
          <img src="/FITBIZ-WORD-ICON.png" width={400} alt="" />
        </div>
        <div className="login-main-form">
          <h3>Please enter your password</h3>
          <Form
            name="fitzba_login"
            className="login-form"
            onFinish={handleSubmit}
            style={{
              textAlign: "left",
              width: "100%",
            }}
          >
            <div className="flex flex-col gap-5 w-full">
              <Form.Item
                name="password"
                // label="Password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                  {
                    max: 20,
                    min: 6,
                    message: "Password: 6-20 chars.",
                  },
                ]}
                className="register-form-item"
              >
                <Input.Password
                  size="large"
                  placeholder="Input your password"
                />
              </Form.Item>

              <Form.Item
                name="confirmPassword"
                // label="Confirm Password"
                dependencies={["password"]}
                // hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Passwords do not match!")
                      );
                    },
                  }),
                ]}
                className="register-form-item"
              >
                <Input.Password size="large" placeholder="Confirm password" />
              </Form.Item>
            </div>
            <Form.Item>
              <button size="large" className="login-button">
                Recover
              </button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ResetPass;
