import React from 'react'
import TaskStepItem from '../../profile/components/TaskStepItem'
import { getOfferTaskStatus } from 'services/api.user.service'
import { useQuery } from '@tanstack/react-query'

export default function GivewayTask() {

    const {
        isPending,
        // error,
        data,
    } = useQuery({
        queryKey: ['getOfferTaskStatus'],
        queryFn: () => getOfferTaskStatus(),
    })

    if (isPending)
        return <div>Loading...</div>

    const status = data?.data || {
        isAllFinished: false,
        favoriteListCount: 0,
        favoriteStoresCount: 0,
        inviteFriendsCount: 0,
        scanQRCode: false,
        downloadApp: false,
        createAccount: true,
        entries: 0
    }

    return (
        <div className='w-full smsize:py-0'>
            <h4 className='uppercase text-[25px] font-normal text-left mb-3'>
                My contests
            </h4>
            <div className='m-auto shadow smsize:w-full bg-white rounded-2xl text-[#E4B456] border p-5 text-left flex flex-col gap-3 smsize:border-0 smsize:rounded-none smsize:shadow-none'>
                <div>
                    <h3 className='uppercase text-[20px]'>
                        $500 shopping spree Giveaway
                    </h3>
                    <span className='text-[14px] text-gray-400'>Contest Ends January 2, 2025</span>
                    <p className='uppercase text-[20px] font-light text-[#CD9426]'>checklist</p>
                </div>
                <div className='w-full flex justify-between items-end'>
                    <span className='bg-[#F9EDD7] text-[16px] rounded-full px-5 py-2 w-[160px]'>
                        Your Entries: <strong>{status?.entries}</strong>
                    </span>
                    {/* <span className='text-[14px] text-[#A0A0A0] font-light'>view details</span> */}
                </div>
                <div className='border-[#F5E2BB] border rounded-2xl flex gap-5 smsize:flex-col'>
                    <div className='p-3 flex flex-col gap-2'>
                        <h4 className='uppercase text-[16px] text-[#E4B456] font-light'>GET MORE CONTEST ENTRIES</h4>
                        <div className='flex text-left gap-5 smsize:flex-col'>
                            <TaskStepItem text="Create an Account" isCompleted={status?.createAccount} />
                            <TaskStepItem text="Download the Fitzba mobile app" isCompleted={status?.downloadApp} />
                            <TaskStepItem text="Add a Favorite Store" isCompleted={status?.favoriteStoresCount > 0} />
                            <TaskStepItem text="Create a Shopping List" isCompleted={status?.favoriteListCount > 0} />
                            <TaskStepItem text="Invite a Friend" isCompleted={status?.inviteFriendsCount > 0} />
                        </div>
                    </div>
                    {/* <div className='p-3 flex flex-col gap-2 border-[#F5E2BB] border-l'>
                        <h4 className='uppercase text-[16px] text-[#E4B456] font-light'>Repeatable Entries</h4>
                        <div className='flex text-left gap-5 smsize:flex-col'>
                            <TaskStepItem text="Scan a Fitzba store QR" isCompleted={status?.scanQRCode}
                                desc="Per store once a day"
                            />
                            <TaskStepItem text="Invite a Friend" isCompleted={status?.inviteFriendsCount > 0} />
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}
