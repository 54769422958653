/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * FilterSideCategory defines a component for filtering by condition in a sidebar.
 * 
 * @author Yang Ming
 * @version February 7th, 2024 
 */

import React from 'react'
// import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai'
import { conditionList } from '@data/filter.data'
import FilterSideSectionHeader from './FilterSideSectionHeader'
import { setFilterState } from '@reducers/userReducer'
import { useSelector } from 'react-redux'


/**
 * A component for filtering by condition in a sidebar.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.itemsFilter - The current filter settings.
 * @param {Function} props.setFilterCondition - A function to set the condition filter.
 * @param {Function} props.dispatch - The Redux dispatch function.
 */
export default function FilterSideCondition({
  itemsFilter,
  setFilterCondition,
  dispatch,
  // isMobile
}) {


  // const [isOpen, setIsOpen] = useState(!isMobile ? (false) : !isMobile)

  // Get the current state of the condition filter
  const isOpen = useSelector(state => state.user.filterState.condition)

  // Function to set the state of the condition filter
  const setIsOpen = (value) => {
    dispatch(setFilterState({ type: 'condition', value }))
  }

  return (
    <div className='filter-item' style={{
      overflow: 'hidden',
      marginBottom: '100px'
    }}>
      {/* <div className='filter-item-header'>
        <div className='filter-item-header-title'>
          <h4 className='cursor-pointer' onClick={() => setIsOpen(!isOpen)}>Condition</h4>
        </div>
        {isOpen
          ? <AiOutlineUp size={16} onClick={() => setIsOpen(!isOpen)} className='cursor-pointer' />
          : <AiOutlineDown size={16} onClick={() => setIsOpen(!isOpen)} className='cursor-pointer' />}
      </div> */}
      <FilterSideSectionHeader
        title='Condition'
        isOpen={isOpen}
        onClickTitle={() => setIsOpen(!isOpen)}
        isHighlight={itemsFilter?.condition.length > 0}
      />
      <div className='filter-item-list' style={{ display: isOpen ? 'flex' : 'none' }}>
        {conditionList?.map((item, index) => (
          <div className='filter-checkbox-item' key={index}>
            <label>
              <input
                value={item.value}
                type='checkbox'
                className='custom-checkbox'
                checked={itemsFilter?.condition?.includes(item.value)}
                onChange={e => {
                  dispatch(
                    setFilterCondition({
                      name: item.value,
                      checked: e.target.checked,
                    }),
                  )
                }}
              />
              <span className='truncate'>{item.label}</span>
            </label>
          </div>
        ))}
      </div>
    </div>
  )
}
