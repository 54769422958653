/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * Registerv2 is a functional React component that renders a multi-step registration
 * form to collect user and business information for account creation.
 *
 *  *
 * @author Yang Ming
 * @version May 09, 2023
 */

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
// import { registerConsumer, registerBusiness } from '@services/api.service'
import { message } from 'antd'
import { useSelector } from 'react-redux'
import RegisterBusiness from './components/RegisterBusiness'
import { addMessage } from '@services/api.service'
import { InlineWidget, useCalendlyEventListener } from 'react-calendly'
import { Config } from '@Config'

export default function RegisterV3() {
  const [current, setCurrent] = useState(0)
  const [formData, setFormData] = useState({})
  const [calendlyLink, setCalendlyLink] = useState('')
  const isMobile = useSelector(state => state.content.isMobile)

  async function handleSubmit(values) {
    try {
      await addMessage({
        ...values,
        type: 'launch-offer',
      })
      message.success('Launch offer registration successful!')
      // delay 1 second
      setTimeout(() => {
        setCurrent(2)
      }, 1000)
    } catch (err) {
      console.log('err', err)
      message.error(err?.data?.message || 'Action failed, please try again')
    }
  }

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      console.log('calendly event scheduled', e)
      setCalendlyLink(e.data?.payload?.event?.uri)
      handleSubmit({
        ...formData,
        calendlyLink: e.data?.payload?.event?.uri,
      })
    },
  })

  const finishButtonColor = calendlyLink ? 'fitzba-bright-gold-static' : 'gray-300'
  const finishButtonHoverColor = calendlyLink ? 'white' : 'gray-300'
  return (
    // <div className=' flex  h-max tall:h-[100vh]'>
    //   <div className='hidden flex-1 flex-col items-center justify-center bg-fitzba-off-black-static xl:flex'>
    <div className='flex min-h-[100vh]'>
      <div className='relative hidden flex-1 xl:flex'>
        <div className='fixed flex h-[100vh] w-1/2 flex-col items-center justify-center bg-fitzba-off-black-static'>
          <Link to='/'>
            <img
              src='/fitzba-ICON-WORD-WHITE.png'
              alt='Fitzba.com'
              className='md:w-[200px] lg:w-[400px]'
            />
          </Link>
          <div className='mt-[122px] w-[276px] text-center text-[20px] font-light text-white'>
            Have questions?{' '}
            <Link
              to='/contact'
              className=' text-fitzba-bright-gold-static underline'
            >
              Contact us
            </Link>{' '}
            and we will get back to you with answers
          </div>
        </div>

        <div className='mt-[122px] w-[276px] text-center text-[20px] font-light text-white'>
          Have questions?{' '}
          <Link
            to='/contact'
            className=' text-fitzba-bright-gold-static underline'
          >
            Contact us
          </Link>{' '}
          and we will get back to you with answers
        </div>
      </div>
      <div className='register-wrapper flex-[1]'>
        <div className='flex min-h-[100vh] w-full flex-col items-center justify-center p-2 xl:flex-row'>
          <div className='block xl:hidden'>
            <img src='/fitzba-ICON-WORD-BLACK.png' width={240} alt='Fitzba' />
          </div>
          <div className='register-main-form'>
            {current === 0 && (
              <>
                <h3 className='pb-[40px] text-[38px] font-medium uppercase'>
                  Secure Your Spot<br /> in Our Exclusive<br /> Launch Offer!
                </h3>
                <RegisterBusiness
                  formData={formData}
                  setFormData={setFormData}
                  handleSubmit={handleSubmit}
                  setCurrent={setCurrent}
                />
              </>
            )}
            {current === 1 && (
              <>
                <h3 className='text-[38px] font-medium uppercase'>
                  Set up a time to meet
                </h3>
                {/* <p className='text-[30px] font-light'>Meet with a Fitzba team member <br />to set up your account</p> */}
                <div className='w-full overflow-hidden'>
                  <InlineWidget
                    // url='https://calendly.com/alana-fitzba/intro-call'
                    url={Config.calendlyUrl}
                    // HOOG377CRRYQHP3RKT2JCDWVJWWXQ5MJ
                    // eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzE1NjYzNjU2LCJqdGkiOiI3M2JiODE1Yi01NGQ2LTQwYjAtYjM0My0xMzExMWE2MTg4OTgiLCJ1c2VyX3V1aWQiOiJlODFhNWRlMi1lYjNkLTRmOGQtYjBiNC1iMjEyNGViZWY0ZTgifQ.kbp-xWR0rsy27rfrF0SVIHIF_atVF1b0cYC6RD7FKrBH_p7HDpDjKIETtIFUuwoR1K7CttLH7kUWcjDiTLJ4Iw
                    styles={{ overflow: 'hidden', height: '700px' }}
                    prefill={{
                      name: formData?.name,
                      email: formData?.email,
                      smsReminderNumber: formData?.phoneNumber,
                      customAnswers: {
                        a1: "Launch Offer",
                      }
                    }}
                    pageSettings={{
                      hideEventTypeDetails: true,
                      // hideLandingPageDetails: true,
                    }}
                  />
                </div>
                <div
                  className='mx-auto flex w-[300px] items-center justify-center gap-2'
                >
                  <div
                    className='flex w-full max-w-[100px] cursor-pointer justify-center rounded-full border-2 border-black bg-white p-[6px] text-black hover:bg-slate-200'
                    onClick={() => setCurrent(0)}
                  >
                    BACK
                  </div>
                  <button
                    className={`flex w-full cursor-pointer justify-center rounded-full border-2 border-${finishButtonColor} bg-${finishButtonColor} p-[6px] text-white hover:bg-${finishButtonHoverColor} hover:text-${calendlyLink ? 'fitzba-bright-gold-static' : 'white'}`}
                    onClick={() => setCurrent(2)}
                    disabled={!calendlyLink}
                  >
                    FINISH
                  </button>
                </div>
              </>
            )}
            {current === 2 && (
              <div className='gap-[10px] mx-auto flex w-full flex-col items-center justify-center text-black'>
                <h3 className='text-[38px] font-medium text-black uppercase'>
                  {isMobile ?
                    <>
                      Thank you for<br />your interest in<br /> our launch offer!
                    </> :
                    <>
                      Thank you for<br />your interest in our <br />launch offer!
                    </>
                  }
                </h3>
                <p className='text-[22px] font-light'>Be sure to check your email. We will be in touch!</p>
                {/* <Link
                  to='/search'
                  className='w-[200px] flex cursor-pointer justify-center rounded-full border-2 border-fitzba-bright-gold-static bg-fitzba-bright-gold-static p-[6px] text-white hover:bg-white hover:text-fitzba-bright-gold-static'
                >
                  START SEARCHING
                </Link> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
