/**
 * Copyright Fitzba Technologies Inc. 2023
 * The NearProducts component fetches and displays a list of products that are near the user's location.
 * 
 * Each product is displayed as an Item component.
 * 
 * The component uses the useQuery hook from react-query for data fetching
 * and the useSelector hook from react-redux to get the user's location and information.
 * 
 * The component also provides a horizontal scrolling feature for the list of products.
 *
 * @author Yang Ming
 * @version June 5th, 2023
 */


import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ShoppingCartHook from '@hooks/ShoppingCart.hook'
import { getFavoriteItems } from '@services/api.user.service'
import LoadingSection from '@components/LoadingSection'
import { useQuery } from '@tanstack/react-query'
import SelectableItemCard from './SelectableItemCard'
import SearchPagination from './SearchPagination'
import { Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

/**
 * NearProducts fetches and displays a list of products that are near the user's location.
 * 
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.setIsShowRecommended - The function to set the visibility of recommended products (no longer used).
 * @param {Array} props.searchHistory - The user's search history.
 * @returns {JSX.Element} The JSX code for the NearProducts component.
 */
export default function FavoriteFormItems({
    business,
    isMobile,
    selectedList, setSelectedList
}) {
    const location = useSelector(state => state.user.location)
    // const userinfo = useSelector(state => state.user.userinfo)
    const [input, setInput] = React.useState('')
    const [filter, setFilter] = React.useState({
        title: '',
        sortBy: 'latest',
        currentPage: 1
    })

    const productListRef = React.useRef(null)

    const {
        isPending,
        // error,
        data,
    } = useQuery({
        queryKey: ['getFavoriteItems', 'list', location?.lat, location?.lng, filter],
        queryFn: async () => {
            const params = {
                lat: location?.lat,
                lng: location?.lng,
                type: 'list',
                ...filter
            }
            console.log('params', params)
            const res = await getFavoriteItems(params)
            console.log('res', res)
            return res
        },
    })

    const onClickItem = (id) => {
        console.log('onClickItem', id)
        if (selectedList.includes(id)) {
            setSelectedList(selectedList.filter((item) => item !== id))
        } else {
            setSelectedList([...selectedList, id])
        }
    }

    const updateFilter = (key, value) => {
        let params = {
            ...filter,
            [key]: value
        }
        if (key !== 'currentPage') {
            params.currentPage = 1
        }
        setFilter(params)
        console.log('params', params)
    }

    if (isPending)
        return <LoadingSection text='Loading items' height='200px' />

    const items = data || null

    return (
        <section id="favorite-item-list" className="w-full max-w-[1440px] relative m-auto flex flex-col gap-5">
            <div className='flex justify-between items-center font-light'>
                <Input
                    allowClear
                    className='rounded-full max-w-[500px]'
                    placeholder="Search your favourites"
                    prefix={<SearchOutlined />}
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            console.log('search', e.target.value)
                            updateFilter('title', e.target.value)
                        }
                    }} />
                <div className='smsize:text-[12px] flex items-center justify-between gap-1 border rounded-full px-2 py-1 smsize:p-2'>
                    <h3>Sort by:</h3>
                    <select
                        className='border-none font-light'
                        onChange={(e) => {
                            updateFilter('sortBy', e.target.value)
                        }}
                        value={filter.sortBy}
                    >
                        <option value="latest">Latest</option>
                        <option value="low-to-high">Low to High</option>
                        <option value="high-to-low">High to Low</option>
                    </select>
                </div>
            </div>
            <div className="w-full flex gap-5 text-left flex-wrap smsize:gap-3" ref={productListRef}>
                {items?.data?.map((item) => {
                    return <SelectableItemCard key={item?._id}
                        item={item}
                        selectedList={selectedList}
                        onClick={onClickItem}
                    />
                })}
            </div>
            <SearchPagination itemsList={items} updateFilter={updateFilter}/>
            {/* {storeProfileList.currentPage < storeProfileList.totalPages &&
                <div className='mt-5'>
                    <ShowMore onClick={onClickShowmore} />
                </div>
            } */}
        </section>
    )
}
