/**
 * Copyright Fitzba Technologies Inc. 2023
 * Login is a functional React component that renders the login form
 * with email, password, and Google ReCAPTCHA inputs. It handles user
 * authentication and navigation upon successful login.
 *
 * @author Yang Ming
 * @version May 09, 2023
 */

import React from 'react'
import './Login.css'
import { useSelector } from 'react-redux'
import LoginLeftNew from './components/LoginLeftNew'
import LoginPage1 from './components/LoginPage1New'
import LoginPage2 from './components/LoginPage2New'

/**
 * @returns Renders the login form with email, password, and Google ReCAPTCHA inputs
 */
export default function LoginV4() {

  const [recaptchaCode, setRecaptchaCode] = React.useState('')
  const tempUser = useSelector(state => state.temp.userinfo)

  const [page, setPage] = React.useState(1)

  // dispatch(setIsGoogle(false))

  return (
    <div className='flex h-max tall:h-[100vh]'>
      <LoginLeftNew />
      <div className='flex-[1] m-auto w-full h-[100vh] bg-white flex items-center justify-center'>
        <div className='flex min-h-[100vh] w-full items-center justify-center p-2'>
          {page === 1 && (
            <LoginPage1
              setLoginPage={setPage}
              tempUser={tempUser}
              recaptchaCode={recaptchaCode}
              setRecaptchaCode={setRecaptchaCode}
            />
          )}
          {page === 2 &&
            <LoginPage2
              setLoginPage={setPage}
              tempUser={tempUser}
              recaptchaCode={recaptchaCode}
            />
          }
        </div>
      </div>
    </div>
  )
}
