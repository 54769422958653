/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * The BlogView component renders the full content of a blog post.
 * 
 * It displays the blog post's title, picture, description, date, content, and tags.
 * 
 * It also includes a section for the author and a section for related blog posts.
 * 
 * The component uses the Helmet library to set the HTML document's title and meta tags based on the blog post.
 * 
 * It receives the current blog post and the blog list state as props.
 *
 * @author Yang Ming, James Nguyen
 * @version October 1st, 2023 
 */

import './BlogView.css'
import React from 'react'
import moment from 'moment'
import BlogItem from './BlogItem'
import { HelmetProvider, Helmet } from 'react-helmet-async'
import { Config } from '@Config'


/**
 * @param {Object} props - The props for the component.
 * @param {Object} props.currentBlog - The current blog post.
 * @param {Object} props.blogList - The blog list state.
 */
export default function BlogView({
    currentBlog,
    blogList
}) {
    // if (!currentBlog) return null
    return (
        <HelmetProvider>
            <Helmet>
                <title>{currentBlog?.title} - {Config.appName}</title>
                <meta name="description" content={currentBlog?.seo?.description || currentBlog?.description} />
                <meta name="keywords" content={currentBlog?.seo?.keywords || currentBlog?.tags?.join(', ')} />
            </Helmet>
            <div className="blog-detail">
                <div className='blog-detail-content text-left text-[14px]'>
                    <div className='border border-black rounded-xl w-full h-fit px-[25px]'>
                    <h3 className='text-left font-medium text-[55px]'>{currentBlog?.title}</h3>
                    </div>
                    <p className='image'>{currentBlog?.picture && <img src={currentBlog?.picture} alt={currentBlog?.title} />}</p>
                    <p className='desc desc-content ql-editor' dangerouslySetInnerHTML={{ __html: currentBlog?.description }}></p>
                    <p className='date'>Date: {moment(currentBlog?.updatedAt).format('dddd MMM DD, YYYY')}</p>
                    <p className='content desc-content ql-editor' dangerouslySetInnerHTML={{ __html: currentBlog?.content }}></p>
                    {currentBlog?.tags && <p className='tags'>Tags: {currentBlog?.tags}</p>}
                </div>
                <div className='blog-detail-side'>
                    <div className='blog-view-author'>
                        <div className='author-content'>
                            <img src='/logo192.png' alt={currentBlog?.title} />
                            <div className='author-desc text-center'>
                                <p>Written by:</p>
                                <p className='text-[18px] font-light'>{currentBlog?.author}</p>
                                <p>Fitzba.com</p>
                            </div>
                        </div>
                    </div>
                    {blogList?.data.length > 1 && <div className='blog-view-related text-left'>
                        <h3>More Blogs:</h3>
                        {blogList?.data && blogList?.data?.filter(item => item._id !== currentBlog?._id).slice(0, 4).map((item, index) => (
                            <BlogItem
                                key={index}
                                index={index}
                                currentRow={item}
                                isSide={true}
                            />
                        ))}
                    </div>}
                </div>
            </div>
        </HelmetProvider>
    )
}
