/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * CartEmpty is a functional React component that displays a message when the user's shopping cart is empty.
 * It shows an image and a button to start searching for items.
 * If the user is not logged in, it also provides a link to log into their account.
 *
 * @author James Nguyen, Yang Ming
 * @version December 12th, 2023
 */

import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import './CartEmpty.css'
import { useDispatch, useSelector } from 'react-redux'
import { setBackToRoute } from '@reducers/tempReducer'

export default function CartEmpty() {
  const currentRoute = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userinfo = useSelector(state => state.user.userinfo)

  const handleCartLogin = () => {
    dispatch(setBackToRoute(currentRoute.pathname))
    navigate('/login')
  }
  return (
    <div className='cart-empty'>
      <h3>Your Shopping Cart is empty</h3>
      <img src='/images/cart-empty.png' alt='cart-empty' />
      <Link to='/search'>
        <button>Start searching</button>
      </Link>
      {!userinfo && (
        <p>
          To see any items you have already added to your list,{' '}
          <span
            className='cursor-pointer text-[#e4b456] underline'
            onClick={handleCartLogin}
          >
            log into your account
          </span>
        </p>
      )}
    </div>
  )
}
