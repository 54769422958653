/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * LoginPage2 is a functional React component that renders the second page of the login process.
 * It includes a form for the user to enter their password and a checkbox for remembering the user.
 * It also handles the form submission, signing in the user and navigating to the previous page.
 *
 * @author Yang Ming, James Nguyen
 * @version January 30th, 2024
 */

import React, { useContext } from 'react'
import { Form, Input, message } from 'antd'
import { VscAccount } from 'react-icons/vsc'
import { BiLockAlt } from 'react-icons/bi'
import { RxEyeClosed } from 'react-icons/rx'
import { VscEye } from 'react-icons/vsc'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
// import { setTempUserinfo } from '@reducers/tempReducer'
import { AuthContext } from '@contexts/AuthProvider'

const useAuth = () => useContext(AuthContext)

/**
 * Handles the form submission.
 * Signs in the user and navigates to the previous page.
 *
 * @param {Object} values - The form values.
 */

export default function LoginPage2({
    recaptchaCode,
    setLoginPage,
    tempUser,
}) {
    const navigate = useNavigate()
    const auth = useAuth()
    const [rememberMe, setRememberMe] = React.useState(false)

    const backToRoute = useSelector(state => state.temp.backToRoute) || '/'

    const handleSubmit = async values => {
        // if (!recaptchaCode) {
        //     message.error('Please verify that you are not a robot')
        //     return
        // }

        await auth.signIn(
            {
                ...values,
                recaptchaCode,
            },
            () => navigate(backToRoute, { replace: true }),
        )
    }

    return (
        <div className='m-[auto] flex w-full flex-col items-center justify-center gap-[20px] p-5 text-black'>
            <div className='hidden smsize:block smsize:mb-10'>
                <img width={240} src="/fitzba-ICON-WORD-BLACK.png" alt="Fitzba Logo" />
            </div>
            <h3 className='text-[30px] smsize:text-[30px] font-medium'>Welcome back</h3>
            <h3 className='text-[24px] font-light uppercase'>
                {tempUser?.firstName}
            </h3>
            <Form
                name='fitzba_login'
                className='w-[340px] text-left'
                onFinish={handleSubmit}
            >
                <Form.Item
                    name='username'
                    initialValue={tempUser?.username}
                    rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid email',
                        },
                        {
                            required: true,
                            message: 'Please input your email',
                        },
                    ]}
                    hasFeedback
                >
                    <Input
                        size='large'
                        className='rounded-full border-black'
                        prefix={<VscAccount size={22} />}
                        placeholder='Email'
                        disabled
                        allowClear
                    />
                </Form.Item>
                <Form.Item
                    name='password'
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password',
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password
                        size='large'
                        className='rounded-full border-black'
                        prefix={<BiLockAlt size={22} />}
                        iconRender={visible =>
                            visible ? <VscEye /> : <RxEyeClosed />
                        }
                        placeholder='Password'
                    />
                </Form.Item>
                <div className='flex items-center justify-between text-[12px] text-[#A6A6A6] mb-4'>
                    <label className='flex gap-2 cursor-pointer'>
                        <input
                            type='checkbox'
                            className='custom-checkbox'
                            checked={rememberMe}
                            onChange={e => setRememberMe(e.target.checked)}
                        />{' '}
                        Remember me
                    </label>
                    <Link to='/recover'>Forgot your password?</Link>
                </div>
                <Form.Item className='w-full text-left text-fitzba-bright-gold-static'>
                    <div className='flex flex-row gap-3'>
                        <button
                            size='large'
                            type='button'
                            className='px-5 flex w-full flex-[1] cursor-pointer items-center justify-center rounded-full border border-black bg-white text-base text-black hover:bg-slate-300'
                            onClick={() => { setLoginPage(1) }}
                        >
                            BACK
                        </button>
                        <button
                            type='submit'
                            size='large'
                            className='flex w-full flex-[2] cursor-pointer justify-center rounded-full  border-2 border-fitzba-bright-gold-static bg-fitzba-bright-gold-static p-[6px] text-base text-white hover:bg-white hover:text-fitzba-bright-gold-static'
                        >
                            LOGIN
                        </button>
                    </div>
                </Form.Item>
            </Form>
        </div>
    )
}
