/**
 * faq is a functional React component that renders a frequently asked
 * questions (FAQ) accordion using the Ant Design Collapse component.
 *
 *
 * @author James Nguyen, Yang Ming
 * @version Nov 06, 2023
 */

import { useState } from 'react'
import { getFaqList } from '@services/api.service'
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai'
import Loading from '@components/Loading'
import { useQuery } from '@tanstack/react-query'

/**
 * FAQ is a functional React component that renders an FAQ accordion.
 * It fetches a list of FAQs and displays them in an accordion format.
 * Each FAQ can be expanded or collapsed by clicking on it.
 * 
 * @returns {JSX.Element} The JSX code for the FAQ component.
 */

export default function FAQ() {
  const [activeIndex, setActiveIndex] = useState(null)

  // Fetch the FAQ list using react-query
  const {
    isPending,
    error,
    data: faqList,
  } = useQuery({
    queryKey: ['faqList'],
    queryFn: getFaqList,
  })

/**
 * handleAccordionClick is a function that expands or collapses an FAQ when it is clicked.
 * If the FAQ is already expanded, it collapses it. If it is collapsed, it expands it.
 * 
 * @param {number} index - The index of the FAQ that was clicked.
 */
  const handleAccordionClick = index => {
    setActiveIndex(index === activeIndex ? null : index)
  }
  // Show a loading spinner while the FAQ list is being fetched
  if (isPending) return <Loading />
  // If there was an error while fetching return an error message
  if (error) return 'About Page: An error has occurred: ' + error.message

  return (
    <div
      className='container mx-auto mb-20 px-5 font-lexend lg:px-80'
      style={{ backGroundColor: '#FFF' }}
    >
      <h1 className='pt-20 pb-12 text-left text-[64px] font-medium'>FAQ</h1>
      {faqList?.data?.map((faq, index) => (
        <div key={index} className='mb-4'>
          <div
            className='flex cursor-pointer items-center justify-between rounded-xl border-2 border-black bg-white px-5 py-5'
            onClick={() => handleAccordionClick(index)}
            style={{ backgroundColor: activeIndex === index ? '#F1F1F1' : '' }}
          >
            <div className='text-left text-[22px] font-medium'>
              {faq.question}
            </div>
            <div className='text-2xl font-semibold text-fitzba-bright-gold-static'>
              {activeIndex === index ? (
                <AiOutlineMinus size={20} />
              ) : (
                <AiOutlinePlus size={20} />
              )}
            </div>
          </div>
          {activeIndex === index && (
            <div className='mt-2 rounded-b-xl border-2 border-black bg-white px-5 py-5 text-left text-[17px] font-[330]'>
              <p dangerouslySetInnerHTML={{ __html: faq.answer }} />
            </div>
          )}
        </div>
      ))}
    </div>
  )
}
