import React, { useState, useEffect } from 'react'
import { MdArrowForwardIos } from "react-icons/md"
import { useSelector, useDispatch } from 'react-redux'
import { setNewStoreFilter } from 'reducers/contentReducer'

export default function StoreFilterLocation({
    isMobile
}) {
    const dispatch = useDispatch()
    const newStoreFilter = useSelector(state => state.content.newStoreFilter)
    const [input, setInput] = useState(newStoreFilter?.distance)

    useEffect(() => {
        setInput(newStoreFilter?.distance)
    }, [newStoreFilter?.distance])

    return (
        <div className='filter-item' style={{
            overflow: 'hidden',
        }}>
            <div className='filter-item-header'>
                <div className='filter-item-header-title'>
                    <h4 className='cursor-pointer text-black'>Location</h4>
                </div>
            </div>
            <div className='flex flex-row items-center gap-1 font-light text-[16px] text-black'>
                Up to{' '}
                <input
                    className='ml-1 h-7 w-[3.75rem] border rounded-md bg-white px-[5px] py-[10px] text-base text-black'
                    type='text'
                    name='distance'
                    placeholder='km'
                    value={input === -1 ? '' : input}
                    onChange={(e) => {
                        setInput(Number(e.target?.value.replace(/[^0-9]/g, '') || -1))
                    }}
                    onKeyDown={k => {
                        if (k.key === 'Enter') {
                            dispatch(setNewStoreFilter({
                                ...newStoreFilter,
                                distance: input
                            }))
                        }
                    }}
                />
                <button
                    className='flex h-7 w-7 items-center justify-center rounded-md bg-white hover:bg-[#D9D9D9] text-base text-black'
                    onClick={() => {
                        dispatch(setNewStoreFilter({
                            ...newStoreFilter,
                            distance: input
                        }))
                    }}
                >
                    <MdArrowForwardIos />
                </button>
            </div>
        </div>
    )
}
