/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * The LandingLocation component displays the user's shopping location.
 * It uses the useSelector hook from react-redux to get the user's location.
 *
 * @author Yang Ming
 * @version November 21st, 2023
 */

import React from 'react'
import { useSelector } from 'react-redux'

/**
 * LandingLocation displays the user's shopping location.
 * 
 * @returns {JSX.Element} The JSX code for the LandingLocation component.
 */

export default function LandingLocationNew() {
    const location = useSelector(state => state.user.location)
    const userinfo = useSelector(state => state.user.userinfo)
    return (
        <div className='w-full flex items-center justify-center'>
            <div className='w-full smsize:text-[15px] smsize:w-full bg-[#E4B456] text-white text-[18px] font-light py-3 uppercase font-lexend'>
                Hello, {userinfo ? userinfo?.firstName + '!' : ''} YOU'RE SHOPPING IN{' '}
                <span className='font-semibold'>
                    {location?.city}, {location?.province}
                </span>
            </div>
        </div>
    )
}
