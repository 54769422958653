import React from 'react'

export default function FormButtons({
    backText = 'BACK',
    onClickBack,
    submitText,
    onClickSubmit
}) {
    return (
        <div className='w-full flex items-center justify-center'>
            <div className='w-[340px] flex flex-row gap-3'>
                <div
                    size='large'
                    className='px-5 flex w-full flex-[1] cursor-pointer items-center justify-center rounded-full border border-black bg-white text-base text-black hover:bg-slate-300'
                    onClick={onClickBack}
                >
                    {backText}
                </div>
                <button
                    type='submit'
                    size='large'
                    className='flex w-full flex-[2] cursor-pointer justify-center rounded-full  border-2 border-fitzba-bright-gold-static bg-fitzba-bright-gold-static p-[6px] text-base text-white hover:bg-white hover:text-fitzba-bright-gold-static'
                    onClick={onClickSubmit}
                >
                    {submitText}
                </button>
            </div>
        </div>
    )
}
