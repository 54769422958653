/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 *  Get the app pop-up window
 *
 * @author Yang Ming
 * @version June 09, 2023
 */

import React from 'react'
import { Modal } from 'antd'
import './DownloadApp.css'


export default function DownloadApp({
  isOpen,
  setIsOpen
}) {


  return (
    <Modal
      // title="Get the app"
      open={isOpen}
      onCancel={() => {
        setIsOpen(false)
      }}
      footer={null}
      forceRender
      destroyOnClose={false}
      width={600}
      zIndex={99999}
    >
      <div className='download-wrapper'>
        <div className='download-header'>
          <img src="/logo192.png" width={100} alt="logo" />
          <h1>Get the fitzba app!</h1>
          <p>Shopping local just got convenient</p>
        </div>
        <div className='download-qr'>
          <img src="/images/QR_Code.png" alt="qr" />
          <p>Scan the QR to download the app</p>
        </div>
        <div className='download-icons'>
          <a href="https://apps.apple.com/us/app/fitzba/id6447017095" target='_blank' rel="noreferrer">
            <img src="/images/app_store.png" alt="app store" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.fitzba.mobile&hl=en_US&pli=1" target='_blank' rel="noreferrer">
            <img src="/images/google_play.png" alt="google play" />
          </a>
        </div>
      </div>
    </Modal >
  )
}
