/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * InputSuffixTip is a functional React component that renders an
 * InfoCircle icon inside a Tooltip. The Tooltip will display the
 * given text when the user hovers over the icon. This component
 * can be used as a suffix for input fields to provide additional
 * information to the user.
 *  
 * @author Yang Ming
 * @version May 09, 2023
 */

import React from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'



/**
 *
 * @param text - The text to be displayed in the tooltip
 * @param color - The color of the icon
 * @returns - A React component
 */
export default function InputSuffixTip({
    text,
    color
}) {
    return (
        <Tooltip title={text}>
            <InfoCircleOutlined
                style={{
                    color: color || 'rgba(0,0,0,.45)',
                }}
            />
        </Tooltip>
    )
}
