import React from 'react'
import CartReserveCompleted from './CartReserveCompleted'
import CommonModal from '@components/CommonModal'

/**
 * Displays a form for the user to reserve items in their cart.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.userinfo - The user's information.
 * @param {boolean} props.isMobile - Whether the user is on a mobile device.
 * @param {Object} props.cartData - The data of the user's cart.
 * @param {Function} props.setShowPopup - Function to show or hide the popup.
 *
 * @returns {JSX.Element} The rendered component.
 */

export default function CartReservationFinished({ showPopup, setShowPopup }) {
  return (
    <CommonModal
      isOpen={showPopup}
      setIsOpen={setShowPopup}
      width={'600px'}
      children={
        <div className='relative flex h-fit w-full flex-col items-center justify-center rounded-3xl bg-fitzba-white-static font-lexend drop-shadow-2xl sm:w-[500px] lg:w-[600px]'>
          <div
            className={`flex h-[120px] w-full items-center justify-center rounded-t-3xl bg-black`}
          >
            <img
              src='/fitzba-ICON-WORD-WHITE.png'
              alt='logo'
              className='h-16'
            />
            <div className='absolute top-4 right-5 text-xl text-[#a6a6a6]'>
              <button
                onClick={() => {
                  setShowPopup(false)
                }}
              >
                x
              </button>
            </div>
          </div>

          <div className='w-full overflow-y-scroll px-[10px]'>
            <CartReserveCompleted />
          </div>
        </div>
      }
    />
  )
}
