import React from 'react'

export default function ShowMore({
    onClick
}) {
    return (
        <button className='border-2 rounded-full px-5 py-2 cursor-pointer'
            onClick={onClick}>
            show more
        </button>
    )
}
