import React from 'react'

export default function StoreMedia() {
    return (
        <div className='w-full py-10'>
            <div className='m-auto w-full max-w-[1280px] flex justify-between gap-10 smsize:max-w-full smsize:flex-col smsize:p-5 smsize:gap-5'>
                This store has no social media content
            </div>
        </div>
    )
}
