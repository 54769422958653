/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * RetailRegisterStep5 is a functional React component that handles the fifth step in the registration process for retail users.
 * It renders a form for users to enter their email to book a demo.
 * It also handles form submission, sending a request to book a demo with the entered email.
 * If the form is submitted from the demo page, the user is redirected to the sixth step.
 * If the form is submitted from the retail page, the user is redirected to the previous step.
 *
 * @author James Nguyen, Yang Ming
 * @version October 13th, 2023
 */

import React from 'react'
import { bookDemo } from '@services/api.service'
import { Form, Input } from 'antd'

/**
 * Handles the fifth step in the registration process for retail users.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.handleSubmit - Function to handle form submission.
 * @param {Function} props.setCurrent - Function to set the current step.
 * @param {Function} props.setFormData - Function to update the form data.
 * @param {Object} props.formData - The data from the form.
 * @param {Function} props.setBookDemo - Function to set the book demo state.
 * @param {Function} props.next - Function to go to the next step.
 * @param {Function} props.prev - Function to go to the previous step.
 *
 * @returns {JSX.Element} The rendered component.
 */
function RetailRegisterStep5({
  handleSubmit,
  setCurrent,
  setFormData,
  formData,
  setBookDemo,
  next,
  prev,
}) {
  const onPrev = () => {
    setFormData({
      ...formData,
    })
    if (setBookDemo) {
      setBookDemo(false)
    }

    if (prev) {
      prev()
    }
    setCurrent(0)
  }

  const onFinish = async values => {
    console.log(values)
    await bookDemo(values)
    if (handleSubmit) {
      // demo page to book a demo
      setCurrent(6)
    } else {
      // retail page to book a demo
      prev()
    }
  }
  return (
    <div className='mt-10 flex w-full items-center justify-center'>
      <Form
        onFinish={onFinish}
        className='flex flex-col items-center justify-center gap-8'
      >
        <Form.Item
          name='email'
          rules={[
            {
              required: true,
              message: 'Please input email',
              whitespace: true,
            },
            {
              type: 'email',
              message: 'Please enter a valid email',
            },
          ]}
          style={{
            width: '300px',
            textAlign: 'center',
          }}
        >
          <Input
            placeholder='Email'
            type='text'
            className=' w-full rounded-full border-2 border-black text-[15px] outline-none'
          />
        </Form.Item>
        <div className='text-[20px] font-light '>
          Our team will get back to you to set up your demo!
        </div>
        <div className='mx-auto flex w-[300px] items-center justify-center gap-2'>
          <div
            className='flex w-full max-w-[100px] cursor-pointer justify-center rounded-full border-2 border-black bg-white p-[6px] text-black hover:bg-slate-200'
            onClick={onPrev}
          >
            BACK
          </div>
          <button
            type='submit'
            className=' flex w-full cursor-pointer justify-center rounded-full border-2 border-fitzba-bright-gold-static bg-fitzba-bright-gold-static p-[6px] text-white hover:bg-white hover:text-fitzba-bright-gold-static'
          >
            CONTINUE
          </button>
        </div>
      </Form>
    </div>
  )
}

export default RetailRegisterStep5
