
import React from 'react'
import MyImage from '@components/MyImage'
import { Avatar } from 'antd'
import { Link } from 'react-router-dom'

export default function BrandListCard({
  item,
  onClickBrand
}) {

  return (
    <div className='text-center border-1 h-28 w-28 rounded-full border-black bg-white md:h-36 md:w-36 flex flex-col items-center justify-start gap-3'>
      <Link onClick={() => onClickBrand(item._id)} to="/search">
        {item?.image ? (
          <MyImage
            src={item?.image}
            className={`rounded-full border object-contain h-28 w-28 border-[#eee]`}
            alt={item?.title}
          />
        ) : (
          <Avatar
            className={`font-sbold mx-auto cursor-pointer break-words border border-[#eee] bg-fitzba-white-static align-middle font-anke text-2xl text-fitzba-off-black-static`}
            size={{ xs: 112, sm: 112, md: 130, lg: 130, xl: 144, xxl: 144 }}
          >
            {item?.title}
          </Avatar>
        )}
      </Link>
      <Link to="/search" className=' leading-4 cursor-pointer' onClick={() => onClickBrand(item._id)}>{item?.title}</Link>
    </div>
  )
}
