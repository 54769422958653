import React from 'react'

export default function GiftCardsItem({
  item
}) {
  return (
    <a href={item?.link} target='_blank' rel="noreferrer" className=' relative w-[300px] h-[200px] border rounded-lg overflow-hidden'>
      <img src={item?.image} alt={item?.title} className='w-full h-full object-cover' />
      <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-20 p-2">
        <h2 className="text-white text-lg font-semibold">{item?.title}</h2>
      </div>
    </a>
  )
}
