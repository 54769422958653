import React from 'react'
import {
    EmailShareButton,
    FacebookShareButton,
    // FacebookIcon,
    // EmailIcon
} from "react-share"
import { IoShareSocialOutline, IoMailOutline } from "react-icons/io5"
import { FaFacebook } from "react-icons/fa"
import copy from 'copy-to-clipboard'
import { message } from 'antd'
export default function ShareButtons() {
    return (
        <div className='flex items-center gap-2 mt-6'>
            <EmailShareButton url={window.location.href}>
                <IoMailOutline size={24} />
            </EmailShareButton>
            <FacebookShareButton url={window.location.href}>
                <FaFacebook size={24} />
            </FacebookShareButton>
            <IoShareSocialOutline className='cursor-pointer' size={24} onClick={() => {
                // get the title of the page
                const title = document.title
                // get the url of the page
                const url = window.location.href
                // copy the title and url to the clipboard
                copy(`${title} ${url}`)
                console.log('Copied to clipboard:', url)
                // show a message to the user
                message.success('Store profile link copied')
            }} />
        </div>
    )
}
