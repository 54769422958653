import React from 'react'
import PlanIcon from './PlanIcon'
import RetailButtons from './RetailButtons'

export default function PlanListMobile({
    isMobile,
    setIsOpen,
    setIsOpenForm
}) {

    return (
        <div className=' bg-[#F9EDD6] flex flex-col w-full m-auto text-center items-center justify-center gap-4 px-3 py-12'>
            <h3 className='py-3 text-[50px] uppercase text-left leading-[50px] font-medium -tracking-[5px]'>
                Be a launch
                partner and
                SAVE $$$
            </h3>
            <div className='w-full flex items-center justify-between bg-black rounded-full text-white px-8'>
                <img width={39} height={39} src='images/retail/star.png' alt='start' />
                <div className='leading-5'>
                    <span className='uppercase text-[9px]'>limited time offer</span>
                    <h4 className='text-[15px] leading-5 uppercase'>plans starting at<br /><span className='text-[22px]'>$50</span>/mo</h4>
                </div>
                <img width={39} height={39} src='images/retail/star.png' alt='start' />
            </div>
            <table className='plan-table-mobile'>
                <tbody>
                    <tr>
                        <th></th>
                        <th valign='top' className='plan-title-mobile'>Basicz</th>
                        <th valign='top' className='plan-title-mobile'>Showz</th>
                        <th valign='top' className='plan-title-mobile'>Sellz</th>
                    </tr>
                    <tr>
                        <th className='plan-left-mobile'>Store profile</th>
                        <td><PlanIcon size={24} type='yes' /></td>
                        <td><PlanIcon size={24} type='yes' /></td>
                        <td><PlanIcon size={24} type='yes' /></td>
                    </tr>
                    <tr>
                        <th className='plan-left-mobile'>Inventory</th>
                        <td className='plan-inventory-func-mobile'>MANUAL</td>
                        <td className='plan-inventory-func-mobile'>AUTO</td>
                        <td className='plan-inventory-func-mobile'>AUTO</td>
                    </tr>
                    <tr>
                        <th className='plan-left-mobile'>Reservations</th>
                        <td><PlanIcon size={24} type='no' /></td>
                        <td><PlanIcon size={24} type='yes' /></td>
                        <td><PlanIcon size={24} type='yes' /></td>
                    </tr>
                    <tr>
                        <th className='plan-left-mobile'>Promoted items</th>
                        <td><PlanIcon size={24} type='no' /></td>
                        <td><PlanIcon size={24} type='yes' /></td>
                        <td><PlanIcon size={24} type='yes' /></td>
                    </tr>
                    <tr>
                        <th className='plan-left-mobile'>Reports and insights</th>
                        <td><PlanIcon size={24} type='no' /></td>
                        <td><PlanIcon size={24} type='yes' /></td>
                        <td><PlanIcon size={24} type='yes' /></td>
                    </tr>
                    <tr>
                        <th className='plan-left-mobile-width'>Features on the site and social media</th>
                        <td><PlanIcon size={24} type='no' /></td>
                        <td><PlanIcon size={24} type='no' /></td>
                        <td><PlanIcon size={24} type='yes' /></td>
                    </tr>
                </tbody>
            </table>

            <div className='text-[15px] py-3'>White glove account setup and one <strong className='text-[#0297A7] underline'>FREE</strong> month</div>
            <RetailButtons
                setIsOpen={setIsOpen}
                setIsOpenForm={setIsOpenForm}
                gap={'20px'}
                extraClass='px-8'
                isOpposite={true}
            />
        </div>
    )
}
