//TODO: discuss this component with yang 

/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * MyImage is a component for displaying an image with lazy loading and a blur effect.
 * It uses the LazyLoadImage component from 'react-lazy-load-image-component'.
 * Props include src, alt, width, height, style, and className. Width and height default to '100%' if not provided.
 * 
 * @author Dean Yang
 * @version November 10th, 2023 
 */

import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

export default function MyImage({ src, alt, width, height, style, className }) {
    return <LazyLoadImage
        alt={alt}
        effect="blur"
        className={className}
        src={src}
        width={width ? width : '100%'}
        height={height ? height : '100%'}
        style={style}
    />
}