import React, { useEffect, useState, useMemo } from 'react'
// import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { Tree } from 'antd'
import {
  setFilterCategoryList,
  setFilterExpandedCategories,
  setCategoryList,
} from '@reducers/contentReducer'
import { getStoreCategoryList } from '@services/api.business.service'
import FilterSideSearchBar from '../../zearch/components/FilterSideSearchBar'
import FilterSideSectionHeader from '../../zearch/components/FilterSideSectionHeader'
import { setFilterState } from '@reducers/userReducer'

const getParentKey = (key, tree) => {
  let parentKey
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i]
    if (node.children) {
      if (node.children.some((item) => item.key === key)) {
        parentKey = node.key
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children)
      }
    }
  }
  return parentKey
}

const dataList = []

export default function FilterSideCategory({
  business,
  itemsFilter,
  dispatch,
  isMobile
}) {

  const generateList = (data) => {
    for (let i = 0; i < data?.length; i++) {
      const node = data[i]
      const { key } = node
      dataList.push({
        key,
        title: node.title,
      })
      if (node.children) {
        generateList(node.children)
      }
    }
  }

  const isOpen = useSelector(state => state.user.filterState.category)
  const setIsOpen = (value) => {
    dispatch(setFilterState({ type: 'category', value }))
  }

  const categoryListExpanded = useSelector(state => state.content.categoryListExpanded)
  const [categoryList, setCategoryList] = useState([])
  const [expandedKeys, setExpandedKeys] = useState(categoryListExpanded)
  const [searchValue, setSearchValue] = useState('')
  const [autoExpandParent, setAutoExpandParent] = useState(true)
  const [allCheckbox, setAllCheckbox] = useState(false)

  useEffect(() => {
    async function fetchData() {
      const res = await getStoreCategoryList({
        business_id: business?._id
      })
      console.log('getStoreCategoryList', res)
      setCategoryList(res.data || [])
      // setShowNumber(res.data.length || DEFAULT_SHOW_NUMBER)
    }
    fetchData()
  }, [business?._id, dispatch])

  // useEffect(() => {
  //   setExpandedKeys(categoryListExpanded)
  // }, [categoryList, categoryListExpanded])

  generateList(categoryList)

  const onExpand = (newExpandedKeys) => {
    console.log('onExpand', newExpandedKeys)
    setExpandedKeys(newExpandedKeys)
    setAutoExpandParent(false)
    dispatch(setFilterExpandedCategories(newExpandedKeys))
  }

  const onCheck = (checkedKeysValue, e) => {
    // console.log('onCheck', checkedKeysValue)
    // setCheckedKeys(checkedKeysValue)
    dispatch(setFilterCategoryList(checkedKeysValue))
  }

  const onChange = (e) => {
    const { value } = e.target
    console.log('onChange', value)
    // console.log('categoryList', categoryList)
    const newExpandedKeys = dataList
      .map((item) => {
        if (item.title?.toLowerCase()?.indexOf(value?.toLowerCase()) > -1) {
          return getParentKey(item.key, categoryList)
        }
        return null
      })
      .filter((item, i, self) => !!(item && self.indexOf(item) === i))
    setExpandedKeys(newExpandedKeys)
    setSearchValue(value)
    setAutoExpandParent(true)
  }

  function defaultSetting() {
    setSearchValue('')
    setExpandedKeys([])
    setAutoExpandParent(true)
  }

  const treeData = useMemo(() => {
    if (!searchValue) {
      defaultSetting()
      return categoryList
    }
    let capitalizedString = searchValue?.charAt(0).toUpperCase() + searchValue?.slice(1)
    const loop = (data) =>
      data.map((item) => {
        const strTitle = item.title
        const index = strTitle?.indexOf(capitalizedString)
        if (index !== -1) {
          // add expaned key
          setExpandedKeys([item.key])
        }
        const beforeStr = strTitle.substring(0, index)
        const afterStr = strTitle.slice(index + searchValue.length)
        const title =
          index > -1 ? (
            <span>
              {beforeStr}
              <span className="site-tree-search-value">{capitalizedString}</span>
              {afterStr}
            </span>
          ) : (
            <span>{strTitle}</span>
          )
        if (item.children) {
          return {
            title,
            key: item.key,
            children: loop(item.children),
          }
        }
        return {
          title,
          key: item.key,
        }
      })
    return loop(categoryList)
  }, [searchValue, categoryList])

  function onClickAllCheckbox(checked) {
    console.log('onClickAllCheckbox', checked)
    if (checked) {
      // select all
      // const ids = dataList?.map(item => item.key)
      dispatch(setFilterCategoryList(['All Categories']))
    } else {
      // deselect all
      // const ids = filterList?.stores.map(item => item.id)
      dispatch(setFilterCategoryList([]))
    }
    setAllCheckbox(checked)
  }

  let checkedList = itemsFilter?.categories

  return (
    <div className='filter-category' style={{
      color: '#000'
    }}>
      <FilterSideSectionHeader
        title='Category'
        isOpen={isOpen}
        onClickTitle={() => setIsOpen(!isOpen)}
        isHighlight={itemsFilter?.categories?.length > 0}
        color='#000'
      />
      <FilterSideSearchBar
        input={searchValue}
        isOpen={isOpen}
        onSearch={onChange}
        onClear={defaultSetting}
        allCheckbox={allCheckbox}
        onSelectAll={() => onClickAllCheckbox(!allCheckbox)}
        selectText={['Select All', 'Clear All']}
        isShowClear={true}
        onClearAll={() => onClickAllCheckbox(false)}
      />
      <Tree
        style={{
          display: isOpen ? 'flex' : 'none',
          marginTop: 10,
          // left: '-15px',
          width: '100%',
          color: '#000000',
          fontFamily: 'Lexend, sans-serif',
        }}
        // selectable={false}
        // className='filter-category-tree'
        checkable
        // treeData={categoryList}
        treeData={treeData}
        onExpand={onExpand}
        expandedKeys={expandedKeys}
        autoExpandParent={autoExpandParent}
        onCheck={onCheck}
        checkedKeys={checkedList}
      />
    </div>
  )
}
