/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * CartSummary is a functional React component that displays a summary of the user's shopping cart.
 * It shows the subtotal, taxes, and total for the items in the cart.
 * It also provides a form for the user to enter their email to receive their shopping list.
 * If the user is not logged in, it provides a button to log in to save their list.
 * The user can also send their shopping list to their email.
 *
 * @author James Nguyen, Yang Ming
 * @version June 29th, 2023
 */

import React, { useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { formatCurrency } from '@utils/Helper'
import { useSelector } from 'react-redux'
import { Space, Button, Input, message, Radio } from 'antd'
import {
  sendShoppingListEmail,
  // shoppingCartCheckout,
} from '@services/api.shoppingCart.service'
import { getAvailablePromotions } from '@services/api.user.service'

/**
 * Displays a summary of the user's shopping cart.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.shoppingCart - The user's shopping cart.
 *
 * @returns {JSX.Element} The rendered component.
 */

export default function CartSummary({
  shoppingCart,
  promoCode,
  setPromoCode,
  selectedStore,
  isCheckout,
}) {
  const navigate = useNavigate()
  const userinfo = useSelector(state => state.user.userinfo)
  const location = useSelector(state => state.user.location)
  const [isAddingPromo, setIsAddingPromo] = React.useState(false)
  const [sendEmail, setSendEmail] = React.useState(userinfo?.username || '')
  const [isSending, setIsSending] = React.useState(false)
  const [buttonName, setButtonName] = React.useState('Send shopping list')
  const [availablePromotions, setAvailablePromotions] = React.useState([])
  const [discount, setDiscount] = React.useState(0)

  const cartData = shoppingCart?.data
  console.log('cartData', cartData)
  const handleSendShoppingList = async () => {
    if (!sendEmail) {
      message.error('Please enter your email')
      return
    }
    const hide = message.loading('Sending email...')
    try {
      setIsSending(true)
      await sendShoppingListEmail(sendEmail, location)
      let time = 3
      const tick = setInterval(() => {
        time--
        setButtonName(`Email Sent`)
        if (time === 0) {
          clearInterval(tick)
          setIsSending(false)
          setButtonName('Send shopping list')
          return
        }
        console.log('time', time)
      }, 1000)
      hide()
      message.success('Email sent successfully')
    } catch (error) {
      hide()
      setIsSending(false)
      setButtonName('Send shopping list')
      message.error('Error sending email: ' + error?.message || '')
    }
  }

  // const handleCheckout = async () => {
  //   try {
  //     const items = cartData?.data?.carts?.filter(item => item.isSelected)
  //     console.log('items', items)
  //     const res = await shoppingCartCheckout(items)
  //     console.log('res', res)
  //     // redirect to checkout page open a new tab
  //     window.open(res?.data?.pos_checkout_webUrl, '_blank')
  //     message.success('Checkout successful')
  //   } catch (error) {
  //     message.error('Error checkout: ' + error?.data?.message || 'Please try again')
  //   }
  // }

  useEffect(() => {
    async function fetchAvailablePromotions() {
      if (!selectedStore) return
      const res = await getAvailablePromotions({
        business_id: selectedStore,
      })
      setAvailablePromotions(res?.data)
    }
    fetchAvailablePromotions()
  }, [selectedStore, isCheckout])
  let subtotal = cartData?.subTotal || 0
  let taxTotal = cartData?.taxTotal || 0
  let total = cartData?.total || 0
  let selectedItems = cartData?.selectedItems || 0
  if (isCheckout) {
    console.log('selectedStore', selectedStore)
    const findBusiness = cartData?.data?.find(
      item => item._id === selectedStore,
    )
    if (findBusiness) {
      const tmp_subtotal = findBusiness?.carts
        .filter(item => item.isSelected)
        .reduce((acc, item) => {
          return acc + item.subTotal
        }, 0)
      const tmp_taxTotal = findBusiness?.carts
        .filter(item => item.isSelected)
        .reduce((acc, item) => {
          return acc + item.taxTotal
        }, 0)
      const tmp_total = findBusiness?.carts
        .filter(item => item.isSelected)
        .reduce((acc, item) => {
          return acc + item.total
        }, 0)
      const tmp_selectedItems = findBusiness?.carts.filter(
        item => item.isSelected,
      ).length
      subtotal = tmp_subtotal
      taxTotal = tmp_taxTotal
      total = tmp_total
      selectedItems = tmp_selectedItems
    }
  }

  return (
    <div className='cart-summary' id='cart-summary'>
      <div className='cart-summary-header'>
        <h3>Summary</h3>
        <p>({selectedItems}) ITEMS</p>
        <div className='cart-summary-total'>
          <div className='cart-total-line'>
            <span>Subtotal</span>
            <span>{formatCurrency(subtotal)}</span>
          </div>
          <div className='cart-total-line'>
            <span>Taxes</span>
            <span>{formatCurrency(taxTotal)}</span>
          </div>
          {discount > 0 && (
            <>
              <p className='cart-total-border-bottom' />
              <div className='cart-total-line'>
                <span>Discount</span>
                <span className='text-red-500'>
                  -{formatCurrency(discount)}
                </span>
              </div>
            </>
          )}
          <p className='cart-total-border-bottom' />
          <div className='cart-total-line' style={{ paddingTop: 10 }}>
            <span>TOTAL</span>
            <span>{formatCurrency(total - discount)}</span>
          </div>
          <p className='cart-total-border-bottom' />
          {/* {isCheckout && (
            <>
              <div
                className='flex w-full flex-col gap-2 text-left'
                style={{ paddingTop: 10 }}
              >
                <span>Available Offers</span>
                <Radio.Group onChange={() => {}}>
                  <Space direction='vertical'>
                    {availablePromotions.map((promotion, index) => (
                      <Radio
                        disabled={promotion?.disabled}
                        key={index}
                        value={promotion._id}
                        onChange={() => {
                          setDiscount(promotion?.discount)
                        }}
                      >
                        {promotion.label}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
                {isAddingPromo ? (
                  <div className='flex items-center gap-2'>
                    <Space.Compact
                      style={{
                        width: '100%',
                        maxWidth: '300px',
                      }}
                    >
                      <Input
                        type='text'
                        placeholder='Enter promo code'
                        allowClear
                        value={promoCode}
                        onChange={e => {
                          console.log('e.target.value', e.target.value)
                          setPromoCode(e.target.value)
                        }}
                      />
                      <Button>Apply</Button>
                    </Space.Compact>
                    <button onClick={() => setIsAddingPromo(false)}>x</button>
                  </div>
                ) : (
                  <div className='flex items-center justify-center'>
                    <button onClick={() => setIsAddingPromo(true)}>
                      Add promo code
                    </button>
                  </div>
                )}
              </div>
              <p className='cart-total-border-bottom' />
            </>
          )} */}
        </div>
      </div>

      {/* <div className='cart-summary-other'>
        <button className='w-full' onClick={() => {}}>
          Place order
        </button>
      </div> */}
      {!isCheckout && (
        <div className='cart-summary-other'>
          <h4>Receive your shopping list directly to your email.</h4>
          <div>
            <div className='cart-summary-input'>
              <label>Email:</label>
              <input
                type='email'
                placeholder='Enter your email'
                value={sendEmail}
                onChange={e => {
                  console.log('e.target.value', e.target.value)
                  setSendEmail(e.target.value)
                }}
              />
            </div>
          </div>
          <div style={{ textAlign: 'right' }}>
            <button onClick={handleSendShoppingList} disabled={isSending}>
              {buttonName}
            </button>
          </div>
          {/* <div style={{ textAlign: 'right' }}>
          <button onClick={() => navigate('/reserve')}>
            Reserve All Items
          </button>
        </div> */}
        </div>
      )}
      {userinfo == null && (
        <div className='cart-summary-other'>
          <h4>Login/Create an account to SAVE your list.</h4>
          <div style={{ textAlign: 'right' }}>
            <Link to='/login'>
              <button style={{ backgroundColor: '#000' }}>login to save</button>
            </Link>
          </div>
        </div>
      )}
    </div>
  )
}
