import React from 'react'
import MobileTopFilterItem from './MobileTopFilterItem'
import { Drawer } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { useSelector, useDispatch } from 'react-redux'
import { setFilterDistance } from '@reducers/contentReducer'

const distanceList = [
    {
        value: 5,
        text: 'within 5 km'
    },
    {
        value: 10,
        text: 'within 10 km'
    },
    {
        value: 15,
        text: 'within 15 km'
    },
    {
        value: 25,
        text: 'within 25 km'
    }
]

export default function MobileTopFilterDistance() {
    const itemsFilter = useSelector(state => state.content.itemsFilter)
    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false)
    const [input, setInput] = React.useState(-1)

    function onClickViewResults() {
        dispatch(setFilterDistance(input))
        setOpen(false)
    }

    function onClickClear() {
        setInput(-1)
    }

    return (
        <>
            <MobileTopFilterItem
                // selectedable={true}
                text='Distance'
                onClick={() => {
                    setOpen(true)
                }}
                isSelected={itemsFilter?.distance !== -1}
            />
            <Drawer
                title={''}
                closeIcon={''}
                className='filter-mobile-drawer'
                placement={'bottom'}
                onClose={() => setOpen(false)}
                open={open}
                key={'mobile-filter-price'}
                height={'400px'}
                styles={{
                    body: {
                        paddingTop: 0,
                        paddingBottom: 0
                    }
                }}
                extra={
                    <div className='flex justify-end'>
                        <CloseOutlined onClick={() => setOpen(false)} />
                    </div>
                }
                // extra={<NavZearchBar />}
                style={{
                    // height: '100vh',
                    textAlign: 'left',
                    overflowY: 'auto',
                    paddingBottom: 20,
                    // backgroundColor: '#202020',
                    borderRadius: '10px 10px 0 0',
                }}
                footer={<button className='w-full rounded-full bg-fitzba-bright-gold-static px-5 py-2 text-center text-white'
                    onClick={onClickViewResults}
                >
                    View results
                </button>}
            >
                <div className='flex flex-col gap-3 justify-between h-full'>
                    <div className='flex flex-col gap-3'>
                        <h3 className='text-lg text-center'>Distance</h3>
                        <div className='flex flex-row items-center gap-1 font-light text-[20px]'>
                            Up to{' '}
                            <input
                                className='h-[30px] w-[60px] rounded-md bg-white px-[5px] py-[10px] text-base text-black border-2'
                                type='number'
                                name='distance'
                                placeholder='km'
                                value={input === -1 ? '' : input}
                                onChange={(e) => {
                                    setInput(Number(e.target?.value.replace(/[^0-9]/g, '') || -1))
                                }}
                            />
                            km
                        </div>
                        <div className='flex gap-2 flex-wrap'>
                            {distanceList.map((item, index) => (
                                <button
                                    key={index}
                                    className={`rounded-full px-5 py-2 text-center  font-light ${input === item.value ? 'bg-[#f5a623] text-white' : 'bg-[#eaeaea] text-black'}`}
                                    onClick={() => {
                                        setInput(item.value)
                                    }}
                                >
                                    {item.text}
                                </button>
                            ))}
                        </div>
                    </div>
                    <div
                        className='text-center underline text-gray-400 cursor-pointer'
                        onClick={onClickClear}
                    >
                        Clear
                    </div>
                </div>
            </Drawer>
        </>
    )
}
