
import { Config } from '../Config'
import axios from 'axios'

const httpClient = axios.create({
    baseURL: Config.apiUrl + Config.apiPrefix,
    timeout: 60 * 1e3,
})

httpClient.interceptors.request.use(config => {
    // Perform logic before sending request
    config.headers['authorization'] = `${Config.apiKey} ` + localStorage.getItem('token')
    return config
})

export async function addEmailSubscription(email) {
    try {
        const res = await httpClient.post(`/emailSubscription`, {
            email: email
        })
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function cancelEmailSubscription(email) {
    try {
        const res = await httpClient.delete(`/emailSubscription/${email}`)
        return res.data
    } catch (error) {
        throw error.response
    }
}