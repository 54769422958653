import React, { useEffect } from 'react'
import { Modal, Input, Switch } from 'antd'
import FavouriteButton from './FavoriteButton'
import FavoriteFormItems from './FavoriteFormItems'

export default function FavoriteForm({
    open,
    setOpen,
    onSubmit,
    currentItem
}) {

    const [selectedList, setSelectedList] = React.useState([])
    const [title, setTitle] = React.useState('')
    const [isPublic, setIsPublic] = React.useState(false)

    useEffect(() => {
        setSelectedList(currentItem?._id ? currentItem.items : [])
        setTitle(currentItem?.title || '')
        setIsPublic(currentItem?.isPublic || false)
    }, [currentItem, open])

    return (
        <Modal
            title="CREATE A NEW LIST"
            open={open}
            style={{
                top: 20,
            }}
            width={1440}
            onCancel={() => setOpen(false)}
            footer={
                <div className='flex justify-between items-center'>
                    <div className='uppercase'>{selectedList.length} Selected</div>
                    <div className='flex gap-3'>
                        <FavouriteButton
                            text='Cancel'
                            onClick={() => setOpen(false)}
                        />
                        <FavouriteButton
                            text='Save'
                            onClick={async () => {
                                onSubmit({
                                    _id: currentItem?._id || null,
                                    title,
                                    isPublic,
                                    items: selectedList
                                })
                            }}
                            isHightlighted={true}
                        />
                    </div>
                </div>
            }
        >
            <div className='flex flex-col gap-5 overflow-y-auto max-h-[800px]'>
                <div className='flex flex-col gap-5'>
                    <Input
                        placeholder='List Name'
                        className='w-[400px] rounded-full'
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                    <div className='flex items-center gap-3'>
                        <h4>PUBLIC LIST</h4>
                        <Switch
                            checkedChildren="on" unCheckedChildren="off"
                            className='favorite-switch'
                            style={{
                                // border: '1px solid #727272',
                                // backgroundColor: '#F9F1E3',
                                color: '#202020',
                            }}
                            checked={isPublic}
                            onChange={(checked) => setIsPublic(checked)}
                        />
                    </div>
                    <p className='text-[#A6A6A6] text-[14px] w-[300px]'>Share your curated lists with others or keep them private—it's up to you!</p>

                </div>
                <div className='flex flex-col gap-3'>
                    <h4 className='text-[25px]'>Add items</h4>
                    <p className='text-[14px]'>From your favourites</p>
                    <div>
                        <FavoriteFormItems
                            selectedList={selectedList}
                            setSelectedList={setSelectedList}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}
