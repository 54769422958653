/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * Background for the Location component
 * 
 * @author Yang Ming
 * @version May 09, 2023
 */

import React, { useState, useEffect } from 'react'
import './Location.css'
import { GoogleMap, Marker } from "@react-google-maps/api"
import { message } from 'antd'
import { BiCurrentLocation } from 'react-icons/bi'
import { isValidPostalCodeCAandUS } from '@utils/Helper'
import { useSelector } from 'react-redux'
// Styling for the container
const containerStyle = {
    width: "100%",
    height: "300px",
}
/**
 * 
 * @param handleChangeLocation      A function that handle location 
*                                   change
 * @param handleGetCurrentPosition  A function that get the user 
 *                                  current location
 * @returns The background of the minimap window
 */
export default function LocationBackground({
    handleChangeLocation,
    handleGetCurrentPosition
}) {
    //Redux state manager to manage the user location 
    // const dispatch = useDispatch()
    const location = useSelector(state => state.user.location)
    const [postalCode, setPostalCode] = useState(location?.postalCode)


    useEffect(() => {
        setPostalCode(location?.postalCode)
    }, [location])

    // Handle location change using postal code
    const handleChange = async () => {
        if (!postalCode) {
            message.error('Postal code is empty')
            return
        }
        if (!isValidPostalCodeCAandUS(postalCode)) {
            message.error('Postal code is invalid')
            return
        }
        await handleChangeLocation(postalCode)
    }
    // Hnadle location change through click on map
    const handleClickMap = async (e) => {
        console.log('handleClickMap', e)
        await handleGetCurrentPosition({
            lat: e.latLng.lat(),
            lng: e.latLng.lng()
        })
    }

    return (
        <div className='location-wrapper' >
            <div className='location-header'>
                <div className='location-change'>
                    <input type='text'
                        className='location-input'
                        value={postalCode}
                        placeholder='Postal Code/Zip Code'
                        onChange={(e) => setPostalCode(e.target.value)}
                    />
                    <button
                        className='location-button'
                        onClick={handleChange}>
                        Change
                    </button>
                </div>

                <div className='location-current' onClick={() => handleGetCurrentPosition()}>
                    <BiCurrentLocation /> Current
                </div>
            </div>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={location}
                zoom={15}
                onClick={handleClickMap}
            >
                {location && <Marker position={location} />}
            </GoogleMap>
        </div >
    )
}
