/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * Renders a single store element
 *
 * @author James Nguyen
 * @version May 23, 2023
 */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setFilterStoreOnlyOne } from '@reducers/contentReducer'
import { Avatar, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import { getStoreLink, getDistance } from '@utils/Helper'
/**
 *
 * @param item              a json that contain the store info
 * @param userinfo          a json that contain the user info, we use this
 *                          to dertermined if a user is loged in or not
 * @returns Render an Store card for each item
 */
export default function StoreListCard({ item, stores, keywordsList }) {
  // const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useSelector((state) => state.user.location)
  // const itemFilter = useSelector(state => state.content.itemFilter)
  // console.log('StoreCard', item)
  const handleClickStore = () => {
    // setShowAllStores(false)
    dispatch(setFilterStoreOnlyOne(item._id))

  }

  const link = getStoreLink(item?.shortLink, item?.city, item?.province)
  // highlight keywords in title
  const getTitle = () => {
    let title = item?.name
    if (title && keywordsList && keywordsList.length > 0) {
      title = title.replace(
        new RegExp(`(${keywordsList.join('|')})`, 'gi'),
        '<font color="#E4B456">$1</font>',
      )
    }
    return title
  }

  const borderStyle = stores?.includes(item._id) ? {
    borderWidth: '2px',
    borderColor: '#E4B456',
  } : {
    borderWidth: '1px',
    borderColor: '#d9d9d9',
  }
  return (
    <div className='flex flex-col w-fit text-center font-lexend md:w-44 items-center justify-center smsize:w-[112px]'>
      <div className='border-1 h-28 w-28 rounded-full border-black bg-white md:h-40 md:w-40'>
        <Link onClick={handleClickStore} to={link}>
          {item?.logo ? (
            <img
              src={item?.logo}
              className={`aspect-[4/3] rounded-full border object-contain`}
              style={{ height: '100%', width: '100%', ...borderStyle }}
              alt={item?.name}
            />
          ) : (
            <Avatar
              className={`font-sbold mx-auto cursor-pointer break-words border bg-fitzba-white-static align-middle font-anke text-2xl text-fitzba-off-black-static`}
              size={{ xs: 112, sm: 112, md: 160, lg: 160, xl: 160, xxl: 160 }}
              style={borderStyle}
            >
              {item?.name}
            </Avatar>
          )}
        </Link>
      </div>
      <Tooltip title={item.name} placement='bottom'>
        <div className='truncate-multiline smsize:text-[12px] h-[45px] smsize:h-[35px] leading-5' style={{ textAlign: 'center' }}>
          <Link onClick={handleClickStore} to={link}>
            {/* {item.name} */}
            <p dangerouslySetInnerHTML={{ __html: getTitle() }} />
          </Link>
        </div>
      </Tooltip>
      <div className='text-[11px] lg:text-[15px] text-center'>
        {getDistance(item?.distance, location?.country === 'US')}
      </div>
    </div>
  )
}
