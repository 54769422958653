/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * This module exports a React component named 'ItemTags'. This component displays a list of tags for a specific item.
 * Each tag is displayed as a clickable element that dispatches a 'setSearchTitle' action and navigates to the search page when clicked.
 * 
 * The component takes four props: 'tags', 'dispatch', 'setSearchTitle', and 'navigate'. 'tags' is an array of tags for the item.
 * 'dispatch' is a function to dispatch actions to the redux store. 'setSearchTitle' is an action creator for setting the search title.
 * 'navigate' is a function for navigating to different pages.
 * 
 * @author Yang Ming, James Nguyen
 * @version November 20th, 2023
 */

import React from 'react'

/**
 * A component that displays a list of tags for a specific item.
 * 
 * @param {Object} props - The props object.
 * @param {Array<string>} props.tags - An array of tags for the item.
 * @param {Function} props.dispatch - A function to dispatch actions to the redux store.
 * @param {Function} props.setSearchTitle - An action creator for setting the search title.
 * @param {Function} props.navigate - A function for navigating to different pages.
 * @returns {JSX.Element} The 'ItemTags' component.
 */
export default function ItemTags({ tags, dispatch, setSearchTitle, navigate }) {
    if (!tags) return null
    return (
        <div className='flex flex-row mt-[20px] gap-[10px] flex-wrap'>
            {' '}
            {tags.map(tag => (
                <div key={tag} className='text-white rounded-full bg-gray-400 px-[10px] flex items-center justify-center py-[5px] cursor-pointer capitalize'
                    onClick={() => {
                        dispatch(setSearchTitle(tag))
                        navigate('/search?keywords=' + tag + '&page=1')
                    }}>
                    {tag}
                </div>
            ))}
        </div>
    )
}
