import React from 'react'
import { BsChevronDown } from "react-icons/bs"

export default function MobileTopFilterItem({
    selectedable,
    firstIcon,
    text,
    onClick,
    isSelected,
}) {
    return (
        <div onClick={onClick} className={`whitespace-nowrap flex gap-2 items-center justify-center py-[5px] px-[15px]  rounded-full font-light text-[14px] ${isSelected ? 'bg-black text-white' : 'bg-[#eaeaea] text-black'}`}>
            {firstIcon && firstIcon}
            <h4>{text}</h4>
            {selectedable && <BsChevronDown />}
        </div>
    )
}
