/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * This module exports a React component named 'ImagePreOwned'. This component displays a 'PRE-OWNED' label if the item is used.
 * 
 * The component takes one prop: 'isUsed'. 'isUsed' is a boolean indicating whether the item is used.
 * 
 * @author Yang Ming
 * @version January 19th, 2024
 */
import React from 'react'

/**
 * A component that displays a 'PRE-OWNED' label if the item is used.
 * 
 * @param {Object} props - The props object.
 * @param {boolean} props.isUsed - A boolean indicating whether the item is used.
 * @returns {JSX.Element|null} The 'ImagePreOwned' component, or null if the item is not used.
 */
export default function ImagePreOwned({
    isUsed
}) {
    if (!isUsed) return null
    return (
        <div className='absolute flex items-center justify-center left-[-30px] top-[20px] text-[#FFF] text-[11px]'>
            <p className='triangle'></p>
            <h4 className='pl-[5px] pt-[5px] pb-[5px] bg-[#E4B456] z-10'>PRE-OWNED</h4>
        </div>
    )
}
