/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * FilterSideSelectedMobile.js implements functionality for displaying selected filter options at the top of the screen
 * For mobile devices 
 * 
 * @author Yang Ming
 * @version February 12th, 2024 
 */

import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
// import { AiOutlineClose } from 'react-icons/ai'
import {
  // setStoreProfileFilter,
  setItemsFilter
} from '@reducers/contentReducer'

import FilterSideSelectedItem from './FilterSideSelectedItem'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'

/**
 * FilterSideSelectedMobile component. This component is responsible for displaying selected items in the menu on mobile devices.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.itemsFilter - The current state of the items filter.
 * @param {Function} props.dispatch - The dispatch function from the Redux store.
 * @param {Object} props.filterList - The list of available filters.
 * @param {boolean} props.isMobile - A flag indicating whether the device is a mobile device.
 */
export default function FilterSideSelectedMobile({
  storeProfileFilter,
  dispatch,
  brands,
  categories,
  isMobile
}) {
  const selectedListRef = React.useRef(null)

  /**
   * Scrolls the selected items list by a given step.
   *
   * @param {number} step - The amount to scroll by.
   */
  const onClickScrollBtn = step => {
    if (!selectedListRef.current) return
    let scrollAmount = 0
    const slideTimer = setInterval(() => {
      selectedListRef.current.scrollLeft += step > 0 ? 100 : -100
      scrollAmount += 100
      if (scrollAmount >= Math.abs(step)) {
        clearInterval(slideTimer)
      }
    }, 50)
  }

  const onClearAll = () => {
    dispatch(setItemsFilter(null))
  }

  const onClearTitle = () => {
    dispatch(
      setItemsFilter({
        ...storeProfileFilter,
        title: '',
      }),
    )
  }

  const onClearPrice = () => {
    dispatch(
      setItemsFilter({
        ...storeProfileFilter,
        priceMin: -1,
        priceMax: -1,
      }),
    )
  }

  const onClearBrand = id => {
    dispatch(
      setItemsFilter({
        ...storeProfileFilter,
        brands: storeProfileFilter.brands.filter(item => !item._id === id),
      }),
    )
  }

  const getBrandById = id => {
    const item = brands.find(item => item._id === id)
    if (!item) return
    return (
      <FilterSideSelectedItem
        key={item._id}
        label={item.label}
        value={item._id}
        onClickRemove={() => onClearBrand(item._id)}
      />
    )
  }

  const getCategoryById = id => {
    console.log('id', id)
    console.log('categories', categories)
    const item = categories.find(item => item._id === id)
    if (!item) return null
    return (
      <FilterSideSelectedItem
        key={id}
        label={item.label}
        value={id}
        onClickRemove={() => onClearCategory(id)}
      />
    )
  }

  const onClearCategory = async id => {
    console.log('id', id)
    dispatch(
      setItemsFilter({
        ...storeProfileFilter,
        categories: storeProfileFilter.categories.filter(item => item !== id),
      }),
    )
  }
  console.log('storeProfileFilter', storeProfileFilter)
  if (
    storeProfileFilter.title === '' &&
    storeProfileFilter.priceMin === -1 &&
    storeProfileFilter.priceMax === -1 &&
    storeProfileFilter.brands.length === 0 &&
    storeProfileFilter.categories.length === 0 &&
    storeProfileFilter.deliveryTypes.length === 0 &&
    storeProfileFilter.condition.length === 0 
    // storeProfileFilter.isOnSale === false
  )
    return null

  // get item-content width by id items-content
  const itemContent = document.getElementById('items-content')
  const itemContentWidth = itemContent?.offsetWidth
  // console.log('itemContentWidth', itemContentWidth)

  return (
    <>
      <div className={` relative flex border-b py-2 items-center justify-center gap-3`}
        style={{
          width: itemContentWidth || '100%'
        }}
      >
        {!isMobile && (
          <>
            <span
              className='scroll-left top-0'
              onClick={() => onClickScrollBtn(-500)}
            >
              <AiOutlineLeft />
            </span>
            <span
              className='scroll-right top-0'
              onClick={() => onClickScrollBtn(500)}
            >
              <AiOutlineRight />
            </span>
          </>
        )}
        <div className={`filter-selected-clear-all`} onClick={onClearAll}>
          Clear All
        </div>
        <div className='w-full flex gap-2 flex-nowrap overflow-x-auto' ref={selectedListRef}>
          {storeProfileFilter.title !== '' && (
            <FilterSideSelectedItem
              label={storeProfileFilter.title}
              value={storeProfileFilter.title}
              onClickRemove={onClearTitle}
            />
          )}
          {/* {displayCategorySelected &&
            (itemsFilter.categories.length === 1 && itemsFilter.categories[0] === 'All Categories')
            ? <FilterSideSelectedItem
              key={'All Categories'}
              label={'All Categories'}
              value={'All Categories'}
              onClickRemove={() => onClearCategory('All Categories')}
            />
            : displayCategorySelected.map(item => (
              <FilterSideSelectedItem
                key={item.id}
                label={item.name}
                value={item.id}
                onClickRemove={() => onClearCategory(item.id)}
              />
            ))} */}
          {storeProfileFilter.priceMin !== -1 && storeProfileFilter.priceMax !== -1 && (
            <FilterSideSelectedItem
              label={`$${storeProfileFilter.priceMin} - $${storeProfileFilter.priceMax}`}
              value={''}
              onClickRemove={onClearPrice}
            />
          )}
          {storeProfileFilter.priceMin !== -1 && storeProfileFilter.priceMax === -1 && (
            <FilterSideSelectedItem
              label={`Min $${storeProfileFilter.priceMin}`}
              value={storeProfileFilter.priceMin}
              onClickRemove={onClearPrice}
            />
          )}
          {storeProfileFilter.priceMin === -1 && storeProfileFilter.priceMax !== -1 && (
            <FilterSideSelectedItem
              label={`Max $${storeProfileFilter.priceMax}`}
              value={storeProfileFilter.priceMax}
              onClickRemove={onClearPrice}
            />
          )}
          {storeProfileFilter.brands.length > 0 &&
            brands?.length > 0 &&
            storeProfileFilter.brands.map(id => (
              getBrandById(id)
            ))}
          {storeProfileFilter.categories.length > 0 &&
            categories?.length > 0 &&
            storeProfileFilter.categories.map(id => (
              getCategoryById(id)
            ))}
          {storeProfileFilter.isOnSale && (
            <FilterSideSelectedItem
              key={'isOnSale'}
              label={'On Sale'}
              value={'isOnSale'}
              onClickRemove={() =>
                dispatch(setItemsFilter({
                  ...storeProfileFilter,
                  isOnSale: false
                }))
              }
            />
          )}
          {storeProfileFilter.condition.length > 0 &&
            storeProfileFilter.condition.map((type, index) => (
              <FilterSideSelectedItem
                key={index}
                label={type}
                value={type}
                onClickRemove={() =>
                  dispatch(setItemsFilter({
                    ...storeProfileFilter,
                    condition: storeProfileFilter.condition.filter(item => item !== type)
                  }))
                }
              />
            ))}
          {storeProfileFilter.deliveryTypes.length > 0 &&
            storeProfileFilter.deliveryTypes.map((type, index) => (
              <FilterSideSelectedItem
                key={index}
                label={type}
                value={type}
                onClickRemove={() =>
                  dispatch(setItemsFilter({
                    ...storeProfileFilter,
                    deliveryTypes: storeProfileFilter.deliveryTypes.filter(item => item !== type)
                  }))
                }
              />
            ))}
        </div>
      </div>
    </>
  )
}
