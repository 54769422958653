/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * The ShoppingCartHook is a custom hook that provides functions for managing a shopping cart.
 * It provides functions to add an item to the cart, update an item in the cart, and delete an item from the cart.
 * Each function dispatches actions to update the Redux state and makes API calls to update the server state.
 * The hook uses the useDispatch and useSelector hooks from the react-redux library to interact with the Redux state.
 *
 * @author Yang Ming, James Nguyen
 * @version November 7th, 2023 
 */

import { message } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { setCartRefresh, setCartTmpItems } from '@reducers/userReducer'
import {
  addShoppingCart,
  updateShoppingCart,
  deleteShoppingCart,
} from '@services/api.shoppingCart.service'

message.config({
  prefixCls: 'my-message',
})

export default function ShoppingCartHook() {
  const dispatch = useDispatch()
  const userinfo = useSelector(state => state.user.userinfo)

  // Add an item to shopping cart
  const addToCart = async (id, variant_id) => {
    console.log('handleAddToCart', id, variant_id)
    try {
      if (!userinfo) {
        dispatch(setCartTmpItems())
      }
      await addShoppingCart({
        item_id: id,
        variant_id: variant_id
      })
      message.success('Added to list')
      dispatch(setCartRefresh(true))
    } catch (error) {
      console.log('ShoppingCartHook addToCart', error)
    }
  }
  // Update an item in shopping cart
  const updateCart = async (cartId, data) => {
    console.log('handleUpdateCart cartId', cartId)
    console.log('handleUpdateCart data', data)
    try {
      await updateShoppingCart(cartId, data)
      dispatch(setCartRefresh(true))
    } catch (error) {
      console.log('ShoppingCartHook updateCart', error)
    }
  }
  // Delete an item from shopping cart
  const deleteCart = async cartId => {
    console.log('handleDeleteCart', cartId)
    try {
      await deleteShoppingCart(cartId)
      dispatch(setCartRefresh(true))
    } catch (error) {
      console.log('ShoppingCartHook deleteCart', error)
    }
  }

  return { addToCart, updateCart, deleteCart }
}
