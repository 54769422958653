import React from 'react'
import { FaRegHeart, FaHeart } from "react-icons/fa"
import { actionFavoriteItems } from '@services/api.user.service'
import { setUserinfo, setIsRefreshUserinfo } from '@reducers/userReducer'
import { useDispatch } from 'react-redux'

export default function FavoriteButton({
    item_id,
    userinfo
}) {

    const dispatch = useDispatch()
    const isFavorited = userinfo?.favoriteItems?.includes(item_id)
    const onClick = async () => {
        try {
            await actionFavoriteItems({
                item_id: item_id,
                action: isFavorited ? 'remove' : 'add'
            })
            // console.log('FavoriteButton click:', res)
            // dispatch(setUserinfo({
            //     ...userinfo,
            //     favoriteItems: res?.data?.favoriteItems
            // }))
            dispatch(setIsRefreshUserinfo(true))
        } catch (error) {
            console.log(error)
        }
    }

    if (!userinfo) {
        return null
    }

    return (
        <div className='absolute top-3 right-3 z-[999] cursor-pointer'>
            {isFavorited
                ? <FaHeart onClick={onClick} size={24} />
                : <FaRegHeart onClick={onClick} size={24} />
            }
        </div>
    )
}
