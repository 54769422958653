import React from 'react'
import { ssoLoginGoogle } from '@services/api.service'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setUserinfo } from '@reducers/userReducer'
import { useGoogleLogin } from '@react-oauth/google'

export default function SSOLoginGoogle({
    inviteUser
}) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const backToRoute = useSelector(state => state.temp.backToRoute) || '/'

    const handleOnSuccess = async (response) => {
        console.log('handleOnSuccess', response)
        try {
            // const access_token = response.access_token
            const res = await ssoLoginGoogle({
                ...response,
                inviteCode: inviteUser?.inviteCode
            })
            console.log('handleOnSuccess', res)
            dispatch(setUserinfo(res.data))
            localStorage.removeItem('tmp_id')
            navigate(backToRoute, { replace: true })
        } catch (err) {
            console.log('handleOnSuccess error:', err)
        }
    }

    const handleOnError = (response) => {
        console.log('handleOnError', response)
    }

    const login = useGoogleLogin({
        onSuccess: handleOnSuccess,
        onError: handleOnError
    })

    return (
        <button onClick={login} className='h-[43px] border rounded-full border-[#747775] w-full flex items-center justify-center gap-3 text-[15px] font-medium text-center'>
            <img src='/images/Google.png' alt='Sign up with Google' />Sign in with Google
        </button>

        // <GoogleOAuthProvider clientId={Config.googleSSOClientId}>
        //      <GoogleLogin
        //         render={(renderProps) => {
        //             console.log('renderProps', renderProps)
        //             return <button className='h-[43px] border rounded-full border-[#747775] w-full flex items-center justify-center gap-3 text-[15px] font-medium text-center'>
        //                 <img src='/images/Google.png' alt='Sign up with Google' />Sign up with Google
        //             </button>
        //         }}
        //         onSuccess={handleOnSuccess}
        //         onError={handleOnError}
        //         cookiePolicy="single_host_origin"
        //     />
        // </GoogleOAuthProvider>
    )
}
