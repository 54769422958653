/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * This file contains the setup for the axios HTTP client and the getRecommendedList function.
 * The axios HTTP client is configured with a base URL and a timeout.
 * An interceptor is added to the HTTP client to add an authorization header to each request.
 * The getRecommendedList function is used to fetch a list of recommended items from the '/recommended' endpoint.
 * The function takes a params object as an argument, which is used as the query parameters for the request.
 * If the request is successful, the function returns the data from the response.
 * If the request fails, the function throws the response from the error.
 *
 * @author Yang Ming
 * @version June 8th, 2023
 */

import { Config } from '../Config'
import axios from 'axios'


const httpClient = axios.create({
    baseURL: Config.apiUrl + Config.apiPrefix,
    timeout: 60 * 1e3,
})

httpClient.interceptors.request.use(config => {
    // Perform logic before sending request
    config.headers['authorization'] = `${Config.apiKey} ` + localStorage.getItem('token')
    return config
})

/**
 * Fetches a list of recommended items from the '/recommended' endpoint.
 *
 * @param {Object} params - The query parameters for the request.
 * @returns {Promise} A promise that resolves to the data from the response.
 * @throws {Object} The response from the error.
 */

export async function getRecommendedList(params) {
    try {
        const res = await httpClient.get(`/recommended`, {
            params: params
        })
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function getRecommended(id) {
    try {
        const res = await httpClient.get(`/recommended/${id}`)
        return res.data
    } catch (error) {
        throw error.response
    }
}