/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * This module exports a React component named 'ItemContinueShippingBar'. This component displays a fixed bar with a 'Continue Shopping' button and the name and price of the current item.
 * The bar is positioned below the header. When the 'Continue Shopping' button is clicked, the user is redirected to the search page.
 * 
 * The component takes three props: 'item', 'headerHeight', and 'onClickContinueShopping'. 'item' is the current item. 'headerHeight' is the height of the header.
 * 'onClickContinueShopping' is a function that is called when the 'Continue Shopping' button is clicked.
 * 
 * @author Yang Ming, James Nguyen
 * @version January 18th, 2024
 */
import React from 'react'
import { AiOutlineLeft } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import ItemPrice from '../../zearch/components/ItemPrice'
import { getShortString } from '@utils/Helper'

/**
 * A component that displays a fixed bar with a 'Continue Shopping' button and the name and price of the current item.
 * 
 * @param {Object} props - The props object.
 * @param {Object} props.item - The current item.
 * @param {number} props.headerHeight - The height of the header.
 * @param {Function} props.onClickContinueShopping - A function that is called when the 'Continue Shopping' button is clicked.
 * @returns {JSX.Element} The 'ItemContinueShippingBar' component.
 */
export default function ItemContinueShippingBar({
    item,
    headerHeight,
    onClickContinueShopping
}) {
    return (
        <div className='item-continue-shopping-fixed-wrap' style={{
            top: headerHeight
        }}>
            <div className='item-continue-shopping-fixed'>
                <Link
                    onClick={onClickContinueShopping}
                    className='button-continue'
                    to='/search'
                >
                    <AiOutlineLeft />Continue shopping
                </Link>
                <div className='item-continue-shopping-fixed-title'>
                    <h3>
                        {getShortString(item?.name, 55)}
                    </h3>
                    <div className='product-item-info-price gap-1'>
                        <ItemPrice originalPrice={item?.originalPrice} salePrice={item?.salePrice} />
                    </div>
                </div>
            </div>
        </div>
    )
}
