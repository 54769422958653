import React from 'react'
import { getDistance2 } from '@utils/Helper'
import CartItemCard from './CartItemCard'
import CartButton from './CartButton'
import { message } from 'antd'

export default function CartItemStore({
  item,
  location,
  handleReserve,
  isMobile,
  handleCheckout,
  handleDeleteCart,
  handleUpdateCart,
  userinfo
}) {
  const selectedItems = item?.carts?.filter(item => item?.isSelected)
  console.log('selectedItems', selectedItems)

  const onClickReservation = () => {
    if (selectedItems?.length === 0) {
      return
    }
    handleReserve(item?.business?._id)
  }

  const onClickCheckout = async () => {
    if (!userinfo) {
      message.error('Please login to checkout')
      return
    }
    if (selectedItems?.length === 0) {
      message.error('Please select items to checkout')
      return
    }
    handleCheckout(item?.business?._id)
  }

  return (
    <div className='cart-item'>
      <div className='flex w-full flex-row items-center justify-between bg-[#f5f5f5] p-[10px]'>
        <div className='cart-item-store'>
          <h3>{item?.business?.name}</h3>
          <p>
            {item?.business?.address}, {item?.business?.city},{' '}
            {item?.business?.province} {item?.business?.postalCode},{' '}
            {getDistance2(location, {
              lat: item?.business?.lat,
              lng: item?.business?.lng,
            })}
          </p>
        </div>
        {!isMobile && (
          <div className='flex gap-3'>
            {item?.business?.isReservationEnabled && (
              <CartButton
                text='Reserve'
                onClick={onClickReservation}
                bgColor='white'
                textColor='fitzba-bright-gold-static'
                borderColor='fitzba-bright-gold-static'
                disabled={selectedItems?.length === 0}
              />
            )}
            {item?.business?.isCheckoutEnabled && (
              <CartButton
                text='Checkout'
                onClick={onClickCheckout}
                bgColor='fitzba-bright-gold-static'
                textColor='white'
                borderColor='fitzba-bright-gold-static'
                disabled={selectedItems?.length === 0}
              />
            )}
          </div>
        )}
      </div>
      <div className='cart-item-list'>
        {item?.carts?.map((item, index) => (
          <CartItemCard
            key={index}
            item={item}
            handleUpdateCart={handleUpdateCart}
            handleDeleteCart={handleDeleteCart}
          />
        ))}
      </div>
      {isMobile && (
        <div className='flex w-full justify-end gap-3'>
          {item?.business?.isReservationEnabled && (
            <CartButton
              text='Reserve'
              onClick={onClickReservation}
              bgColor='white'
              textColor='fitzba-bright-gold-static'
              borderColor='fitzba-bright-gold-static'
              disabled={selectedItems?.length === 0}
            />
          )}
          {item?.business?.isCheckoutEnabled && (
            <CartButton
              text='Checkout'
              onClick={onClickCheckout}
              bgColor='fitzba-bright-gold-static'
              textColor='white'
              borderColor='fitzba-bright-gold-static'
              disabled={selectedItems?.length === 0}
            />
          )}
        </div>
      )}
    </div>
  )
}
