import React, { useState } from 'react'
import { Form, Input, Select, message } from 'antd'
import EditProfileSection from './EditProfileSection'
import { changeProfile } from 'services/api.service'
import { canadaProvinces, usStates } from '@data/provinces.data'
import { setIsRefreshUserinfo } from '@reducers/userReducer'

export default function AddressInfo({
    userinfo,
    dispatch
}) {

    const [isUpdateInfo, setIsUpdateInfo] = useState(false)
    const [form] = Form.useForm()
    const [provinceList, setProvinceList] = useState(
        canadaProvinces.map(item => ({ label: item.name, value: item.code })),
    )
    const [country, setCountry] = useState('CA')

    const onClickSave = async (values) => {
        try {
            if (values.password) {
                if (values['new-password'] !== values['confirm-password']) {
                    message.error('New password and confirm password do not match!')
                    return
                }
            }
            await changeProfile(userinfo._id, {
                ...values,
                firstName: userinfo.firstName,
                lastName: userinfo.lastName,
                phone: userinfo.phone
            })
            message.success('Profile updated successfully!')
            setIsUpdateInfo(false)
            dispatch(setIsRefreshUserinfo(true))
        } catch (err) {
            console.error('handleSubmit', err)
            message.error('Action failed: ' + err.data?.message || 'Please try again!')
        }
    }

    const onClickCancel = () => {
        setIsUpdateInfo(false)
    }

    const onClickEdit = () => {
        setIsUpdateInfo(true)
        if (form) {
            form.setFieldsValue({
                city: userinfo?.city,
                address: userinfo?.address,
                postalCode: userinfo?.postalCode,
                country: userinfo?.country,
                province: userinfo?.province,
            })
        }
    }

    const onChangeCountry = value => {
        setCountry(value)
        const dataList = value === 'CA' ? canadaProvinces : usStates
        setProvinceList(
            dataList.map(item => ({ label: item.name, value: item.code })),
        )
        form?.setFieldsValue({ province: dataList[0].code })
    }

    return (
        <EditProfileSection
            title='Address'
            isShowEdit={!isUpdateInfo}
            onClickEdit={onClickEdit}
            contents={
                <>
                    {isUpdateInfo ? (
                        <Form
                            className='w-full flex flex-col gap-3 text-left'
                            layout="vertical"
                            wrapperCol={{
                                span: 20,
                            }}
                            form={form}
                            onFinish={onClickSave}
                        >
                            <div className='flex smsize:flex-col'>
                                <Form.Item
                                    label='Country'
                                    name={'country'}
                                    className='w-[200px] smsize:w-full'
                                    initialValue={'CA'}
                                >
                                    <Select
                                        className='rounded-full border border-black py-[10px]'
                                        placeholder='Country'
                                        // bordered={false}
                                        variant="borderless"
                                        dropdownStyle={{
                                            fontSize: '20px',
                                        }}
                                        onChange={onChangeCountry}
                                        options={[
                                            {
                                                label: 'Canada',
                                                value: 'CA',
                                            },
                                            {
                                                label: 'United States',
                                                value: 'US',
                                            },
                                        ]}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label='Province/State'
                                    name={'province'}
                                    rules={[
                                        { required: true, message: 'Province/State' },
                                    ]}
                                    className='w-[200px] smsize:w-full'
                                >
                                    <Select
                                        // size='large'
                                        // bordered={false}
                                        variant="borderless"
                                        className='rounded-full border border-black py-[10px]'
                                        placeholder={`${country === 'CA' ? 'Select a Province' : 'Select a State'
                                            }`}
                                        style={{ width: '100%' }}
                                        options={provinceList}
                                    />
                                </Form.Item>
                            </div>
                            <div className='flex border-t pt-3 smsize:flex-col'>
                                <Form.Item
                                    label='City'
                                    name={'city'}
                                    rules={[
                                        { required: true, message: 'City' },
                                    ]}
                                    className='w-[200px] smsize:w-full'
                                >
                                    <Input className='rounded-full border border-black max-w-[200px] smsize:max-w-none' placeholder='City' />
                                </Form.Item>
                                <Form.Item
                                    label='Postal/Zip Code'
                                    name={'postalCode'}
                                    className='w-[200px] smsize:w-full'
                                >
                                    <Input
                                        className='rounded-full border border-black max-w-[260px] smsize:max-w-none'
                                        placeholder={`${country === 'CA' ? 'Postal' : 'Zip'} Code`}
                                    />
                                </Form.Item>
                            </div>
                            <div className='flex border-t pt-3 smsize:flex-col'>
                                <Form.Item
                                    label='Address'
                                    name={'address'}
                                    className='w-[450px] text-left smsize:w-full'
                                >
                                    <Input className='rounded-full border border-black' placeholder='Address' />
                                </Form.Item>
                            </div>
                            <div className='flex gap-5 items-center justify-end'>
                                <button onClick={onClickCancel} type='button' className='bg-white text-[#F45045] border rounded-full border-[#F45045] w-[110px]'>Cancel</button>
                                <button type='submit' className='bg-[#E4B456] text-white border border-[#E4B456] rounded-full w-[110px]'>Save</button>
                            </div>
                        </Form>
                    ) : (
                        <div className='max-w-[800px] grid grid-cols-3 gap-4 text-left text-[15px] smsize:text-[13px] smsize:grid-cols-2'>
                            <div className=''>
                                <h4 className='text-[#A0A0A0]'>City</h4>
                                <span>{userinfo?.city}</span>
                            </div>
                            <div>
                                <h4 className='text-[#A0A0A0]'>Province/State</h4>
                                <span>{userinfo?.province}</span>
                            </div>
                            <div>
                                <h4 className='text-[#A0A0A0]'>Country</h4>
                                <span>
                                    {userinfo?.country === 'CA' ? 'Canada' : 'United States'}
                                </span>
                            </div>
                            <div>
                                <h4 className='text-[#A0A0A0]'>Address</h4>
                                <span>{userinfo?.address}</span>
                            </div>
                            <div>
                                <h4 className='text-[#A0A0A0]'>Postal/Zip code</h4>
                                <span>{userinfo?.postalCode}</span>
                            </div>
                        </div>
                    )}
                </>
            }
        />
    )
}
