/**
 * Copyright Fitzba Technologies Inc. 2023
 * The NearProducts component fetches and displays a list of products that are near the user's location.
 * 
 * Each product is displayed as an Item component.
 * 
 * The component uses the useQuery hook from react-query for data fetching
 * and the useSelector hook from react-redux to get the user's location and information.
 * 
 * The component also provides a horizontal scrolling feature for the list of products.
 *
 * @author Yang Ming
 * @version June 5th, 2023
 */


import React from 'react'
import { useSelector } from 'react-redux'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import './Products.css'
import ShoppingCartHook from '@hooks/ShoppingCart.hook'
import { getStoreProducts } from '@services/api.service'
import { Link } from 'react-router-dom'
import LoadingSection from '@components/LoadingSection'
import { useQuery } from '@tanstack/react-query'
import ItemCard from '../../zearch/components/ItemCard'
import SectionTitle from './SectionTitle'

/**
 * NearProducts fetches and displays a list of products that are near the user's location.
 * 
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.setIsShowRecommended - The function to set the visibility of recommended products (no longer used).
 * @param {Array} props.searchHistory - The user's search history.
 * @returns {JSX.Element} The JSX code for the NearProducts component.
 */
export default function DealProducts({
    business,
    width,
    isMobile
}) {
    const location = useSelector(state => state.user.location)
    const userinfo = useSelector(state => state.user.userinfo)

    const { addToCart } = ShoppingCartHook()
    const productListRef = React.useRef(null)

    const {
        isPending,
        // error,
        data,
    } = useQuery({
        queryKey: ['getStoreProducts', 'deal', location?.lat, location?.lng, business?._id],
        queryFn: () => getStoreProducts({
            lat: location?.lat,
            lng: location?.lng,
            type: 'deal',
            storeid: business?._id
        }),
    })

    /**
        * onClickScrollBtn horizontally scrolls the list of products by a specified step.
        *
        * @param {number} step - The step size for the scroll.
    */
    const onClickScrollBtn = (step) => {
        if (!productListRef.current)
            return
        let scrollAmount = 0
        const slideTimer = setInterval(() => {
            productListRef.current.scrollLeft += step
            scrollAmount += Math.abs(step)
            if (scrollAmount >= 1000) {
                clearInterval(slideTimer)
            }
        }, 20)
    }

    if (isPending)
        return <LoadingSection text='Loading items' height='200px' />

    const products = data?.data || []

    console.log('deal products', products)
    if (products.length === 0)
        return null

    return (
        <section
            style={{
                maxWidth: isMobile ? '100%' : `${width - 130}px`
            }}
            className={`m-auto w-full relative flex flex-col`}>
            <SectionTitle title={'DEALS'} />
            <div className="w-full overflow-x-hidden smsize:overflow-x-auto flex gap-5 text-left scroll-wrap smsize:gap-3" ref={productListRef}>
                <span className='scroll-left' style={{ top: '150px' }} onClick={() => onClickScrollBtn(-50)}><AiOutlineLeft /></span>
                <span className='scroll-right' style={{ top: '150px' }} onClick={() => onClickScrollBtn(50)}><AiOutlineRight /></span>
                {products?.map((item, index) => {
                    return <ItemCard key={index} userinfo={userinfo} item={item} handleAddToCart={addToCart} />
                })}
            </div>
        </section>
    )
}
