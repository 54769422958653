/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * RegisterStep2 is a functional React component that renders the second step of a multi-step registration form.
 * It allows users to input their business details, such as name, address, type, phone, city, province, and postal code.
 * The component utilizes the antd Form component, the AutoComplete feature to search for businesses,
 * and the Google Maps API to obtain latitude and longitude from the postal code.
 *
 * @author Yang Ming
 * @version May 09, 2023
 */

import React, { useEffect, useRef } from 'react'
import { Form, Input, Select } from 'antd'
// import { PhoneOutlined } from '@ant-design/icons'

import InputSuffixTip from '../InputSuffixTip'
import { isValidPostalCode } from '@utils/Helper'
// import { businessSearch } from '@services/api.service'

import { getLatLngAndAddressFromPostalCode } from '@services/google.map.service'
import { canadaProvinces, usStates } from '@data/provinces.data'
import { StandaloneSearchBox, useJsApiLoader } from '@react-google-maps/api'
import { isValidPostalCodeCAandUS } from '../../../../utils/Helper'
import { Config } from '../../../../Config'

/**
 * Create a form using antd Form component
 *
 * @returns Form component
 */
// const renderTitle = (title, count) => (
//   <div
//     style={{
//       display: 'flex',
//       justifyContent: 'space-between',
//     }}
//   >
//     {title}
//     <span>found {count} businesses</span>
//   </div>
// )
// const renderItem = (key, title, phone) => ({
//   value: key,
//   label: (
//     <div
//       key={key}
//       style={{
//         display: 'flex',
//         justifyContent: 'space-between',
//       }}
//     >
//       {title}
//       {phone && (
//         <span
//           style={{
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'center',
//           }}
//         >
//           <PhoneOutlined /> {phone}
//         </span>
//       )}
//     </div>
//   ),
// })

/**
 * The second step of the multi-step registeration
 */
export default function RetailRegisterStep1({
  setFormData,
  formData,
  prev,
  next,
  isUS,
  setIsUS,
}) {
  // Create a form using antd Form component
  const [form] = Form.useForm()
  // let latlng
  // Initializing states
  const [address, setAddress] = React.useState({
    name: formData?.business?.businessAddress || '',
  })
  // const [searchList, setSearchList] = React.useState([])
  const [ libraries ] = React.useState(['places']);

  // const [businessList, setBusinessList] = React.useState([])
  // const [openBusinessSearch, setOpenBusinessSearch] = React.useState(true)
  // const [businessId, setBusinessId] = React.useState(null)
  const [provinceList, setProvinceList] = React.useState(
    canadaProvinces.map(item => ({ label: item.name, value: item.name })),
    
  )
  const inputRef = useRef()

  const handlePlacesChange = () => {
    const [place] = inputRef.current.getPlaces()
    if (place) {
      setAddress(place)
      autofill(place)
    }
  }
  // populate form with data
  useEffect(() => {
    if (form !== null) {
      form.setFieldsValue(formData.business)
    }
  }, [form, formData])

  // console.log(form.getFieldValue())
  useEffect(() => {
    setIsUS(formData?.country === 'United States')
  }, [setIsUS, formData])
  // async function to populate the input fields with business data
  // const onChange = async value => {
  //   if (!openBusinessSearch) {
  //     // setBusinessId(null)
  //     setBusinessList([])
  //     setSearchList([])
  //     return
  //   }
  //   if (value.length === 24) {
  //     const business = businessList.find(item => item._id === value)
  //     if (business) {
  //       // setBusinessId(business._id)
  //       form.setFieldsValue({
  //         businessName: business.customerName,
  //         businessAddress: business.address1,
  //         city: business.city,
  //         province: business.province,
  //         businessPhone: business.phone,
  //         postalCode: business.postalCode,
  //       })
  //     }
  //     return
  //   }
  //   if (value.length <= 2) {
  //     setSearchList([])
  //     return
  //   }
  //   try {
  //     const res = await businessSearch({
  //       businessName: value,
  //     })
  //     const list = {
  //       label: renderTitle('Matching result', res.data.length),
  //       options: [],
  //     }
  //     res.data?.forEach(item => {
  //       list.options.push(renderItem(item._id, item.customerName, item.phone))
  //     })
  //     setBusinessList(res.data)
  //     setSearchList([list])
  //   } catch (err) {
  //     console.log('err', err)
  //   }
  // }
  const onPrev = () => {
    setFormData({
      ...formData,
      ...form.getFieldsValue(),
    })
    prev()
  }
  // Creating onFinish function to use for form submission
  const onFinish = async values => {
    // get lat lng from postal code
    let latlng = {
      lat: 0,
      lng: 0,
    }
    if (values.postalCode) {
      const res = await getLatLngAndAddressFromPostalCode(values.postalCode)
      if (res) {
        latlng = {
          lat: res?.lat,
          lng: res?.lng,
        }
      }
    }
    console.log(values)
    setFormData({
      ...formData,
      business: {
        ...values,
        ...latlng,
      },
    })
    next()
    // await sendVerificationEmail(formData.username)
  }
  //Save the data of the current form and return to the previous step
  // const onPrev = () => {
  //   setFormData({
  //     ...formData,
  //     ...form.getFieldsValue(),
  //   })
  //   prev()
  // }
  // Allow business searching if the city is Cochrane an the province is AB
  const onCityAndProvinceChange = e => {
    // setOpenBusinessSearch(
    //   form.getFieldValue('city')?.toLowerCase() === 'cochrane' &&
    //     form.getFieldValue('province') === 'Alberta',
    // )
  }

  const onCountryChange = e => {
    console.log('e', e)
    if (e === 'Canada') {
      setIsUS(false)
      setProvinceList(
        canadaProvinces.map(item => ({ label: item.name, value: item.name })),
      )
      form.setFieldValue('province', 'Alberta')
    } else {
      setIsUS(true)
      setProvinceList(usStates.map(item => ({ label: item.name, value: item.name })))
      form.setFieldValue('province', 'Alabama')
    }
  }

  // Check if the postal code enter is valid or not
  const checkPostalCode = async (_, postalCode) => {
    // console.log(!form.getFieldValue('country'))
    if (!postalCode) {
      return Promise.resolve()
    }
    if (!form.getFieldValue('country')) {
      if (!isValidPostalCodeCAandUS(postalCode)) {
        throw new Error('Postal code is invalid')
      }
    } else if (!isValidPostalCode(postalCode, isUS)) {
      throw new Error('Postal code is invalid')
    }
  }

  function getStateProvinceFullName(addressComponents) {
    for (const component of addressComponents) {
      for (const type of component.types) {
        if (type === 'administrative_area_level_1') {
          return component.long_name
        }
      }
    }
    return '' // Return an empty string if not found
  }

  const autofill = async address => {
    console.log(address)
    const parser = new DOMParser()
    const doc = parser.parseFromString(address?.adr_address, 'text/html')
    const streetAddress =
      doc.querySelector('.street-address')?.textContent || ''
    const locality = doc.querySelector('.locality')?.textContent || ''
    const postalCode = doc.querySelector('.postal-code')?.textContent || ''
    const countryName = doc.querySelector('.country-name')?.textContent || ''
    if (countryName) {
      if (countryName === 'Canada' || countryName === 'USA') {
        console.log(streetAddress)
        form.setFieldValue('businessAddress', streetAddress)
        const stateProvinceFullName = getStateProvinceFullName(
          address.address_components,
        )
        form.setFieldValue('province', stateProvinceFullName)
        form.setFieldValue('businessPostalCode', postalCode)
        form.setFieldValue('city', locality)
        if (countryName === 'Canada') {
          form.setFieldValue('country', countryName)
        } else if (countryName === 'USA') {
          setIsUS(true)
          form.setFieldValue('country', 'United States')
        }
      }
    }
  }
  const checkRegion = async () => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(address?.adr_address, 'text/html')
    const countryName = doc.querySelector('.country-name')?.textContent || ''
    const place = inputRef.current.getPlaces()

    if (countryName || place) {
      if (countryName === 'Canada' || countryName === 'USA') {
        return Promise.resolve()
      } else {
        return Promise.reject(
          'Please select a location within Canada or the US.',
        )
      }
    }
  }

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: Config.googleMapsApiKey,
    libraries
  })
  if (!isLoaded) {
    return null
  }
  return (
    <Form
      forceRender
      layout='vertical'
      onFinish={onFinish}
      scrollToFirstError
      form={form}
      style={{
        textAlign: 'left',
      }}
      className='retail-form w-full lg:px-40 xl:px-20'
    >
      <div className='flex w-full flex-col xl:flex-row xl:gap-10'>
        <Form.Item
          name='storeName'
          rules={[
            {
              required: true,
              message: 'Please input store name',
              whitespace: true,
            },
          ]}
          className='register-form-item'
        >
          <Input
            className='rounded-full border-2 border-black'
            size='large'
            placeholder='Store Name'
            allowClear
          />
        </Form.Item>
        <Form.Item name='businessName' className='register-form-item'>
          <Input
            className='rounded-full border-2 border-black text-base'
            size='large'
            placeholder='Business Name (if different)'
          />
        </Form.Item>
      </div>
      <div className='flex w-full flex-col xl:flex-row xl:gap-10'>
        <Form.Item
          name='businessPhone'
          rules={[
            { required: true, message: 'Please input your phone number' },
            {
              max: 12,
              min: 12,
              message: 'The format is ###-###-####',
              pattern: /^\d{3}-\d{3}-\d{4}$/,
            },
          ]}
          style={{
            width: '100%',
          }}
          className='register-form-item'
        >
          <Input
            className='rounded-full border-2 border-black'
            size='large'
            suffix={<InputSuffixTip text='The format is ###-###-####' />}
            placeholder='Store Phone'
            onChange={e => {
              const value = e.target.value
              if (value.length === 10 && /^\d+$/.test(value)) {
                const text =
                  value.slice(0, 3) +
                  '-' +
                  value.slice(3, 6) +
                  '-' +
                  value.slice(6)
                form.setFieldsValue({
                  businessPhone: text,
                })
              }
            }}
            allowClear
          />
        </Form.Item>
        <Form.Item
          name='businessAddress'
          initialValue={form.getFieldValue('businessAddress')}
          rules={[
            {
              validator: checkRegion,
            },
            {
              required: true,
              message: 'Please input your store`s address',
              whitespace: true,
            },
          ]}
          className='register-form-item'
        >
          <StandaloneSearchBox
            onLoad={ref => (inputRef.current = ref)}
            onPlacesChanged={handlePlacesChange}
          >
            <Input
              className='rounded-full border-2 border-black'
              size='large'
              value={address.name}
              onChange={e => {
                setAddress({ ...address, name: e.target.value })
                form.setFieldValue('businessAddress', e.target.value)
              }}
              place
              placeholder='Store Address'
              allowClear
            />
          </StandaloneSearchBox>
        </Form.Item>
      </div>
      <div className='flex w-full flex-col xl:flex-row xl:gap-10'>
        <Form.Item
          name='country'
          rules={[
            {
              required: true,
              message: 'Please select a country',
              whitespace: true,
            },
          ]}
          className='register-form-item'
        >
          <Select
            className='rounded-full border-2 border-black'
            id='country-select'
            placeholder='Country'
            // bordered={false}
            variant="borderless"
            size='large'
            onChange={onCountryChange}
            options={[
              { label: 'Canada', value: 'Canada' },
              { label: 'United States', value: 'United States' },
            ]}
          />
          {/* </div> */}
        </Form.Item>
        <Form.Item
          name='province'
          hasFeedback
          onChange={onCityAndProvinceChange}
          className='register-form-item font-normal'
        >
          <Select
            className='rounded-full border-2 border-black'
            placeholder='Province/State'
            id='province-select'
            // bordered={false}
            variant="borderless"
            size='large'
            options={provinceList}
          />
        </Form.Item>
      </div>
      <div className='flex w-full flex-col xl:flex-row xl:gap-10'>
        <Form.Item
          name='city'
          rules={[
            {
              required: true,
              message: 'Please input city',
              whitespace: true,
            },
          ]}
          hasFeedback
          className='register-form-item'
          onChange={onCityAndProvinceChange}
        >
          <Input
            className='rounded-full border-2 border-black'
            size='large'
            placeholder='City/Town'
            allowClear
          />
        </Form.Item>
        <Form.Item
          name='businessPostalCode'
          rules={[
            {
              required: true,
              message: 'Please input your postal/zip code',
              whitespace: true,
            },
            { validator: checkPostalCode },
          ]}
        >
          <Input
            className='rounded-full border-2 border-black'
            size='large'
            suffix={
              <InputSuffixTip
                text={
                  isUS
                    ? 'The format is 12345 or 12345-1234'
                    : 'The format is A1A 1A1'
                }
              />
            }
            placeholder='Postal/Zip code'
          />
        </Form.Item>
      </div>
      <div
        style={{
          marginTop: 24,
        }}
        className='mx-auto flex w-[300px] items-center justify-center gap-2'
      >
        <div
          className='flex w-full max-w-[100px] cursor-pointer justify-center rounded-full border-2 border-black bg-white p-[6px] text-black hover:bg-slate-200'
          onClick={onPrev}
        >
          BACK
        </div>
        <button
          type={'submit'}
          className=' flex w-full cursor-pointer justify-center rounded-full border-2 border-fitzba-bright-gold-static bg-fitzba-bright-gold-static p-[6px] text-white hover:bg-white hover:text-fitzba-bright-gold-static'
        >
          CONTINUE
        </button>
      </div>
    </Form>
  )
}
