import React from 'react'
import { Result } from 'antd'

export default function ReserveCompleted({
    closePopup
}) {
    return (
        <Result
            key={'reserve-completed'}
            status="success"
            title="Successfully Reserved"
            subTitle="Thank you for reserving with us!"
            extra={[
                <div className='my-5 flex w-full justify-center'>
                    <button
                        type='button'
                        className=' flex w-fit cursor-pointer justify-center rounded-full border-2 border-fitzba-bright-gold-static bg-fitzba-bright-gold-static px-10 py-[6px] text-white hover:bg-white hover:text-fitzba-bright-gold-static'
                        onClick={closePopup}
                    >
                        Continue Shopping
                    </button>
                </div>
            ]}
        />
    )
}
