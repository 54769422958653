/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * AccountSelect is a functional React component that renders the account selection page for users to choose between a consumer or business account.
 * 
 * @author Yang Ming, James Nguyen
 * @version December 19th, 2023
 */
import React from 'react'
import './AccountSelect.css'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setBusiness, setUserinfo } from '../../reducers/userReducer'
import { message } from 'antd'
import { login, registerConsumerV3 } from '../../services/api.service'

const AccountSelect = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // const location = useLocation()
  const isGoogle = useSelector((state => state.temp.isGoogle))
  const tempUser = useSelector((state => state.temp.userinfo))
  // const [target, setTarget] = useState('')
  // useEffect(() => {
  //   // Get the URL of the current page
  //   const queryParams = new URLSearchParams(location.search)

  //   // Get the value of the 'target' parameter
  //   setTarget(queryParams.get('target'))

  //   // Now, you can use the targetParam value in your component
  // }, [location.search])
  const handleAccountType = async accountType => {
    // Handle the selected account type here
    dispatch(setBusiness(accountType))
    // You can navigate to the register page based on the selected account type
    if (isGoogle) {
      await registerConsumerV3({
        username: tempUser?.username,
        firstName: tempUser?.firstName,
        lastName: tempUser?.lastName,
        password: "SSO Account",
        isGoogle: true
      })

      const tmp_id = localStorage.getItem('tmp_id')
      const res2 = await login({
        username: tempUser?.username,
        password: "SSO Account",
        tmp_id,
      })
      console.log('res', res2)

      dispatch(setUserinfo(res2.data))
      if (tmp_id) {
        localStorage.removeItem('tmp_id')
    }
      // dispatch(setBusiness(res2.data.isBusiness))
      message.success('Registered account sucessfully')
      setTimeout(() => {
        navigate('/')
      }, 1500)
    } else {
      navigate('/register')
    }
  }

  return (
    <div className='flex min-h-[100vh]'>
      <div className='relative hidden flex-1 xl:flex'>
        <div className='fixed flex h-[100vh] w-1/3 flex-col items-center justify-center bg-fitzba-off-black-static'>
          <img
            src='/fitzba-ICON-WORD-WHITE.png'
            onClick={() => {
              navigate('/')
            }}
            alt='Fitzba Logo'
            className='md:w-[200px] lg:w-[400px]'
          />
          <div className='mt-[122px] w-[276px] text-center text-[20px] font-light text-white'>
            Have questions?{' '}
            <Link
              to='/contact'
              className=' text-fitzba-bright-gold-static underline'
            >
              Contact us
            </Link>{' '}
            and we will get back to you with answers
          </div>
        </div>

        <div className='mt-[122px] w-[276px] text-center text-[20px] font-light text-white'>
          Have questions?{' '}
          <Link
            to='/contact'
            className=' text-fitzba-bright-gold-static underline'
          >
            Contact us
          </Link>{' '}
          and we will get back to you with answers
        </div>
      </div>
      <div className='login-wrapper flex-[2]'>
        <div className='flex min-h-[100vh] w-full items-center justify-center p-2'>
          {/* <div className='login-main-logo hidden'>
            <img src='/FITZBA-WORD-ICON.png' width={400} alt='' />
          </div> */}
          <div className='m-[auto] flex w-full flex-col items-center justify-center gap-[30px] p-5 text-black'>
            <h3 className='text-[38px] font-medium'>REGISTER</h3>
            <div>
              <h4 className='text-[20px]'>
                Looks like you don’t have an account
              </h4>
              <h4 className='text-[30px]'>Who are you?</h4>
            </div>
            <h5 className='-my-3 text-[24px] font-light'>I'm a</h5>
            <div className='account-select-buttons'>
              <button id='consumer-sign-up' onClick={() => handleAccountType(false)}>CONSUMER</button>
              <button id='business-sign-up' onClick={() => navigate('/launch-offer')}>BUSINESS</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountSelect
