/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * ItemPrice.js is a functional component that renders the price of an item. It takes in props from its parent, which are: originalPrice and salePrice.  The originalPrice prop is a number that represents the original price of the item.  The salePrice prop is a number that represents the sale price of the item.  The component returns a div that contains the price of the item.  If the original price is greater than the sale price, the component will display the sale price and the original price.  If the original price is equal to the sale price, the component will display the original price.  If the original price and the sale price are both 0, the component will display "N/A".
 * 
 * @author Yang Ming, James Nguyen
 * @version October 3rd, 2023
 */

import React from 'react'
import { formatCurrency2 } from '@utils/Helper'

/**
 * Component responsible for displaying the price of an item.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {number} props.originalPrice - The original price of the item.
 * @param {number} props.salePrice - The sale price of the item.
 */

export default function ItemPrice({
    originalPrice,
    salePrice,
}) {
    // if Original Price and Sale Price are both 0, display N/A instead of 0 
    if (!originalPrice && !salePrice) {
        return (
            <div className='flex'>N/A</div>
        )
    }
    return (
        <div className='flex'>
            <div className='text-[15px]'>$</div>
            <div>{formatCurrency2(salePrice)}</div>
            {originalPrice > salePrice && originalPrice !== salePrice &&
                <div className='pl-[5px] text-[18px] text-[#8f8f8f] line-through'>
                    {formatCurrency2(originalPrice)}
                </div>
            }
        </div>
    )
}
