/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * Component for the Contact form
 * 
 * @author Yang Ming, Josh Soke
 * @version May 09, 2023
 */

import "./MessageForm.css"
import React, { useState } from "react"
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai"
import { addBusinessMessage } from "@services/api.business.service"
import ItemCard from "./ItemCard"
/**
 *
 * @returns Render a form that is use to send any question 
 *          the user have to Fitzba
 */
export default function MessageForm({
  business,
  userinfo,
  item
}) {
  // initial state for the form
  const [formData, setFormData] = useState({
    name: userinfo ? `${userinfo?.firstName} ${userinfo?.lastName}` : "",
    email: userinfo?.username || "",
    phoneNumber: userinfo?.phone || "",
    subject: "",
    message: "",
  })
  const [result, setResult] = useState("")

  // get Data
  const getData = (key) => {
    return formData.hasOwnProperty(key) ? formData[key] : ""
  }

  // Set data
  const setData = (key, value) => {
    return setFormData({ ...formData, [key]: value })
  }
  // async function to handle submission and reseting the form
  const handleSubmit = async (e) => {
    e.preventDefault()
    setResult("")

    try {
      await addBusinessMessage({
        ...formData,
        business_id: business._id,
        user_id: userinfo?._id,
        item_id: item?._id
      })
      setFormData({})
      setResult("success")
    } catch (err) {
      setResult(err.data?.message)
    }
  }

  return (
    <div className="form-group">
      <form onSubmit={handleSubmit}>
        <div className="form-group-line">
          <label htmlFor="name">Your Name</label>
          <input
            value={getData("name")}
            onChange={(e) => setData("name", e.target.value)}
            type="text"
            placeholder="Enter your name"
          />
        </div>
        <div className="form-group-line">
          <label htmlFor="email">
            Your Email (<span className="input-required">*</span>)
          </label>
          <input
            value={getData("email")}
            onChange={(e) => setData("email", e.target.value)}
            type="email"
            placeholder="Enter your email"
            required
          />
        </div>
        <div className="form-group-line">
          <label htmlFor="phone">Phone Number</label>
          <input
            value={getData("phoneNumber")}
            onChange={(e) => setData("phoneNumber", e.target.value)}
            type="text"
            placeholder="Enter phone number"
          />
        </div>
        <div className="form-group-line">
          <label htmlFor="subject">Subject</label>
          <input
            value={getData("subject")}
            onChange={(e) => setData("subject", e.target.value)}
            type="text"
            placeholder="Enter a title"
          />
        </div>
        <div className="form-group-line">
          <label htmlFor="content">
            Your Message (<span className="input-required">*</span>)
          </label>
          <textarea
            rows={5}
            value={getData("message")}
            onChange={(e) => setData("message", e.target.value)}
            placeholder="Enter text here"
            required
          />
        </div>
        <div className="form-group-line">
          <button type="submit" className="message-button">
            SUBMIT
          </button>
        </div>
        {item && (
          <div className="form-group-line">
            <label htmlFor="content">Referenced Item:</label>
            <ItemCard item={item} />
          </div>
        )}
        {result && (
          <div className={result === "success" ? "success" : "error"}>
            <label className="message-result">
              {result === "success" ? (
                <AiOutlineCheckCircle size={32} />
              ) : (
                <AiOutlineCloseCircle size={32} />
              )}
              {result === "success"
                ? `Success! We will contact you as soon as possible!`
                : result}
            </label>
          </div>
        )}
      </form>
    </div>
  )
}
