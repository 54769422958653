import React from 'react'
import './GiftGuide.css'

export default function GiftGuide2() {
  return (
    <div className='m-auto flex h-[1280px] w-[768px] flex-col items-center gap-3 bg-white p-3 text-[#202020]'>
      <div className='flex h-[60px] w-full items-center justify-between bg-[#222222] px-5 text-white'>
        <img src='/logo-white.png' width={120} alt='Fitzba.com' />
        <div>HOLIDAY GIFT GUIDE</div>
      </div>
      <div>
        <img
          src='/gift-guide/message-from-chamber.png'
          alt='A message from the chamber'
        />
        <div className='h-[60px] w-[445px] bg-[#520a0a]'></div>
        <div className='pl-5 text-left underline'>www.fitzba.com</div>
        <div className='mt-5 flex justify-between'>
          <div></div>
          <p className='flex w-[500px] items-end text-left justify-between font-light'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In a ligula
            faucibus, eleifend mauris ac, dictum ligula. Vivamus nec erat sit
            amet nunc eleifend tempus. Sed non ante in orci cursus dapibus quis
            sit amet nisl. Donec aliquam finibus ante vitae dictum. Fusce eget
            ligula id sapien faucibus viverra posuere eget ligula. Etiam nunc
            libero, lobortis quis vestibulum nec, fringilla eget elit. Vivamus
            vehicula in nunc eu cursus. Vivamus ac mauris dui. Suspendisse dui
            risus, malesuada eget nibh egestas.
          </p>
        </div>
      </div>
    </div>
  )
}
