/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * The ScrollToTop component handles the scrolling behavior when navigating between routes.
 * It scrolls to the top of the page or to a specific element when the route changes.
 * The component uses the useLocation hook from the react-router-dom library to listen for route changes.
 * It also uses the useSelector and useDispatch hooks from the react-redux library to manage the state of the gotoElement.
 * The HelmetProvider and Helmet from the react-helmet-async library are used to manage the document head.
 *
 * @author Yang Ming
 * @version June 14th, 2023 
 */

import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { setGotoElement } from "@reducers/contentReducer"
import { useLocation } from "react-router-dom"
import { HelmetProvider, Helmet } from "react-helmet-async"
import { Config } from '@Config'

export default function ScrollToTop() {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const gotoElement = useSelector((state) => state.content.gotoElement)
  // const gotoScrollY = useSelector((state) => state.content.gotoScrollY)
  // useEffect when gotoElement changed
  useEffect(() => {
    if (!gotoElement) {
      // window.scrollTo(0, 0)
      return
    }
    const element = document.getElementById(gotoElement)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
    dispatch(setGotoElement(null))
  }, [gotoElement, dispatch])

  useEffect(() => {
    // const fetchAddView = async (pathname) => {
    //   await addView({ pathname: pathname })
    // }
    // fetchAddView(pathname)
    window.scrollTo(0, 0)
  }, [pathname])

  let titleName = Config.appName
  if (pathname !== '/') {
    titleName = `${pathname.slice(1).charAt(0).toUpperCase() + pathname.slice(2)} - ${Config.appName}`
    if (pathname === '/zearch') {
      titleName = 'Search - ' + titleName
    }
  }

  return (
    <HelmetProvider>
      <Helmet>
        <title>{titleName}</title>
      </Helmet>
    </HelmetProvider>
  )
}