/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * The Popup component renders a popup window that contains a series of components.
 * These components include OutsideFitzba, WaitListForm, and QuestionForm.
 * The popup window can be closed by clicking outside of it or by clicking the close button.
 * The component uses the useLocation hook from the react-router-dom library to determine if it should be rendered based on the current route.
 * It also uses the useState, useEffect, and useRef hooks from the React library to manage state and handle interactions.
 *
 * @author James Nguyen
 * @version June 7th, 2023
 */

import React, { useState, useEffect, useRef } from 'react'
import WaitListForm from './WaitListForm'
// import CountDown from './CountDown'
import QuestionForm from './QuestionForm'
import { AiOutlineLeft } from 'react-icons/ai'
import { useLocation } from 'react-router-dom'
import OutsideFitzba from './OutsideFitzba'

/**
 * Popup is a functional component that renders a popup window.
 * The popup contains the OutsideFitzba, WaitListForm, and QuestionForm components and can be closed by clicking outside of it or by clicking the close button.
 * 
 * @returns {JSX.Element} The rendered Popup component.
 */
function Popup() {
  const [showPop, setShowPop] = useState(true)
  const [prog, setProg] = useState(0)
  const wrapperRef = useRef(null)
  const items = [
    <OutsideFitzba setProg={setProg} />,
    <WaitListForm showPop={showPop} setShowPop={setShowPop} />,
    <QuestionForm />,
  ]

  useEffect(() => {
    if (!wrapperRef) return

    function handleClickOutside(event) {
      if (wrapperRef?.current && !wrapperRef?.current?.contains(event.target)) {
        setShowPop(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef, setShowPop])

  const location = useLocation()
  if (location.pathname !== '/' || !showPop) return null

  localStorage.setItem('isPoped', "1")

  return (
    <div className='z-[999999]'>
      <div className='fixed z-10 flex h-full w-full items-center justify-center bg-fitzba-darkcoal-static/70'
      // onClick={() => {
      //   setShowPop(false)
      // }}
      >
        {/* <WaitListForm /> */}
        <div ref={wrapperRef} className='flex relative -mt-20 h-[520px] w-[300px] flex-col items-center justify-center rounded-3xl bg-fitzba-white-static font-lexend drop-shadow-2xl sm:w-[500px] lg:w-[600px] smsize:w-full smsize:mx-3'>
          <div className='absolute top-4 right-5 text-xl text-[#a6a6a6]'>
            <button onClick={() => { setShowPop(false) }}>X</button>
          </div>

          {prog !== 0 && (
            <div className='absolute top-4 left-5 text-xl'>
              <button
                onClick={() => {
                  setProg(0)
                }}
              >
                <AiOutlineLeft className='text-[#a6a6a6]' />
              </button>
            </div>
          )}
          <div className={`w-full flex-[3.5] justify-center items-center rounded-t-3xl flex ${prog === 0 ? 'bg-black  ' : ''}`}>
            {prog === 0 ? (<img src='/fitzba-ICON-WORD-WHITE.png' alt='logo' className='h-16 ' />) : (<img src='/logo192.png' alt='logo' className='h-24 ' />)}
          </div>
          <div className='flex-[7] w-fit'>{items[prog]}</div>
          <div className='flex flex-1 flex-row pb-5'>
            <h4 className='flex flex-row items-center gap-[2px] pt-5 text-[12px] font-light tracking-[.5px] text-fitzba-black-static'>
              To join us on our journey, follow us on
              <a
                href='https://www.instagram.com/thefitzba/'
                className='w-[14px] text-black'
              >
                <img src='/images/instagram_pop.png' alt='instagram' />
              </a>
              <a
                href='https://www.facebook.com/TheFitzba'
                className='w-[15px] text-black'
              >
                <img src='/images/facebook_pop.png' alt='facebook' />
              </a>
              <a
                href='https://www.linkedin.com/company/fitzba-technologies-inc/'
                className='w-[14px] text-black'
              >
                <img src='/images/linkedin_pop.png' alt='linkedin' />
              </a>
            </h4>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Popup
