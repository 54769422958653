/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * A list of item cards
 *
 * @author Yang Ming
 * @version February 12th, 2024
 */

import React from 'react'
import { Tooltip } from 'antd'
import { BsPatchCheckFill } from 'react-icons/bs'

export default function ItemLiveInventoryIcon({
    size = 24,
    className = 'absolute right-1 top-[5px] z-10'
}) {
    return (
        <Tooltip
            placement="top"
            title="Live verified inventory"
            overlayClassName='item-live-inventory'
            color='#fff'
            overlayInnerStyle={{ color: '#000' }}
            overlayStyle={{
                '--antd-arrow-background-color': '#000'
            }}
        >
            <BsPatchCheckFill className={className} size={size} />
        </Tooltip>
    )
}
