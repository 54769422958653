import React, { useState, useEffect } from 'react'
import { Form, Input, Select, Modal, message, Spin } from 'antd'
import { canadaProvinces, usStates } from '@data/provinces.data'
import { shoppingCartCheckout } from '@services/api.shoppingCart.service'

export default function CartShippingForm({
  setIsCheckout,
  userinfo,
  isCheckout,
  selectedStore,
}) {
  const [formRef] = Form.useForm()
  const [provinceList, setProvinceList] = useState(
    canadaProvinces.map(item => ({ label: item.name, value: item.code })),
  )
  const [country, setCountry] = useState('CA')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (formRef) {
      formRef.setFieldsValue({
        country: userinfo?.country || 'CA',
        province: userinfo?.province || canadaProvinces[0].code,
        firstName: userinfo?.firstName,
        lastName: userinfo?.lastName,
        address: userinfo?.address,
        apt: userinfo?.apt,
        city: userinfo?.city,
        postalCode: userinfo?.postalCode,
        phone: userinfo?.phone,
      })
    }
  }, [formRef, userinfo])

  const onChangeCountry = value => {
    setCountry(value)
    const dataList = value === 'CA' ? canadaProvinces : usStates
    setProvinceList(
      dataList.map(item => ({ label: item.name, value: item.code })),
    )
    formRef?.setFieldsValue({ province: dataList[0].code })
  }

  const handleSubmit = async values => {
    const hide = message.loading('Checkout in progress...')
    setIsSubmitting(true)
    try {
      const res = await shoppingCartCheckout({
        business_id: selectedStore,
        shippingInfo: values,
      })
      console.log('handleSubmit')
      hide()
      setIsOpen(true)
      setTimeout(() => {
        // redirect to "google.com" add blank
        setIsSubmitting(false)
        window.open(res?.data?.pos_checkout_webUrl, '_blank')
      }, 2000)
    } catch (error) {
      console.log(error)
      hide()
      setIsSubmitting(false)
      message.error(
        'Checkout failed: ',
        error?.response?.message || 'Please try again',
      )
    }
  }

  return (
    <>
      <Modal
        title={isSubmitting ? 'Checkout in progress...' : 'Checkout'}
        centered
        open={isOpen}
        onOk={() => setIsOpen(false)}
        onCancel={() => setIsOpen(false)}
        // hide footer
        footer={null}
      >
        <div className='flex flex-col items-start justify-center gap-3'>
          {isSubmitting ? (
            <>
              <Spin size='large' tip='Loading' />
              <p>
                After submitting the checkout, you’ll be automatically
                redirected to the payment page upon completion.
              </p>
            </>
          ) : (
            <p>
              Checkout submitted successfully, please proceed to complete the
              payment.
            </p>
          )}
          <div className='mt-3 flex justify-end gap-5'>
            <button
              type='button'
              onClick={() => setIsOpen(false)}
              className='flex w-fit cursor-pointer justify-center rounded-full border-2 border-fitzba-bright-gold-static bg-white px-10 py-[6px] uppercase text-fitzba-bright-gold-static hover:bg-fitzba-bright-gold-static hover:text-white'
            >
              CLOSE
            </button>
            <button
              type='button'
              onClick={() => setIsOpen(false)}
              className='flex w-fit cursor-pointer justify-center rounded-full border-2 border-fitzba-bright-gold-static bg-fitzba-bright-gold-static px-10 py-[6px] uppercase text-white hover:bg-white hover:text-fitzba-bright-gold-static'
            >
              FINISH
            </button>
          </div>
        </div>
      </Modal>

      <div className='cart-content-header'>
        <h3>Checkout</h3>
        <p>Please input your shipping and billing information.</p>
      </div>
      <div className='flex-1'>
        <h3 className='mb-2 text-[22px] font-[300]'>Shipping information</h3>
        <Form form={formRef} layout='vertical' onFinish={handleSubmit}>
          <div className='flex w-full items-center gap-4'>
            <Form.Item
              label='First Name'
              name='firstName'
              rules={[
                {
                  required: true,
                  message: 'Please input your first name',
                },
              ]}
              hasFeedback
              className='w-full'
            >
              <Input
                size='large'
                className='rounded-full border-black'
                placeholder='First Name'
                allowClear
              />
            </Form.Item>
            <Form.Item
              label='Last Name'
              name='lastName'
              rules={[
                {
                  required: true,
                  message: 'Please input last name',
                },
              ]}
              hasFeedback
              className='w-full'
            >
              <Input
                size='large'
                className='rounded-full border-black'
                placeholder='Last Name'
                allowClear
              />
            </Form.Item>
          </div>
          <Form.Item
            label='Address'
            name='address'
            rules={[
              {
                required: true,
                message: 'Please input address',
              },
            ]}
            hasFeedback
          >
            <Input
              size='large'
              className='rounded-full border-black'
              placeholder='Address'
              allowClear
            />
          </Form.Item>
          <Form.Item label='Apt, suite, etc. (optional)' name='apt' hasFeedback>
            <Input
              size='large'
              className='rounded-full border-black'
              placeholder='Apt, suite, etc. (optional)'
              allowClear
            />
          </Form.Item>
          <div className='flex items-center gap-4'>
            <Form.Item
              name='country'
              label='Country'
              initialValue={'CA'}
              className='flex-1'
              rules={[
                {
                  required: true,
                  message: 'Please select country',
                },
              ]}
              hasFeedback
            >
              <Select
                className='rounded-full border border-black py-[18px]'
                placeholder='Country'
                variant='borderless'
                dropdownStyle={{
                  fontSize: '20px',
                }}
                onChange={onChangeCountry}
                options={[
                  {
                    label: 'Canada',
                    value: 'CA',
                  },
                  {
                    label: 'United States',
                    value: 'US',
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              label='Province'
              name='province'
              className='flex-1'
              rules={[
                {
                  required: true,
                  message: 'Please input province',
                },
              ]}
            >
              <Select
                size='large'
                // bordered={false}
                variant='borderless'
                className='rounded-full border border-black py-[18px]'
                placeholder={`${
                  country === 'CA' ? 'Select a Province' : 'Select a State'
                }`}
                style={{ width: '100%' }}
                options={provinceList}
              />
            </Form.Item>
          </div>
          <div className='flex items-center gap-4'>
            <Form.Item
              label='City'
              className='flex-1'
              name='city'
              rules={[
                {
                  required: true,
                  message: 'Please input city',
                },
              ]}
              hasFeedback
            >
              <Input
                size='large'
                className='rounded-full border-black'
                placeholder='City'
                allowClear
              />
            </Form.Item>
            <Form.Item
              label={`${country === 'CA' ? 'Postal' : 'Zip'} Code`}
              className='flex-1'
              name='postalCode'
              rules={[
                {
                  required: true,
                  message: 'Please input postal code',
                },
              ]}
              hasFeedback
            >
              <Input
                size='large'
                className='rounded-full border-black'
                placeholder={`${country === 'CA' ? 'Postal' : 'Zip'} Code`}
                allowClear
              />
            </Form.Item>
          </div>
          <div className='mt-3 flex justify-end gap-5'>
            <button
              type='button'
              onClick={() => setIsCheckout(false)}
              className='flex w-fit cursor-pointer justify-center rounded-full border-2 border-fitzba-bright-gold-static bg-white px-10 py-[6px] uppercase text-fitzba-bright-gold-static hover:bg-fitzba-bright-gold-static hover:text-white'
            >
              Black
            </button>
            <button
              type='submit'
              onClick={() => {}}
              className='flex w-fit cursor-pointer justify-center rounded-full border-2 border-fitzba-bright-gold-static bg-fitzba-bright-gold-static px-10 py-[6px] uppercase text-white hover:bg-white hover:text-fitzba-bright-gold-static'
            >
              Continue
            </button>
          </div>
        </Form>
      </div>
    </>
  )
}
