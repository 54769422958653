import React from 'react'
import MobileTopFilterItem from './MobileTopFilterItem'
import { useSelector, useDispatch } from 'react-redux'
import { setFilterCode } from '@reducers/contentReducer'

export default function MobileTopFilterOnsale() {
    const dispatch = useDispatch()
    const itemsFilter = useSelector(state => state.content.itemsFilter)

    return (
        <>
            <MobileTopFilterItem
                selectedable={false}
                text='On sale'
                onClick={() => {
                    console.log('itemsFilter.code', itemsFilter.code)
                    dispatch(setFilterCode(itemsFilter.code === 'on-sale' ? '' : 'on-sale'))
                }}
                isSelected={itemsFilter.code === 'on-sale'}
            />
        </>
    )
}
