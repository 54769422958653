/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * ImageListMobile.js exports a React component named 'ImageListMobile'. This component displays a list of images for a specific item in a mobile-friendly format.
 * The user can swipe left or right to navigate through the images. The currently displayed image is indicated by a dot below the image.
 * 
 * The component takes three props: 'images', 'name', and 'isUsed'. 'images' is an array of image URLs. 'name' is the name of the item. 'isUsed' is a boolean indicating whether the item is used.
 * 
 * @author Yang Ming
 * @version January 19th, 2024
 */

import React from 'react'
import { useSwipeable } from 'react-swipeable'
import ImagePreOwned from './ImagePreOwned'
import Zoom from 'react-medium-image-zoom'
/**
 * Component that displays a list of images for a specific item in a mobile-friendly format.
 * 
 * @param {Object} props - The props object.
 * @param {Array} props.images - An array of image URLs.
 * @param {string} props.name - The name of the item.
 * @param {boolean} props.isUsed - A boolean indicating whether the item is used.
 * @returns {JSX.Element} The 'ImageListMobile' component.
 */
export default function ImageListMobile({
  images,
  name,
  isUsed,
  imageIndex,
  setImageIndex
}) {
  // const [activeIndex, setActiveIndex] = useState(0)

  const handlers = useSwipeable({
    onSwipedLeft: () => handleSwipe('left'),
    onSwipedRight: () => handleSwipe('right'),
  })

  const handleSwipe = direction => {
    if (direction === 'left') {
      setImageIndex(prevIndex =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1,
      )
    } else if (direction === 'right') {
      setImageIndex(prevIndex =>
        prevIndex === 0 ? images.length - 1 : prevIndex - 1,
      )
    }
  }
  let image = images?.length > 0 ? images[imageIndex] : '/item-default.png'
  if (images.length > 0) {
    image = imageIndex < images.length ? images[imageIndex] : images[0]
  }
  return (
    <div
      {...handlers}
      className='flex flex-1 flex-col items-center pb-10 sm:pb-20'
    >
      <div id="item-image" className='relative h-[300px] w-[300px] rounded-xl border-2 border-fitzba-darkcoal-static border-opacity-20 sm:h-[500px] sm:w-[500px]'>
        <ImagePreOwned isUsed={isUsed} />
        <Zoom>
          <img
            src={image}
            alt={name}
            className='w-full h-[295px] sm:h-[495px] object-contain rounded-[10px]'
          />
        </Zoom>
      </div>
      <div className='mt-4 flex justify-center'>
        {images?.length > 1 &&
          images.map((_, index) => (
            <div
              key={index}
              className={`mx-2 h-4 w-4 rounded-full border-2  
              ${index === imageIndex ? 'bg-fitzba-bright-gold-static border-fitzba-bright-gold-static ' : ' bg-white border-black'}`}
            />
          ))}
      </div>
    </div>
  )
}