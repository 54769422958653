/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * StoreProfileCategories.js contains the StoreProfileCategory component, which is responsible for displaying and managing the categories of a store's profile.
 * The component uses the useDispatch and useSelector hooks from Redux for state management.
 * The setFilterCategory function is used to update the Redux store with the selected category.
 * The component renders a list of categories that can be expanded or collapsed.
 * Each category can be clicked to select it and update the Redux store.
 * The component also includes a button to clear the selected category.
 *
 * @author Yang Ming, James Nguyen
 * @version January 10th, 2024 
 */

import React from 'react'
// import { useSelector } from 'react-redux'
import { setFilterCategory } from '@reducers/contentReducer'
import { Modal } from 'antd'
/**  
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.dispatch - The Redux dispatch function.
 * @param {Function} props.setFilterCategoryProfile - The function to set the selected category.
 * @param {Object} props.itemsFilter - The current items filter.
 * @param {Array} props.categories - The list of categories.
 **/

export default function StoreProfileCategory({
  isOpen,
  setIsOpen,
  dispatch,
  onClickCategoryItem,
  itemsFilter,
  categories,
}) {

  if (!categories || categories.length === 0) return null

  function onClick() {
    setIsOpen(!isOpen)
  }

  // function onClickClear() {
  //   dispatch(setFilterCategory(null))
  // }

  categories.sort((a, b) => {
    if (a.label?.toLowerCase() < b.label?.toLowerCase()) {
      return -1
    }
    if (a.label?.toLowerCase() > b.label?.toLowerCase()) {
      return 1
    }
    return 0
  })

  return (
    <Modal
      title="Categories"
      open={isOpen}
      onCancel={onClick}
      maskClosable={true}
      destroyOnClose={true}
      footer={null}
      width={800}
    >
      <div className='max-h-[600px] show-scroll grid grid-cols-1 gap-x-[17px] gap-y-[19px] overflow-y-scroll rounded-b-xl px-[10px] pb-[20px] @sm:px-[20px]'>
        {categories.map((category, index) => (
          <div
            key={index}
            className={` flex h-[31px] w-full items-center truncate rounded-md cursor-pointer border ${itemsFilter.categories.find(id => id === category._id)
              ? 'border-[#E4B456]'
              : 'border-[#D9D9D9]'
              } px-2 text-[13px] hover:bg-[#D9D9D9]`}
            onClick={() => {
              onClickCategoryItem(category._id)
            }}
          >
            <span className='w-full truncate'>
              {category.label}
            </span>
          </div>
        ))}
      </div>
    </Modal>
  )
}
