import React from 'react'
import MobileTopFilterItem from './MobileTopFilterItem'
import { Drawer } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { conditionList } from '@data/filter.data'
import { useSelector, useDispatch } from 'react-redux'
import { setFilterCondition } from '@reducers/contentReducer'

export default function MobileTopFilterCondition() {
    const itemsFilter = useSelector(state => state.content.itemsFilter)
    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false)
    return (
        <>
            <MobileTopFilterItem
                selectedable={true}
                text='Condition'
                onClick={() => {
                    setOpen(true)
                }}
                isSelected={itemsFilter?.condition?.length > 0}
            />
            <Drawer
                title={null}
                closeIcon={''}
                // className='filter-mobile'
                className='filter-mobile-drawer'
                placement={'bottom'}
                onClose={() => setOpen(false)}
                open={open}
                key={'mobile-filter-price'}
                height={'600px'}
                styles={{
                    body: {
                        paddingTop: 0,
                        paddingBottom: 0
                    }
                }}
                extra={
                    <div className='flex justify-end'>
                        <CloseOutlined onClick={() => setOpen(false)} />
                    </div>
                }
                // extra={<NavZearchBar />}
                style={{
                    // height: '100vh',
                    textAlign: 'left',
                    overflowY: 'auto',
                    paddingBottom: 20,
                    // backgroundColor: '#202020',
                    borderRadius: '10px 10px 0 0',
                }}
                footer={<button className='w-full rounded-full bg-fitzba-bright-gold-static px-5 py-2 text-center text-white'
                    onClick={() => {
                        setOpen(false)
                    }}
                >
                    View results
                </button>}
            >
                <div className='flex flex-col gap-3 justify-between h-full'>
                    <div className='flex flex-col gap-3'>
                        <h3 className='text-lg text-center'>Condition</h3>
                        <div className='filter-item-list'>
                            {conditionList?.map((item, index) => (
                                <div className='filter-checkbox-item' key={index}>
                                    <label>
                                        <input
                                            value={item.value}
                                            type='checkbox'
                                            className='custom-checkbox'
                                            checked={itemsFilter?.condition?.includes(item.value)}
                                            onChange={e => {
                                                dispatch(
                                                    setFilterCondition({
                                                        name: item.value,
                                                        checked: e.target.checked,
                                                    }),
                                                )
                                            }}
                                        />
                                        <span className='truncate text-black'>{item.label}</span>
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div
                        className='text-center underline text-gray-400 cursor-pointer'
                        onClick={() => {

                        }}
                    >
                        Clear
                    </div>
                </div>
            </Drawer>
        </>
    )
}
