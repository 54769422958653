

import { Form, Input } from 'antd'
import React, {  useState } from 'react'
import { formatCurrency } from '@utils/Helper'

export default function ReservePopup({ item, form, userinfo, selectedVariant, handleSubmit, error, setError }) {
    const [total, setTotal] = useState(0)

    let price = selectedVariant?.price || item?.salePrice
    let quantity = selectedVariant?.quantity || item?.quantity

    const handleQuantityChange = async value => {
        let amount = Number(value.target.value || 0)
        if (amount <= 0) {
            setError('the amount cannot be less than 1')
            return
        }
        setError('')
        setTotal(0)
        if (amount > quantity) {
            setError('The available quantity is ' + quantity)
            return
        }
        // get total keep 2 decimal
        setTotal((item.salePrice * amount).toFixed(2))
    }

    let image = item?.image || '/item-default.png'
    if (item?.images && item?.images.length > 0) {
        image = item?.images[0]
        if (selectedVariant?.image_id >= 0 && selectedVariant?.image_id < item?.images.length) {
            image = item?.images[selectedVariant?.image_id]
        }
    }

    return (
        <Form
            form={form}
            className='flex w-full flex-col justify-center items-center text-[18px]'
            onFinish={handleSubmit}
        >
            <div className='w-full max-w-[450px] flex flex-col justify-center'>
                <div className='py-5'>
                    To reserve this item enter your name, email and quantity
                </div>
                <div className='flex h-fit w-full justify-center gap-2 text-[16px] mb-2'>
                    <div className='h-[100%] w-[100px] flex items-center justify-center flex-col'>
                        <img
                            alt={item?.name}
                            src={image}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                                borderRadius: '10px',
                            }}
                        />
                        <div className='col-span-1 text-right'>
                            {formatCurrency(price)}/ea
                        </div>
                    </div>
                    <div className='flex-auto flex flex-col gap-2 w-full'>
                        <div className='text-left'>{item?.name}</div>
                        {selectedVariant?.title && (
                            <span className='text-left text-[12px] text-[#999]'>Option: {selectedVariant?.title}</span>
                        )}
                        <div className='flex gap-2 items-center h-[100%]'>
                            <Form.Item
                                name={'amount'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Minimum is 1',
                                    },
                                    {
                                        pattern: /^[0-9]+$/, // Regular expression to match numeric values
                                        message: 'Numeric value only',
                                    },
                                ]}
                                initialValue={1}
                                onChange={handleQuantityChange}
                            // className='w-full'
                            >
                                <Input
                                    type='number'
                                    prefix='Qty: '
                                    className='rounded-full border-2 border-black w-[100px] hover:border-fitzba-bright-gold-static focus:outline-none focus:ring-3 focus:ring-fitzba-bright-gold-static'
                                    />
                            </Form.Item>
                            {total > 0 && (
                                <div className='h-[100%] text-sm text-red-500 pt-3'>$ {total}</div>
                            )}
                        </div>
                    </div>
                </div>
                {error && (
                    <div className='text-sm flex justify-center items-center text-red-500 pt-2 pb-2'>Error: {error}</div>
                )}
                <Form.Item
                    name={'username'}
                    rules={[
                        {
                            required: true,
                            message: 'Please enter your name',
                        },
                    ]}
                    className='w-full'

                >
                    <Input
                        placeholder='Name'
                        className='rounded-full border-2 border-black hover:border-fitzba-bright-gold-static focus:border-fitzba-bright-gold-static'
                    />
                </Form.Item>
                <Form.Item
                    name={'userEmail'}
                    rules={[
                        {
                            required: true,
                            message: 'Please enter your email address',
                        },
                        {
                            type: 'email',
                            message: 'Please enter a valid email address',
                        },
                    ]}
                    className='w-full'

                >
                    <Input
                        placeholder='Email'
                        className='rounded-full border-2 border-black hover:border-fitzba-bright-gold-static focus:border-fitzba-bright-gold-static'
                    />
                </Form.Item>
                <Form.Item
                    name={'content'}
                    className='w-full'

                >
                    <Input.TextArea
                        placeholder='Message (Optional)'
                        className='rounded-[15px] border-2 border-black hover:border-fitzba-bright-gold-static focus:border-fitzba-bright-gold-static'
                        rows={2}
                    />
                </Form.Item>
                <p className='text-base'>
                    We'll email you to confirm that your reserved item has been
                    put aside for you!
                </p>
                <Form.Item className='my-5 flex w-full justify-center'>
                    <button
                        type='submit'
                        className=' flex w-fit cursor-pointer justify-center rounded-full border-2 border-fitzba-bright-gold-static bg-fitzba-bright-gold-static px-10 py-[6px] text-white hover:bg-white hover:text-fitzba-bright-gold-static'
                    >
                        RESERVE NOW
                    </button>
                </Form.Item>
            </div>
        </Form>
    )
}