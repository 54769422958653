/** 
*  Copyright Fitzba Technologies Inc. 2023

*  PolicyPage is a functional React component that renders the privacy policy page.
* It uses the useSelector hook from Redux to access the contents from the Redux store
* and finds the privacy policy content from the contents array. The page is rendered
* using JSX and CSS classes for styling.
*
@author Yang Ming
@version June 07, 2023 
*/

import React from 'react'
import './Policy.css'
import { useDispatch } from 'react-redux'
import { setGotoElement } from '@reducers/contentReducer'
import { contentCode } from '@data/contentCode.data'
import PolicyContent from './components/PolicyContent'
import ContentDataHook from '@hooks/ContentData.hook'
import Loading from '@components/Loading'
/**
 * 
 * @returns Renders the privacy policy page
 */
export default function PolicyPage() {

  const dispatch = useDispatch()
  // Using the useSelector hook to access the contents from the Redux store
  // const contents = useSelector(state => state.content.contents)

  // Finding the privacy policy content from the contents array
  // const policy = contents.find(item => item.code === 'privacy')
  // const terms = contents.find(item => item.code === 'terms')
  const { isPending, error, data: policy } = ContentDataHook(contentCode.PRIVACY)
  const { data: terms } = ContentDataHook(contentCode.TERMS)

  if (isPending) return <Loading />

  // console.log('about', data)

  if (error) return 'Policy Page: An error has occurred: ' + error.message

  return (
    <>
      <div className='policy-wrapper'>
        <div className='policy-section'>
          <div className='policy-side'>
            <div className='policy-side-list'>
              <h3 onClick={() => {
                dispatch(setGotoElement('terms'))
              }}>Terms of Use</h3>
              <h3 onClick={() => {
                dispatch(setGotoElement('policy'))
              }}>Privacy Policy</h3>
            </div>
          </div>
          <div className='policy-content'>
            <PolicyContent content={terms} h3id='terms' />
            <PolicyContent content={policy} h3id='policy' />
          </div>
        </div>
      </div>
    </>
  )
}
