/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * LoginPage1 is a functional React component that renders the first page of the login process.
 * It includes a form for the user to enter their email, a Google Single Sign-On (SSO) component, and a reCAPTCHA for bot verification.
 * It also handles the form submission, checking if the user exists and navigating to the next page or account creation page accordingly.
 *
 * @author Yang Ming, James Nguyen
 * @version January 30th, 2024
 */

import React from 'react'
import { Form, Input, message } from 'antd'
import ReCAPTCHA from 'react-google-recaptcha'
import { VscAccount } from 'react-icons/vsc'
import { checkUser } from '@services/api.service'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setTempUserinfo } from '@reducers/tempReducer'
import GoogleAuthSSO from './GoogleAuthSSO'
import { Config } from '@Config'
// import AppleSignInButton from './AppleAuthSSO'
import { Link } from 'react-router-dom'
import { FaApple } from "react-icons/fa"
import SSOLogin from './SSOLogin'

export default function LoginPage1New({
    recaptchaCode,
    setRecaptchaCode,
    setLoginPage,
    tempUser,
}) {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const backToRoute = useSelector(state => state.temp.backToRoute) || '/'

    const recaptchaRef = React.useRef(null)

    /**
    * Handles the form submission.
    * Checks if the user exists and navigates to the next page or account creation page accordingly.
    *
    * @param {Object} values - The form values.
    */
    const handleChangePage = async (values) => {
        // if (!recaptchaCode) {
        //     message.error('Please verify that you are not a robot')
        //     return
        // }
        try {
            console.log('login', values)
            const userinfo = await checkUser({ ...values })
            console.log('login data:', userinfo)
            dispatch(setTempUserinfo({ username: values.username }))
            if (userinfo?.userExists) {
                dispatch(setTempUserinfo({ username: values.username, firstName: userinfo?.firstName }))
                // setIsGoogle(false)
                setLoginPage(2)
            } else {
                navigate('/registerv4')
            }
        } catch (error) {
            console.error('login failed', error)
        }
    }

    return (
        <div className='w-full flex flex-col items-center justify-center gap-[20px] text-black'>
            <div className='hidden smsize:block smsize:mb-10'>
                <img width={240} src="/fitzba-ICON-WORD-BLACK.png" alt="Fitzba Logo" />
            </div>
            <h3 className='text-[38px] smsize:text-[30px] font-medium'>Welcome</h3>
            <h4 className='text-[24px] font-light'>
                Lets' check if you have an <br />account with us!
            </h4>
            <Form
                name='fitzba_login'
                className='w-[340px] text-left'
                onFinish={handleChangePage}
            >
                <Form.Item
                    name='username'
                    initialValue={tempUser?.username}
                    rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid email',
                        },
                        {
                            required: true,
                            message: 'Please input your email',
                        },
                    ]}
                    hasFeedback
                >
                    <Input
                        size='large'
                        className='rounded-full border-black'
                        prefix={<VscAccount size={22} />}
                        placeholder='Email'
                        allowClear
                    />
                </Form.Item>
                {/* <GoogleAuthSSO /> */}
                {/* <GoogleAuthSSO />
                <AppleSignInButton /> */}

                {/* <ReCAPTCHA
                    sitekey={Config.gogoleRecaptchaSiteKey}
                    onChange={setRecaptchaCode}
                    style={{
                        marginBottom: '0px',
                    }}
                    ref={recaptchaRef}
                /> */}

                <Form.Item className='w-full text-left text-fitzba-bright-gold-static'>
                    <div className='flex flex-row gap-3'>
                        <button
                            size='large'
                            className='mt-2 px-5 flex w-full flex-1 cursor-pointer items-center justify-center rounded-full  border border-black bg-white text-base text-black hover:bg-slate-300'
                            onClick={() => {
                                navigate(backToRoute)
                            }}
                            type='button'
                        >
                            BACK
                        </button>
                        <button
                            type='submit'
                            size='large'
                            className='mt-2 flex w-full flex-[2] cursor-pointer justify-center rounded-full  border-2 border-fitzba-bright-gold-static bg-fitzba-bright-gold-static p-[6px] text-base text-white hover:bg-white hover:text-fitzba-bright-gold-static'
                        >
                            CONTINUE
                        </button>
                    </div>
                    <div className='mt-3 text-[13px] text-[#A6A6A6] font-light text-left flex flex-col'>
                        <p className=' whitespace-nowrap'>Are you a business owner interested in joining fitzba? {' '}
                        </p> <Link to='/retail' className='underline'>Click to learn more</Link>
                    </div>
                </Form.Item>
            </Form>
            <SSOLogin />
        </div>
    )
}
