import React from 'react'
// import { useSelector, useDispatch } from 'react-redux'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
// import { setFilterStoreOnlyOne } from '@reducers/contentReducer'
import LoadingSection from '@components/LoadingSection'
import { useQuery } from '@tanstack/react-query'
import OfferItem from './OfferItemExplore'
import { getPromotions } from '@services/api.business.service'
import { promotionRedeem } from '@services/api.user.service'
// import FavouriteButton from './FavoriteButton'
// import OfferListExplore from './OfferListExplore'
import NoFound from './NoFound'
import OfferDetail from './OfferDetail'
import { message } from 'antd'

export default function OfferList({
    isMobile
}) {
    // const userinfo = useSelector(state => state.user.userinfo)
    // const location = useSelector(state => state.user.location)
    const [isDetailOpen, setIsDetailOpen] = React.useState(false)
    const [currentOffer, setCurrentOffer] = React.useState(null)
    const storesListRef = React.useRef(null)

    const {
        isPending,
        // error,
        data,
    } = useQuery({
        queryKey: ['myPromotions'],
        queryFn: () => getPromotions(),
    })

    const onClickScrollBtn = (step) => {
        if (!storesListRef.current)
            return
        console.log('FavoriteStores storesListRef', storesListRef?.current?.scrollLeft)
        let scrollAmount = 0
        const slideTimer = setInterval(() => {
            storesListRef.current.scrollLeft += step
            scrollAmount += Math.abs(step)
            if (scrollAmount >= 1020) {
                clearInterval(slideTimer)
            }
        }, 50)
    }
    if (isPending)
        return <LoadingSection text='Loading offers' height='200px' />

    const promotions = data?.data || []
    console.log('FavoriteOffers', promotions)
    // if (promotions.length === 0)
    //     return null

    const onClickOffer = async (offer_id) => {
        try {
            const res = await promotionRedeem({
                promotion_id: offer_id
            })
            const offer = promotions.find(offer => offer._id === offer_id)
            setCurrentOffer({
                ...offer,
                userPromotion: res.data || null
            })
            setIsDetailOpen(true)
        } catch (error) {
            console.log('error', error)
            message.error('Failed: ' + error?.data?.message)
        }
    }

    return (
        <div id="favorite-offers" className='relative w-full bg-[#F4F4F4] rounded-[33px] p-10 text-left flex flex-col gap-5 smsize:p-5 smsize:pr-0'>
            <OfferDetail
                isOpen={isDetailOpen}
                setIsOpen={setIsDetailOpen}
                offer={currentOffer}
            />
            <div className='flex justify-between items-center'>
                <h4 className='uppercase text-[25px] font-normal'>
                    My offers
                </h4>
                {/* <FavouriteButton
                    text="Explore Offers"
                    onClick={() => setIsOpen(true)}
                /> */}
            </div>
            {promotions.length === 0 ?
                <NoFound text="No offers found" /> :
                <div className="w-full flex gap-5 scroll-wrap smsize:overflow-x-auto" ref={storesListRef}>
                    <span
                        className='scroll-left smsize:hidden'
                        style={{ top: '150px', left: '20px' }}
                        onClick={() => onClickScrollBtn(-50)}
                    >
                        <AiOutlineLeft />
                    </span>
                    <span
                        className='scroll-right smsize:hidden'
                        style={{ top: '150px', right: '20px' }}
                        onClick={() => onClickScrollBtn(50)}>
                        <AiOutlineRight />
                    </span>
                    {promotions.map((offer, index) =>
                        <OfferItem
                            key={index}
                            offer={offer}
                            onClick={onClickOffer} />
                    )}
                </div>
            }
        </div>
    )
}
