/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * This module exports a React component named 'ItemHeaderMobile'. This component displays the header section of an item's detail page on mobile devices.
 * It includes the item's last updated time, name, brand, store information, live inventory status, and average rating.
 * 
 * The component takes one prop: 'item'. 'item' is the item being displayed.
 * 
 * @author Yang Ming, James Nguyen
 * @version January 22nd, 2024
 */

import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import ItemRating from './ItemRating'
import ItemStoreNameBox from './ItemStoreNameBox'
import ItemBrand from './ItemBrand'
import { getItemUpdatedTime } from '@utils/Helper'

/**
 * A component that displays the header section of an item's detail page on mobile devices.
 * 
 * @param {Object} props - The props object.
 * @param {Object} props.item - The item being displayed.
 * @returns {JSX.Element} The 'ItemHeaderMobile' component.
 */
export default function ItemHeaderMobile({
  item,
}) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  return (
    <div className='text-left'>
      <div className='px-5'>
        <div className='text-[12px] text-[#ACACAC] font-light'>
          LAST UPDATED: {getItemUpdatedTime(item?.updatedAt)}
        </div>
        <h1 className='sm:leading-12 text-2xl leading-10 tracking-[1px] text-fitzba-black-static sm:text-3xl'>
          {item?.name}
        </h1>
        <ItemBrand
          brandTitle={item?.brand?.title}
          navigate={navigate}
          dispatch={dispatch}
        />
      </div>
      {/* store info */}
      <ItemStoreNameBox
        item={item}
        isMobile={true}
        navigate={navigate}
        dispatch={dispatch}
      />
      {item?.business?.isLiveInventory &&
        <div className='px-5 text-[13px] uppercase font-[400]'>Live Verified inventory</div>
      }
      {item?.review?.totalCount > 0 &&
        <ItemRating review={item.review} isMobile={true} />
      }
    </div>
  )
}
