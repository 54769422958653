export const deliveryMethod = [
    {
        label: 'Pickup',
        value: 'Pick-up',
        available: true,
    },
    {
        label: 'Reserve',
        value: 'Reserve',
        available: true,
    },
 {
        label: 'Local Delivery',
        value: 'Delivery',
        available: true,
    },
    {
        label: 'Shipping',
        value: 'Shipping',
        available: true,
    }

]

export const conditionList = [
    {
        label: 'New',
        value: 'New',
        available: true,
    },
    {
        label: 'Pre-owned',
        value: 'Used',
        available: true,
    }
]