/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * Loading Component, used for loading page.
 * 
 * @author Dean Yang
 * @version Jan 8, 2023
 */
import './Loading.css'
import LoadingSpin from './LoadingSpin'
/**
 * @returns A loading screen to promt the user to wait 
 *          while back ground processes is happening
 */
export default function Loading() {
  return (
    <div className="loading">
      <img src="/fitzba-ICON-WORD-BLACK.png" width={200} alt="loading" />
      <LoadingSpin text="Loading" />
    </div>
  )
}
