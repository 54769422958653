import React from 'react'
import { formatCurrency } from '@utils/Helper'

export default function CartReserveItemList({
    cartData,
}) {
    const items = cartData?.carts?.filter(item => item.isSelected)
    return (
        <div className='show-scroll max-h-[200px] overflow-y-scroll flex flex-col gap-3'>
            {items?.map((item, index) => {
                let variant = null
                if (item?.variant_id && item?.item?.variants?.length > 0) {
                    variant = item?.item?.variants?.find(v => v.variant_id === item?.variant_id)
                }
                let image = item?.item?.image || '/item-default.png'
                let price = variant ? variant?.price : item?.item?.salePrice
                if (item?.item?.images?.length > 0) {
                    image =
                        variant && variant?.image_id < item?.item?.images?.length
                            ? item?.item?.images[variant?.image_id]
                            : item?.item?.images[0]
                }
                if (!image && item?.item?.images?.length > 0) {
                    image = item?.item?.images[0]
                }

                return <div
                    key={index}
                    className='flex w-full grid-cols-4 justify-center gap-3 text-[16px]'
                >
                    <div className='rounded-lg border border-fitzba-darkcoal-static'>
                        <img
                            alt={item?.item?.name}
                            src={image}
                            style={{
                                width: '100%',
                                maxHeight: '100px',
                                objectFit: 'contain',
                                borderRadius: '10px',
                            }}
                        />
                    </div>
                    <div className='w-full flex h-full flex-col justify-between gap-1'>
                        <h3 className='text-left py-0 my-0'>{item?.item?.name}</h3>
                        {variant && <span className='text-[12px] text-[#666]'>Option: {variant?.title}</span>}
                        {/* <div className='flex max-w-[80px] items-center justify-center rounded-full border-2 border-black'>
                            Qty: {item?.quantity}
                        </div> */}
                        <div className='w-full flex justify-between items-center'>
                            <span>Qty: {item?.quantity}</span>
                            <span>{formatCurrency(price)}/ea</span>
                        </div>
                    </div>
                    {/* <div className='col-span-1 text-right font-[14px]'>
                        {formatCurrency(item?.item?.salePrice)}/ea
                    </div> */}
                </div>
            })}
        </div>
    )
}
