/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * storeReducers.js defines a Redux slice for temporary user data.
 * 
 * @author Yang Ming, James Nguyen
 * @version July 31st, 2023
 */

import { createSlice } from "@reduxjs/toolkit"

// combine two reducers of content
export const initialState = {
  storeinfo: null,
  nearStores: [],
  nearProducts: [],
  nearBrands: [],
}

const storeSlice = createSlice({
  name: "store",
  initialState: initialState,
  reducers: {
    setStoreInfo: (state, action) => {
      state.storeinfo = action.payload
    },
    setNearStores: (state, action) => {
      state.nearStores = action.payload
    },
    setNearProducts: (state, action) => {
      state.nearProducts = action.payload
    },
    setNearBrands: (state, action) => {
      state.nearBrands = action.payload
    }
  },
})

export const { setStoreInfo, setNearStores, setNearProducts,setNearBrands } = storeSlice.actions

export default storeSlice.reducer
