/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * RetailButtons is a functional React component that displays two buttons: "Book a call" and "Get more info".
 *
 * @author Yang Ming, James Nguyen, Joshua Soke
 * @version January 26th, 2024
 */

import React from 'react'
import { Link } from 'react-router-dom'

/**
 * Displays two buttons: "Book a call" and "Get more info".
 *
 * @param {Object} props - The properties passed to the component.
 * @param {function} props.setIsOpen - Function to set the state of the "Book a call" button's open status.
 * @param {string} [props.gap='60px'] - The gap between the buttons.
 * @param {boolean} props.isOpposite - Whether the order of the buttons should be reversed.
 * @param {function} props.setIsOpenForm - Function to set the state of the "Get more info" button's open status.
 * @param {string} [props.extraClass=''] - Extra CSS classes to add to the component.
 *
 * @returns {JSX.Element} The rendered component.
 */

export default function RetailButtons({
    setIsOpen,
    gap = '60px',
    isOpposite,
    setIsOpenForm,
    extraClass = '',
    isLeft = false
}) {
    const btns = [
        <button
            key={'book a call'}
            className='flex w-[267px] smsize:w-full cursor-pointer h-[43px] justify-center items-center rounded-full border  border-fitzba-bright-gold-static bg-fitzba-bright-gold-static text-[18px] text-base font-medium text-white hover:bg-white hover:text-fitzba-bright-gold-static uppercase'
            onClick={() => {
                setIsOpen(true)
                // setIsOpenForm(true)
            }}
        >
            Book a call
        </button>,
        <Link
            to={'/register-steps'}
            key={'sign up'}
            className='flex text-black w-[267px] smsize:w-full cursor-pointer h-[43px] justify-center items-center rounded-full border  border-black bg-white text-[18px] text-base font-medium hover:bg-white hover:border-fitzba-bright-gold-static hover:text-fitzba-bright-gold-static uppercase'
            // onClick={() => {
            //     setIsOpenForm(true)
            // }}
        >
            sign up
        </Link>
    ]
    if (isOpposite) {
        btns.reverse()
    }
    return (
        <div className={`w-full flex ${isLeft ? 'justify-start' : 'justify-center'} items-center smsize:flex-col smsize:gap-[20px] ${extraClass}`}
            style={{
                gap: gap
            }}
        >
            {btns}
        </div>
    )
}
