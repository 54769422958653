import React from 'react'
import { CiHeart } from 'react-icons/ci'

export default function StoreBox() {
    return (
        <div>
            <div className=' flex flex-col gap-2 border border-[#D9D9D9] rounded-lg p-4 smsize:w-[320px]'>
                <div className='w-full flex gap-2'>
                    <div><img className='w-[80px] h-[80px] smsize:w-[65px] smsize:h-[65px] rounded-lg border border-[#D9D9D9] bg-white' src='/images/Shopping Bags.png' alt='Shopping Bags' /></div>
                    <div><img className='w-[80px] h-[80px] smsize:w-[65px] smsize:h-[65px] rounded-lg border border-[#D9D9D9] bg-white' src='/images/Shopping Bags.png' alt='Shopping Bags' /></div>
                    <div><img className='w-[80px] h-[80px] smsize:w-[65px] smsize:h-[65px] rounded-lg border border-[#D9D9D9] bg-white' src='/images/Shopping Bags.png' alt='Shopping Bags' /></div>
                    <div><img className='w-[80px] h-[80px] smsize:w-[65px] smsize:h-[65px] rounded-lg border border-[#D9D9D9] bg-white' src='/images/Shopping Bags.png' alt='Shopping Bags' /></div>
                </div>
                <div className='w-full flex gap-3 items-center'>
                    <img className='w-[64px] h-[64px] rounded-full border-2 border-[#E4B456] bg-white' src='/images/Shopping Bags.png' alt='Shopping Bags' />
                    <div className='text-left'>
                        <h4 className='text-[16px]'>Real Canadian Superstore</h4>
                        <p className='text-[11px] font-light'>549 Riverfront Ave, Calgary, AB T2G 1K2</p>
                    </div>
                </div>
                <div className='w-full flex items-center justify-center'>
                    <button className='w-[109px] flex items-center text-[13px] font-light justify-center gap-1 border border-black rounded-full px-3 py-1 hover:bg-[#EFEFEF]'>
                        <CiHeart size={20} />Favourite
                    </button>
                </div>
            </div>
        </div>
    )
}
