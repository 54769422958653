import React, { useState } from 'react'
import PurchasesItemCard from './PurchasesItemCard'
import { formatCurrency } from '@utils/Helper'

export default function PurchasesCard({
    item
}) {
    const [open, setOpen] = useState(false)
    return (
        <div className='w-full'>
            <div className='flex items-center justify-between bg-[#F5F5F5] p-3'>
                <div className='flex flex-col text-left'>
                    <span className='text-[20px]'>{item?.updatedAt?.split('T')[0]}</span>
                    <span className='font-light text-[14px]'>{formatCurrency(item?.totalAmount)}</span>
                    <span className='font-light text-[14px]'>{item?.itemList?.length || 0} items</span>
                </div>
                <button onClick={() => setOpen(!open)} className='flex items-center justify-center rounded-full text-white bg-[#E4B456] px-10 py-1 text-[13px] uppercase font-light'>
                    {open ? 'Hide Details' : 'View Details'}
                </button>
            </div>
            {open && (
                <div className='border border-[#D9D9D9] border-t-0 p-3'>
                    <div className='flex text-left gap-5 font-light text-[14px]'>
                        <div className='flex-1'>
                            <h4 className='font-bold'>Order method</h4>
                            <span>Reserved</span>
                        </div>
                        <div className='flex-1'>
                            <h4 className='font-bold'>Payment method</h4>
                            <span>-</span>
                        </div>
                        <div className='flex-[2]'>
                            <h4 className='font-bold'>Order summary</h4>
                            <div className='w-[200px] flex justify-between'>
                                <h4>Subtotal</h4>
                                <span>{formatCurrency(item?.totalSubtotal)}</span>
                            </div>
                            {/* <div className='w-[200px] flex justify-between'>
                                <h4>Shipping</h4>
                                <span>$0.00</span>
                            </div> */}
                            <div className='w-[200px] flex justify-between'>
                                <h4>Taxes</h4>
                                <span>{formatCurrency(item?.totalTax)}</span>
                            </div>
                            <div className='w-[200px] flex justify-between'>
                                <h4 className='font-bold'>Total</h4>
                                <span>{formatCurrency(item?.totalAmount)}</span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {item?.itemList?.map((item, index) => (
                <PurchasesItemCard item={item} key={index} />
            ))}
        </div>
    )
}
