import React, { useEffect, useState } from 'react'
import {
  Form,
  Input,
  message,
  Select,
  Radio,
  // Checkbox,
  InputNumber,
} from 'antd'
import { useSelector } from 'react-redux'
import { canadaProvinces, usStates } from '@data/provinces.data'
import { isCreditCardValid, isExpiryDateValid, isCVCValid } from '@utils/Helper'
import { paymentCreditCard } from '@services/api.user.service'
import { MdCheckCircle } from 'react-icons/md'
import StatementModal from './components/StatementModal'
import './Payment.css'

const planList = [
  {
    type: 'monthly',
    price: 50,
    label: '$50/month',
  },
  {
    type: 'yearly',
    price: 500,
    label: '$500/year',
  },
]

export default function Payment() {
  const [formRef] = Form.useForm()
  const [provinceList, setProvinceList] = useState(
    canadaProvinces.map(item => ({ label: item.name, value: item.code })),
  )
  const [country, setCountry] = useState('CA')

  const isMobile = useSelector(state => state.content.isMobile)
  const [isOpen, setIsOpen] = useState(false)
  const [isAgree, setIsAgree] = useState(true)
  const [isSuccess, setIsSuccess] = useState(false)
  const [planType, setPlanType] = useState('monthly')
  const [isCustom, setIsCustom] = useState(false)

  // const plan = planList.find(item => item.type === planType)

  useEffect(() => {
    if (formRef) {
      setIsSuccess(false)
      formRef.setFieldsValue({
        country: 'CA',
        province: canadaProvinces[0].code,
        planType: 'monthly',
        planAmount: 50,
      })
    }
  }, [formRef])

  const onChangeCountry = value => {
    setCountry(value)
    const dataList = value === 'CA' ? canadaProvinces : usStates
    setProvinceList(
      dataList.map(item => ({ label: item.name, value: item.code })),
    )
    formRef?.setFieldsValue({ province: dataList[0].code })
  }

  const checkCardNumber = async (_, cardNumber) => {
    if (!cardNumber) {
      return Promise.resolve()
    }
    // return true
    if (!isCreditCardValid(cardNumber)) {
      throw new Error('Card number is invalid')
    }
  }

  const checkExpireDate = async (_, expireDate) => {
    if (!expireDate) {
      return Promise.resolve()
    }
    if (expireDate.length < 4 || expireDate.length > 5) {
      throw new Error('Expire date is invalid')
    }
    if (!isExpiryDateValid(expireDate)) {
      throw new Error('Expire date is invalid')
    }
  }

  const checkCvv = async (_, cvv) => {
    if (!cvv) {
      return Promise.resolve()
    }
    if (!isCVCValid(cvv)) {
      throw new Error('CVV code is invalid')
    }
  }

  const onFinish = async values => {
    if (!isAgree) {
      return message.error('Please agree the statement of Fitzba payment.')
    }
    try {
      // TODO: send the payment details to the server
      if (isCustom) {
        if (!values.planAmount)
          return message.error('Please input custom price')
        if (values.planAmount < 1) {
          return message.error('The custom price should be greater than $1.')
        }
      }
      console.log('values', values)
      await paymentCreditCard(values)
      message.success('Submitted successfully.')
      setIsSuccess(true)
    } catch (err) {
      console.error('onFinish', err)
      message.error('Error: ' + err?.data?.message)
    }
  }

  if (isSuccess) {
    return (
      <div className='flex w-full flex-col items-center justify-center gap-[10px] py-[100px] px-[10px]'>
        <h3 className='text-[35px]'>SUBMITTED SUCCESSFULLY</h3>
        <MdCheckCircle size={90} color='#E4B465' />
      </div>
    )
  }

  return (
    <div className='m-auto w-[960px] p-0 smsize:w-full smsize:p-3'>
      {isOpen && (
        <StatementModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          isMobile={isMobile}
        />
      )}
      <div className='pb-[100px] text-left'>
        <h2 className='py-[80px] text-[40px] smsize:py-[20px] smsize:text-[25px]'>
          PAYMENT DETAILS
        </h2>
        <Form
          form={formRef}
          className='flex w-full flex-wrap items-start justify-center gap-[100px] smsize:gap-4'
          onFinish={onFinish}
          style={{
            textAlign: 'left',
            width: '100%',
          }}
        >
          <div className='flex-1'>
            <h3 className='mb-2 text-[22px] font-[300]'>Store Information</h3>
            <Form.Item
              name='email'
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid email',
                },
                {
                  required: true,
                  message: 'Please input your email',
                },
              ]}
              hasFeedback
            >
              <Input
                size='large'
                className='rounded-full border-black'
                placeholder='Email'
                allowClear
              />
            </Form.Item>
            <Form.Item
              name='storeName'
              rules={[
                {
                  required: true,
                  message: 'Please input store name',
                },
              ]}
              hasFeedback
            >
              <Input
                size='large'
                className='rounded-full border-black'
                placeholder='Store Name'
                allowClear
              />
            </Form.Item>
            <Form.Item
              name='firstName'
              rules={[
                {
                  required: true,
                  message: 'Please input your first name',
                },
              ]}
              hasFeedback
            >
              <Input
                size='large'
                className='rounded-full border-black'
                placeholder='First Name'
                allowClear
              />
            </Form.Item>
            <Form.Item
              name='lastName'
              rules={[
                {
                  required: true,
                  message: 'Please input last name',
                },
              ]}
              hasFeedback
            >
              <Input
                size='large'
                className='rounded-full border-black'
                placeholder='Last Name'
                allowClear
              />
            </Form.Item>
            <div className='flex items-center gap-2'>
              <Form.Item name='country' initialValue={'CA'} className='flex-1'>
                <Select
                  className='rounded-full border border-black py-[18px]'
                  placeholder='Country'
                  // bordered={false}
                  variant="borderless"
                  dropdownStyle={{
                    fontSize: '20px',
                  }}
                  onChange={onChangeCountry}
                  options={[
                    {
                      label: 'Canada',
                      value: 'CA',
                    },
                    {
                      label: 'United States',
                      value: 'US',
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item
                className='flex-1'
                name='postalCode'
                rules={[
                  {
                    required: true,
                    message: 'Please input postal code',
                  },
                ]}
                hasFeedback
              >
                <Input
                  size='large'
                  className='rounded-full border-black'
                  placeholder={`${country === 'CA' ? 'Postal' : 'Zip'} Code`}
                  allowClear
                />
              </Form.Item>
            </div>
            <div className='flex items-center gap-2'>
              <Form.Item name='province' className='flex-1'>
                <Select
                  size='large'
                  // bordered={false}
                  variant="borderless"
                  className='rounded-full border border-black py-[18px]'
                  placeholder={`${
                    country === 'CA' ? 'Select a Province' : 'Select a State'
                  }`}
                  style={{ width: '100%' }}
                  options={provinceList}
                />
              </Form.Item>
              <Form.Item
                className='flex-1'
                name='city'
                rules={[
                  {
                    required: true,
                    message: 'Please input city',
                  },
                ]}
                hasFeedback
              >
                <Input
                  size='large'
                  className='rounded-full border-black'
                  placeholder='City'
                  allowClear
                />
              </Form.Item>
            </div>
            <Form.Item
              name='address'
              rules={[
                {
                  required: true,
                  message: 'Please input address',
                },
              ]}
              hasFeedback
            >
              <Input
                size='large'
                className='rounded-full border-black'
                placeholder='Address'
                allowClear
              />
            </Form.Item>
            <Form.Item name='apt' hasFeedback>
              <Input
                size='large'
                className='rounded-full border-black'
                placeholder='Apt, suite, etc. (optional)'
                allowClear
              />
            </Form.Item>
          </div>
          <div className='flex-1'>
            <h3 className='mb-2 text-[22px] font-[300]'>Plan Information</h3>
            <Form.Item
              name='planType'
              onChange={e => {
                console.log(e?.target?.value)
                let plan = planList.find(item => item.type === e?.target?.value)
                setPlanType(e?.target?.value)
                console.log('plan', plan)
                formRef.setFieldsValue({ planAmount: plan?.price })
              }}
            >
              <Radio.Group size='large'>
                <Radio.Button value='monthly'>Monthly</Radio.Button>
                <Radio.Button value='yearly'>Yearly</Radio.Button>
              </Radio.Group>
            </Form.Item>
            {/* <div className='flex justify-between'>
              <h4 className='mb-4 text-[18px]'>{plan?.label}</h4>
              <Form.Item name='isCustom' valuePropName='checked'>
                <Checkbox onChange={e => setIsCustom(e.target.checked)}>
                  I'd like to set a custom price
                </Checkbox>
              </Form.Item>
            </div> */}
            {/* {isCustom && ( */}

            <Form.Item
              name='planAmount'
              // extra='Starting from $10'
              rules={[
                {
                  type: 'number',
                  message: 'The input is not valid number',
                },
                // at least $10
                {
                  validator: (_, value) => {
                    if (value < 1) {
                      return Promise.reject(
                        'The custom price should be greater than $1.',
                      )
                    }
                    return Promise.resolve()
                  },
                },
              ]}
            >
              <InputNumber
                addonBefore='$'
                size='large'
                className='rounded-full border-black'
                placeholder='Custom Price'
                style={{
                  width: '200px',
                  marginRight: '20px',
                }}
              />
            </Form.Item>

            {/* )} */}
            <h3 className='mb-2 text-[22px] font-[300]'>Card Information</h3>
            <Form.Item
              name='cardName'
              rules={[
                {
                  required: true,
                  message: 'Please input card name',
                },
              ]}
              hasFeedback
            >
              <Input
                size='large'
                className='rounded-full border-black'
                placeholder='Name on card'
                allowClear
              />
            </Form.Item>
            <Form.Item
              name='cardNumber'
              rules={[
                {
                  validator: checkCardNumber,
                },
                {
                  required: true,
                  message: 'Please input card number',
                },
              ]}
              hasFeedback
            >
              <Input
                size='large'
                className='rounded-full border-black'
                placeholder='Card number'
                suffix={<img src='/images/credit-icon.png' alt='' />}
                allowClear
              />
            </Form.Item>
            <div className='flex items-center justify-center gap-5'>
              <Form.Item
                name='cardExpireDate'
                rules={[
                  {
                    validator: checkExpireDate,
                  },
                  {
                    required: true,
                    message: 'Please input expiry date',
                  },
                ]}
                hasFeedback
              >
                <Input
                  size='large'
                  className='rounded-full border-black'
                  placeholder='MM/YY'
                  allowClear
                />
              </Form.Item>
              <Form.Item
                name='cardCvv'
                rules={[
                  {
                    validator: checkCvv,
                  },
                  {
                    required: true,
                    message: 'Please input cvv',
                  },
                ]}
                hasFeedback
              >
                <Input
                  size='large'
                  className='rounded-full border-black'
                  placeholder='CVV'
                  allowClear
                />
              </Form.Item>
            </div>
            {/* <label className='mb-3 flex cursor-pointer items-center gap-2'>
              <input
                type='checkbox'
                className='custom-checkbox'
                value={isAgree}
                onChange={e => {
                  setIsAgree(e.target.checked)
                }}
              />
              <span className='text-[16px] smsize:text-[12px]'>
                I consent I agree{' '}
                <Link onClick={() => setIsOpen(true)} className=' underline'>
                  the statement of Fitzba payment.
                </Link>
              </span>
            </label> */}
            {/* <p className='text-[12px] text-[#ff0000]'>
              Note: Charges begin after 1 Month Free.
            </p> */}
            <button
              type='submit'
              disabled={!isAgree}
              className='mt-3 flex w-full cursor-pointer justify-center rounded-full border-2 border-fitzba-bright-gold-static bg-fitzba-bright-gold-static px-10 py-[6px] text-[16px] text-white hover:bg-white hover:text-fitzba-bright-gold-static disabled:border-none disabled:bg-[#ccc] disabled:hover:text-white'
            >
              FINISH
            </button>
          </div>
        </Form>
      </div>
    </div>
  )
}
