/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * The About component renders the About page of the application.
 * 
 * It fetches the About page content using the ContentDataHook and displays it.
 * 
 * @author Yang Ming
 * @version June 8th, 2023
 */
import React from 'react'
import './About.css'
import Loading from '@components/Loading'
import { contentCode } from '@data/contentCode.data'
import ContentDataHook from '@hooks/ContentData.hook'

export default function About() {
  const { isPending, error, data: about } = ContentDataHook(contentCode.ABOUT)
  if (isPending) return <Loading />
  if (error) return 'About Page: An error has occurred: ' + error.message

  return (
    <>
      <div className='relative min-h-[80vh]'>
        <div className='about-wrapper pb-20'>
          <div className='about-section'>
            <div className='about-content'>
              <h1>{about?.title}</h1>
              <p dangerouslySetInnerHTML={{ __html: about?.content }}></p>
            </div>
            <div className='about-logo'>
              <img width={300} src='/logo512.png' alt='fitzba logo' />
            </div>
          </div>
        </div>
        <div className='absolute bottom-0 h-[65px]  w-full bg-fitzba-bright-gold-static' />
      </div>
    </>
  )
}
