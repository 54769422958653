import React, { useState, useEffect } from 'react'
// import { BsChevronRight } from 'react-icons/bs'
import { MdArrowForwardIos } from "react-icons/md"
import FilterSideSectionHeader from './FilterSideSectionHeader'
import { useSelector } from 'react-redux'
import { setFilterState } from '@reducers/userReducer'

/**
 * A component for filtering by location in a sidebar.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.dispatch - The Redux dispatch function.
 * @param {Function} props.setFilterDistance - A function to set the distance filter.
 * @param {Object} props.itemsFilter - The current filter settings.
 * @param {boolean} props.isMobile - A flag indicating whether the device is mobile.
 */
export default function FilterSideLocation({
    dispatch,
    setFilterDistance,
    itemsFilter,
    isMobile
}) {
    const isOpen = useSelector(state => state.user.filterState.location)
    const setIsOpen = (value) => {
        dispatch(setFilterState({ type: 'location', value }))
    }
    // const [isOpen, setIsOpen] = useState(!isMobile ? (itemsFilter?.distance > 0) : !isMobile)
    const [input, setInput] = useState(itemsFilter?.distance)

    useEffect(() => {
        setInput(itemsFilter?.distance)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsFilter?.distance])

    return (
        <div className='filter-item mt-2' style={{
            overflow: 'hidden',
            // marginBottom: '100px'
        }}>
            {/* <div className='filter-item-header'>
                <h4 onClick={() => setIsOpen(!isOpen)} className='cursor-pointer'>Location</h4>
                {isOpen
                    ? <AiOutlineUp size={16} onClick={() => setIsOpen(!isOpen)} className='cursor-pointer' />
                    : <AiOutlineDown size={16} onClick={() => setIsOpen(!isOpen)} className='cursor-pointer' />}
            </div> */}
            <FilterSideSectionHeader
                title='Distance'
                isOpen={isOpen}
                onClickTitle={() => setIsOpen(!isOpen)}
                isHighlight={itemsFilter?.distance > 0}
            />
            <div className='flex flex-row items-center gap-1 font-light text-[16px]' style={{ display: isOpen ? 'flex' : 'none' }}>
                Up to{' '}
                <input
                    className='ml-1 h-7 w-[3.75rem] rounded-md bg-white px-[5px] py-[10px] text-base text-black'
                    type='text'
                    name='distance'
                    placeholder='km'
                    value={input === -1 ? '' : input}
                    onChange={(e) => {
                        // let distance = Number(e.target.value.replace(/[^0-9]/g, ''))
                        // dispatch(setFilterDistance(distance))
                        setInput(Number(e.target?.value.replace(/[^0-9]/g, '') || -1))
                    }}
                    onKeyDown={k => {
                        if (k.key === 'Enter') {
                            dispatch(setFilterDistance(input))
                        }
                    }}
                />
                <button
                    className='flex h-7 w-7 items-center justify-center rounded-md bg-white hover:bg-[#D9D9D9] text-base text-black'
                    onClick={() => {
                        dispatch(setFilterDistance(input))
                    }}
                >
                    <MdArrowForwardIos />
                </button>
            </div>
        </div>
    )
}
