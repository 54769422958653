import React from 'react'
import MobileTopFilterItem from './MobileTopFilterItem'
import { useSelector, useDispatch } from 'react-redux'
import { setFilterDeliveryTypes } from '@reducers/contentReducer'

export default function MobileTopFilterReserve() {
    const dispatch = useDispatch()
    const itemsFilter = useSelector(state => state.content.itemsFilter)

    return (
        <>
            <MobileTopFilterItem
                selectedable={false}
                text='Reserve'
                onClick={() => {
                    dispatch(setFilterDeliveryTypes({
                        name: 'Reserve',
                        checked: !itemsFilter.deliveryTypes.includes('Reserve'),
                    }))
                }}
                isSelected={itemsFilter.deliveryTypes.includes('Reserve')}
            />
        </>
    )
}
