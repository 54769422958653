/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * Results Header.js displays the total count of search results found on the search results page. 
 * and provides options to sort the results. Additionally this component allows users to switch between grid and list views on mobile.
 * 
 * @author Yang Ming, James Nguyen
 * @version February 9th, 2023 
 */

import React from 'react'
import SortBy from './SortBy'
// import { useDispatch } from 'react-redux'
// import { setFilterSortBy } from '@reducers/contentReducer'

/**
 * Header of the results list.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.itemsList - The list of items.
 * @param {Object} props.itemsFilter - The current state of the items filter.
 * @param {Object} props.SORTBY_CODE - The codes for the different sort options.
 * @param {Function} props.onClickSortBy - The function to call when a sort option is selected.
 * @param {Object} props.userinfo - The user's information.
 * @param {boolean} props.isGrid - Whether the current view is grid.
 * @param {Function} props.setIsGrid - The function to set the view type.
 * @param {boolean} props.isMobile - Whether the current view is mobile.
 */

export default function ResultHeader({
  itemsList,
  itemsFilter,
  SORTBY_CODE,
  onClickSortBy,
  userinfo,
  isGrid,
  setIsGrid,
  isMobile,
}) {

  // const dispatch = useDispatch()

  return (
    <div className='flex flex-col items-start justify-between p-5 pb-0 gap-5 md:flex-row md:items-center'>
      <div className='text-[18px] flex justify-center items-center gap-10'>
        <h3 className='text-[12px] font-normal'>
          FOUND {itemsList?.total} LOCAL RESULTS{' '}
          {itemsFilter?.title && (
            <>
              FOR{' '}
              <span
                className='font-light'
                style={{
                  transform: 'uppercase',
                }}
              >
                "{itemsFilter?.title?.toUpperCase()}"
              </span>
            </>
          )}
        </h3>
      </div>
      <SortBy
        itemsFilter={itemsFilter}
        SORTBY_CODE={SORTBY_CODE}
        onClickSortBy={onClickSortBy}
        isMobile={isMobile}
        isGrid={isGrid}
        setIsGrid={setIsGrid}
      />
      {/* <div className='w-full flex items-center justify-between'>
        <SortBy
          itemsFilter={itemsFilter}
          SORTBY_CODE={SORTBY_CODE}
          onClickSortBy={onClickSortBy}
        />
        {isMobile && (
          <span onClick={() => setIsGrid(!isGrid)}>{isGrid ? <IoGridOutline /> : <FaListUl />} </span>
        )}
      </div> */}
    </div>
  )
}
