import React from 'react'

export default function OfferItemExplore({
    offer,
    onClick
}) {

    let valueText = null
    switch (offer.type) {
        case 'Discount':
            valueText = `${offer.value}% off`
            break
        case 'Cash':
            valueText = `$${offer.value} off`
            break
        default:
            break
    }
    let endedAt = null
    if (offer.endedAt) {
        const date = new Date(offer.endedAt)
        endedAt = date.toISOString().split('T')[0]
    }
    return (
        <div className='bg-white p-5 w-[350px] h-[210px] flex flex-col justify-between border rounded-lg'>
            <div style={{
                color: offer.color
            }}>
                <h4 className={`text-[20px] font-bold uppercase leading-6`}>{offer.title}</h4>
                {valueText &&
                    <p className='text-[12px]'>{valueText}</p>
                }
                {offer.minAmount &&
                    <p className='text-[12px]'>On purchases of ${offer.minAmount} or more</p>
                }
                {/* {endedAt &&
                    <p className='text-[12px]'>Expires {endedAt}</p>
                } */}
            </div>
            <div className='flex justify-between items-end'>
                <img src={offer?.business?.logo} className='border-4 border-[#eee] rounded-full w-[90px] h-[90px]' alt='offer' />
                <button
                    onClick={() => onClick(offer?._id)}
                    style={{
                        backgroundColor: offer.color
                    }}
                    className={`rounded-full py-1 px-6 text-white flex items-center justify-center`}>
                    redeem
                </button>
            </div>
        </div>
    )
}
