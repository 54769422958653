/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * Registerv2 is a functional React component that renders a multi-step registration
 * form to collect user and business information for account creation.
 *
 *  *
 * @author Yang Ming
 * @version May 09, 2023
 */

import React, { useState } from 'react'
import './Registerv2.css'
import { Link, useNavigate } from 'react-router-dom'
import { registerConsumer, registerBusiness } from '@services/api.service'
import { message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import SSORegisterStep1 from './components/ssoExtra/SSORegisterStep1'
import RegisterStep3 from './components/RegisterStep3'
// import RegisterBusinessStep2 from './components/RegisterBusinessStep2'
import RetailRegisterStep3 from './components/retail/RetailRegisterStep3'
import RetailRegisterStep4 from './components/retail/RetailRegisterStep4'
import RetailRegisterStep5 from './components/retail/RetailRegisterStep5'
import { login, ssoLogin } from '../../services/api.service'
import { setBusiness, setUserinfo } from '../../reducers/userReducer'
// import { useJsApiLoader } from '@react-google-maps/api'
// import { Config } from '../../Config'
import RetailRegisterStep1 from './components/retail/RetailRegisterStep1'
import DemoFinal from './components/retail/DemoFinal'
const enumSteps = {
  CHOOSE_PLAN: 1,
  TELL_US_ABOUT_YOUR_STORE: 2,
  TELL_US_ABOUT_YOURSELF: 0,
  LETS_CONNECT_YOUR_INVENTORY: 3,
  BOOK_A_DEMO: 4,
  SUBSCRIPTION: 5,
  DEMO_FINAL_PAGE: 6,
  FINAL_PAGE: 7,
}
export default function SSOAdditionalInfo() {
  const isBusiness = useSelector(state => state.user.isBusiness)
  const tempUser = useSelector(state => state.temp.userinfo)
  // console.log(tempUser)
  const [bookDemo, setBookDemo] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // const [libraries] = useState(['places'])

  // Initialize registration steps

  const [current, setCurrent] = useState(0)
  const [isUS, setIsUS] = React.useState(false)

  const [formData, setFormData] = useState({})
  // Proceed to the next step
  const next = () => {
    setCurrent(current + 1)
    if (current === 3) {
      if (formData.type === 'startz') {
        setCurrent(4)
      }
    }
  }
  // Go back to the previous step
  const prev = () => {
    setCurrent(current - 1)
  }

  // const businessTypeList = useSelector(state => state.content.businessTypeList)
  // const [openBusinessSearch, setOpenBusinessSearch] = React.useState(true)

  // Handle form submission
  async function handleSubmit(values) {
    try {
      if (bookDemo) {
        return
      }
      message.loading('Registration in progress...')
      const res = isBusiness
        ? await registerBusiness({
          username: tempUser.username,
          type: formData.type || values.type,
          role: formData.role,
          firstName: tempUser.firstName,
          isLocked: false,
          lastName: tempUser.lastName,
          postalCode: formData.postalCode || 'T4C 1A1',
          phone: formData.phone,
          password: formData.password,
          businessType: formData.businessType,
          business: {
            website: values.website || '',
            pos: values.pos || '',
            businessType: formData.businessType,
            registeredBusiness: formData.business?.businessId || null,
            name: formData.business.businessName || null,
            businessName: formData.business.businessName || null,
            address: formData.business.businessAddress || null,
            businessPhone: formData.business.businessPhone || null,
            city: formData.business?.city,
            province: formData.business?.province,
            postalCode: formData.business?.businessPostalCode,
            country: formData.business?.country,
            lat: formData.business?.lat || 51.1784,
            lng: formData.business?.lng || -115.5708,
          },
        })
        : await registerConsumer({
          username: tempUser?.username,
          isLocked: false,
          firstName: tempUser?.firstName,
          lastName: tempUser?.lastName,
          gender: values?.gender,
          phone: values?.phone,
          password: values?.password,
          lat: values?.lat || 51.1784,
          lng: values?.lng || -115.5708,
        })
      console.log('res', res)
      // message.success(res.message)
      message.success('Register succsessfully')
      if (isBusiness) {
        const tmp_id = localStorage.getItem('tmp_id')
        const res2 = await login({
          username: tempUser.username,
          password: formData.password,
          tmp_id,
        })

        dispatch(setUserinfo(res2.data))
        dispatch(setBusiness(res2.data.isBusiness))

        if (tmp_id) {
          localStorage.removeItem('tmp_id')
        }
        if (values.type === 'startz') {
          setCurrent(4)
        } else {
          navigate('/subscription?plan=' + formData.type)
        }
      } else {
        const res2 = await ssoLogin({ username: tempUser.username })
        dispatch(setUserinfo(res2.data))
        dispatch(setBusiness(res2.data.isBusiness))
        next()
      }
      // timeout function maybe needed?
      // setTimeout(() => {
      //     navigate('/login')
      // }, 1500)
    } catch (err) {
      console.log('err', err)
      message.error(err.data?.message || 'Register failed, please try again')
    }
  }

  // const { isLoaded } = useJsApiLoader({
  //   id: 'google-map-script',
  //   googleMapsApiKey: Config.googleMapsApiKey,
  //   libraries
  // })
  // // console.log(isBusiness)
  // // console.log(userinfo)

  // if (!isLoaded) {
  //   return null
  // }


  return (
    // <div className=' flex  h-max tall:h-[100vh]'>
    //   <div className='hidden flex-1 flex-col items-center justify-center bg-fitzba-off-black-static xl:flex'>
    <div className='flex '>
      {current !== enumSteps.SUBSCRIPTION && (<div className='hidden flex-1 flex-col items-center justify-center bg-fitzba-off-black-static xl:flex'>
        <div className='fixed flex h-[100vh] w-1/2 items-center justify-center bg-black'>
          <img
            src='/fitzba-ICON-WORD-WHITE.png'
            onClick={() => { navigate('/') }}
            alt='Fitzba Logo'
            className='md:w-[200px] lg:w-[400px]'
          />
        </div>
        <div className='mt-[122px] w-[276px] text-center text-[20px] font-light text-white'>
          Have questions?{' '}
          <Link
            to='/contact'
            className=' text-fitzba-bright-gold-static underline'
          >
            Contact us
          </Link>{' '}
          and we will get back to you with answers
        </div>
      </div>)}
      <div className='register-wrapper flex-1'>
        <div className='flex min-h-[100vh] w-full flex-col items-center justify-center p-2 xl:flex-row'>
          <div className='block xl:hidden'>
            <img src='/FITBIZ-WORD-ICON.png' width={400} alt='' />
          </div>
          <div className='register-main-form'>
            {!isBusiness && (
              <>
                <h3 className='text-[38px] font-medium'>REGISTER</h3>
                {current !== 1 && (
                  <h4 className='text-[30px] font-light'>
                    Tell us about yourself
                  </h4>
                )}
                {current === 0 && (
                  <SSORegisterStep1
                    formData={formData}
                    setFormData={setFormData}
                    handleSubmit={handleSubmit}
                    next={next}
                  />
                )}
                {current === 1 && <RegisterStep3 user={formData} />}
              </>
            )}

            {isBusiness && (
              <>
                {current < enumSteps.DEMO_FINAL_PAGE && current !== enumSteps.SUBSCRIPTION && (<h3 className='text-[38px] font-medium'>GET STARTED</h3>)}
                {(current === 0 || current === 1) && (
                  <h4 className='text-[30px] font-light'>
                    Tell us about yourself
                  </h4>
                )}
                {current === enumSteps.TELL_US_ABOUT_YOURSELF && (
                  <SSORegisterStep1
                    next={next}
                    formData={formData}
                    setFormData={setFormData}
                    handleSubmit={handleSubmit}
                  />
                )}


                {current === enumSteps.CHOOSE_PLAN && (
                  <>
                    <h4 className='mb-10 text-[30px] font-light'>Choose your plan</h4>
                    <RetailRegisterStep3
                      formData={formData}
                      setFormData={setFormData}
                      handleSubmit={handleSubmit}
                      next={next}
                      prev={prev}
                      setBookDemo={setBookDemo}
                      setCurrent={setCurrent}
                    />
                  </>
                )}

                <div className={`w-full ${current === enumSteps.SUBSCRIPTION ? 'px-[200px]' : 'px-[20px]'}`}>
                  {current === enumSteps.TELL_US_ABOUT_YOUR_STORE && (
                    <>
                      <h4 className='mb-10 text-[30px] font-light'>
                        Tell us about your retail store
                      </h4>
                      <RetailRegisterStep1
                        formData={formData}
                        setFormData={setFormData}
                        handleSubmit={handleSubmit}
                        isUS={isUS}
                        setIsUS={setIsUS}
                        next={next}
                        prev={prev}
                      />
                    </>
                  )}
                  {current === enumSteps.LETS_CONNECT_YOUR_INVENTORY && (
                    <>
                      <h4 className='mb-10 text-[30px] font-light'>
                        Let's connect your inventory
                      </h4>
                      <RetailRegisterStep4
                        formData={formData}
                        setFormData={setFormData}
                        handleSubmit={handleSubmit}
                        prev={prev}

                      />
                    </>
                  )}
                  {current === enumSteps.SUBSCRIPTION && (
                    <div className='w-full'>
                      {/* <SubscriptionNew
                        plan={formData.type}
                        setCurrent={setCurrent}
                        enumSteps={enumSteps}
                      /> */}
                    </div>
                  )}
                  {current === enumSteps.BOOK_A_DEMO && (
                    <>
                      <h4 className='mb-10 text-[30px] font-light'>Book a demo</h4>
                      <RetailRegisterStep5
                        formData={formData}
                        setFormData={setFormData}
                        setBookDemo={setBookDemo}
                        handleSubmit={handleSubmit}
                        setCurrent={setCurrent}
                        prev={prev}
                        next={next}
                      />
                    </>
                  )}
                  {current === enumSteps.DEMO_FINAL_PAGE && (
                    <>
                      {/* <h4 className='mb-10 text-[30px] font-light'>Book a demo</h4> */}
                      <DemoFinal />
                    </>
                  )}
                </div>
                {current === enumSteps.FINAL_PAGE && <RegisterStep3 user={formData} />}
              </>
            )}

            {((!isBusiness && current !== 2) ||
              (isBusiness && current !== 4)) && (
                <div className='register-form-footer font-light'>
                  <h4>Already have an account?</h4>
                  <Link to='/login' className='text-[#0B6186] underline'>
                    Login
                  </Link>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  )
}
