/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * Item is a functional React component that renders a single item
 * in the list view, with an image, title, rating, price, and other details.
 *
 * @author Yang Ming
 * @version June 07, 2023
 */
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { formatCurrency2 } from '@utils/Helper'
import MyImage from '@components/MyImage'
import { useDispatch, useSelector } from 'react-redux'
import { setFilterBrand } from '@reducers/contentReducer'

export default function ItemCard({
  item,
  handleAddToCart,
  keywordsList,
}) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  return (
    <div className='w-full flex flex-row gap-4'>
      <div className='h-[130px] w-[130px] overflow-hidden rounded-lg border-2 border-black border-opacity-10 xs:h-[150px] xs:w-[150px] xl:h-[180px] xl:w-[180px] '>
        <MyImage
          src={
            item?.images && item.images.length > 0
              ? item.images[0]
              : '/item-default.png'
          }
          alt={item?.name}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            mixBlendMode: 'multiply',
          }}
        />
      </div>
      <div className='w-[130px] py-2 xs:w-[150px] xl:w-[180px] text-left'>
        <h4 className=' relative mb-[3px] cursor-pointer text-[14px] font-lexend'>
          {item?.name}
        </h4>
        {item?.brand && (
          <p
            className='mt-4 flex w-full cursor-pointer justify-between truncate text-[11px] text-[#727272]'
            onClick={() => {
              dispatch(
                setFilterBrand({
                  id: item?.brand?._id,
                  checked: true,
                }),
              )
              navigate('/search')
            }}
          >
            <span className=' uppercase'>{item?.brand?.title}</span>
          </p>
        )}
        <div className='uppercase mb-1 max-w-full cursor-pointer truncate text-[10px] text-[#727272]'>
          {item?.business?.name}
        </div>
        <div className='product-item-info-price gap-1'>
          <div className='flex'>
            <div className='text-[15px]'>$</div>
            <div>{formatCurrency2(item?.salePrice)}</div>
            {item?.originalPrice > item?.salePrice && item?.originalPrice !== item?.salePrice &&
              <div className='pl-[5px] text-[18px] text-[#8f8f8f] line-through'>
                {formatCurrency2(item?.originalPrice)}
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
