/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * ItemRating.js is a functional component that renders the rating of an item.  It takes in props from its parent, which are: review, isMobile, and isShowCount.  The review prop is an object that contains the averageRating and totalCount of the item.  The isMobile prop is a boolean that determines if the user is on a mobile device.  The isShowCount prop is a boolean that determines if the count of the rating should be displayed.  The component uses the BsStarFill, BsStar, and BsStarHalf components from the react-icons/bs library to render the rating.  The component returns a div that contains the rating and count of the item.
 * 
 * @author Yang Ming, James Nguyen
 * @version January 4th, 2024
 */

import React from 'react'
import { BsStarFill, BsStar, BsStarHalf } from "react-icons/bs"

/**
 * A component for displaying the rating of an item.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.review - The review data for the item, including average rating and total count.
 * @param {boolean} props.isMobile - Whether the current view is mobile.
 * @param {boolean} props.isShowCount - Whether the count of the rating should be displayed.
 */
export default function ItemRating({
    review = {
        averageRating: 0,
        totalCount: 0
    },
    isMobile,
    isShowCount = true
}) {
    // if (!review?.totalCount)
    //     return null
    // console.log('review', review)
    let isHafStar = review.totalCount === 0
    return (
        <div className={`flex gap-1`}>
            <div className='flex flex-row items-center gap-[2px] text-[12px] text-[#000]'>
                {[...new Array(5)].map((_, index) => (
                    review?.averageRating >= index + 1 ?
                        <BsStarFill key={index} /> :
                        Math.abs(review?.averageRating - index) >= 0.5 && !isHafStar ?
                            isHafStar = true && <BsStarHalf key={index} /> : <BsStar key={index} />
                ))}
            </div>
            {isShowCount && <span className='text-[12px] text-[#0B6186]'>{review?.averageRating}</span>}
        </div>
    )
}
