import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'
import allReducers from './reducers'
import { Config } from './Config'
import { ConfigProvider } from 'antd'
import enUS from 'antd/locale/en_US'
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { datadogRum } from '@datadog/browser-rum'

// import { LoadScript } from '@react-google-maps/api'
// import { GoogleOAuthProvider } from '@react-oauth/google'

const queryClient = new QueryClient()

// define the store of redux
const store = configureStore({
  reducer: allReducers,
  devTools: Config.debug
})

// Initialize Datadog RUM
// if (!Config.debug && process.env.REACT_APP_APP_HOST === 'https://www.fitzba.com/') {
console.log('Initialize Datadog RUM')
datadogRum.init({
  applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  site: 'us3.datadoghq.com',
  env: process.env.REACT_APP_DATADOG_ENV,
  service: 'fitzba',
  version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'allow',
})
datadogRum.startSessionReplayRecording()
// }

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ConfigProvider locale={enUS}>
          {/* <GoogleOAuthProvider clientId={Config.googleSSOClientId}>
            <LoadScript googleMapsApiKey={Config.googleMapsApiKey} libraries={["places"]}> */}
          <App />
          {/* </LoadScript>
          </GoogleOAuthProvider> */}
        </ConfigProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </Provider>
)