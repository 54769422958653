/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * LoginLeft is a functional React component that renders the left side of the login page.
 * It displays the company logo and a link to the contact page for users who have questions.
 * This component is hidden on smaller screens and only visible on extra large (xl) screens.
 *
 * @author Yang Ming, James Nguyen
 * @version January 4th, 2024
 */

import React from 'react'
import { Link } from 'react-router-dom'


export default function LoginLeft() {
    return (
        <div className='flex h-[100vh] flex-[1] flex-col py-16 items-center justify-between bg-fitzba-off-black-static smsize:hidden'>
            <div className='flex-auto flex justify-center items-center'>
                <Link to='/'>
                    <img
                        src='/fitzba-ICON-WORD-WHITE.png'
                        alt='Fitzba Logo'
                        className='md:w-[200px] lg:w-[400px]'
                    />
                </Link>
            </div>
            <div className='  w-[276px] text-center text-[15px] font-light text-white'>
                Have questions?{' '}
                <Link
                    to='/contact'
                    className=' text-fitzba-bright-gold-static underline'
                >
                    Contact us
                </Link>{' '}
                and we will get back to you with answers
            </div>
        </div>
    )
}
