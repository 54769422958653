import React from 'react'
// import { useNavigate } from 'react-router-dom'
import { getBrandList } from '@services/api.service'
import { useQuery } from '@tanstack/react-query'
import Loading from '@components/Loading'
import BrandListCard from './components/BrandListCard'
import {
    setBrandFilter,
    setBrandList,
    setSearchFilter
} from '@reducers/contentReducer'
import ShowMore from './components/ShowMore'
import { useDispatch, useSelector } from 'react-redux'
import StoreListFilterSelected from './StoreListFilterSelected'

export default function BrandList({
    location,
}) {
    // const navigate = useNavigate()
    const dispatch = useDispatch()
    const brandList = useSelector(state => state.content.brandList)
    const brandFilter = useSelector(state => state.content.brandFilter)

    const {
        isPending,
        // error,
        // data,
    } = useQuery({
        queryKey: ['getBrandList', location?.lat, location?.lng, brandFilter],
        queryFn: async () => {
            const res = await getBrandList({
                lat: location?.lat,
                lng: location?.lng,
                ...brandFilter
            })
            dispatch(setBrandList(res))
            return res
        },
    })

    if (isPending)
        return <Loading />

    const onClickBrand = (id) => {
        dispatch(setSearchFilter({
            'type': 'brands',
            'value': [id]
        }))
        // navigate('/search')
    }

    const onClickSeemore = () => {
        dispatch(setBrandFilter({
            ...brandFilter,
            currentPage: brandFilter.currentPage + 1
        }))
    }

    return (
        <div className='w-full flex flex-col'>
            <StoreListFilterSelected />
            <div className='flex px-10 py-8 gap-7 flex-col smsize:px-5 smsize:py-5'>
                <h1 className='text-[18px] font-normal uppercase'>Brands</h1>
                <div className='flex gap-7 flex-wrap px-5 smsize:px-0 smsize:justify-center smsize:items-center smsize:gap-10'>
                    {brandList?.data?.map((item, index) => {
                        return <div key={index} className='pb-7'>
                            <BrandListCard
                                key={index}
                                item={item}
                                onClickBrand={onClickBrand}
                            />
                        </div>
                    })}
                </div>
                {brandList?.data?.length > 0 && brandList.currentPage < brandList.totalPage &&
                    <div className='text-center'>
                        <ShowMore onClick={onClickSeemore} />
                    </div>
                }
            </div>
        </div>
    )
}
