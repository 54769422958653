import React, { useEffect, useState } from 'react'
import MobileTopFilterItem from './MobileTopFilterItem'
import { Drawer } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { useSelector, useDispatch } from 'react-redux'
import { setFilterBrand } from '@reducers/contentReducer'
import { debounce } from 'lodash'
import FilterSideSearchBar from './FilterSideSearchBar'
import { CiShoppingTag } from "react-icons/ci";
const DEFAULT_SHOW_NUMBER = 10

export default function MobileTopFilterBrand() {
    const itemsFilter = useSelector(state => state.content.itemsFilter)
    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false)
    const filterList = useSelector(state => state.content.filterList)
    const [input, setInput] = useState('')
    const [showNumber, setShowNumber] = useState(filterList?.brands.length)
    const [brands, setBrands] = useState(filterList?.brands)
    const [allCheckbox, setAllCheckbox] = useState(false)

    useEffect(() => {
        setBrands(filterList?.brands)
    }, [filterList?.brands])

    function defaultSetting() {
        setInput('')
        setShowNumber(filterList?.brands.length)
        setBrands(filterList?.brands)
    }

    const handleChange = debounce((value) => {
        // console.log('onSearch', value)
        //search function
        const findList = filterList?.brands.filter(item => {
            // startswith or each words startswith if the name is contain splace
            if (!item.name)
                return false
            const words = item.name.indexOf(' ') !== -1 ? item.name.toLowerCase().split(' ') : [item.name.toLowerCase()]
            const inputLower = value.toLowerCase()
            const result = words.find(item => item.startsWith(inputLower))
            // console.log('result', result)
            return result
        })
        setShowNumber(filterList?.brands?.length)
        setBrands(findList)
    }, 600)

    const onSearch = (e) => {
        const value = e.target.value
        setInput(value)
        if (!value) {
            defaultSetting()
            return
        }
        handleChange(value)
    }

    function onClickAllCheckbox(checked) {
        console.log('onClickAllCheckbox', checked)
        // const checked = e.target.checked
        if (checked) {
            // select all
            const ids = filterList?.brands.map(item => item.id)
            dispatch(setFilterBrand({ id: ids, checked: true }))
        } else {
            // deselect all
            // const ids = filterList?.brands.map(item => item.id)
            dispatch(setFilterBrand(null))
        }
        setAllCheckbox(checked)
    }

    return (
        <>
            <MobileTopFilterItem
                selectedable={true}
                firstIcon={<CiShoppingTag size={16} />}
                text={itemsFilter?.brands?.length > 0 ? `Brand (${itemsFilter?.brands?.length})` : 'Brand'}
                onClick={() => {
                    setOpen(true)
                }}
                isSelected={itemsFilter?.brands?.length > 0}
            />
            <Drawer
                title={''}
                closeIcon={''}
                className='filter-mobile-drawer'
                placement={'bottom'}
                onClose={() => setOpen(false)}
                open={open}
                key={'mobile-filter-price'}
                height={'80%'}
                styles={{
                    body: {
                        paddingTop: 0,
                        paddingBottom: 0
                    }
                }}
                extra={
                    <div className='flex justify-end'>
                        <CloseOutlined onClick={() => setOpen(false)} />
                    </div>
                }
                // extra={<NavZearchBar />}
                style={{
                    // height: '100vh',
                    textAlign: 'left',
                    overflowY: 'auto',
                    paddingBottom: 20,
                    // backgroundColor: '#202020',
                    borderRadius: '10px 10px 0 0',
                }}
                footer={<button className='w-full rounded-full bg-fitzba-bright-gold-static px-5 py-2 text-center text-white'
                    onClick={() => {
                        setOpen(false)
                    }}
                >
                    View results
                </button>}
            >
                <div className='flex flex-col gap-3 justify-between h-full'>
                    <div className='flex flex-col gap-3'>
                        <h3 className='text-lg text-center'>What brands are you interested in?</h3>
                        <FilterSideSearchBar
                            input={input}
                            isOpen={true}
                            onSearch={onSearch}
                            onClear={defaultSetting}
                            allCheckbox={allCheckbox}
                            onSelectAll={() => onClickAllCheckbox(!allCheckbox)}
                            onClearAll={() => onClickAllCheckbox(false)}
                        />
                        <div className={`filter-item-list smsize:gap-2 smsize:text-[16px]`} style={{ display: 'flex' }}>
                            {brands.slice(0, showNumber).map((brand) => (
                                <div key={brand.id} className='filter-checkbox-item'>
                                    <label>
                                        <input
                                            value={brand.id}
                                            type='checkbox'
                                            className={`custom-checkbox`}
                                            checked={itemsFilter?.brands.includes(brand.id)}
                                            onChange={e => {
                                                dispatch(
                                                    setFilterBrand({
                                                        id: brand.id,
                                                        checked: e.target.checked,
                                                    }),
                                                )
                                            }}
                                        />
                                        <span className={`truncate text-[16px] text-black font-light`}>{brand.name}</span>
                                    </label>
                                </div>
                            ))}
                        </div>
                        {/* {filterList?.brands.length > DEFAULT_SHOW_NUMBER && */}
                        {((input !== '' && brands.length > DEFAULT_SHOW_NUMBER) || (input === '' && filterList?.brands.length > DEFAULT_SHOW_NUMBER)) &&
                            <div className='filter-checkbox-item underline' style={{ display:'flex' }}>
                                <span onClick={() => setShowNumber(showNumber === DEFAULT_SHOW_NUMBER ? brands.length : DEFAULT_SHOW_NUMBER)}>
                                    Show {showNumber === DEFAULT_SHOW_NUMBER ? 'More' : 'Less'} Brands ({brands.length})
                                </span>
                            </div>
                        }
                    </div>
                    {/* <div
                        className='text-center underline cursor-pointer'
                        onClick={() => {

                        }}
                    >
                        Clear
                    </div> */}
                </div>
            </Drawer>
        </>
    )
}
