/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * StoreInfoMobile is a functional React component that displays information about a store on mobile devices.
 * The information includes the store's logo, name, description, phone number, address, and opening hours.
 * The store's logo and name are clickable and will navigate to a search page with items from only this store.
 * The description can be expanded or collapsed.
 * The phone number is clickable and will open the phone's dialer with the number filled in.
 * The address is clickable and will open the store's location on Google Maps.
 *
 * @author Yang Ming, James Nguyen
 * @version January 22nd, 2024
 */

import React, { useState } from 'react'
import '../ItemDetail.css'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import {
  initItemsFilter,
  setFilterStoreOnlyOne,
} from '@reducers/contentReducer'
import { getAddress, getStoreLink } from '@utils/Helper'
import { FiPhoneCall } from "react-icons/fi"
import { GoHome } from "react-icons/go"
import { IoTimeOutline } from "react-icons/io5"
import StoreOpenHours from './StoreOpenHours'

export default function StoreInfoMobile({ info }) {
  // console.log('StoreInfo', info)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [descExpanded, setDescExpanded] = useState(false)

  const link = getStoreLink(info?.shortLink, info?.city, info?.province)

  return (
    <div className='relative min-h-[100px] w-full bg-[#373737] py-8 px-5 text-left text-fitzba-white-static lg:px-20'>
      <div className='absolute -top-16 right-1/4 translate-x-1/2 transform rounded-full border-8 border-[#373737] lg:right-14 lg:translate-x-0'>
        <div
          className='border-1 h-32 w-32 cursor-pointer rounded-full border-black bg-white'
          onClick={() => {
            dispatch(initItemsFilter())
            dispatch(setFilterStoreOnlyOne(info?._id))
            navigate(link)
          }}
        >
          {info?.logo ? (
            <img
              src={info?.logo}
              className='aspect-[4/3] rounded-full object-contain'
              style={{ height: '100%', width: '100%' }}
              alt={info?.name}
            />
          ) : (
            <div
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '1rem',
                color: 'black',
                textAlign: 'center',
              }}
            >
              {info?.name}
            </div>
          )}
        </div>
      </div>
      <div className='flex w-full flex-col lg:block lg:pt-1 text-left'>
        <h4 className='text-[16px]'>ABOUT THE SHOP</h4>
        <h3
          className='cursor-pointer py-5 text-left text-[25px] font-bold leading-[25px] tracking-[1px] md:text-left'
          onClick={() => {
            dispatch(initItemsFilter())
            dispatch(setFilterStoreOnlyOne(info?._id))
            navigate(link)
          }}
        >
          {info?.name}
        </h3>
        <div className='flex w-full flex-col-reverse items-center lg:flex-row lg:items-start lg:gap-5'>
          <div className='flex-[3]'>
            <p className={`break-words text-left text-[13px] font-medium text-white lg:text-left overflow-y-hidden ${!descExpanded && 'h-[220px]'}`}
              dangerouslySetInnerHTML={{ __html: info.description || 'No Description yet' }}>
            </p>
            {info.description?.length > 550 && (
              <div className='text-right underline text-[13px] cursor-pointer text-[#E4B456]'
                onClick={() => setDescExpanded(!descExpanded)}>
                {descExpanded ? 'See Less' : 'See More'}
              </div>
            )}
          </div>
          <div className='my-5 h-[2px] w-full bg-white lg:hidden' />
          <div className='flex-[1.5] break-words text-center leading-8 lg:text-left'>
            <div className='w-fit flex flex-col whitespace-nowrap gap-x-3' style={{ gridTemplateColumns: 'min-content auto' }}>
              <div className='text-[14px] w-fit flex items-center gap-2'>
                <FiPhoneCall size={18} color='#E4B456' />
                <h4>Phone:</h4>
                <a href={`tel:${info?.businessPhone}`}>{info?.businessPhone}</a>
              </div>
              <div className='text-[14px] w-fit flex items-center gap-2'>
                <IoTimeOutline size={24} color='#E4B456' />
                <h4>Address:</h4>
                <a
                  href={
                    info?.mapUrl
                      ? info.mapUrl
                      : `https://maps.google.com/?q=${info?.lat},${info?.lng}`
                  }
                  className='w-full text-[14px] font-light leading-10 break-all'
                  target='_blank'
                  rel='noreferrer'
                >
                  {getAddress(info)}
                </a>
              </div>
              {info.openHours?.length > 0 && (
                <div className='text-[14px] w-fit flex items-start gap-2'>
                  <h4 className='flex items-center gap-2'><GoHome size={18} color='#E4B456' /> Hours:</h4>
                  <StoreOpenHours openHours={info.openHours} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
