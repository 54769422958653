/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * This module exports a React component named 'Similars'. This component fetches and displays similar items 
 * based on a given item id. It uses the 'useQuery' hook from 'react-query' for data fetching and state management.
 * 
 * The component takes three props: 'id', 'userinfo', and 'addToCart'. 'id' is used to fetch similar items, 
 * 'userinfo' is passed to the 'Item' component, and 'addToCart' is a function that is called when an item is added to the cart.
 * 
 * While the data is being fetched, a 'LoadingSection' component is displayed. If there's an error during the fetch, 
 * it returns a string displaying the error message. Once the data is successfully fetched, it maps over the 'similars' 
 * data and renders an 'Item' component for each similar item.
 * 
 * @author Yang Ming, James Nguyen
 * @version November 15th, 2023
 */

import React from 'react'
import ItemCard from '../../zearch/components/ItemCard'
import { useQuery } from '@tanstack/react-query'
import { getSimilars } from '@services/api.service'
import LoadingSection from '@components/LoadingSection'

export default function Similars({
    id,
    userinfo,
    addToCart,
}) {

    const {
        isPending,
        error,
        data: similars,
    } = useQuery({
        queryKey: ['similars', id],
        queryFn: () => getSimilars(id),
    })

    if (isPending) return <LoadingSection text='Loading Similars' />

    // console.log('about', data)

    if (error) return 'Similars: An error has occurred: ' + error.message

    return (
        <div className='flex flex-1 flex-col gap-10 text-left'>
            <h3 className='text-xl tracking-[1px] text-fitzba-black-static'>
                Similar Items
            </h3>
            <div className='flex flex-row sm:flex-col'>
                {similars?.data?.map(item => (
                    <ItemCard
                        key={item?._id}
                        item={item}
                        userinfo={userinfo}
                        handleAddToCart={addToCart}
                    />
                ))}
            </div>
        </div>
    )
}
