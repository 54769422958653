/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * The ItemEmpty component renders an empty state message when the list of 
 * items is empty.
 * 
 * @author Yang Ming
 * @version May 09, 2023
 */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setSearchInput, setItemsFilter, initItemsFilter } from '@reducers/contentReducer'

/**
 * 
 * @returns An Empty component from antd
 */
export default function ItemEmpty({
    keywords
}) {
    const preItemsFilter = useSelector(state => state.content.preItemsFilter)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    function onClick() {
        // dispatch(setSearchInput(''))
        dispatch(initItemsFilter(null))
        // back to the previous page
        // dispatch(setItemsFilter(preItemsFilter))
        // navigate(-1)
        navigate('/search')
    }
    return (
        <div className='flex justify-center items-center py-16 smsize:py-20 smsize:px-10'>
            <div className='flex items-center justify-center gap-6 smsize:flex-col'>
                <img src="/images/itemEmpty.png" alt="no result" />
                <div className='flex flex-col gap-2'>
                    <h3 className='text-[28px]'>SORRY!</h3>
                    <h4 className='text-[16px] uppercase'>No Results Found</h4>
                    {/* <p className='text-[16px]'>Please go back to the previous page or clear all filters</p> */}
                    <div className='flex items-center justify-between gap-5'>
                        {/* <button className='flex w-[200px] h-fit justify-center whitespace-nowrap rounded-full border border-black px-[16px] py-[7px] text-[16px] hover:bg-[#D9D9D9]'>GO BACK</button> */}
                        <button onClick={onClick} className='flex w-[200px] h-fit justify-center whitespace-nowrap rounded-full border border-black px-[16px] py-[7px] text-[16px] hover:bg-[#D9D9D9]'>
                            Clear all filters
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
