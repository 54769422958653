import React from 'react'
import { MdCheckCircle } from 'react-icons/md'

export default function RegisterStep5({
    isMobile
}) {

    return (
        <div className='w-full flex flex-col items-center justify-center gap-[20px] h-[400px] smsize:w-full'>
            <h3 className='text-[35px] leading-7 uppercase smsize:text-[25px]'>registration success</h3>
            <p className='text-[20px] font-light leading-5 smsize:text-[15px]'>Thank you for your joining us! <br />Be sure to check your email. We will be in touch!</p>
            <MdCheckCircle size={90} color='#E4B465' />
        </div>
    )
}
