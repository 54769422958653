/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * SortBy.js defines a component for sorting items. It provides options to sort by relevance, 
 * location, and price. It also allows switching between grid and list views on mobile.
 * 
 * @author Yang Ming, James Nguyen
 * @version February 9th, 2024 
 */

import React from 'react'
import { IoGridOutline } from "react-icons/io5"
import { FaListUl } from "react-icons/fa"

/**
 * A component for sorting items.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.itemsFilter - The current state of the items filter.
 * @param {Function} props.onClickSortBy - The function to call when a sort option is selected.
 * @param {Object} props.SORTBY_CODE - The codes for the different sort options.
 * @param {boolean} props.isMobile - Whether the current view is mobile.
 * @param {boolean} props.isGrid - Whether the current view is grid.
 * @param {Function} props.setIsGrid - The function to set the view type.
 */

export default function SortBy({ itemsFilter, onClickSortBy, SORTBY_CODE, isMobile, isGrid, setIsGrid }) {
  return (
    <div className={`${isMobile && 'w-full'} flex items-center justify-between`}>
      <div className='relative rounded-full border border-black bg-white px-2 py-2 text-xs flex flex-row items-center leading-tight text-black '>
        <span className='whitespace-nowrap'>Sort by:</span>
        <select
          onChange={onClickSortBy}
          className='outline-none w-full bg-white text-center'
          value={itemsFilter.sortBy}
          name='sortBy'
        >
          <option value={SORTBY_CODE.RELEVANCE}>
            Relevance
          </option>
          <option value={SORTBY_CODE.NEAREST_TO_FARTHEST}>
            Closest Location
          </option>
          <option value={SORTBY_CODE.LOW_TO_HIGH}>
            Low Price
          </option>
          <option value={SORTBY_CODE.HIGH_TO_LOW}>
            High Price
          </option>
        </select>
        <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'></div>
      </div>
      {isMobile && (
        <span onClick={() => setIsGrid(!isGrid)}>{isGrid ? <IoGridOutline /> : <FaListUl />} </span>
      )}
    </div>
  )
}
