
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { setSearchTitle } from 'reducers/contentReducer'


export default function CollectionCard({
  item,
  dispatch,
  setCurrentCollection
}) {

  const navigate = useNavigate()


  if (!item) return null
  
  const onClickRecommended = () => {
    setCurrentCollection(item)
    console.log('onClickRecommended', )
    if (item.type === 'keyword') {
      dispatch(setSearchTitle(item.target))
      navigate(`/search?keyword=${item.target}`)
      return
    }
    // navigate(`/collections?id=${item._id}`)
  }

  return (
    <div onClick={onClickRecommended} className=' cursor-pointer'>
      <div style={{
        backgroundImage: `url(${item.image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }} className={`w-[300px] h-[300px] rounded-[20px] border-[3px] border-[#fff] smsize:w-[250px] smsize:h-[250px]`}>
        <div className='bg-black/40 text-white w-full h-[100px] px-6 py-5 text-left font-semibold text-[24px] rounded-t-[20px] uppercase leading-7'>
          <h3>{item?.title}</h3>
        </div>
      </div>
    </div>
  )
}
