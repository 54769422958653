/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * SSORegisterStep1 is a functional React component that handles the first step in the SSO registration process.
 * It renders a form for users to enter their phone number, postal code, password, and either their role (if they are a business user) or their gender.
 * It also handles form submission, updating the form data with the entered values and proceeding to the next step.
 * If the user is not a business user, it also retrieves the latitude and longitude from the entered postal code and includes them in the form data.
 * The form also provides a back button to return to the login page.
 *
 * @author James Nguyen, Yang Ming
 * @version September 13th, 2023
 */

import { Form, Input, Select, Tooltip } from 'antd'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import InputSuffixTip from '../InputSuffixTip'
import { BiLockAlt, BiPhoneCall } from 'react-icons/bi'
import { getLatLngAndAddressFromPostalCode } from '@services/google.map.service'
import { BsGenderAmbiguous, BsMailbox } from 'react-icons/bs'
import { VscEye } from 'react-icons/vsc'
import { RxEyeClosed } from 'react-icons/rx'

/**
 * Handles the first step in the SSO registration process.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.formData - The data from the form.
 * @param {Function} props.setFormData - Function to update the form data.
 * @param {Function} props.next - Function to go to the next step.
 * @param {Function} props.handleSubmit - Function to handle form submission.
 *
 * @returns {JSX.Element} The rendered component.
 */

export default function SSORegisterStep1({
  formData,
  setFormData,
  next,
  handleSubmit,
}) {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const isBusiness = useSelector(state => state.user.isBusiness)
  // Defining the onFinish function to handle form submission

  const onFinish = isBusiness
    ? values => {
        // console.log('Received values of form: ', values)

        // Updating the form data with the submitted values
        setFormData({
          ...formData,
          ...values,
        })

        // Proceeding to the next step of the registration process
        next()
      }
    : async values => {
        // get lat lng from postal code
        let latlng = {
          lat: 0,
          lng: 0,
        }
        console.log(values)
        if (values.postalCode) {
          const res = await getLatLngAndAddressFromPostalCode(values.postalCode)
          if (res) {
            latlng = {
              lat: res?.lat,
              lng: res?.lng,
            }
          }
        }
        setFormData({
          ...formData,
          ...values,
          ...latlng,
        })
        handleSubmit({
          ...values,
          ...latlng,
        })
      }
  // Using the useEffect hook to set the initial form values when the form instance is available
  useEffect(() => {
    if (form !== null) {
      form.setFieldsValue(formData)
    }
  }, [form, formData])
  const onPrev = () => {
    navigate('/login')
  }

  return (
    <div>
      <Form
        forceRender
        layout='vertical'
        onFinish={onFinish}
        scrollToFirstError
        form={form}
        style={{
          textAlign: 'left',
        }}
        className='register-form-content'
      >
        <div className='flex flex-col'>
          <Form.Item
            name='phone'
            rules={[
              {
                required: true,
                message: 'Please input your phone',
                whitespace: true,
              },
              {
                max: 12,
                min: 12,
                message: 'The format is ###-###-####',
                regex: /^\d{3}-\d{3}-\d{4}$/,
              },
            ]}
            className='register-form-item'
          >
            <Input
              className='rounded-full border-2 border-black text-base'
              size='large'
              suffix={<InputSuffixTip text='The format is ###-###-####' />}
              prefix={<BiPhoneCall size={22} />}
              placeholder='Phone Number'
              onChange={e => {
                const value = e.target.value
                if (value.length === 10 && /^\d+$/.test(value)) {
                  const text =
                    value.slice(0, 3) +
                    '-' +
                    value.slice(3, 6) +
                    '-' +
                    value.slice(6)
                  form.setFieldsValue({
                    phone: text,
                  })
                }
              }}
            />
          </Form.Item>
          <Form.Item
            name='postalCode'
            rules={[
              {
                required: true,
                message: 'Please input your postal code',
              },
              { min: 5, max: 7 },
            ]}
            style={{
              color: '#fff',
            }}
            className='register-form-item flex-[4]'
          >
            <Input
              className='rounded-full border-2 border-black text-base'
              prefix={<BsMailbox size={22} />}
              size='large'
              suffix={
                <InputSuffixTip text='The format is 11111 for US or A1A 1A1 for Canada' />
              }
              placeholder='Postal/Zip Code'
            />
          </Form.Item>
          <Tooltip
            placement='right'
            title='If you happen to lose your SSO info, you can use this password to log back in'
          >
            <Form.Item
              name='password'
              rules={[
                {
                  required: true,
                  message: 'Please input your password',
                },
                {
                  max: 20,
                  min: 6,
                  message: 'Password: 6-20 chars.',
                },
              ]}
              className='register-form-item'
            >
              <Input.Password
                className='rounded-full border-2 border-black text-base'
                size='large'
                prefix={<BiLockAlt size={22} />}
                iconRender={visible => (visible ? <VscEye /> : <RxEyeClosed />)}
                placeholder='Input your password'
              />
            </Form.Item>
          </Tooltip>
          {isBusiness ? (
            <Form.Item
              name='role'
              initialValue={formData.role}
              rules={[
                {
                  required: true,
                  message: 'Role is required',
                  whitespace: true,
                },
              ]}
            >
              <div className='rounded-full border-2 border-black'>
                <Select
                  // bordered={false}
                  variant="borderless"
                  onChange={value => {
                    form.setFieldValue('role', value)
                  }}
                  value={formData.role}
                  size='large'
                  placeholder='Role'
                  options={[
                    { value: 'Owner', label: 'Owner' },
                    { value: 'Manager', label: 'Manager' },
                    { value: 'Staff', label: 'Staff' },
                  ]}
                />
              </div>
            </Form.Item>
          ) : (
            <Form.Item
              name='gender'
              initialValue={formData.gender}
              prefix={<BsGenderAmbiguous size={22} />}
              rules={[
                {
                  required: true,
                  message: 'Gender is required',
                  whitespace: true,
                },
              ]}
            >
              <div className='rounded-full border-2 border-black'>
                <Select
                  // bordered={false}
                  variant="borderless"
                  onChange={value => {
                    form.setFieldValue('gender', value)
                  }}
                  size='large'
                  placeholder='Gender'
                  options={[
                    { value: 'Male', label: 'Male' },
                    { value: 'Female', label: 'Female' },
                    { value: 'Other', label: 'Other' },
              { label: 'Prefer not to disclose', value: 'N/A' },

                  ]}
                />
              </div>
            </Form.Item>
          )}
        </div>
        <div
          style={{
            marginTop: 24,
          }}
          className='mx-auto flex w-[300px] items-center justify-center gap-2'
        >
          <div
            className='flex w-full max-w-[100px] cursor-pointer justify-center rounded-full border-2 border-black bg-white p-[6px] text-black hover:bg-slate-200'
            onClick={onPrev}
          >
            BACK
          </div>
          <button
            type='submit'
            className=' flex w-full cursor-pointer justify-center rounded-full border-2 border-fitzba-bright-gold-static bg-fitzba-bright-gold-static p-[6px] text-white hover:bg-white hover:text-fitzba-bright-gold-static'
          >
            CONTINUE
          </button>
        </div>
      </Form>
    </div>
  )
}
