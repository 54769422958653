import React, { useState } from 'react'
import { conditionList } from '@data/filter.data'
import FilterSideSectionHeader from '../../zearch/components/FilterSideSectionHeader'

export default function FilterSideCondition({
  itemsFilter,
  setFilterCondition,
  isMobile
}) {
  const [isOpen, setIsOpen] = useState(!isMobile ? false : !isMobile)
  // const isOpen = useSelector(state => state.user.filterState.condition)

  // const setIsOpen = (value) => {
  //   dispatch(setFilterState({ type: 'condition', value }))
  // }

  return (
    <div className='filter-item' style={{
      overflow: 'hidden',
      marginBottom: '100px',
      color: '#000'
    }}>
      <FilterSideSectionHeader
        title='Condition'
        isOpen={isOpen}
        onClickTitle={() => setIsOpen(!isOpen)}
        isHighlight={itemsFilter?.condition.length > 0}
        color='#000'
      />
      <div className='filter-item-list' style={{ display: isOpen ? 'flex' : 'none', color: '#000' }}>
        {conditionList?.map((item, index) => (
          <div className='filter-checkbox-item' key={index}>
            <label>
              <input
                value={item.value}
                type='checkbox'
                className='custom-checkbox'
                checked={itemsFilter?.condition?.includes(item.value)}
                onChange={e => {
                  setFilterCondition({
                    name: item.value,
                    checked: e.target.checked,
                  })
                }}
              />
              <span className='truncate'>{item.label}</span>
            </label>
          </div>
        ))}
      </div>
    </div>
  )
}
