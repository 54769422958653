import React from 'react'
import PlanIcon from './PlanIcon'
import RetailButtons from './RetailButtons'

export default function PlanList({
    isMobile,
    setIsOpen,
    setIsOpenForm
}) {

    return (
        <div className='bg-[#F9EDD6] flex flex-col gap-10 p-10 m-auto text-center items-center justify-center'>
            <table className='plan-table'>
                <tbody>
                    <tr>
                        <th rowSpan="2" className='plan-big-title'>
                            Be a launch<br />
                            partner and<br />
                            SAVE $$$
                        </th>
                        <th valign='top' className='plan-title'>Basicz</th>
                        <th valign='top' className='plan-title'>Showz</th>
                        <th valign='top' className='plan-title'>Sellz</th>
                    </tr>
                    {/* <tr>
                        <th colSpan="3" className='h-[100px]'>
                            <div className='py-1 flex items-center justify-between bg-black rounded-full text-white px-10'>
                                <img width={65} height={65} src='images/retail/star.png' alt='start' />
                                <div>
                                    <span className='uppercase text-[14px]'>limited time offer</span>
                                    <h4 className='text-[24px] leading-7 uppercase'>plans starting at<br /><strong className='text-[37px]'>$50</strong>/mo</h4>
                                </div>
                                <img width={65} height={65} src='images/retail/star.png' alt='start' />
                            </div>
                        </th>
                    </tr> */}
                    <tr>
                        <td className='text-[24px]'>$50</td>
                        <td className='text-[24px]'>$100</td>
                        <td className='text-[24px]'>$250</td>
                    </tr>
                    <tr>
                        <th className='left'>Store profile</th>
                        <td><PlanIcon type='yes' /></td>
                        <td><PlanIcon type='yes' /></td>
                        <td><PlanIcon type='yes' /></td>
                    </tr>
                    <tr>
                        <th className='left'>Inventory</th>
                        <td>MANUAL</td>
                        <td>AUTO</td>
                        <td>AUTO</td>
                    </tr>
                    <tr>
                        <th className='left'>Reservations</th>
                        <td><PlanIcon type='no' /></td>
                        <td><PlanIcon type='yes' /></td>
                        <td><PlanIcon type='yes' /></td>
                    </tr>
                    <tr>
                        <th className='left'>Promoted items</th>
                        <td><PlanIcon type='no' /></td>
                        <td><PlanIcon type='yes' /></td>
                        <td><PlanIcon type='yes' /></td>
                    </tr>
                    <tr>
                        <th className='left'>Reports and insights</th>
                        <td><PlanIcon type='no' /></td>
                        <td><PlanIcon type='yes' /></td>
                        <td><PlanIcon type='yes' /></td>
                    </tr>
                    <tr>
                        <th className='left'>Features on the site and social media</th>
                        <td><PlanIcon type='no' /></td>
                        <td><PlanIcon type='no' /></td>
                        <td><PlanIcon type='yes' /></td>
                    </tr>
                </tbody>
            </table>

            <div className='text-[20px]'>White glove account setup and one <strong className='text-[#0297A7] underline'>FREE</strong> month</div>
            <RetailButtons setIsOpen={setIsOpen} setIsOpenForm={setIsOpenForm} gap={
                isMobile ? '20px' : '60px'
            } />
        </div>
    )
}
