/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * ContactPage contains "AdditionalContactPage()" is a
 * functional React component that renders additional 
 * contact information and Fitzba's mission statement.
 * 
 * It includes an email address for inquiries and a 
 * description of Fitzba's mission to strengthen local 
 * communities.
 * 
 * This page was made to satisfy apples app-store policy 
 * requirements
 * 
 * @author Josh Soke, Yang Ming, James Nguyen
 * @version May 09, 2023
 */

import React from "react";
import "./ContactPage.css";


/**
 * 
 * @returns Renders the additional contact information and mission statement.
 */
export default function AdditionalContactPage() {
  // Rendering the additional contact information and mission statement
  return (
    <div className="policy-wrapper flex flex-wrap gap-5 text-left">
      <div className="policyGlass border border-black w-3/4 m-auto box-border-2 p-5 flex-2 basis-3/4 rounded-lg text-middle flex-col px-30">
        <h1 className="text-3xl font-bold mb-5">Get In Touch</h1>
        <p className="text-lg mb-3">For any inquiries, please contact us at</p>
        <p className="text-lg font-semibold">
          <a
            href="mailto:hello@support.com"
            className="text-black hover:text-fitzba-bright-gold-static"
          >
            support@fitzba.com
          </a>
        </p>

        <h2 className="text-2xl font-bold mt-24 mb-5">Fitzba's Mission</h2>
        <p className="text-lg">
          Fitzba's mission is to strengthen local communities by connecting consumers with the businesses around
          them. As a centralized marketplace, we empower businesses to showcase
          their inventory and store information, enabling customers to discover
          and support the unique offerings of their community. Our commitment to
          fostering local economies drives our innovative approach to
          simplifying and enriching the shopping experience for both consumers
          and businesses alike.
        </p>
      </div>
    </div>
  );
}
