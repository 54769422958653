import React, { useState } from 'react'
import './ShoppingCart.css'
import CartEmpty from './components/CartEmpty'
import CardSummary from './components/CartSummaryNew'
import CardItemListReserve from './components/CartItemListReserve'
import { useSelector, useDispatch } from 'react-redux'
import { formatCurrency } from '@utils/Helper'
import { setGotoElement } from '@reducers/contentReducer'
import ShoppingCartHook from '@hooks/ShoppingCart.hook'
import { Link } from 'react-router-dom'
import { AiOutlineLeft } from 'react-icons/ai'

export default function ShoppingCartReserve() {
  const dispatch = useDispatch()

  const shoppingCart = useSelector(state => state.user.shoppingCart.data) || null
  const location = useSelector(state => state.user.location)
  const isMobile = useSelector(state => state.content.isMobile)
  const { updateCart, deleteCart } = ShoppingCartHook()
  
  console.log('ShoppingCartReserve', shoppingCart)

  if (
    shoppingCart === null ||
    shoppingCart?.status === 'error' ||
    shoppingCart?.totalItems === 0
  )
    return (
      <div className='cart-wrapper'>
        <CartEmpty />
      </div>
    )

  return (
    <div className='cart-wrapper'>
      <div className='cart-main'>
        <div className='cart-content'>
          <div className='cart-content-header'>
            <Link to='/list' className='flex gap-2 items-center justify-center border-2 w-[250px] rounded-full px-3 py-2 '>
              <AiOutlineLeft /> Back to shopping cart
            </Link>
            <h3>Reservation</h3>
            <p>
              {/* write some description for your reservation */}
              You can reserve items by clicking on the Reserve button.
            </p>
          </div>
          <CardItemListReserve
            isMobile={isMobile}
            shoppingCart={shoppingCart}
            location={location}
            handleUpdateCart={updateCart}
            handleDeleteCart={deleteCart}
          />
        </div>
        <CardSummary shoppingCart={shoppingCart} />
      </div>
    </div>
  )
}
