/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * FilterSidePrice allows users to filter inventory results by price 
 * 
 * @author Yang Ming
 * @version February 7th, 2024
 */

import React, { useEffect, useState } from 'react'
import { MdArrowForwardIos } from "react-icons/md"
import FilterSideSectionHeader from '../../zearch/components/FilterSideSectionHeader'

/**
 * Component for filtering by low-high price in the sidebar.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.itemsFilter - The current filter settings.
 * @param {Function} props.dispatch - The Redux dispatch function.
 * @param {Function} props.setFilterPrice - A function to set the price filter.
 */
export default function FilterSidePrice({
  itemsFilter,
  setFilterPrice,
  isMobile
}) {
  const [isOpen, setIsOpen] = useState(!isMobile ? false : !isMobile)
  // const isOpen = useSelector(state => state.user.filterState.price)
  const [message, setMessage] = useState('')
  const [minPrice, setMinPrice] = useState('')
  const [maxPrice, setMaxPrice] = useState('')

  useEffect(() => {
    setMinPrice(itemsFilter.priceMin === -1 ? '' : itemsFilter.priceMin)
    setMaxPrice(itemsFilter.priceMax === -1 ? '' : itemsFilter.priceMax)
  }, [itemsFilter.priceMin, itemsFilter.priceMax])

  const handleSubmit = () => {
    setMessage('')
    const min = Number(minPrice || -1)
    const max = Number(maxPrice || -1)
    if (!min && !max) {
      return
    }
    if (min > 0 && min > max && max > 0) {
      setMessage('Min price must be less than max price')
      return
    }
    if (max > 0 && max < min && min > 0) {
      setMessage('Max price must be greater than min price')
      return
    }
    setFilterPrice({
      priceMin: min > -1 ? min : -1,
      priceMax: max > -1 ? max : -1,
    })
  }

  function onChangeMinPrice(e) {
    setMessage('')
    if (!e.target.value) {
      setMinPrice('')
      return
    }
    setMinPrice(e.target.value)
    const value = Number(e.target.value)
    if (value < 0) {
      setMessage('Please enter a positive number')
      return
    }
    const max = Number(maxPrice)
    if (value > max && max > 0) {
      setMessage('Min price must be less than max price')
      return
    }
  }

  function onChangeMaxPrice(e) {
    setMessage('')
    if (!e.target.value) {
      setMaxPrice('')
      return
    }
    setMaxPrice(e.target.value)
    const value = Number(e.target.value)
    if (value < 0) {
      setMessage('Please enter a positive number')
      return
    }
    const min = Number(minPrice)
    if (value < min && min > 0) {
      setMessage('Max price must be greater than min price')
      return
    }
  }

  return (
    <div
      className='filter-item mt-2'
      style={{
        overflow: 'hidden',
        color: '#000'
      }}
    >
      <FilterSideSectionHeader
        title='Price'
        isOpen={isOpen}
        onClickTitle={() => setIsOpen(!isOpen)}
        isHighlight={itemsFilter.priceMin !== -1 || itemsFilter.priceMax !== -1}
        color='#000'
      />
      <div className='filter-item-list' style={{ display: isOpen ? 'flex' : 'none', color: '#000' }}>
        <div className='filter-price-input'>
          <div className='filter-price-item border'>
            <span>$</span>
            <input placeholder='Min' type='number' value={minPrice} onChange={onChangeMinPrice} />
          </div>
          <div className='filter-price-item border'>
            <span>$</span>
            <input placeholder='Max' type='number' value={maxPrice} onChange={onChangeMaxPrice} />
          </div>
          <button className='w-[30px] h-[29px]' onClick={handleSubmit}>
            <MdArrowForwardIos />
          </button>
        </div>
        {message && (
          <div className='font-light text-[13px] text-red-500'>
            {message}
          </div>
        )}
        <div className='filter-checkbox-item'>
          <label>
            <input
              value={'on-sale'}
              type='checkbox'
              className={'custom-checkbox cursor-not-allowed'}
              checked={itemsFilter?.isOnSale === true}
              onChange={e => {
                setFilterPrice({ isOnSale: e.target.checked ? true : false })
              }}
            />
            <span className='cursor-not-allowed truncate'>On Sale</span>
          </label>
        </div>
      </div>
    </div>
  )
}
