/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * The AuthProvider component is a React component that provides authentication context to its children.
 * It uses the useSelector and useDispatch hooks from react-redux to access and update the user information in the state.
 * The useNavigate hook from react-router-dom is used to navigate the user.
 * The login and logout functions from the api.service are used to authenticate the user.
 * The setUserinfo and logout actions from the userReducer are used to update the user information in the state.
 * The signIn and signOut functions are provided as context values and can be used by child components to authenticate the user.
 *
 * @author James Nguyen, Yang Ming
 * @version September 15th, 2023
 */

import { createContext } from "react"
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux"
import { setUserinfo, logout } from '@reducers/userReducer'
import { login as loginAPI, logout as logoutAPI } from '@services/api.service'
import { message } from 'antd'

export let AuthContext = createContext(null)

const AuthProvider = ({ children }) => {

    // Get the current user info from the Redux store
    const userinfo = useSelector(state => state.user.userinfo)

    // Get the dispatch function from Redux
    const dispatch = useDispatch()

    // Get the navigate function from react-router-dom
    const navigate = useNavigate()

    /**
     * Handles the sign in process.
     * It validates the recaptcha code, performs the login API call, and updates the user info in the state.
     * If the login is successful, it removes the 'tmp_id' from local storage and navigates the user back.
     * @param {Object} values - The form values.
     * @param {Function} callback - The callback function to be called after the sign in process is complete.
    */
    const signIn = async (values, callback) => {
        console.log('AuthProvider signIn', values)
        // If Recaptcha code is not provided display an error message
        // if (!values.recaptchaCode) {
        //     message.error('Please verify that you are not a robot')
        //     return
        // }
        try {
            //Get the temporary id from local storage
            const tmp_id = localStorage.getItem('tmp_id')

            //Try to log in with the provided values and temporary id 
            const res = await loginAPI({
                ...values,
                tmp_id,
            })
            console.log('signIn', res)

            //If the login was successful display a success message, if not show an error message
            if (res.data) {
                message.success('Login succeeded')
            } else {
                message.error(res.message)
            }

            dispatch(setUserinfo(res.data))

            if (tmp_id) {
                localStorage.removeItem('tmp_id')
            }
            
            // Navigate the user back after a delay
            setTimeout(() => {
                if (callback)
                    callback()
                else
                    navigate(-1)
            }, 1500)
        } catch (err) {
            message.error(err.data?.message || 'Login failed, please try again')
        }
    }

    const signOut = async (callback) => {
        // Log out and update the Redux store
        await logoutAPI()
        dispatch(logout())
        navigate('/')
    }

    return (
        <AuthContext.Provider value={{ userinfo, signIn, signOut }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider