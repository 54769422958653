/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * FilterSideSearchBar is a component that is presented in the sidebar of the search results page.
 * This component allows users to search through the filter options results list. 
 * 
 * @author Yang Ming
 * @version February 12th, 2024
 */

import React from 'react'
import { IoIosSearch } from 'react-icons/io'
import { IoCloseOutline } from "react-icons/io5"

/**
 * A component for a search bar in a sidebar filter.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.input - The current input in the search bar.
 * @param {boolean} props.isOpen - Whether the search bar is open.
 * @param {Function} props.onSearch - The function to call when the search input changes.
 * @param {Function} props.onClear - The function to call when the clear button is clicked.
 * @param {Function} props.onSelectAll - The function to call when the "Select All" link is clicked.
 * @param {boolean} props.allCheckbox - Whether all checkboxes are selected.
 * @param {Array} props.selectText - The text to display for the "Select All" and "Clear All" links.
 * @param {boolean} props.isShowClear - Whether to show the "Clear All" link.
 * @param {Function} props.onClearAll - The function to call when the "Clear All" link is clicked.
 */
export default function FilterSideSearchBar({
    input,
    isOpen,
    onSearch,
    onClear,
    onSelectAll,
    allCheckbox,
    selectText = ['Select All', 'Clear All'],
    isShowClear = true,
    onClearAll,
}) {
    return (
        <div className='flex flex-col gap-3 mb-3' style={{ display: isOpen ? 'flex' : 'none' }}>
            <div className='filter-item-search-bar border-2'>
                <IoIosSearch size={32} />
                <input value={input} onChange={onSearch} placeholder='Search' className='font-light' />
                {input.length > 0 &&
                    <IoCloseOutline
                        size={32}
                        style={{ color: '#0f0f0f', cursor: 'pointer' }}
                        onClick={onClear}
                    />
                }
            </div>
            <div className='flex items-center justify-start gap-2'>
                <div className='underline cursor-pointer text-[14px] font-light' onClick={onSelectAll}>
                    {/* {allCheckbox ? selectText[1] : selectText[0]} */}
                    {selectText[0]}
                </div>
                {isShowClear && (
                    <>
                        <div>·</div>
                        <div className='underline cursor-pointer text-[14px] font-light' onClick={onClearAll}>
                            {selectText[1]}
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
