/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * Dropdown component
 *
 * @author James Nguyen
 * @version May 09, 2023
 */

import React, { useState } from 'react'
import DropdownButton from './DropdownButton'
import DropdownBackground from './DropdownBackground'
import DropdownMenu from './DropdownMenu'

/**
 *
 * @param userinfo a json that contain the user info, we use this
 *                 to dertermined if a user is loged in or not
 * @returns Render the dropdown component
 */
function Dropdown({ userinfo }) {
  // state for displaying the dropdown menu
  const [dropdownOpen, setDropdownOpen] = useState(false)

  return (
    <div className='flex justify-center'>
      <div className='relative'>
        <DropdownButton onClick={() => setDropdownOpen(!dropdownOpen)} />
        <DropdownBackground
          onClick={() => setDropdownOpen(false)}
          open={dropdownOpen}
        />
        <DropdownMenu
          open={dropdownOpen}
          setOpen={setDropdownOpen}
          userinfo={userinfo}
        />
      </div>
    </div>
  )
}

export default Dropdown
