/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * Reservation is a functional React component that displays a form for the user to leave a message for their customer.
 * The form includes a text area for the message and a submit button.
 * If the reservation is confirmed, the form also includes a select input for the number of days the item will be reserved.
 * The form data is sent to the server when the form is submitted.
 * If the server response is successful, a success message is displayed. If not, an error message is displayed.
 *
 * @author Yang Ming, James Nguyen
 * @version October 17th, 2023
 */

import { Form, Input, message, Radio } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { answerReservation, getReservation } from '@services/api.reservation.service'
import LoadingSection from '@components/LoadingSection'
import { MdCheckCircle } from 'react-icons/md'
import ReserveItemList from './components/ReserveItemList'
import { formatCurrency } from '@utils/Helper'

const Reservation = () => {
  const [answer, setAnswer] = useState()
  const [searchParams] = useSearchParams()
  const [form] = Form.useForm()
  const [isSuccess, setIsSuccess] = useState(false)
  const [reservation, setReservation] = useState(null)
  useEffect(() => {
    const fetchData = async () => {
      const answer = searchParams.get('answer')
      const reserveId = searchParams.get('reserveId')
      if (!answer || !reserveId) {
        message.error('Invalid request')
        return
      }
      setAnswer(answer)
      try {
        const res = await getReservation(reserveId)
        console.log('fetchData', res)
        setReservation(res.data)
      } catch (err) {
        message.error('Something went wrong: ' + err.message)
      }
    }
    fetchData()
  }, [searchParams])

  const handleSubmit = async value => {
    try {
      await answerReservation({
        status: answer,
        days: value.days,
        message: value.message,
        reserveId: reservation?._id,
      })
      message.success('Reservation has been answered')
      setIsSuccess(true)
    } catch (error) {
      message.error('Something went wrong: ' + error.message)
    }
  }
  if (!answer)
    return <LoadingSection text='Loading Stores' />

  if (isSuccess) {
    return (
      <div className='flex w-full flex-col items-center justify-center gap-[10px] py-[100px] px-[10px]'>
        <h3 className='text-[35px]'>Reservation has been answered</h3>
        <MdCheckCircle size={90} color='#E4B465' />
      </div>
    )
  }

  return (
    <div className='m-auto w-[400px] smsize:w-full smsize:px-3 flex min-h-[320px] flex-col text-left items-center justify-center py-6'>
      <h1 className='w-full text-left text-[20px] mb-5 whitespace-nowrap'>Item's Information</h1>
      {reservation?.itemList &&
        <ReserveItemList itemList={reservation?.itemList} />
      }
      <br />
      <div className='w-full'>
        <div className='flex flex-row items-center justify-between text-[15px] mt-[5px] border-t border-[#00000033]'>
          <span>Subtotal</span>
          <p className='font-light'>{formatCurrency(reservation?.totalSubtotal)}</p>
        </div>
        <div className='flex flex-row items-center justify-between text-[15px] '>
          <span>Taxes</span>
          <p className='font-light'>
            {formatCurrency(reservation?.totalTax)}
          </p>
        </div>
        <div className='flex flex-row items-center justify-between border-y border-[#00000033] py-[5px] text-[15px]'>
          <span>Total</span>
          <p className='text-[22px] font-light'>
            <span className='text-[14px]'>CAD</span>
            {formatCurrency(reservation?.totalAmount)}
          </p>
        </div>
      </div>
      <br />
      <h1 className='w-full text-left text-[20px] mb-5 whitespace-nowrap'>
        {answer === 'confirmed' ? 'Confirm Reservation' : 'Decline Reservation'}
      </h1>
      <Form onFinish={handleSubmit} className='w-full h-full text-left' form={form} layout='vertical'>
        <Form.Item name={'status'} label='Operation Type' initialValue={answer}>
          <Radio.Group
            options={[{
              label: 'Confirmed',
              value: 'confirmed',
            }, {
              label: 'Denied',
              value: 'denied',
            }]}
            onChange={(e) => {
              console.log(e.target.value)
              setAnswer(e.target.value)
            }}
            // value={value4}
            optionType="button"
            buttonStyle="solid"
          />
        </Form.Item>
        {answer === 'confirmed' && (
          <Form.Item
            name='days'
            initialValue={3}
            label='Item will be reserved for'
          >
            <Radio.Group>
              <Radio value={3}>3 days</Radio>
              <Radio value={5}>5 days</Radio>
              <Radio value={7}>7 days</Radio>
            </Radio.Group>
          </Form.Item>
        )}
        <Form.Item
          name='message'
          rules={[
            {
              required: true,
              message: 'Please provide a message',
            },
          ]}
          label='Please leave a message for your customer'
        >
          <Input.TextArea
            placeholder={
              answer === 'confirmed'
                ? "Hi there, we've reserved this item for you for x days."
                : 'Sorry but this item is currently unavailable.'
            }
            autoSize={{ minRows: 4, maxRows: 4 }}
            className=' rounded-lg border-2 border-black'
          />
        </Form.Item>
        <Form.Item className='flex w-full justify-end'>
          <button
            type='submit'
            className='mt-2 flex w-fit cursor-pointer justify-center rounded-full border-2 border-fitzba-bright-gold-static bg-fitzba-bright-gold-static px-4 py-1 text-base text-white hover:bg-white hover:text-fitzba-bright-gold-static'
          >
            SUBMIT
          </button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default Reservation
