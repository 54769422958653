/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * The BlogSide component renders a filter section for the blog posts.
 * 
 * It displays a list of blog post types that users can select to filter the blog posts.
 * 
 * Each type is a checkbox that, when clicked, dispatches an action to update the filter types in the blog list state.
 * 
 * The component uses the blog list state to determine which checkboxes should be checked.
 *
 * @author Yang Ming
 * @version October 1st, 2023 
 */

import './BlogSide.css'
import React from 'react'
import { setBlogFilter } from '@reducers/contentReducer'
import blogData from '@data/blog.data'



/**
 * BlogSide is a functional component that renders a filter section for blog posts.
 * It receives the blog list state and dispatch function as props.
 * 
 * @param {Object} props - The props for the component.
 * @param {Object} props.blogList - The blog list state.
 * @param {Function} props.dispatch - The dispatch function.
 */

export default function BlogSide({
    blogList,
    dispatch
}) {


 /**
 * onClickTypes is a function that handles click events on the filter checkboxes.
 * It dispatches an action to update the filter types in the blog list state based on the clicked checkbox.
 * 
 * @param {Event} e - The click event.
 */

    const onClickTypes = (e) => {
        const value = e.target.value
        console.log(value)
        if (value === 'all') {
            dispatch(setBlogFilter({ value: [], action: 'all' }))
            return
        }
        dispatch(setBlogFilter({ value: value, action: e.target.checked ? 'add' : 'remove' }))
        // goto the top smoothly
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }

    return (
        <section className="blog-side">
            <div className="blog-filter-item">
                <h3 className='flex justify-between text-left'>
                    Filter
                    {/* <span>
                        <label>
                            All {' '}
                            <input type="checkbox" value="all"
                                className="input-radio-checkbox"
                                checked={blogList?.filterTypes?.length === 0}
                                onChange={onClickTypes} />
                        </label>
                    </span> */}
                    <label className='filter-item-header-all'>
                        <input
                            value={'all'}
                            type='checkbox'
                            className='custom-checkbox'
                            checked={blogList?.filterTypes?.length === 0}
                            onChange={onClickTypes}
                        />
                        All
                    </label>
                </h3>
                <div className="blog-filter-types">
                    {blogData?.types?.map((type, key) => (
                        <div key={key} className="blog-type-title">
                            {/* <label>
                                <input type="checkbox" value={type}
                                    className="blog-input-radio-checkbox"
                                    checked={blogList?.filterTypes.includes(type)}
                                    onChange={onClickTypes} /> {type}
                            </label> */}
                            <label className='filter-item-header-all'>
                                <input
                                    value={type}
                                    type='checkbox'
                                    className='custom-checkbox'
                                    checked={blogList?.filterTypes.includes(type)}
                                    onChange={onClickTypes}
                                />
                                {type}
                            </label>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}