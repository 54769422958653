/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * CartSummary is a functional React component that displays a summary of the user's shopping cart.
 * It shows the subtotal, taxes, and total for the items in the cart.
 * It also provides a form for the user to enter their email to receive their shopping list.
 * If the user is not logged in, it provides a button to log in to save their list.
 * The user can also send their shopping list to their email.
 *
 * @author James Nguyen, Yang Ming
 * @version June 29th, 2023
 */

import React, { useRef, useState } from 'react'
import { formatCurrency } from '@utils/Helper'
import { useSelector } from 'react-redux'
import { message, Form, Input } from 'antd'
import {
  reserveCartNew,
  // shoppingCartCheckout,
} from '@services/api.reservation.service'
import CartReservationFinished from './CartReservationFinished'

/**
 * Displays a summary of the user's shopping cart.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.shoppingCart - The user's shopping cart.
 *
 * @returns {JSX.Element} The rendered component.
 */

export default function CartSummaryNew({ shoppingCart }) {
  const formRef = useRef()
  const userinfo = useSelector(state => state.user.userinfo)
  const location = useSelector(state => state.user.location)
  const [showPopup, setShowPopup] = useState(false)

  const handleSubmit = async values => {
    console.log('handleSubmit', values)
    const hide = message.loading('Sending reservation...')
    try {
      const tmp_id = localStorage.getItem('tmp_id') || null
      const user_id = userinfo?._id || null
      await reserveCartNew({
        ...values,
        tmp_id,
        user_id,
        lat: location?.lat,
        lng: location?.lng,
      })
      setShowPopup(true)
      hide()
      message.success('Your reservation sent successfully')
    } catch (error) {
      hide()
      message.error('Error sending reservation: ' + error?.message || '')
    }
  }

  return (
    <div className='cart-summary-reserve smsize:p-4' id='cart-summary'>
      <CartReservationFinished
        showPopup={showPopup}
        setShowPopup={setShowPopup}
      />
      <div className='cart-summary-header'>
        <h3>Summary</h3>
        <p className='mb-2'>
          ({shoppingCart?.reserveSelectedItems || 0}) ITEMS
        </p>
        <div className='cart-summary-total'>
          <div className='cart-total-line'>
            <span>Subtotal</span>
            <span>{formatCurrency(shoppingCart?.reserveSubTotal)}</span>
          </div>
          <div className='cart-total-line'>
            <span>Taxes</span>
            <span>{formatCurrency(shoppingCart?.reserveTaxTotal)}</span>
          </div>
          <p className='cart-total-border-bottom' />
          <div className='cart-total-line' style={{ paddingTop: 10 }}>
            <span>TOTAL</span>
            <span>{formatCurrency(shoppingCart?.reserveTotal)}</span>
          </div>
          <p className='cart-total-border-bottom' />
        </div>
      </div>
      <div className='cart-summary-other'>
        <Form
          ref={formRef}
          className='flex w-full flex-col items-center justify-center text-left text-[16px]'
          onFinish={handleSubmit}
        >
          <h3 className='mb-3'>Enter your name and email.</h3>
          <Form.Item
            name={'username'}
            rules={[
              {
                required: true,
                message: 'Please enter your name',
              },
            ]}
            className='w-full'
          >
            <Input
              placeholder='Name'
              className='rounded-full border-2 border-black'
            />
          </Form.Item>
          <Form.Item
            name={'userEmail'}
            rules={[
              {
                required: true,
                message: 'Please enter your email address',
              },
              {
                type: 'email',
                message: 'Please enter a valid email address',
              },
            ]}
            className='w-full'
          >
            <Input
              placeholder='Email'
              className='rounded-full border-2 border-black'
            />
          </Form.Item>
          <Form.Item name={'content'} className='w-full'>
            <Input.TextArea
              placeholder='Message (Optional)'
              className='rounded-[15px] border-2 border-black'
              rows={4}
            />
          </Form.Item>
          <Form.Item className='flex w-full justify-center'>
            <button
              type='submit'
              className='flex w-fit cursor-pointer items-center justify-center rounded-full border-2 border-fitzba-bright-gold-static bg-fitzba-bright-gold-static px-10 py-[6px] text-white hover:bg-white hover:text-fitzba-bright-gold-static'
            >
              RESERVE NOW
            </button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}
