import React from 'react'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { Link } from 'react-router-dom'

export default function QuestionSection({
    title,
    list,
    onClickSeemore,
    isMobile
}) {

    const [isSeeMore, setIsSeeMore] = React.useState(false)

    return (
        <div className='mt-3 flex px-16 flex-col gap-5 smsize:px-5 smsize:gap-3 smsize:mt-1'>
            <h4 className='uppercase text-[20px] font-medium'>{title}</h4>
            <div className='flex flex-wrap gap-3 items-center justify-between '>
                {list?.slice(0, isSeeMore ? list.length : isMobile ? 4 : 12).map((item, index) => (
                    <button onClick={() => { }} key={index} className='bg-[#EDEDED] rounded-full w-[150px] py-2 text-[15px] font-light hover:bg-[#F5E2BB] overflow-hidden overflow-ellipsis whitespace-nowrap'>
                        {item.title}
                    </button>
                ))}
            </div>
            <Link onClick={() => {
                setIsSeeMore(!isSeeMore)
                onClickSeemore()
            }} className='text-[#0B6186] flex justify-end items-center text-[15px] font-light'>
                {isSeeMore ?
                    <>See less <IoIosArrowUp /></>
                    :
                    <>See more <IoIosArrowDown /></>
                }
            </Link>
        </div>
    )
}
