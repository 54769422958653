import React from 'react'

export default function SectionTitle({
  title,
  color,
}) {
  return (
    <div className='flex gap-3 items-center mb-5'>
      <div style={{
        border: `3px solid ${color}`
      }} className={`w-[16px] h-[16px] bg-white rounded-full`}></div>
      <h2 className='text-[22px]'>
        {title}
      </h2>
    </div>
  )
}
