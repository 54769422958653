/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * Item is a functional React component that renders a single item
 * in the list view, with an image, title, rating, price, and other details.
 *
 * @author Yang Ming
 * @version June 07, 2023
 */
import React from 'react'
import { getShortString, getDistance } from '@utils/Helper'
import { Link, useNavigate } from 'react-router-dom'
import ItemPrice from '../../zearch/components/ItemPrice'
import ItemRating from '../../zearch/components/ItemRating'
import { getItemLink } from '@utils/Helper'
import MyImage from '@components/MyImage'
import { setFilterStoreOnlyOne } from '@reducers/contentReducer'
import { setFilterBrand } from '@reducers/contentReducer'
import { useSelector } from 'react-redux'
import FavoriteRemoveButton from './FavoriteRemoveButton'


export default function FavoriteItemCardMobile({
  item,
  handleAddToCart,
  keywordsList,
  setFilterCondition,
  dispatch
}) {
  const navigate = useNavigate()
  const itemLink = getItemLink(item._id, item.name)
  const location = useSelector((state) => state.user.location)

  function onClickPreowned() {
    dispatch(setFilterCondition(dispatch(
      setFilterCondition({
        name: 'Used',
        checked: true,
      }),
    )))
  }

  const getTitle = () => {
    let title = getShortString(item?.name, 30)
    if (keywordsList && keywordsList.length > 0) {
      title = title.replace(
        new RegExp(`(${keywordsList.join('|')})`, 'gi'),
        '<font color="#E4B456">$1</font>',
      )
    }
    return title
  }

  return (
    <div className='mobile-item-card'>
      <div className='mobile-item-img relative'>
        <FavoriteRemoveButton item_id={item?._id} />
        <Link to={itemLink}>
          <MyImage
            src={
              item?.images && item.images.length > 0
                ? item.images[0]
                : '/item-default.png'
            }
            alt={item?.name}
            className='h-full flex items-center justify-center'
          />
        </Link>
      </div>
      <div className='mobile-item-content'>
        <h4 className='leading-5'>
          <Link to={itemLink}>
            <p className='leading-5 pr-3 smsize:text-[13px]' dangerouslySetInnerHTML={{ __html: getTitle() }} />
          </Link>
        </h4>
        {item?.brand && (
          <p className='flex w-full justify-between truncate text-[11px] text-[#727272] cursor-pointer' onClick={() => {
            dispatch(setFilterBrand({
              id: item?.brand?._id,
              checked: true,
            }))
          }}>
            <span className=' uppercase'>{item?.brand?.title}</span>
          </p>
        )}
        <div className='cursor-pointer text-[13px] text-[#727272] font-light'>
          <div onClick={() => {
            dispatch(setFilterStoreOnlyOne(item?.business?._id))
            navigate(`/search?ref=fitzba&stores=${item?.business?._id}&onlyonestore=true`)
          }} title={item?.name}
            className='uppercase'
          >
            {item?.business?.name}
          </div>
        </div>
        <span className='text-[13px] font-light uppercase'>
          {getDistance(item?.distance, location?.country === 'US')}
        </span>
        {item?.review?.totalCount > 0 &&
          <ItemRating review={item.review} isMobile={true} />
        }
        <div className='mobile-item-price mt-1'>
          <ItemPrice originalPrice={item?.originalPrice} salePrice={item?.salePrice} />
        </div>
        <div className='flex gap-1 mt-2 text-[10px] font-light text-[#0B6186] whitespace-nowrap'>
          <Link
            to={itemLink + '&pickup=true'}
            className='bg-[#E4F2F3] rounded-sm p-1 cursor-pointer'
          >
            pick-up
          </Link>
          {item?.business?.isReserve && (
            <Link
              to={itemLink + '&reserve=true'}
              className='bg-[#E4F2F3] rounded-sm p-1 cursor-pointer'
            >
              reservation
            </Link>
          )}
        </div>
        {item?.isUsed && (
          <p className='text-[13px] font-light '>
            This item is <Link className='text-[#0B6186]' onClick={onClickPreowned}>preowned</Link>
          </p>
        )}
        <button className='mt-3 w-full rounded-full bg-fitzba-bright-gold-static px-5 py-1 text-center text-[#fff] text-[14px]'
          onClick={() => handleAddToCart(item._id)}
        >
          + Add
        </button>
      </div>
    </div>
  )
}
