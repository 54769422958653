/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * FilterSideSelectedItem.js defines a component that handles the selection of an item in the sidebar filter.
 * 
 * @author Yang Ming
 * @version January 16th, 2024
 */
import React from 'react'
import { AiOutlineClose } from 'react-icons/ai'

export default function FilterSideSelectedItem({
    label,
    value,
    onClickRemove
}) {
    return (
        <div className='filter-selected-item'>
            <AiOutlineClose
                className='filter-selected-item-close'
                onClick={() => onClickRemove(value)}
            />
            <span className='filter-selected-item-label'>
                {label}
            </span>
        </div>
    )
}
