import React, { useState } from 'react'
import { message } from 'antd'
import { addEmailSubscription } from '@services/api.emailsubscription.service'
import { isValidEmail } from '@utils/Helper'

export default function EmailSubscription() {
    const [inputValue, setInputValue] = useState('')
    const handleSubscribe = async () => {
        if (!inputValue) {
            message.error('Please enter your email')
            return
        }
        if (!isValidEmail(inputValue)) {
            message.error('Please enter a valid email')
            return
        }
        try {
            await addEmailSubscription(inputValue)
            message.success('Thank you for subscribing!')
        } catch (error) {
            console.log('handleSubscribe', error)
            message.error(`Failed to subscribe, ${error?.data?.error || 'please try again later'}`)
        }
    }
    return (
        <div className='flex'>
            {/* a subscription input and button */}
            <input
                placeholder='Your email'
                onChange={e => setInputValue(e.target.value)}
                value={inputValue}
                className='rounded-l-md py-1 px-1 text-[14px] w-full text-black'
            />
            <button
                onClick={handleSubscribe}
                className='bg-[#E4B456] text-white rounded-r-md py-1 px-2 text-[14px]'
            >
                Subscribe
            </button>
        </div>
    )
}
