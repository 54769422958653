

import React, { useEffect } from 'react'
import QuestionSection from './QuestionSection'
import FormButtons from './FormButtons'
import { Link } from 'react-router-dom'
import { REGISTER_STEPS } from '../RegisterV4'
import { useQuery } from '@tanstack/react-query'
import { getGlobalCategoryTopHeaderListNew } from '@services/api.globalCategory.service'


const categories = [
  {
    _id: '1',
    title: 'Category 1',
  },
  {
    _id: '2',
    title: 'Category 2',
  },
  {
    _id: '3',
    title: 'Category 3',
  },
  {
    _id: '4',
    title: 'Category 4',
  },
  {
    _id: '5',
    title: 'Category 5',
  },
  {
    _id: '6',
    title: 'Category 6',
  },
  {
    _id: '7',
    title: 'Category 7',
  },
  {
    _id: '8',
    title: 'Category 8',
  },
]

const brands = [
  {
    _id: '1',
    title: 'Brand 1',
  },
  {
    _id: '2',
    title: 'Brand 2',
  },
  {
    _id: '3',
    title: 'Brand 3',
  },
  {
    _id: '4',
    title: 'Brand 4',
  },
  {
    _id: '5',
    title: 'Brand 5',
  },
  {
    _id: '6',
    title: 'Brand 6',
  },
  {
    _id: '7',
    title: 'Brand 7',
  },
  {
    _id: '8',
    title: 'Brand 8',
  },
]

const stores = [
  {
    _id: '1',
    title: 'Store 1',
  },
  {
    _id: '2',
    title: 'Store 2',
  },
  {
    _id: '3',
    title: 'Store 3',
  },
  {
    _id: '4',
    title: 'Store 4',
  },
  {
    _id: '5',
    title: 'Store 5',
  },
  {
    _id: '6',
    title: 'Store 6',
  },
  {
    _id: '7',
    title: 'Store 7',
  },
  {
    _id: '8',
    title: 'Store 8',
  },
]

export default function QuestionStep1({
  current,
  setCurrent,
  isMobile
}) {

  const {
    // isPending,
    // error,
    data: topHeaderList,
  } = useQuery({
    queryKey: ['topHeaderList'],
    queryFn: getGlobalCategoryTopHeaderListNew,
  })

  return (
    <div className='flex flex-col gap-1 mb-3'>
      <h3 className='text-[38px] smsize:text-[30px] font-medium mb-2 smsize:mb-0'>
        Register
      </h3>
      <h4 className='text-[30px] smsize:text-[24px] font-light'>What interests you?</h4>
      <div className='flex flex-col max-h-[650px] smsize:max-h-[500px] overflow-y-auto smsize:mb-5'>
        <QuestionSection
          title={'Categories'}
          list={topHeaderList?.data || []}
          onClickSeemore={() => { }}
          onClickCategory={() => { }}
          isMobile={isMobile}
        />
        <QuestionSection
          title={'Brands'}
          list={brands}
          onClickSeemore={() => { }}
          onClickCategory={() => { }}
          isMobile={isMobile}
        />
        <QuestionSection
          title={'Stores'}
          list={stores}
          onClickSeemore={() => { }}
          onClickCategory={() => { }}
          isMobile={isMobile}
        />
      </div>
      <FormButtons
        backText={'BACK'}
        onClickBack={() => setCurrent(REGISTER_STEPS.REGISTER)}
        submitText={'CONTINUE'}
        onClickSubmit={() => setCurrent(REGISTER_STEPS.QUESTION2)}
      />
      <Link className='mt-3 text-[15px] font-light text-[#0B6186] underline' onClick={() => setCurrent(REGISTER_STEPS.QUESTION2)}>Skip this step</Link>
    </div>
  )
}
