import React from 'react'
import FavouriteButton from './FavoriteButton'

export default function TopButtons() {

    function onClickTopButton(name) {
        const element = document.getElementById(name)
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' })
        }
    }

    return (
        <div className='w-full flex gap-4 items-center smsize:justify-center p-3 smsize:shadow smsize:bg-white'>
            <FavouriteButton text="offers" onClick={() => onClickTopButton('favorite-offers')} isHightlighted={true} />
            <FavouriteButton text="stores" onClick={() => onClickTopButton('favorite-stores')} />
            <FavouriteButton text="lists" onClick={() => onClickTopButton('favorite-list')} />
            <FavouriteButton text="items" onClick={() => onClickTopButton('favorite-item-list')} />
        </div>
    )
}
