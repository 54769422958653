/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * This module exports a React component named 'ItemInfo'. This component displays detailed information about a specific item.
 * It includes the item's price, delivery options, description, and tags. It also includes action buttons for adding the item to a shopping list,
 * buying the item, and reserving the item.
 *
 * The component takes three props: 'item', 'onClickReserve', and 'isMobile'. 'item' is the item being displayed. 'onClickReserve' is a function
 * that is called when the 'Reserve' button is clicked. 'isMobile' is a boolean indicating whether the device is mobile or not.
 *
 * @author Yang Ming, James Nguyen
 * @version January 19th, 2024
 */

import React, { useEffect, useRef, useState } from 'react'
import ShoppingCartHook from '@hooks/ShoppingCart.hook'
import ItemPrice from '../../zearch/components/ItemPrice'
import { useDispatch } from 'react-redux'
import { setSearchTitle } from '@reducers/contentReducer'
import { useNavigate } from 'react-router-dom'
import ItemActionButton from './ItemActionButton'
import ItemTags from './ItemTags'
// import { useMediaQuery } from 'react-responsive'
import ItemDeliveryIconList from './ItemDeliveryIconList'
import ItemVariants from './ItemVariants'
import ShareButtons from './ShareButtons'
import MessageForm from 'pages/store/components/MessageForm'
import { Drawer } from 'antd'

/**
 * A component that displays detailed information about a specific item.
 *
 * @param {Object} props - The props object.
 * @param {Object} props.item - The item being displayed.
 * @param {Function} props.onClickReserve - A function that is called when the 'Reserve' button is clicked.
 * @param {boolean} props.isMobile - A boolean indicating whether the device is mobile or not.
 * @returns {JSX.Element} The 'ItemInfo' component.
 */
export default function ItemInfo({
  imageIndex,
  setImageIndex,
  item,
  onClickReserve,
  isMobile,
  selectedVariant,
  setSelectedVariant,
  userinfo
}) {
  console.log('ItemInfo: ', selectedVariant)

  const { addToCart } = ShoppingCartHook()
  // const isTablet = useMediaQuery({ query: '(max-width: 1279px)' })
  const ref = useRef()
  // const [openMap, setOpenMap] = useState(true)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isOpenMessage, setIsOpenMessage] = useState(false)

  const [prices, setPrices] = useState({
    price: item?.salePrice,
    compare_at_price: item?.originalPrice,
  })

  useEffect(() => {
    if (item?.variants?.length > 1) {
      setPrices({
        price: item?.variants[0]?.price,
        compare_at_price: item?.variants[0]?.compare_at_price,
      })
    }
  }, [item?.variants])

  const canReserve = item?.canBeReserved
  const reserveColor = canReserve
    ? 'text-white border-black bg-black hover:bg-white hover:text-black hover:border-black'
    : 'text-white border-[#e1e1e1] bg-[#e1e1e1]'

  return (
    <div className='px-5 text-left'>
      <Drawer
        title="Send a message"
        placement="right"
        closable={true}
        onClose={() => setIsOpenMessage(false)}
        open={isOpenMessage}
        width={isMobile ? '100%' : '500px'}
        style={{ zIndex: 999999 }}
      >
        <MessageForm business={item?.business} userinfo={userinfo} item={item}/>
      </Drawer>
      <div className='flex flex-row items-center gap-2 py-3 sm:gap-5'>
        <div
          className='flex flex-1 flex-row items-baseline gap-1 text-2xl leading-8 tracking-[1px] text-fitzba-black-static sm:text-3xl sm:leading-10'
          ref={ref}
        >
          <ItemPrice
            originalPrice={prices?.compare_at_price}
            salePrice={prices?.price}
          />
          {/* {isSoldout && (
            <div className='bg-[black] text-[white] text-[14px] font-light rounded-full flex items-center justify-center px-[10px]'>Sold out</div>
          )} */}
          {item?.name?.indexOf('100g') !== -1 && item?.unitType && item?.unitValue && (
            <div className='bg-[grey] text-[white] text-[14px] font-light rounded-full flex items-center justify-center px-[10px]'>/{item.unitValue} {item.unitType}</div>
          )}
        </div>
      </div>
      {/* Various */}
      {item?.options?.length > 0 && item?.variants?.length > 1 && (
        <ItemVariants
          options={item?.options}
          variants={item?.variants}
          setPrices={setPrices}
          imageIndex={imageIndex}
          setImageIndex={setImageIndex}
          setSelectedVariant={setSelectedVariant}
        />
      )}
      <div className='py-2 text-[12px] uppercase'>
        CREATE A <strong>SHOPPING LIST</strong> TO HELP YOU PLAN YOUR SHOPPING TRIP.{' '}
      </div>
      <div
        className='flex flex-row justify-start gap-1 text-sm sm:gap-2 sm:text-base'
        style={{
          marginBottom: '1rem',
          marginTop: '1rem',
        }}
      >
        <ItemActionButton
          text={'ADD TO CART'}
          onClick={() => {
            addToCart(item?._id, selectedVariant?.variant_id, selectedVariant?.title)
          }}
          disabled={false}
          className={
            'border-fitzba-bright-gold-static bg-fitzba-bright-gold-static text-white hover:bg-fitzba-white-static hover:text-fitzba-bright-gold-static'
          }
        />
        <ItemActionButton
          text={'RESERVE'}
          onClick={
            canReserve
              ? () => {
                onClickReserve()
                window.scrollTo(0, 0)
              }
              : () => { }
          }
          disabled={false}
          hoverText={canReserve ? null : 'Unavailable'}
          className={reserveColor}
        />
      </div>
      <ItemDeliveryIconList
        business={item?.business}
        deliveryTypes={item?.deliveryTypes}
        extraClass={`${isMobile ? 'pt-3' : 'pt-1'}`}
        setIsOpenMessage={setIsOpenMessage}
      />
      <div className='my-6 w-full border-b border-[#A6A6A6] xl:hidden' />
      <div
        className='mt-12 font-light'
        dangerouslySetInnerHTML={{
          __html: item?.description || 'No Description yet',
        }}
      />
      <ItemTags
        tags={item?.tags}
        dispatch={dispatch}
        setSearchTitle={setSearchTitle}
        navigate={navigate}
      />
      <ShareButtons />
    </div>
  )
}
