/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * This module exports a React component named 'ItemDeliveryIcon'. This component displays an icon and label for a specific delivery option.
 * The icon and label are grayed out if the delivery option is not available. A tooltip is displayed when the icon is hovered over.
 * 
 * The component takes four props: 'text', 'Icon', 'deliveryTypes', and 'tip'. 'text' is the label for the delivery option. 'Icon' is the icon for the delivery option.
 * 'deliveryTypes' is an array of delivery options available for the item. 'tip' is the tooltip text.
 * 
 * @author Yang Ming, James Nguyen
 * @version January 18th, 2024
 */

import React from 'react'
import { Tooltip } from 'antd'

/**
 * ItemDeliveryIcon is a component that displays an icon and label for a specific delivery option.
 * 
 * @param {Object} props - The props object.
 * @param {string} props.text - The label for the delivery option.
 * @param {Function} props.Icon - The icon for the delivery option.
 * @param {Array} props.deliveryTypes - An array of delivery options available for the item.
 * @param {string} props.tip - The tooltip text.
 * @returns {JSX.Element} The 'ItemDeliveryIcon' component.
 */
export default function ItemDeliveryIcon({
    text,
    Icon,
    deliveryTypes,
    tip
}) {

    const enabled = deliveryTypes.includes(text)
    const textColor = enabled ? '#0B6186' : '#ccc'
    const borderColor = enabled ? '#7B7B7B' : '#ccc'
    const iconColor = enabled ? '#7B7B7B' : '#ccc'

    return (
        <Tooltip title={tip} arrow placement="top">
            <div className='flex flex-col items-center justify-center text-center cursor-pointer'>
                <div className={`w-[48px] h-[48px] rounded-full border-2 border-opacity-50 p-2 border-[${borderColor}]`}>
                    <Icon
                        size={30}
                        color={iconColor}
                    />
                </div>
                <span style={{ color: textColor, fontWeight: 300 }}>
                    {text}
                </span>
            </div>
        </Tooltip>
    )
}
