import { useContext } from "react"
import { useLocation, Navigate } from "react-router-dom"
import { AuthContext } from "./contexts/AuthProvider"

const useAuth = () => useContext(AuthContext)

const RequireAuth = ({ children }) => {
    let auth = useAuth()
    let { userinfo } = auth
    let location = useLocation()

    if (!userinfo) return <Navigate to="/login" state={{ from: location }} replace />

    return children
}

export default RequireAuth