/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * ItemDeliveryIcon exports a React component named 'ItemBrand'. This component displays the brand of a specific item as a link.
 * When the link is clicked, the search input is set to the brand title and the user is redirected to the search page with the brand title as the keyword.
 * 
 * The component takes five props: 'brandTitle', 'fontSize', 'fontColor', 'dispatch', and 'setSearchInput'. 'brandTitle' is the title of the brand. 'fontSize' and 'fontColor' are the font size and color of the link.
 * 'dispatch' is the dispatch function from Redux. 'setSearchInput' is a function that sets the search input.
 * 
 * @author Yang Ming, James Nguyen
 * @version January 18th, 2024
 */
import React from 'react'
import { Link } from 'react-router-dom'

/**
 * A component that displays the brand of a specific item as a link.
 * 
 * @param {Object} props - The props object.
 * @param {string} props.brandTitle - The title of the brand.
 * @param {string} props.fontSize - The font size of the link.
 * @param {string} props.fontColor - The color of the link.
 * @param {Function} props.dispatch - The dispatch function from Redux.
 * @param {Function} props.setSearchInput - A function that sets the search input.
 * @returns {JSX.Element} The 'ItemBrand' component.
 */
export default function ItemBrand({
    brandTitle,
    fontSize = '1rem',
    fontColor = '#999',
    dispatch,
    setSearchInput
}) {
    if (!brandTitle) return null
    return (
        <Link
            className={`mt-1 text-[${fontSize}] font-light text-[${fontColor}] cursor-pointer`}
            onClick={() => {
                // dispatch(initItemsFilter())
                dispatch(setSearchInput(brandTitle))
            }}
            to={`/search?keywords=${brandTitle}&page=1`}
        >
            {brandTitle?.toUpperCase()}
        </Link>
    )
}
