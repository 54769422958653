/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * GetMoreInfoConfirm2 is a functional React component that displays a confirmation modal after the user requests more information.
 * The modal includes a thank you message, a notification that an email will be sent, and a check circle icon.
 * The modal can be closed by clicking on the close icon or outside the modal.
 * The layout of the modal changes based on the screen size.
 *
 * @author Yang Ming, James Nguyen
 * @version January 26th, 2024
 */

import React from 'react'
import { MdCheckCircle } from 'react-icons/md'
import { IoCloseOutline } from "react-icons/io5"
import { Modal } from 'antd'

/**
 * Displays a confirmation modal after the user requests more information.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} props.isMobile - Whether the screen size is mobile or not.
 * @param {boolean} props.isShowConfirm - Whether the modal is shown or not.
 * @param {function} props.setIsShowConfirm - Function to set the state of the modal's show status.
 *
 * @returns {JSX.Element} The rendered component.
 */

export default function GetMoreInfoConfirm2({
  isMobile,
  isShowConfirm,
  setIsShowConfirm,
}) {

  // const wrapperRef = useRef(null)

  // useEffect(() => {
  //   if (!wrapperRef) return

  //   function handleClickOutside(event) {
  //     if (wrapperRef?.current && !wrapperRef?.current?.contains(event.target)) {
  //       setIsShowConfirm(false)
  //     }
  //   }
  //   document.addEventListener('mousedown', handleClickOutside)
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside)
  //   }
  // }, [wrapperRef, setIsShowConfirm])

  // if (!isShowConfirm) return null

  return (
    <Modal
      open={isShowConfirm}
      onCancel={() => setIsShowConfirm(false)}
      footer={null}
      forceRender
      destroyOnClose={false}
      width={400}
      style={{
        background: "transparent",
        pointerEvents: "auto"
      }}
      zIndex={99999}
      modalRender={() => (
        <div className='relative flex h-fit w-[350px] flex-col items-center justify-center rounded-lg bg-fitzba-white-static font-lexend drop-shadow-lg sm:w-[400px] lg:w-[400px]'>
          <div className={`flex h-[120px] w-full items-center justify-center rounded-t-lg bg-black`}>
            <img src='/fitzba-ICON-WORD-WHITE.png' alt='logo' className='h-16 ' />
            <div className='absolute top-4 right-5 text-xl text-[#a6a6a6]'>
              <IoCloseOutline size={24} className=' cursor-pointer' onClick={() => setIsShowConfirm(false)} />
            </div>
          </div>
          <div className='z-[9999] w-full overflow-y-scroll px-[10px]'>
            <div className='flex py-6 w-full flex-col items-center justify-center gap-[30px] px-[10px]'>
              <div className='text-center text-[24px] text-[#202020]'>
                <h3>Thank you!</h3>
                <p className='font-light'>You will receive an e-mail from us soon.</p>
              </div>
              <MdCheckCircle size={100} color='#E4B465' />
            </div>
          </div>
        </div>
      )}
    >

    </Modal>
  )
}
