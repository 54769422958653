/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * The ZearchToSearch component is a React component that redirects from the zearch endpoint to the search endpoint. 
 * It uses the useEffect hook from React to perform the redirection when the component mounts.
 * The useNavigate and useSearchParams hooks from react-router-dom are used to navigate the user and access the search parameters.
 * The useDispatch hook from react-redux is used to dispatch actions to the Redux store.
 * The setFilterStoreOnlyOne action from the contentReducer is used to set the store filter.
 * If a 'store' search parameter is present it dispatches the setFilterStoreOnlyOne action with the 'store' parameter.
 *
 * @author James Nguyen, Yang Ming
 * @version December 12th, 2023 
 */

import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setFilterStoreOnlyOne } from '@reducers/contentReducer'

function ZearchToSearch() {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()

  const currentURL = window.location.href
  console.log('currentURL', currentURL)

  // const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    // const goToSearch = () => {
    //   if (location.search) {
    //     navigate('/search' + location.search)
    //   }
    //   else {
    //     navigate('/search')
    //   }
    // }
    // goToSearch()
    const store = searchParams.get('store')
    if (store) {
      dispatch(setFilterStoreOnlyOne(store))
    }
    navigate('/search')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // navigate to search page use window.location.href
  return null
}

export default ZearchToSearch
