/*
 * Copyright Fitzba Technologies Inc. 2023

 * RegisterStep1 is a functional React component that renders the first step of the registration process.
 * It uses Ant Design components and provides validation for email, phone number, and password formats.
 * The checkUsernameExist function validates if the email is unique and the onFinish function proceeds to the next step.
 * 
 * @author Yang Ming
 * @version May 09, 2023
 */

import React, { useEffect, useState } from 'react'
import { Form, Input, Select } from 'antd'
import { useNavigate } from 'react-router-dom'
import { canadaProvinces, usStates } from '@data/provinces.data'
import { useSelector } from 'react-redux'
/**
 * @param formData - Form data object
 * @param setFormData - Function to set form data
 * @param next - Function to proceed to the next step
 * @returns Renders a form for users to enter their email, phone number, password, first name, and last name
 */
export default function RegisterBusiness({
  formData,
  setFormData,
  handleSubmit,
  setCurrent
}) {
  // Using the  hook from Ant Design's Form component to create a form instance
  const [form] = Form.useForm()
  const [country, setCountry] = useState('CA')
  const [clickedBookCall, setClickedBookCall] = useState(false)
  const tempUser = useSelector(state => state.temp.userinfo)

  const [provinceList, setProvinceList] = useState(
    canadaProvinces.map(item => ({ label: item.name, value: item.code })),
  )

  const onChangeCountry = value => {
    setCountry(value)
    const dataList = value === 'CA' ? canadaProvinces : usStates
    setProvinceList(
      dataList.map(item => ({ label: item.name, value: item.code })),
    )
    form?.setFieldsValue({ province: dataList[0].code })
  }

  // Defining the onFinish function to handle form submission
  const onFinish = (values) => {
    setFormData({
      ...formData,
      ...values,
    })
    if (clickedBookCall) {
      setCurrent(1)
    } else {
      handleSubmit(values)
    }
  }
  // Using the useEffect hook to set the initial form values when the form instance is available
  useEffect(() => {
    if (form !== null) {
      form.setFieldsValue(formData)
    }
  }, [form, formData])

  return (
    <Form
      layout='vertical'
      onFinish={onFinish}
      scrollToFirstError
      form={form}
      style={{
        textAlign: 'left',
      }}
      className='retail-form w-full phone:max-w-[300px]'
    >
      <Form.Item
        name='name'
        rules={[
          {
            required: true,
            message: 'Please input your full name',
            whitespace: true,
          },
          {
            min: 2,
            message: 'Full name: at least 2 chars.',
          },
        ]}
        className='register-form-item'
      >
        <Input
          className='rounded-full border-1 border-black text-base'
          size='large'
          // suffix={<InputSuffixTip text='Full name is required' />}
          placeholder='Full name'
        />
      </Form.Item>
      <Form.Item
        name='email'
        initialValue={tempUser?.username}
        rules={[
          {
            required: true,
            message: 'Please input your email',
          },
          {
            type: 'email',
            message: 'The input is not a valid email!',
          },
        ]}
        style={{
          color: '#fff',
        }}
        className='register-form-item'
      >
        <Input
          className='rounded-full border-1 border-black text-base'
          size='large'
          // prefix={<VscAccount size={22} />}
          // suffix={<InputSuffixTip text='Must be a valid email' />}
          placeholder='Email'
        />
      </Form.Item>
      <Form.Item
        name='phoneNumber'
        rules={[
          {
            required: true,
            message: 'Please input your phone number!',
          },
          {
            pattern:
              /^(\+\d{1,3}[- ]?)?\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/,
            message: 'The input is not a valid phone number!',
          },
        ]}
      >
        <Input
          size='large'
          className='rounded-full border-black'
          placeholder='Phone number'
          allowClear
        />
      </Form.Item>
      <Form.Item name='storeName'
        rules={[{
          required: true,
          message: 'Please input your store name',
          whitespace: true,
        }]}
      >
        <Input
          size='large'
          className='rounded-full border-black'
          placeholder='Store name'
          allowClear
        />
      </Form.Item>
      <div className='flex items-center gap-2'>
        <Form.Item name='country' initialValue={'CA'} className='flex-2'>
          <Select
            className=' rounded-full border border-black py-[18px]'
            placeholder='Country'
            // bordered={false}
            variant="borderless"
            dropdownStyle={{
              fontSize: '20px',
            }}
            onChange={onChangeCountry}
            options={[
              {
                label: 'Canada',
                value: 'CA',
              },
              {
                label: 'United States',
                value: 'US',
              },
            ]}
          />
        </Form.Item>
        <Form.Item name='province' className='flex-1' initialValue={'AB'}>
          <Select
            size='large'
            // bordered={false}
            variant="borderless"
            className=' rounded-full border border-black py-[18px]'
            placeholder={`${country === 'CA' ? 'Province' : 'State'
              }`}
            style={{ width: '100%' }}
            options={provinceList}
          />
        </Form.Item>
      </div>
      <div
        className='w-full flex flex-col items-center justify-center gap-5'
      >
        <button
          className='uppercase flex w-full cursor-pointer justify-center rounded-full border-2 border-black bg-white p-[6px] text-black hover:bg-slate-200'
          type='submit'
        >
          save my spot and set up later
        </button>
        <button
          className='uppercase flex w-full cursor-pointer justify-center rounded-full border-2 border-fitzba-bright-gold-static bg-fitzba-bright-gold-static p-[6px] text-white hover:bg-white hover:text-fitzba-bright-gold-static'
          type='button'
          onClick={() => {
            setClickedBookCall(true)
            form.submit()
          }}
        >
          book a call now
        </button>
      </div>
    </Form >
  )
}
