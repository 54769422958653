/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * A list of item cards
 *
 * @author Yang Ming
 * @version May 09, 2023
 */
import React, { useState } from 'react'
import ItemCard from './ItemCard'
import ItemCardMobile from './ItemCardMobile'
import { useSelector, useDispatch } from 'react-redux'
import ShoppingCartHook from '@hooks/ShoppingCart.hook'

import {

  setFilterCondition
} from '@reducers/contentReducer'

import LoadingSection from '@components/LoadingSection'
import './ItemListNew.css'

export default function GuessYouLike({ isShowFilterDetail, isMobile, itemsList, isLoading, searchParams }) {
  const dispatch = useDispatch()
  const { addToCart } = ShoppingCartHook()
  const itemsFilter = useSelector(state => state.content.itemsFilter)
  const userinfo = useSelector(state => state.user.userinfo)
  const [isGrid, setIsGrid] = useState(false)

  const keywordsList = itemsFilter?.title?.split(' ') || []

  if (isShowFilterDetail && isMobile) return null

  if (isLoading)
    return <LoadingSection text='Loading Items' />

  return (
    <>
      <div className='flex flex-col items-start justify-between p-5 pb-0 gap-5 md:flex-row md:items-center'>
        <div className='flex justify-center items-center gap-10'>
          <h3 className='text-[20px]'>Guess You Like</h3>
          <button onClick={()=>{}} className='flex w-[100px] h-fit justify-center whitespace-nowrap rounded-full border border-black px-[16px] py-[5px] text-[14px] hover:bg-[#D9D9D9]'>
            Refresh
          </button>
        </div>
      </div>
      <div className='items-content'>
        {(isMobile && isGrid === false) ? (
          <div className='mobile-items'>
            <div className='mobile-items-list'>
              {itemsList?.map(item => (
                <ItemCardMobile
                  key={item._id}
                  item={item}
                  userinfo={userinfo}
                  handleAddToCart={addToCart}
                  keywordsList={keywordsList}
                  searchParams={searchParams}
                  setFilterCondition={setFilterCondition}
                  dispatch={dispatch}
                />
              ))}
            </div>
          </div>
        ) :
          <div className='items-content-list'>
            <div className='grid w-full grid-cols-2 gap-x-4 gap-y-5 sm:gap-x-8 md:grid-cols-4 3xl:grid-cols-5 3xl:gap-x-2'>
              {itemsList?.map(item => (
                <ItemCard
                  key={item._id}
                  item={item}
                  userinfo={userinfo}
                  handleAddToCart={addToCart}
                  keywordsList={keywordsList}
                  searchParams={searchParams}
                />
              ))}
            </div>
          </div>
        }
      </div>
    </>
  )
}
