import React from 'react'

export default function SectionTitle({
  title,
}) {
  return (
    <div className='flex gap-3 items-center mb-5'>
      <h2 className='text-[22px]'>
        {title}
      </h2>
    </div>
  )
}
