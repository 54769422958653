import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setNewStoreFilter } from '@reducers/contentReducer'
import FilterSideSelectedItem from './components/FilterSideSelectedItem'

export default function StoreListFilterSelected() {
    const dispatch = useDispatch()
    const newCategoryList = useSelector(state => state.content.newCategoryList)
    const storeFilter = useSelector(state => state.content.newStoreFilter)
    if (!storeFilter.title &&
        storeFilter.distance < 0 &&
        storeFilter.categories.length === 0)
        return null

    return (
        <div className={`w-full flex text-black shadow py-6 px-10 gap-5`}>
            <button className='underline text-[#818181] text-[14px]' onClick={() =>
                dispatch(setNewStoreFilter({
                    title: '',
                    distance: -1,
                    currentPage: 1,
                    categories: []
                }))
            }>
                Clear all
            </button>
            {storeFilter?.title &&
                <FilterSideSelectedItem
                    label={'Store: ' + storeFilter?.title}
                    value={storeFilter?.title}
                    onClickRemove={() => dispatch(setNewStoreFilter({
                        ...storeFilter,
                        title: '',
                    }))} />
            }
            {storeFilter?.distance > 0 &&
                <FilterSideSelectedItem
                    label={'Up to ' + storeFilter?.distance + ' km'}
                    value={storeFilter?.distance}
                    onClickRemove={() => dispatch(setNewStoreFilter({
                        ...storeFilter,
                        distance: -1,
                    }))} />
            }
            {storeFilter?.categories.length > 0 && storeFilter?.categories.map((category_id, index) => {
                // find the category object
                const category = newCategoryList.find(i => i._id === category_id)
                if (!category)
                    return null
                return <FilterSideSelectedItem
                    key={index}
                    label={category.title}
                    value={category_id}
                    onClickRemove={() => dispatch(setNewStoreFilter({
                        ...storeFilter,
                        categories: storeFilter.categories.filter(i => i !== category_id),
                    }))} />
            })}
        </div>
    )
}
