/**
 * Copyright Fitzba Technologies Inc. 2023
 * This module exports a React component named 'ReviewForm'. This component provides a form for users to submit a review.
 * It includes a rating input (using the 'Rate' component from 'antd'), a title input, and a comment input.
 * 
 * The component takes five props: 'setShowComment', 'formRef', 'handleSubmit', 'setRating', and 'isMobile'.
 * 'setShowComment' is a function that is called when the cancel button is clicked.
 * 'formRef' is a reference to the form.
 * 'handleSubmit' is a function that is called when the form is submitted.
 * 'setRating' is a function that is called when the rating is changed.
 * 'isMobile' is a boolean indicating whether the device is mobile or not.
 * 
 * @author Yang Ming, James Nguyen
 * @version February 13th, 2024
 */

import React from 'react'
import { Form, Input, Rate } from 'antd'

const TextArea = Input.TextArea

export default function ReviewForm({
    setShowComment,
    formRef,
    handleSubmit,
    setRating,
    isMobile
}) {
    return (
        <div className={'relative mt-10 sm:max-w-[550px]'}>
            <h2 className='text-xl tracking-[1px] text-fitzba-black-static'>
                Your Review
            </h2>
            <div className=' flex flex-col'>
                <div className='flex items-center gap-2 py-5'>
                    <label className='font-light'>Rating:</label>
                    <Rate
                        allowHalf
                        onChange={setRating}
                        style={{
                            color: '#000',
                        }}
                    />
                </div>
                <Form onFinish={handleSubmit} ref={formRef}>
                    <Form.Item
                        name='title'
                        rules={[
                            {
                                required: true,
                                message: 'Please input title',
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input
                            placeholder='Title'
                            type='text'
                            className='h-14 w-full rounded-full border border-black text-[15px] outline-none'
                        />
                    </Form.Item>
                    <Form.Item
                        name='comment'
                        className=''
                        rules={[
                            {
                                required: true,
                                message: 'Please input comment',
                                whitespace: true,
                            },
                        ]}
                    >
                        <TextArea
                            placeholder='Review'
                            style={{ height: 140 }}
                            className=' rounded-2xl border border-black text-[15px] outline-none'
                        />
                    </Form.Item>
                    <div className='flex justify-end gap-2 pt-2 sm:justify-start'>
                        <button
                            className='w-full rounded-full border border-black bg-white py-2 px-4 text-center text-black hover:bg-slate-100 sm:max-w-[150px]'
                            onClick={() => {
                                setShowComment(false)
                            }}
                        >
                            CANCEL
                        </button>
                        <button
                            type='submit'
                            className='w-full rounded-full border border-fitzba-bright-gold-static bg-fitzba-bright-gold-static py-2 px-4  text-white hover:bg-fitzba-dark-gold-static sm:max-w-[150px]'
                        >
                            SUBMIT
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    )
}
