/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * Profile is a functional React component that displays a form for the user to view and edit their profile information.
 * The form includes fields for email, first name, last name, phone number, and postal/zip code.
 * The user can also change their password or delete their account.
 * The form data is sent to the server when the form is submitted.
 *
 * @author Yang Ming, James Nguyen
 * @version January 4th, 2024
 */

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import './Profile.css'

import PersonalInfo from './components/PersonalInfo'
import AddressInfo from './components/AddressInfo'
import UserPhoto from './components/UserPhoto'

export default function Profile() {
  const dispatch = useDispatch()
  const userinfo = useSelector(state => state.user.userinfo)
  const navigate = useNavigate()
  if (!userinfo) {
    navigate('/')
  }

  return (
    <div className='my-6 flex flex-col bg-white smsize:p-3'>
      <div className='m-auto w-full max-w-[1024px] flex flex-col gap-5 smsize:w-full'>
        <h1 className='uppercase text-left text-[30px] smsize:text-[20px]'>edit profile</h1>
        <UserPhoto userinfo={userinfo} dispatch={dispatch} />
        <PersonalInfo userinfo={userinfo} dispatch={dispatch} />
        <AddressInfo userinfo={userinfo} dispatch={dispatch} />
      </div>
    </div>
  )
}
