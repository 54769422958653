/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * The Blog component renders a list of blog posts.
 * 
 * It fetches the list of blog posts from the server and displays them in a paginated format.
 * 
 * Each blog post can be clicked to view its full content.
 * 
 * The component also includes a BlogSide component that allows users to filter the blog posts by category.
 * 
 *
 * @author Yang Ming
 * @version October 17th, 2023  
 */

import './Blog.css'
import React, { useEffect } from 'react'
// import Navigation from '@components/Navigation'
import BlogSide from './components/BlogSide'
import BlogItem from './components/BlogItem'
import ReactPaginate from 'react-paginate'
import { useSelector, useDispatch } from 'react-redux'
import { getBlogList, getBlogByTitle } from '@services/api.blog.service'
import Navigation from '@components/Navigation'
import {
  setGotoElement,
  setBlogList,
  setBlogFilter,
  setBlogCurrent,
} from '@reducers/contentReducer'
import BlogView from './components/BlogView'
import { useParams } from 'react-router-dom'

export default function Blog() {
  const dispatch = useDispatch()
  const blogList = useSelector(state => state.content.blogList)

  const { title } = useParams()


 // useEffect hook that fetches the list of blog posts when the component mounts or when the current page or filter types change.
  useEffect(() => {
    async function fetchData() {
      try {
        const result = await getBlogList({
          page: blogList.currentPage,
          types: blogList.filterTypes,
        })
        dispatch(setBlogList(result))
      } catch (err) {
        console.log(err.data?.message || 'get blog list error')
      }
    }
    fetchData()
  }, [dispatch, blogList.currentPage, blogList.filterTypes])

  useEffect(() => {
    if (!title) return
    const fetchData = async () => {
      try {
        const result = await getBlogByTitle(title)
        dispatch(setBlogCurrent(result.data))
      } catch (err) {
        console.log(err.data?.message || 'get blog content error')
      }
    }
    fetchData()
  }, [dispatch, title])


  /**
   * handlePageClick is a function that handles page changes in the pagination.
   * It dispatches an action to set the current page in the blog filter state with the selected page.
   *     
   * @param {Object} e - The page change event.
 */

  const handlePageClick = e => {
    const selected = e.selected
    dispatch(
      setBlogFilter({
        currentPage: selected + 1,
      }),
    )
    dispatch(setGotoElement('blog-list'))
  }

  return (
    <>
      <Navigation
        title='Blogs'
        titleLink='/blog'
        subTitle={blogList?.currentBlog?.title}
      />
      {title && blogList?.currentBlog ? (
        <BlogView currentBlog={blogList?.currentBlog} blogList={blogList} />
      ) : (
        <div className='blog-content'>
          <div className='blog-list' id='blog-list'>
            <div className='border-b p-1'>
              <h3 className='text-left text-[40px]'>Blogs</h3>
            </div>
            <div className='blog-content-list'>
              {blogList.data &&
                blogList.data.map((item, index) => (
                  <BlogItem key={index} index={index} currentRow={item} />
                ))}
            </div>
            {blogList?.totalPage > 1 && (
              <div className='pagination'>
                <ReactPaginate
                  breakLabel='...'
                  nextLabel='>'
                  pageRangeDisplayed={4}
                  onPageChange={handlePageClick}
                  pageCount={blogList.totalPage}
                  previousLabel='<'
                  renderOnZeroPageCount={null}
                  forcePage={blogList.currentpage - 1}
                />
              </div>
            )}
          </div>
          <BlogSide blogList={blogList} dispatch={dispatch} />
        </div>
      )}
    </>
  )
}
