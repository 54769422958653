/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * ItemDetailMobile is a functional React component that displays detailed information about an item on mobile devices.
 * The information includes the item's name, images, and whether it's used.
 * It also includes a link to continue shopping, a popup for reserving the item, and a bar for continuing shopping.
 * The component also displays information about the store that sells the item, reviews of the item, and similar items.
 *
 * @author Yang Ming, James Nguyen
 * @version October 17th, 2023
 */

import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import StoreInfoMobile from './components/StoreInfoMobile'

import ItemInfo from './components/ItemInfo'
import Review from './components/Review'
import { AiOutlineLeft } from 'react-icons/ai'
import ItemHeaderMobile from './components/ItemHeaderMobile'
import { HelmetProvider, Helmet } from 'react-helmet-async'
import { Config } from '@Config'
import ReservePopup from './components/ReservePopup'
import Similars from './components/Similars'
import ItemContinueShippingBar from './components/ItemContinueShippingBar'
import ImageListMobile from './components/ImageListMobile'

/**
 * Displays detailed information about an item on mobile devices.
 *
 * @param {Object} props The properties passed to the component.
 * @returns {JSX.Element} The rendered component.
 */

export default function ItemDetailMobile({
  item,
  opened,
  setOpened,
  userinfo,
  showContinueShoppingBar,
  onClickContinueShopping,
  headerHeight,
  onClickReserve,
  addToCart,
  selectedVariant,
  setSelectedVariant,
}) {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [imageIndex, setImageIndex] = useState(0)

  return (
    <HelmetProvider>
      <ReservePopup item={item} opened={opened} setOpened={setOpened} userinfo={userinfo} selectedVariant={selectedVariant} />
      <Helmet>
        <title>{item?.name} - {item?.business?.name} - {Config.appName}</title>
      </Helmet>
      {showContinueShoppingBar &&
        <ItemContinueShippingBar
          headerHeight={headerHeight}
          item={item}
          onClickContinueShopping={onClickContinueShopping}
        />}
      <div className='item-wrapper xl:pt-[100px]'>
        <div className='item-header xl:hidden'>
          <Link
            onClick={onClickContinueShopping}
            // to='/search' 
            className='button-continue'>
            <AiOutlineLeft /> Continue shopping
          </Link>
        </div>
        <div className='w-full sm:px-20 2xl:px-72'>
          <div className='flex w-full flex-col gap-0 xl:flex-row'>
            <div className='flex flex-1 flex-col items-center gap-5'>
              <ItemHeaderMobile item={item} isMobile={true} />
              <ImageListMobile
                name={item?.name}
                images={item?.images}
                isUsed={item?.isUsed}
                imageIndex={imageIndex}
                setImageIndex={setImageIndex}
              />
            </div>
          </div>
          <ItemInfo
            item={item}
            onClickReserve={onClickReserve}
            isMobile={true}
            imageIndex={imageIndex}
            setImageIndex={setImageIndex}
            selectedVariant={selectedVariant}
            setSelectedVariant={setSelectedVariant}
          />
          <div className='pt-20'>
            <StoreInfoMobile info={item?.business} />
            <div className='mt-5 flex flex-col px-4 py-3 sm:flex-row'>
              <Review item={item} isMobile={true} />
              <Similars id={item?._id} userinfo={userinfo} addToCart={addToCart} />
            </div>
          </div>
        </div>
      </div>
    </HelmetProvider>
  )
}
