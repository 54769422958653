
import './Filter.css'
import React from 'react'
import { useDispatch } from 'react-redux'
import {
  setFilterDistance,
} from '@reducers/contentReducer'
import CollectionFilterSearch from './CollectionFilterSearch'
// import StoreFilterCategory from './StoreFilterCategory'
// import StoreFilterSearch from './StoreFilterSearch'
import { SlArrowDown, SlArrowUp } from 'react-icons/sl'

export default function CollectionFilter({
  isMobile
}) {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = React.useState(!isMobile)

  return (
    <div className='border-r border-[#eee] smsize:shadow smsize:border-r-0 smsize:border-b smsize:pb-3'>
      <div className='mx-auto w-[300px] min-h-screen px-10 font-lexend text-[15px] smsize:px-5 smsize:w-full smsize:min-h-fit'>
        <div className='mt-7 smsize:mt-5 flex items-center justify-between'>
          <h3 className='text-[18px] font-[500] text-black'>FILTERS</h3>
          {isOpen ? <SlArrowUp size={16} onClick={() => setIsOpen(!isOpen)} className='cursor-pointer' />
            : <SlArrowDown size={16} onClick={() => setIsOpen(!isOpen)} className='cursor-pointer' />
          }
        </div>
        {isOpen &&
          <>
            <CollectionFilterSearch
              dispatch={dispatch}
              setFilterDistance={setFilterDistance}
              isMobile={isMobile}
            />
            {/* <StoreFilterLocation
              dispatch={dispatch}
              setFilterDistance={setFilterDistance}
              isMobile={isMobile}
            />
            <StoreFilterCategory /> */}
          </>
        }
      </div>
    </div>
  )
}
