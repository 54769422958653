import React, { useState, useEffect } from 'react'
import { MdArrowForwardIos } from "react-icons/md"
import { useSelector, useDispatch } from 'react-redux'
import { setNewStoreFilter } from 'reducers/contentReducer'

export default function StoreFilterSearch({
    itemsFilter,
    isMobile
}) {
    const dispatch = useDispatch()
    const storeFilter = useSelector(state => state.content.newStoreFilter)

    const [input, setInput] = useState(storeFilter?.title || '')

    useEffect(() => {
        setInput(storeFilter?.title)
    }, [storeFilter?.title])

    return (
        <div className='filter-item mt-2' style={{
            overflow: 'hidden',
        }}>
            <div className='filter-item-header'>
                <div className='filter-item-header-title'>
                    <h4 className='cursor-pointer text-black'>Store</h4>
                </div>
            </div>
            <div className='flex flex-row items-center gap-1 font-light text-[16px] text-black'>
                <input
                    className='h-9 border rounded-md bg-white px-[5px] py-[10px] text-base text-black'
                    type='text'
                    name='store_name'
                    placeholder='Store name'
                    value={input}
                    onChange={(e) => {
                        setInput(e.target?.value)
                    }}
                    onKeyDown={k => {
                        if (k.key === 'Enter') {
                            dispatch(setNewStoreFilter({
                                ...storeFilter,
                                title: input
                            }))
                        }
                    }}
                />
                <button
                    className='flex h-7 w-7 items-center justify-center rounded-md bg-white hover:bg-[#D9D9D9] text-base text-black'
                    onClick={() => {
                        dispatch(setNewStoreFilter({
                            ...storeFilter,
                            title: input
                        }))
                    }}
                >
                    <MdArrowForwardIos />
                </button>
            </div>
        </div>
    )
}
