/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * EmployeeVerifyForm is a functional React component that handles the employee verification process.
 * It renders a form for employees to confirm their invitation by entering their first name, last name, password, and confirm password.
 * It also handles form submission, including validation of the entered password and confirmation password.
 * Upon successful verification, it sets the `isVerified` state to true.
 *
 * @author Yang Ming
 * @version December 12th, 2023
 */

import React from 'react'
import { Form, Input, message } from 'antd'
import { VscEye } from 'react-icons/vsc'
import { RxEyeClosed } from 'react-icons/rx'
import { BiLockAlt } from 'react-icons/bi'
import { AiOutlineMail } from "react-icons/ai"
import { FaRegUserCircle } from "react-icons/fa"
import { checkEmployeeVerify } from '@services/api.business.service'

/**
 * Renders a form for employees to confirm their invitation.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.employee - The employee to be verified.
 * @param {Function} props.setIsVerified - The function to set the `isVerified` state.
 */

export default function EmployeeVerifyForm({
  employee,
  setIsVerified
}) {
  const [form] = Form.useForm()
  const onFinish = async values => {
    if (values.password !== values.confirmPassword) {
      message.error('The confirm password does not match the password')
      return
    }
    try {
      console.log('onFinish', values)
      await checkEmployeeVerify(employee?._id, {
        ...values,
        employee_id: employee?._id,
        business_id: employee?.business_id || employee.business._id,
      })
      setIsVerified(true)
    } catch (err) {
      console.log(err)
      message.error(`Error: ${err?.data?.message || 'Something went wrong'}`)
    }
  }

  React.useEffect(() => {
    if (form) {
      form.setFieldsValue({
        username: employee?.email || '',
        firstName: employee?.firstName || '',
        lastName: employee?.lastName || '',
      })
    }
  }, [employee, form])

  return (
    <Form
      // forceRender
      layout='vertical'
      onFinish={onFinish}
      scrollToFirstError
      form={form}
      style={{
        width: '100%',
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      className='mt-10'
    >
      <div className='register-main-form' style={{ maxWidth: 500 }}>
        <h3 className='text-[30px] font-medium'>Accept an invitation</h3>
        <h4 className='text-[18px] font-light'>
          The last step to accept the invitation from <strong style={{
            color: 'red'
          }}>{employee.businessName}</strong>
        </h4>
        <div className='register-input-line'>
          <Form.Item
            name='username'
            style={{
              color: '#fff',
            }}
            className='register-form-item'
            value="yang@fitzba.com"
          >
            <Input
              className='rounded-full border-black'
              prefix={<AiOutlineMail size={22} />}
              size='large'
              placeholder='Email'
              disabled={true}
            />
          </Form.Item>
        </div>
        <div className='register-input-line' style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '20px'
        }}>
          <Form.Item
            name='firstName'
            style={{
              color: '#fff',
            }}
            rules={[
              {
                required: true,
                message: 'Please input your first name',
                whitespace: true,
              }]}
            className='register-form-item'
          >
            <Input
              className='rounded-full border-black'
              prefix={<FaRegUserCircle size={22} />}
              size='large'
              placeholder='First Name'
            />
          </Form.Item>
          <Form.Item
            name='lastName'
            rules={[
              {
                required: true,
                message: 'Please input your last name',
                whitespace: true,
              }]}
            className='register-form-item'
          >
            <Input
              className='rounded-full border-black'
              prefix={<FaRegUserCircle size={22} />}
              size='large'
              placeholder='Last Name'
            />
          </Form.Item>
        </div>
        <div className='register-input-line' style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '20px'
        }}>
          <Form.Item
            name='password'
            rules={[
              {
                required: true,
                message: 'Please input your password',
              },
              {
                max: 20,
                min: 6,
                message: 'Password: 6-20 chars.',
              },
            ]}
            className='register-form-item'
          >
            <Input.Password
              className='rounded-full border-black'
              prefix={<BiLockAlt size={22} />}
              size='large'
              iconRender={visible => (visible ? <VscEye /> : <RxEyeClosed />)}
              placeholder='Password'
            />
          </Form.Item>
          <Form.Item
            name='confirmPassword'
            dependencies={['password']}
            // hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error('Passwords do not match'))
                },
              }),
            ]}
            className='register-form-item'
          >
            <Input.Password
              className='rounded-full border-black'
              prefix={<BiLockAlt size={22} />}
              size='large'
              iconRender={visible => (visible ? <VscEye /> : <RxEyeClosed />)}
              placeholder='Confirm password'
            />
          </Form.Item>
        </div>
        <div
          style={{
            marginTop: 24,
          }}
          className='flex items-center justify-center gap-2'
        >
          <button type='submit' className='flex w-full cursor-pointer justify-center rounded-full border-2 border-fitzba-bright-gold-static bg-fitzba-bright-gold-static p-[6px] text-white hover:bg-white hover:text-fitzba-bright-gold-static px-5'>
            Confirm
          </button>
        </div>
      </div>
    </Form>
  )
}
