/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * DemoFinal is a functional React component that displays a success message after a user has booked a demo.
 * It informs the user to check their email for further communication.
 * It also provides a link to return to the home page.
 *
 * @author Yang Ming
 * @version September 1st, 2023
 */

import React from 'react'
import { Link } from 'react-router-dom'

function DemoFinal() {
  return (
    <div>
      <h1 className='text-[38px] font-medium uppercase flex w-full items-center justify-center'>Thank you for<br />booking a demo!</h1>
      <div className='my-10 text-[22px] font-light'>Be sure to check your email. We will be in touch</div>
      <div className='flex w-full items-center flex-col gap-5'>
        <Link
          to='/'
          className='flex w-[300px] h-[40px] cursor-pointer justify-center rounded-full border-2 border-fitzba-bright-gold-static bg-fitzba-bright-gold-static p-[6px] text-white hover:bg-white hover:text-fitzba-bright-gold-static'
        >
          RETURN TO HOME
        </Link>
      </div>
    </div>
  )
}

export default DemoFinal
