/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * Item is a functional React component that renders a single item
 * in the list view, with an image, title, rating, price, and other details.
 *
 * @author Yang Ming
 * @version June 07, 2023
 */
import React from 'react'
import { getShortString, getDistance } from '@utils/Helper'
import ItemPrice from '../../zearch/components/ItemPrice'
import MyImage from '@components/MyImage'
import { useSelector } from 'react-redux'
import ItemRating from '../../zearch/components/ItemRating'
import ItemLiveInventoryIcon from '../../zearch/components/ItemLiveInventoryIcon'

export default function SelectableItemCard({
  item,
  selectedList,
  onClick
}) {
  const location = useSelector((state) => state.user.location)
  return (
    <div onClick={() => onClick(item._id)} className={` cursor-pointer flex w-full justify-center font-lexend md:w-fit ${selectedList.includes(item._id) ? 'border p-1 rounded-xl border-black' : ''}`}>
      <div className='relative overflow-hidden rounded-lg'>
        <div className='relative w-[130px] xs:w-[150px] xl:w-[180px] 2xl:w-full'>
          <div className='h-[130px] w-[130px] overflow-hidden rounded-lg border-2 border-black border-opacity-10 xs:h-[150px] xs:w-[150px] xl:h-[180px] xl:w-[180px] '>
            <MyImage
              src={
                item?.images && item.images.length > 0
                  ? item.images[0]
                  : '/item-default.png'
              }
              alt={item?.name}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                mixBlendMode: 'multiply',
              }}
            />
          </div>
        </div>
        <div className='w-[130px] py-2 xs:w-[150px] xl:w-[180px]'>
          <h4 className=' relative mb-[3px] h-[30px] text-[14px] font-lexend leading-5 pr-3'>
            {getShortString(item?.name, 50)}
          </h4>
          {item?.brand ? (
            <p
              className='mt-4 flex w-full justify-between truncate text-[11px] text-[#727272]'
            >
              <span className=' uppercase'>{item?.brand?.title}</span>
            </p>
          ) : (
            <p className='my-3 h-0' />
          )}

          <div className='mb-1 max-w-full truncate text-[10px] text-[#727272]'>
            <div
              title={item?.name}
              className=' uppercase'
            >
              {item?.business?.name}
            </div>
          </div>
          <p className='mb-1 truncate text-xs text-black'>
            <span className='text-[10px] font-light'>
              {getDistance(item?.distance, location?.country === 'US')?.toUpperCase()}
            </span>
          </p>
          <div className='product-item-info-price gap-1'>
            <ItemPrice
              originalPrice={item?.originalPrice}
              salePrice={item?.salePrice}
            />
          </div>
          <div className='flex gap-1 mt-2 text-[10px] font-light text-[#0B6186] whitespace-nowrap'>
            <div
              className='bg-[#E4F2F3] rounded-sm p-1'
            >
              pick-up
            </div>
            {item?.business?.isReserve && (
              <div
                className='bg-[#E4F2F3] rounded-sm p-1'
              >
                reservation
              </div>
            )}
          </div>
          {item?.review?.totalCount > 0 && (
            <ItemRating
              review={item?.review}
            />
          )}
        </div>
      </div>
    </div>
  )
}
