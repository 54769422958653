import React from 'react'

export default function EditProfileSection({
    title,
    isShowEdit,
    onClickEdit,
    contents
}) {
    return (
        <div className=' rounded-xl border border-[#E3E3E3] p-4 flex flex-col gap-3'>
            <div className='w-full flex justify-between items-center'>
                <h3 className='text-[25px] smsize:text-[15px]'>{title}</h3>
                {isShowEdit &&
                    <button onClick={onClickEdit} className=' rounded-full border border-black px-4 h-[30px] text-[13px] smsize:text-[11px]'>
                        edit
                    </button>
                }
            </div>
            {contents}
        </div>
    )
}
