/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * tempReducers.js defines a Redux slice for temporary user data.
 * 
 * @author Yang Ming, James Nguyen
 * @version December 19th, 2023
 */

import { createSlice } from '@reduxjs/toolkit'

const tempSlice = createSlice({
  name: 'temp',
  initialState: {
    userinfo: null,
    backToRoute: '',
    isGoogle: false
  },
  reducers: {

    // Sets temporary user information
    setTempUserinfo: (state, action) => {
      state.userinfo = action.payload
      localStorage.setItem('userinfo-temp', JSON.stringify(action.payload))
    },
    // Sets the route to go back to
    setBackToRoute: (state, action) => {
      state.backToRoute = action.payload
    },
    // Sets whether the user is using Google
    setIsGoogle: (state, action) => {
      state.isGoogle = action.payload
    }
  },
})
export const { setTempUserinfo, setBackToRoute, setIsGoogle } = tempSlice.actions

export default tempSlice.reducer
