/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * A list of blog APIs to use throughout the app
 * 
 * @author Yang Ming
 * @version Sept 29, 2023
 */
import { Config } from '../Config'
import axios from 'axios'

const httpClient = axios.create({
    baseURL: Config.apiUrl + Config.apiPrefix,
    timeout: 60 * 1e3,
})

httpClient.interceptors.request.use(config => {
    // Perform logic before sending request
    config.headers['authorization'] = `${Config.apiKey} ` + localStorage.getItem('token')
    return config
})

/**
 * Fetches a list of blogs.
 *
 * @param {Object} params - The parameters for the request.
 * @returns {Promise} A promise that resolves to the response data.
 * @throws {Error} If the request fails, an error is thrown.
 */
export async function getBlogList(params) {
    try {
        const res = await httpClient.get(`/blog`, {
            params: params
        })
        return res.data
    } catch (error) {
        throw error.response
    }
}

// export async function getBlogContent(id) {
//     try {
//         const res = await httpClient.get(`/blog/${id}`)
//         return res.data
//     } catch (error) {
//         throw error.response
//     }
// }

/**
 * Fetches a blog by its title.
 *
 * @param {string} title - The title of the blog to fetch.
 * @returns {Promise} A promise that resolves to the response data.
 * @throws {Error} If the request fails, an error is thrown.
 */
export async function getBlogByTitle(title) {
    try {
        const res = await httpClient.get(`/blog/${title}`)
        return res.data
    } catch (error) {
        throw error.response
    }
}