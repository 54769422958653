import React from 'react'

export default function PriceTable() {
  return (
    <div>
      <div className='mt-[60px] mb-[20px] flex flex-col items-center justify-center'>
        <h1 className='text-[40px]'>
          Join Fitzba to showcase your store's inventories.
        </h1>
        <p>
          Note: It will not be charged until 1 month after the inventories of
          your store connection.
        </p>
      </div>
      <stripe-pricing-table
        pricing-table-id='prctbl_1P6yNRDoRMcNDqjVf0ozlicZ'
        publishable-key='pk_live_51OwSGoDoRMcNDqjVlFUCkYzE1y1TS66iremCVO9tNKcprA30SzO3Y0lSyBkhBbRKyY9xLzI66V22LIEXb7DhUpbl00602J3tE9'
      ></stripe-pricing-table>
    </div>
  )
}
