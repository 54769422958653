/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * The ContentDataHook is a custom hook that fetches content data based on a provided code.
 * It uses the useQuery hook from the react-query library to fetch the data.
 * The hook returns an object containing the fetching status, any error that occurred, and the fetched data.
 *
 * @author Yang Ming
 * @version November 7th, 2023
 */

import { useQuery } from '@tanstack/react-query'
import { getContentByCode } from '@services/api.service'

/**
 * ContentDataHook is a custom hook that fetches content data based on a provided code.
 * 
 * @param {string} code - The code of the content to fetch.
 * @returns {Object} The fetching status, any error that occurred, and the fetched data.
 */

export default function ContentDataHook(code) {

  const {
    isPending,
    error,
    data,
  } = useQuery({
    queryKey: [code, code],
    queryFn: () => getContentByCode(code),
  })

  // if (isPending) return <Loading />
  // console.log('about', data)
  // if (error) return 'An error has occurred: ' + error.message

  return { isPending, error, data }
}
