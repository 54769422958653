/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 *  Get the app pop-up window
 *
 * @author Yang Ming
 * @version June 09, 2023
 */

import React, { useState } from 'react'
import { Modal } from 'antd'
import { Form, Input, message } from 'antd'
import { VscAccount } from 'react-icons/vsc'
import GetMoreInfoConfirm from './GetMoreInfoConfirm2'
import { addMessage } from '@services/api.service'

export default function GetMoreInfoForm({
  isMobile,
  userinfo,
  isOpen,
  setIsOpen,
}) {
  const [isShowConfirm, setIsShowConfirm] = useState(false)
  const [isSending, setIsSending] = useState(false)

  async function handleSubmit(values) {
    // console.log(values)
    if (!values.email && !values.phoneNumber) {
      message.error('Please enter your email or phone number.')
      return
    }
    setIsSending(true)
    try {
      await addMessage({
        ...values,
        type: 'retail-page',
      })
      message.success('Submitted successfully.')
      // close current form
      setIsOpen(false)
      // open confirm form
      setIsShowConfirm(true)
    } catch (error) {
      console.log(error)
      message.error('Failed to submit, please try again later.')
    }
    setIsSending(false)
  }

  return (
    <>
      <GetMoreInfoConfirm
        isMobile={isMobile}
        isShowConfirm={isShowConfirm}
        setIsShowConfirm={setIsShowConfirm}
      />
      <Modal
        // title="Get the app"
        open={isOpen}
        onCancel={() => {
          setIsOpen(false)
        }}
        footer={null}
        forceRender
        destroyOnClose={false}
        width={400}
        zIndex={99999}
      >
        <div className='flex w-full flex-col items-center justify-center'>
          <div className='flex flex-col items-center justify-center gap-3 py-5'>
            <img
              src='/fitzba-ICON-WORD-BLACK.png'
              width={200}
              alt='Fitzba.com'
            />
            <h1 className='text-[30px]'>GET MORE INFO</h1>
            <p className='text-[20px] font-light'>
              We’ll e-mail you more info.
            </p>
          </div>
          <div className='flex w-full items-center justify-center'>
            <Form
              name='get_more_info'
              // className='login-form'
              onFinish={handleSubmit}
              style={{
                textAlign: 'left',
                width: '100%',
              }}
            >
              <Form.Item
                name='email'
                initialValue={userinfo?.username}
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid email',
                  },
                ]}
                hasFeedback
              >
                <Input
                  size='large'
                  className='rounded-full border-black'
                  prefix={<VscAccount size={22} />}
                  placeholder='Email'
                  allowClear
                />
              </Form.Item>
              <Form.Item name='name' hasFeedback>
                <Input
                  size='large'
                  className='rounded-full border-black'
                  placeholder='Your name'
                  allowClear
                />
              </Form.Item>
              <Form.Item name='storeName' hasFeedback>
                <Input
                  size='large'
                  className='rounded-full border-black'
                  placeholder='Business name'
                  allowClear
                />
              </Form.Item>
              <Form.Item
                name='phoneNumber'
                rules={[
                  {
                    required: true,
                    message: 'Please input your phone number!',
                  },
                  {
                    pattern:
                      /^(\+\d{1,3}[- ]?)?\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/,
                    message: 'The input is not a valid phone number!',
                  },
                ]}
                hasFeedback
              >
                <Input
                  size='large'
                  className='rounded-full border-black'
                  placeholder='Phone number'
                  allowClear
                />
              </Form.Item>
              <Form.Item name='trackInventory' hasFeedback>
                <Input
                  size='large'
                  className='rounded-full border-black'
                  placeholder='How to you track inventory?'
                  allowClear
                />
              </Form.Item>
              <Form.Item className='w-full text-left text-fitzba-bright-gold-static'>
                <div className='flex flex-row gap-2'>
                  <button
                    type='button'
                    size='large'
                    className='mt-2 flex w-full flex-1 cursor-pointer items-center justify-center rounded-full  border-2 border-black bg-white text-base text-black hover:bg-slate-300'
                    onClick={() => {
                      setIsOpen(false)
                    }}
                  >
                    BACK
                  </button>
                  <button
                    disabled={isSending}
                    type='submit'
                    size='large'
                    className='mt-2 flex w-full flex-[2] cursor-pointer justify-center rounded-full  border-2 border-fitzba-bright-gold-static bg-fitzba-bright-gold-static p-[6px] text-base text-white hover:bg-white hover:text-fitzba-bright-gold-static'
                  >
                    {/* SUBMIT */}
                    {isSending ? 'SENDING' : 'SUBMIT'}
                  </button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  )
}
