/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * Background for the dropdown menu
 *
 * @author james Nguyen
 * @version May 09, 2023
 */

import React from 'react'

/**
 *
 * @param onClick A function to change the state of 'open'
 * @param open    a boolean to determined if the background
 *                should be display or not
 * @returns Renders the backplate of the dropdown
 */
const DropdownBackground = ({ onClick, open }) => (
  <div
    className={`fixed z-10 h-full w-full ${open ? '' : 'hidden'}`}
    onClick={onClick}
  />
)

export default DropdownBackground
