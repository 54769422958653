/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * This file contains the HeaderShoppingCartIcon component, which is responsible for displaying the shopping cart icon in the header.
 * The component uses Redux's useDispatch and useSelector hooks for state management and services from api.shoppingCart.service.
 * The component fetches the shopping cart data from the server and updates the Redux store with the fetched data.
 * The fetch operation is performed whenever the shoppingCart.isRefresh flag is set to true.
 * The component renders a shopping cart icon with a link to the shopping cart list. If there are selected items in the shopping cart, it also displays the number of selected items.
 *
 * @author Yang Ming
 * @version January 4th, 2024
 */

import React from 'react';

const regexList = [
  { name: "Email", regex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/ },
  { name: "Phone", regex: /^\d{3}-\d{3}-\d{4}$/ },
  { name: "ZIP Code", regex: /^\d{5}(-\d{4})?$/ },
  { name: "Postal Code", regex: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/ }
];

function InputBar({ label, placeholder, value, name, type = 'bar', onChange }) {
  const isValid = (inputValue, inputType) => {
    const regexObj = regexList.find(item => item.name === inputType);
    if (regexObj) {
      return regexObj.regex.test(inputValue);
    }
    return true; // If input type not found in regexList, assume it's valid
  };

  const handleChange = event => {
    const { value: inputValue } = event.target;
    const inputIsValid = isValid(inputValue, name);
    if (inputIsValid) {
      onChange(event);
    }
  };

  return (
    <div className='rounded-full flex flex-row items-start justify-start w-full h-fit border-fitzba-black-static border-2 py-[5px] px-2 gap-1 text-[15px]'>
      {label && (`${label}: `)}
      {type === 'bar' && (
        <input
          type='text'
          name={name}
          value={value}
          className='outline-none focus:ring-0 w-full font-light text-[15px]'
          onChange={handleChange}
          placeholder={placeholder}
        />
      )}
      {type === 'box' && (
        <textarea
          type='text'
          name={name}
          value={value}
          className='outline-none resize-none p-0 border-none focus:ring-0 w-full text-[15px]'
          onChange={handleChange}
          placeholder={placeholder}
        />
      )}
    </div>
  );
}

export default InputBar;
