import React from 'react'
import FavouriteButton from './FavoriteButton'


export default function NoFound({
    text,
    btnText,
    btnClick
}) {
    return (
        <div className='w-full h-[100px] flex flex-col gap-3 justify-center items-center'>
            <h4>{text}</h4>
            {btnText &&
                <FavouriteButton
                    text={btnText}
                    onClick={btnClick}
                />
            }
        </div>
    )
}
