import React from 'react'

export default function PolicyContent({ content, h3id }) {
    return (
        <div className='policy-content-item'>
            <h3 id={h3id}>{content?.title}</h3>
            <p dangerouslySetInnerHTML={{ __html: content?.content }}></p>
        </div>
    )
}
