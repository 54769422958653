/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * Loading Component, used for loading page.
 * 
 * @author Dean Yang
 * @version November 15th, 2023 
 */

import React from 'react'
// import { Spin } from 'antd'
import LoadingSpin from './LoadingSpin'


export default function LoadingSection({
    height,
    text,
    size
}) {
    return (
        <div className='flex items-center justify-center'
            style={{ height: height || '300px' }}>
            <LoadingSpin text={text} size={size} />
        </div>
    )
}
