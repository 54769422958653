import React from 'react'
import { Link } from 'react-router-dom'

export default function DropdownMenuItem({
    text,
    link,
    onClick = () => { }
}) {
    return (
        <Link to={link} onClick={onClick} className='flex items-center gap-2'>
            <img src="/images/dot.png" alt={text} />
            <h4>{text}</h4>
        </Link>
    )
}
