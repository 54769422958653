/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * index.js, the contentReducer and userReducer
 * @author Dean Yang
 * @version May 9, 2023
 */
import { combineReducers } from 'redux'
import contentReducer from './contentReducer'
import userReducer from './userReducer'
import storeReducer from './storeReducer'
import tempReducer from './tempReducer'

// combine two reducers of content
export const allReducers = combineReducers({
    content: contentReducer,
    user: userReducer,
    store: storeReducer,
    temp: tempReducer
})

export default allReducers