import React, { useEffect, useState } from 'react'
import {
  Form,
  Input,
  message,
  Select,
  // Radio,
  // Checkbox,
  // InputNumber,
  // Checkbox,
} from 'antd'
import { useSelector } from 'react-redux'
import { canadaProvinces, usStates } from '@data/provinces.data'
// import { isCreditCardValid, isExpiryDateValid, isCVCValid } from '@utils/Helper'
import { paymentCreditCard } from '@services/api.user.service'
import { MdCheckCircle } from 'react-icons/md'
import StatementModal from './components/StatementModal'
import { Link } from 'react-router-dom'
import { contentCode } from '@data/contentCode.data'
import { formatCurrency } from '@utils/Helper'
import './Payment.css'

export default function GiftGuide() {
  const [formRef] = Form.useForm()
  const [provinceList, setProvinceList] = useState(
    canadaProvinces.map(item => ({ label: item.name, value: item.code })),
  )
  const [country, setCountry] = useState('CA')

  const isMobile = useSelector(state => state.content.isMobile)
  const [isOpen, setIsOpen] = useState(false)
  const [isAgree, setIsAgree] = useState(true)
  // const [isSuccess, setIsSuccess] = useState(false)
  const [price, setPrice] = useState(149.0)
  const [isMember, setIsMember] = useState(false)
  const [isCommercial, setIsCommercial] = useState(false)

  // const plan = planList.find(item => item.type === planType)

  useEffect(() => {
    if (formRef) {
      // setIsSuccess(false)
      formRef.setFieldsValue({
        country: 'CA',
        province: canadaProvinces[0].code,
      })
      const giftGuide = localStorage.getItem('gift-guide')
      if (giftGuide) {
        const data = JSON.parse(giftGuide)
        console.log('giftGuide', data)
        formRef.setFieldsValue(data)
        setCountry(data.country)
        const dataList = data.country === 'CA' ? canadaProvinces : usStates
        setProvinceList(
          dataList.map(item => ({ label: item.name, value: item.code })),
        )
        setPrice(data?.isMember ? 99.0 : 149.0)
        setIsMember(data?.isMember || false)
        setIsCommercial(data?.isCommercialStorefront || false)
      }
    }
  }, [formRef])

  const onChangeCountry = value => {
    setCountry(value)
    const dataList = value === 'CA' ? canadaProvinces : usStates
    setProvinceList(
      dataList.map(item => ({ label: item.name, value: item.code })),
    )
    formRef?.setFieldsValue({ province: dataList[0].code })
  }

  // const checkCardNumber = async (_, cardNumber) => {
  //   if (!cardNumber) {
  //     return Promise.resolve()
  //   }
  //   // return true
  //   if (!isCreditCardValid(cardNumber)) {
  //     throw new Error('Card number is invalid')
  //   }
  // }

  // const checkExpireDate = async (_, expireDate) => {
  //   if (!expireDate) {
  //     return Promise.resolve()
  //   }
  //   if (expireDate.length < 4 || expireDate.length > 5) {
  //     throw new Error('Expire date is invalid')
  //   }
  //   if (!isExpiryDateValid(expireDate)) {
  //     throw new Error('Expire date is invalid')
  //   }
  // }

  // const checkCvv = async (_, cvv) => {
  //   if (!cvv) {
  //     return Promise.resolve()
  //   }
  //   if (!isCVCValid(cvv)) {
  //     throw new Error('CVV code is invalid')
  //   }
  // }

  const onFinish = async values => {
    if (!isAgree) {
      return message.error('Please agree the Holiday Gift Guide T & C.')
    }
    try {
      // TODO: send the payment details to the server
      console.log('values', values)
      const params = {
        ...values,
        isMember: isMember,
        planType: 'gift-guide',
        planAmount: isMember ? 99.0 : 149.0,
        client_reference_id: values.email,
        isCommercialStorefront: isCommercial,
      }
      const res = await paymentCreditCard(params)
      if (res?.stripe_link) {
        window.location.href = res?.stripe_link
      }
      localStorage.setItem('gift-guide', JSON.stringify(params))
      // message.success('Submitted successfully.')
      // setIsSuccess(true)
    } catch (err) {
      console.error('onFinish', err)
      message.error('Error: ' + err?.data?.message)
    }
  }

  // if (isSuccess) {
  //   return (
  //     <div className='flex w-full flex-col items-center justify-center gap-[10px] py-[100px] px-[10px]'>
  //       <h3 className='text-[35px]'>SUBMITTED SUCCESSFULLY</h3>
  //       <MdCheckCircle size={90} color='#E4B465' />
  //     </div>
  //   )
  // }

  return (
    <div className='m-auto w-[960px] p-0 smsize:w-full smsize:p-3'>
      {isOpen && (
        <StatementModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          isMobile={isMobile}
          content_code={contentCode.GIFT_GUIDE_STATEMENT}
        />
      )}
      <div className='pb-[100px] text-left'>
        <h2 className='py-[40px] text-[40px] smsize:py-[20px] smsize:text-[22px]'>
          HOLIDAY GIFT GUIDE SPECIAL
        </h2>
        <Form
          form={formRef}
          className='flex w-full flex-wrap items-start justify-center gap-[100px] smsize:flex-col smsize:gap-4'
          onFinish={onFinish}
          style={{
            textAlign: 'left',
            width: '100%',
          }}
        >
          <div className='flex-1'>
            <h3 className='mb-2 text-[22px] font-[300]'>
              Offer ends November 8th
            </h3>
            <p>includes 3 months business feature on Fitzba.com</p>
            <h4 className='mb-2 text-[35px]'>
              <span className='text-[24px]'>CA</span>
              {isMember ? <>
                $99.00 
                <del className='text-[20px] text-gray-500 pl-2'>$149.00</del>
              </> : '$149.00'}
            </h4>
            <label className='mb-3 flex cursor-pointer items-start justify-center gap-2'>
              <input
                type='checkbox'
                checked={isMember}
                className='custom-checkbox mt-1'
                onChange={e => {
                  setPrice(e.target.checked ? 99.0 : 149.0)
                  setIsMember(e.target.checked)
                }}
              />
              <span className='text-[16px]'>
                I am a member of Cochrane & District Chamber of Commerce?
              </span>
            </label>
            <div className='flex flex-col gap-3 pl-10 text-[16px]'>
              <div className='flex items-center justify-between border-b border-[#D9D9D9] leading-10'>
                <h4>Subtotal</h4>
                <p>$149.00</p>
              </div>
              {isMember && (
                <div className='flex items-center justify-between border-b border-[#D9D9D9] leading-10'>
                  <h4>Discount</h4>
                  <p>-$50.00</p>
                </div>
              )}
              <div className='flex items-center justify-between leading-10'>
                <h4>Total due</h4>
                <p>{formatCurrency(isMember ? 99.0 : 149.0)}</p>
              </div>
            </div>
          </div>
          <div className='flex-1'>
            <h3 className='mb-2 text-[22px] font-[300]'>
              Business Information
            </h3>
            <Form.Item
              name='email'
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid email',
                },
                {
                  required: true,
                  message: 'Please input your email',
                },
              ]}
              hasFeedback
            >
              <Input
                size='large'
                className='rounded-full border-black'
                placeholder='Email'
                allowClear
              />
            </Form.Item>
            <Form.Item
              name='storeName'
              rules={[
                {
                  required: true,
                  message: 'Please input business name',
                },
              ]}
              hasFeedback
            >
              <Input
                size='large'
                className='rounded-full border-black'
                placeholder='Business Name'
                allowClear
              />
            </Form.Item>
            <Form.Item
              name='phoneNumber'
              rules={[
                {
                  required: true,
                  message: 'Please input store phone number',
                },
              ]}
              hasFeedback
            >
              <Input
                size='large'
                className='rounded-full border-black'
                placeholder='Phone Number'
                allowClear
              />
            </Form.Item>
            <div className='flex items-center gap-2'>
              <Form.Item
                name='firstName'
                rules={[
                  {
                    required: true,
                    message: 'Please input your first name',
                  },
                ]}
                hasFeedback
              >
                <Input
                  size='large'
                  className='rounded-full border-black'
                  placeholder='First Name'
                  allowClear
                />
              </Form.Item>
              <Form.Item
                name='lastName'
                rules={[
                  {
                    required: true,
                    message: 'Please input last name',
                  },
                ]}
                hasFeedback
              >
                <Input
                  size='large'
                  className='rounded-full border-black'
                  placeholder='Last Name'
                  allowClear
                />
              </Form.Item>
            </div>
            <div className='flex items-center gap-2'>
              <Form.Item name='country' initialValue={'CA'} className='flex-1'>
                <Select
                  className='rounded-full border border-black py-[18px]'
                  placeholder='Country'
                  // bordered={false}
                  variant='borderless'
                  dropdownStyle={{
                    fontSize: '20px',
                  }}
                  onChange={onChangeCountry}
                  options={[
                    {
                      label: 'Canada',
                      value: 'CA',
                    },
                    {
                      label: 'United States',
                      value: 'US',
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item
                className='flex-1'
                name='postalCode'
                rules={[
                  {
                    required: true,
                    message: 'Please input postal code',
                  },
                ]}
                hasFeedback
              >
                <Input
                  size='large'
                  className='rounded-full border-black'
                  placeholder={`${country === 'CA' ? 'Postal' : 'Zip'} Code`}
                  allowClear
                />
              </Form.Item>
            </div>
            <div className='flex items-center gap-2'>
              <Form.Item name='province' className='flex-1'>
                <Select
                  size='large'
                  // bordered={false}
                  variant='borderless'
                  className='rounded-full border border-black py-[18px]'
                  placeholder={`${
                    country === 'CA' ? 'Select a Province' : 'Select a State'
                  }`}
                  style={{ width: '100%' }}
                  options={provinceList}
                />
              </Form.Item>
              <Form.Item
                className='flex-1'
                name='city'
                rules={[
                  {
                    required: true,
                    message: 'Please input city',
                  },
                ]}
                hasFeedback
              >
                <Input
                  size='large'
                  className='rounded-full border-black'
                  placeholder='City'
                  allowClear
                />
              </Form.Item>
            </div>
            <Form.Item
              name='address'
              rules={[
                {
                  required: true,
                  message: 'Please input address',
                },
              ]}
              hasFeedback
            >
              <Input
                size='large'
                className='rounded-full border-black'
                placeholder='Address'
                allowClear
              />
            </Form.Item>
            <Form.Item name='apt' hasFeedback>
              <Input
                size='large'
                className='rounded-full border-black'
                placeholder='Apt, suite, etc. (optional)'
                allowClear
              />
            </Form.Item>
            <div className='text-left'>
              <label className='mb-3 flex cursor-pointer items-start justify-start gap-2'>
                <input
                  type='checkbox'
                  checked={isCommercial}
                  className='custom-checkbox mt-1'
                  onChange={e => {
                    setIsCommercial(e.target.checked)
                  }}
                />
                <span className='text-[16px]'>
                  Is this a commercial storefront?
                </span>
              </label>
            </div>
            {/* <label className='mb-3 flex cursor-pointer items-start gap-2'>
              <input
                type='checkbox'
                className='custom-checkbox mt-1'
                value={isAgree}
                onChange={e => {
                  setIsAgree(e.target.checked)
                }}
              />
              <span className='text-[16px]'>
                I agree to{' '}
                <Link onClick={() => setIsOpen(true)} className='underline'>
                  the Terms & Conditions
                </Link>
              </span>
            </label> */}
            <button
              type='submit'
              disabled={!isAgree}
              className='mt-3 flex w-full cursor-pointer justify-center rounded-full border-2 border-fitzba-bright-gold-static bg-fitzba-bright-gold-static px-10 py-[6px] text-[16px] text-white hover:bg-white hover:text-fitzba-bright-gold-static disabled:border-none disabled:bg-[#ccc] disabled:hover:text-white'
            >
              Go To Payment
            </button>
            <div className='mt-3 flex items-center justify-center gap-1'>
              <p>Powered by</p>
              <img
                width={40}
                src='/images/payment/stripe-logo.webp'
                alt='stripe'
              />
            </div>
          </div>
        </Form>
      </div>
    </div>
  )
}
