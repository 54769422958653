

import React, { useEffect } from 'react'
import QuestionSection from './QuestionSection'
import FormButtons from './FormButtons'
import { Link } from 'react-router-dom'
import { CiHeart } from 'react-icons/ci'
import StoreBox from './StoreBox'
import { REGISTER_STEPS } from '../RegisterV4'

const stores = [
  {
    _id: '1',
    title: 'Store 1',
  },
  {
    _id: '2',
    title: 'Store 2',
  },
  {
    _id: '3',
    title: 'Store 3',
  },
  {
    _id: '4',
    title: 'Store 4',
  },
  {
    _id: '5',
    title: 'Store 5',
  },
  {
    _id: '6',
    title: 'Store 6',
  },
  {
    _id: '7',
    title: 'Store 7',
  },
  {
    _id: '8',
    title: 'Store 8',
  },
]

export default function QuestionStep1({
  current,
  setCurrent
}) {

  return (
    <div className='w-full flex flex-col gap-1 mb-3 overflow-x-hidden'>
      <div className='mb-5'>
        <h3 className='text-[38px] smsize:text-[30px] font-medium mb-2'>
          Register
        </h3>
        <p className='text-[30px] smsize:text-[24px] font-light'>Some stores we think you'd like</p>
      </div>
      <div className='flex overflow-x-auto'>
        <div className='smsize:px-3 grid grid-cols-2 smsize:flex gap-3 overflow-x-hidden overflow-y-auto max-h-[520px] smsize:max-h-[300px] border-b-2 smsize:border-0 smsize:overflow-x-auto smsize:overflow-y-hidden'>
          <StoreBox />
          <StoreBox />
          <StoreBox />
          <StoreBox />
          <StoreBox />
        </div>
      </div>
      <div className='mt-10'>
        <FormButtons
          backText={'BACK'}
          onClickBack={() => setCurrent(REGISTER_STEPS.QUESTION1)}
          submitText={'SIGN UP'}
          onClickSubmit={() => { }}
        />
      </div>
    </div>
  )
}
