/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * RetailRegisterStep3 is a functional React component that handles the third step in the registration process for retail users.
 * It renders a form for users to select a plan from a list of options.
 * It also handles form submission, updating the form data with the selected plan and proceeding to the next step.
 * The form also provides a back button to return to the previous step.
 * If the user selects the 'book a demo' plan, they are redirected to a demo booking page.
 *
 * @author James Nguyen, Yang Ming
 * @version October 13th, 2023
 */

import { message } from 'antd'
import React, { useEffect, useState } from 'react'
import { BiChevronDown, BiChevronUp } from 'react-icons/bi'
import { useLocation } from 'react-router-dom'

export default function RetailRegisterStep3({
  prev,
  next,
  formData,
  setFormData,
  handleSubmit,
  setCurrent,
  setBookDemo,
}) {
  // const [upgrade, setUpgrade] = useState(false)
  const [tier, setTier] = useState('startz')
  const options = [
    {
      id: 1,
      title: 'startz',
      pricing: 'FREE',
      content1: 'Help consumers find your store and the brands you carry.',
      content2: `Got 5 minutes? <br>That’s all it takes.`,
      keypoints: [
        'set up your store profile',
        'share brands and categories',
        'no credit card required',
      ],
    },
    {
      id: 2,
      title: 'showz',
      pricing: '$49/mo.',
      content1: 'Broadcast your inventory to drive in-store sales. ',
      content2: `Integrate your website or POS system easily`,
      keypoints: [
        'one flat monthly fee',
        '5-minutes setup',
        'connect your website or POS',
      ],
    },
    {
      id: 3,
      title: 'book a demo',
    },
  ]
  const location = useLocation()
  console.log(formData.type)
  const [selectedOption, setSelectedOption] = useState(1)
  useEffect(() => {
    setSelectedOption(() => {
      const options = [
        {
          id: 1,
          title: 'startz',
          pricing: 'FREE',
          content1: 'Help consumers find your store and the brands you carry.',
          content2: `Got 5 minutes? <br>That’s all it takes.`,
          keypoints: [
            'set up your store profile',
            'share brands and categories',
            'no credit card required',
          ],
        },
        {
          id: 2,
          title: 'showz',
          pricing: '$49/mo.',
          content1: 'Broadcast your inventory to drive in-store sales. ',
          content2: `Integrate your website or POS system easily`,
          keypoints: [
            'one flat monthly fee',
            '5-minutes setup',
            'connect your website or POS',
          ],
        },
        {
          id: 3,
          title: 'book a demo',
        },
      ]
      const matchingOption = options.find(
        option => option.title === formData?.type,
      )
      return matchingOption ? matchingOption.id : 1 // 4 is the default if no match is found
    })
  }, [formData.type])

  const [openOption, setOpenOption] = useState(0)

  const onFinish = () => {
    // console.log('Received values of form: ', values)
    if (location.pathname === '/plans') {
      if (tier === 'book a demo') {
        setBookDemo(true)
        setCurrent(3)
      } else {
        // if (tier === formData.type) {
        //   message.error('Please select a different plan')
        // } else {
        setFormData({
          type: tier,
        })
        handleSubmit({ type: tier })
        // }
      }
      if (tier === '' || !tier) {
        message.error('Please select a plan first')
        return
      }
    } else {
      // Updating the form data with the submitted values
      if (tier === 'book a demo') {
        setFormData({
          ...formData,
          type: 'startz',
        })
        setBookDemo(true)
        setCurrent(4)
      } else {
        if (tier !== 'sellz') {
          setFormData({
            ...formData,
            type: tier,
          })
        } else {
          message.error('This tier is not ready yet. COMING SOON')
        }

        // Proceeding to the next step of the registration process
        next()
      }
    }
  }

  return (
    <div>
      <div className='h-[300px] w-[300px] space-y-4 overflow-y-scroll pt-5'>
        {options.map(option => (
          <div key={option?.id}>
            <div
              className='relative h-[50px] rounded-full border-2 border-black'
              style={{
                backgroundColor:
                  option.title === 'showz'
                    ? '#F3FEFF'
                    : option.title === 'sellz'
                    ? '#FFF8EC'
                    : '',
              }}
            >
              <label className=' flex h-full w-full cursor-pointer items-center'>
                <input
                  type='radio'
                  className='hidden'
                  checked={selectedOption === option?.id}
                  onChange={() => {
                    setSelectedOption(option?.id)

                    setTier(option?.title)
                    // console.log(tier)
                  }}
                  id={option.title}
                />
                <span
                  className={`radio-button mx-4 ${
                    selectedOption === option?.id ? 'checked' : ''
                  }`}
                ></span>
                <div className='flex w-[195px] flex-col justify-center leading-tight'>
                  <div className='flex flex-row justify-between text-[20px] '>
                    <div>{option?.title}</div>
                    {option?.id === 2 ? (
                      <div className='font-medium'>
                        <span className='text-[17px]'>
                          {option?.pricing.substring(
                            0,
                            option?.pricing.indexOf('/'),
                          )}
                        </span>
                        <span className='text-[11px]'>
                          {option?.pricing.substring(
                            option?.pricing.indexOf('/'),
                          )}
                        </span>
                      </div>
                    ) : (
                      <div className='font-medium'>{option?.pricing}</div>
                    )}
                  </div>
                  {option?.id === 2 && (
                    <div className='text-left text-[11px] text-[#0B6186]'>
                      1st mo. FREE then $1/mo. for 3 mos.
                    </div>
                  )}
                </div>
                {option?.id < 3 && (
                  <div
                    className='absolute right-4 top-1/2 -translate-y-1/2 transform'
                    onClick={() => {
                      setOpenOption(option?.id === openOption ? 0 : option?.id)
                    }}
                  >
                    {openOption !== option?.id ? (
                      <BiChevronDown size={22} />
                    ) : (
                      <BiChevronUp size={22} />
                    )}
                  </div>
                )}
              </label>
            </div>
            {openOption === option?.id && option?.id < 3 && (
              <div className='mt-2 w-[300px] rounded-3xl border-2 border-black p-4'>
                <>
                  <div className='mb-5 flex w-full flex-col gap-5 text-left text-[13px] font-light leading-tight'>
                    <p
                      dangerouslySetInnerHTML={{ __html: option?.content1 }}
                    ></p>
                    <p
                      dangerouslySetInnerHTML={{ __html: option?.content2 }}
                    ></p>
                  </div>
                  <ul className='retail-ul w-full text-[13px]'>
                    {option?.keypoints.map((point, index) => {
                      return (
                        <li key={index}>
                          <span>{point}</span>
                        </li>
                      )
                    })}
                  </ul>
                  {option?.title === 'sellz' && (
                    <div className='mt-5 text-[12px] font-light text-[#727272]'>
                      *plus 7% transaction fee <br /> (includes credit card fee)
                    </div>
                  )}
                </>
              </div>
            )}
          </div>
        ))}
      </div>

      <div
        style={{
          marginTop: 24,
        }}
        className='mx-auto  flex w-[300px] items-center justify-center gap-2'
      >
        <button
          onClick={onFinish}
          title='Continue'
          className=' flex w-full cursor-pointer justify-center rounded-full border-2 border-fitzba-bright-gold-static bg-fitzba-bright-gold-static p-[6px] text-white hover:bg-white hover:text-fitzba-bright-gold-static'
        >
          CONTINUE
        </button>
      </div>
    </div>
  )
}
