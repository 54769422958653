/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * InventoryImporter is a React component that handles the inventory import process.
 * It uses react-csv-importer to provide a user interface for importing CSV files,
 * and useSelector from Redux to retrieve user info. It also handles navigation and
 * error messages related to the import process.
 * 
 * @author Yang Ming, James Nguyen
 * @version May 09, 2023
 */

import React, { useEffect } from "react"
import { Importer, ImporterField } from "react-csv-importer"
import "react-csv-importer/dist/index.css"
import "./index.css"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { message } from 'antd'
import { loginToken } from '@services/api.service'
import { inventoryImport } from '@services/api.service'
import { Radio } from 'antd'
import {
    setUserinfo,
    setBusiness,
} from '@reducers/userReducer'
import { setBackToRoute } from "@reducers/tempReducer"

/**
 * 
 * @returns Renders the inventory import process with 
 *          user interface for importing CSV files
 */
export default function InventoryImporter() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    // Retriving user info from Redux
    const userinfo = useSelector(state => state.user.userinfo)
    // console.log(userinfo)
    // initializing states
    const [result, setResult] = React.useState('')
    const [isSelected, setIsSelected] = React.useState(false)

    useEffect(() => {
        // token login
        const tokenLogin = async () => {
            let userinfo = localStorage.getItem('userinfo')
            if (!userinfo) {
                dispatch(setBackToRoute('/inventoryImporter'))
                navigate('/login')
                return
            }
            userinfo = JSON.parse(userinfo)
            if (!userinfo || !userinfo.token) return
            try {
                const res = await loginToken(userinfo.token)
                dispatch(setUserinfo(res.data))
                dispatch(setBusiness(res.data.isBusiness))
                if (!res.data.isBusiness) {
                    navigate('/')
                    return
                }
            } catch (err) {
                console.error('tokenLogin failed:', err)
                dispatch(setBackToRoute('/inventoryImporter'))
                navigate('/login')
            }
        }
        tokenLogin()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [unmatched, setUnmatched] = React.useState('Ignore')
    const onChange = (e) => {
        console.log('radio checked', e.target.value)
        setUnmatched(e.target.value)
    }


    // Prevent non-business users from accessing this page
    // if (!userinfo || !userinfo?.currentBusiness) {
    //     // console.log('userinfo', userinfo)
    //     navigate('/')
    //     return null
    // }
    // Async function to handle inventory import
    const handleImport = async (rows) => {
        console.log("received batch of rows", rows)
        try {
            await inventoryImport({ list: rows, userId: userinfo?._id, unmatched: unmatched })
            message.success('Imported successfully')
            // navigate to the inventory page
        } catch (error) {
            console.log('error', error)
            message.error('Imported failed:' + error?.data?.message)
        }
    }

    return (
        <div className="inventory-wraper">
            <div className="banner-section" style={{ backgroundImage: 'url(/images/importer-banner.jpg)' }}>
                Inventory import for business
            </div>
            <div className="inventory-section">
                <h3>Inventory importer</h3>
                <h4>Current Store is: <strong>{userinfo?.currentBusiness?.name}</strong></h4>
                <p>
                    Upload your inventory to our website in just a few simple steps. We support multiple file formats, and you can also download different format templates if you need.
                </p>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '20px',
                }}>
                    <h5>Unmatched item operation:</h5>
                    <Radio.Group onChange={onChange} value={unmatched}>
                        <Radio value={'Ignore'}>Ignore</Radio>
                        <Radio value={'Deactivate'}>Deactivate</Radio>
                        <Radio value={'Delete'}><font color='red'>Delete</font></Radio>
                    </Radio.Group>
                </div>
                {result && (<div className="message-error">{result}</div>)}
            </div>
            <div className="csv-container">
                <div className="csv-section" style={isSelected ? { width: '100%' } : { maxWidth: '960px' }}>
                    <Importer
                        dataHandler={handleImport}
                        chunkSize={5000000} // optional, internal parsing chunk size in bytes
                        defaultNoHeader={false} // optional, keeps "data has headers" checkbox off by default
                        restartable={true} // optional, lets user choose to upload another file when import is complete
                        onStart={({ file, fields }) => {
                            // optional, invoked when user has mapped columns and started import
                            console.log("starting import of file", file, "with fields", fields)
                        }}
                        onComplete={({ file, fields }) => {
                            // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
                            console.log("finished import of file", file, "with fields", fields)

                        }}
                        onClose={() => {
                            // optional, invoked when import is done and user clicked "Finish"
                            // (if this is not specified, the widget lets the user upload another file)
                            console.log("importer dismissed")
                            navigate('/')
                        }}
                        onSelectFile={(error) => {
                            // optional, invoked when file upload fails
                            console.log(error)
                            setResult(error)
                            setIsSelected(error === '')
                            // alert("File upload failed: " + error)
                        }}
                    >
                        <ImporterField name="name" label="name" />
                        <ImporterField name="barcodeNumber" label="barcode" optional />
                        <ImporterField name="modelNumber" label="model" optional />
                        <ImporterField name="description" label="description" optional />
                        <ImporterField name="tags" label="tags" optional />
                        <ImporterField name="category" label="category" optional />
                        <ImporterField name="brand" label="brand" optional />
                        <ImporterField name="quantity" label="quantity" optional />
                        <ImporterField name="originalPrice" label="compare_at_price" optional />
                        <ImporterField name="salePrice" label="price" optional />
                        <ImporterField name="image" label="image" optional />
                        {/* <ImporterField name="saleDiscountCode" label="saleDiscountCode" optional /> */}
                        {/* <ImporterField name="isActivated" label="isActivated" optional /> */}
                    </Importer>
                </div>
            </div>
            <div className="format-list">
                <h3>File format templates</h3>
                <div className="format-list-links">
                    <a href="/format/Fitzba-format-XLSX.xlsx" target="_blank" rel="noreferrer">XLSX format</a>
                    <a href="/format/Fitzba-format-CSV.csv" target="_blank" rel="noreferrer">CSV format</a>
                    <a href="/format/Fitzba-format-JSON.json" target="_blank" rel="noreferrer">JSON format</a>
                    <a href="/format/Fitzba-format-TXT.txt" target="_blank" rel="noreferrer">TXT format</a>
                </div>
            </div>
        </div>
    )
}