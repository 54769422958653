/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * AccountRecover is a functional React component that renders the account recovery page.
 * It includes a form for the user to enter their email and a button to submit the recovery request.
 * It also handles the form submission, sending a recovery email to the user.
 *
 * @author Yang Ming, James Nguyen
 * @version November 9th, 2023
 */

import { Form, Input, message } from "antd";
import React from "react";
import { AiOutlineLeft } from "react-icons/ai";
import { Link } from "react-router-dom";
import { MailOutlined } from "@ant-design/icons";
import { sendRecover } from "../../services/api.service";
import './Login.css'



const AccountRecover = () => {

/**
  * Handles the form submission.
  * Sends a recovery email to the user.
  *
  * @param {Object} value - The form value.
 */
    const handleSubmit = async (value) => {
        try {
            const res = await sendRecover(value)
            if (res.status === 'success') {
              message.success('Recovery email sent, please check your email')

            }
            else {
              message.error('Something went wrong, please try again')

            }

            console.log(res)
        } catch (error) {
          message.error('Something went wrong')
            
        }
    }

  return (
    <div className="login-wrapper relative flex justify-center items-center min-h-screen">
      <div className="login-top text-left p-[20px] flex gap-10 font-bold items-center absolute top-2 left-5">
        <AiOutlineLeft />
        <Link to="/">Return</Link>
      </div>
      <div className="login-main flex flex-col gap-20 h-full justify-center items-center">
        <div className="login-main-logo ">
        <img
          src='/fitzba-ICON-WORD-BLACK.png'
          alt='Fitzba Logo'
          className='md:w-[200px] lg:w-[400px]'
        />
        </div>
        <div className="login-main-form text-black gap-5">
          <h3>Please enter your email</h3>
          <Form
            name="fitzba_login"
            className='login-form'
            onFinish={handleSubmit}
            style={{
              textAlign: "left",
              width: "100%",
            }}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid email!",
                },
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
              hasFeedback
            >
              <Input
                size="large"
                prefix={<MailOutlined />}
                placeholder="Input your email"
                allowClear
              />
            </Form.Item>
            <Form.Item>
              <button size="large" className="login-button hover:scale-105 transition-transform duration-150 ease-in-out">
                Recover
              </button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AccountRecover;
