/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * A button to direct the user back to the top of the page
 *
 * @author Yang Ming
 * @version May 09, 2023
 */

import React from 'react'
import { BiArrowToTop } from 'react-icons/bi'
import './BackToTopButton.css'

/**
 *
 * @returns Render a button to redirect the user to the top
 *          of the page if they scroll past a specific point
 */
export default function BackToTopButton() {
  // state for the button to toggle
  const [showBackToTop, setShowBackToTop] = React.useState(false)
  // listen for a scroll activity made by the user
  window.addEventListener('scroll', () => {
    // show the back to top button if the user scroll past 200 px from the top
    // console.log('window.scrollY', window.scrollY)
    setShowBackToTop(window.scrollY > 200)
  })

  if (!showBackToTop) return null

  return (
    <div className='w-full'>
      <button
        className='backToTop'
        onClick={() => {
          // console.log('backToTop clicked')
          const filter = document.getElementById('zearch-filter')
          if (filter) {
            filter.scrollIntoView({ behavior: 'smooth' })
          }
          window.scrollTo(0, 0)
        }}
      >
        <BiArrowToTop size={24} />
      </button>
    </div>
  )
}
