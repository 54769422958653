/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * The WaitListForm component is a React component that provides a form for users to join fitzba's waitlist.
 * It uses the useForm hook from Ant Design to manage the form data.
 * The submitForm function from the api.service is used to submit the form data.
 *
 * @author James Nguyen, Yang Ming
 * @version June 7th, 2023 
 */

import React from 'react'
// import InputBar from './InputBar'
import { submitForm } from '../services/api.service'
import { Form, Input, message } from 'antd'

function WaitListForm({
  setShowPop,
  showPop
}) {
  const [form] = Form.useForm()

  /**
   * Handles the form submission.
   * It submits the form data to the 'Waitlisted Users' sheet and then resets the form fields.
   * @param {Object} values - The form values.
   */

  const handleSubmit = async values => {
    // console.log(values)
    try {
      await submitForm({
        sheet: 'Waitlisted Users',
        ...values,
        date: new Date().toLocaleDateString(),
      })
      // Clear the form data
      form.resetFields()
      setShowPop(false)
      message.success('Form submitted successfully!')
    } catch (error) {
      console.error('Error submitting form:', error)
      message.error('Error submitting form. Please try again.')
    }
  }

  return (
    <div className='flex px-24 flex-col items-center justify-center'>
      <h3 className='w-full pt-3 text-[18px] font-[300] text-fitzba-black-static '>
        Join our waitlist and we'll notify you when we have stores near you
      </h3>
      <Form
        forceRender
        form={form}
        onFinish={handleSubmit}
        className='w-full pt-5 h-[200px]'
        style={{
          textAlign: 'left'
        }}
      >
        <Form.Item
          name='name'
          style={{ marginBottom: '10px' }}
          rules={[
            {
              required: true,
              message: 'Please enter your name',
            },
          ]}
        >
          <Input placeholder='Name' className='h-[42px] rounded-full border-2 border-black' />
        </Form.Item>

        <Form.Item style={{ marginBottom: 0 }}>
          <Form.Item
            name='city'
            rules={[
              {
                required: true,
                message: 'Please enter your city',
              },
            ]}
            style={{
              display: 'inline-block',
              width: 'calc(50% - 8px)',
              marginBottom: '10px',
            }}
          >
            <Input placeholder='City/Town' className='h-[42px] rounded-full border-2 border-black' />
          </Form.Item>
          <Form.Item
            name='province'
            rules={[
              {
                required: true,
                message: 'Please enter your province',
              },
            ]}
            style={{
              display: 'inline-block',
              width: 'calc(50% - 8px)',
              marginLeft: '16px',
              marginBottom: '10px',
            }}
          >
            <Input placeholder='Province/State' className='h-[42px] rounded-full border-2 border-black' />
          </Form.Item>
        </Form.Item>

        <Form.Item
          name='email'
          rules={[
            {
              required: true,
              message: 'Please enter your email',
            },
            {
              type: 'email',
              message: 'Please enter a valid email',
            },
          ]}
        >
          <Input placeholder='Email' className='h-[42px] rounded-full border-2 border-black' />
        </Form.Item>



        <Form.Item className='flex w-full justify-around'>
          <button
            type='submit'
            className='flex w-[230px] cursor-pointer items-center justify-around h-[42px] rounded-full  border-2 border-fitzba-bright-gold-static bg-fitzba-bright-gold-static font-lexend text-[16px] text-fitzba-white-static hover:bg-fitzba-white-static hover:text-fitzba-bright-gold-static'
          >
            JOIN THE WAITLIST
          </button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default WaitListForm
