/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * This module exports a function that can be used to control the behavior of the console.
 * It allows for the suppression of all console output or selective suppression of log, info, warn, and error methods.
 * 
 * @module GlobalDebug
 * @author Yang Ming
 * @version February 13th, 2024
 */

export const GlobalDebug = (function () {
    var savedConsole = console
    /**
    * @param {boolean} debugOn
    * @param {boolean} suppressAll
    */
    return function (debugOn, suppressAll) {
        var suppress = suppressAll || false
        if (debugOn === false) {
            // supress the default console functionality
            // eslint-disable-next-line
            console = {}
            console.log = function () { }
            // supress all type of consoles
            if (suppress) {
                console.info = function () { }
                console.warn = function () { }
                console.error = function () { }
            } else {
                console.info = savedConsole.info
                console.warn = savedConsole.warn
                console.error = savedConsole.error
            }
        } else {
            // igonre warning
            // eslint-disable-next-line
            console = savedConsole
        }
    }
})()