
import React from 'react'

export default function RecommendedCardNew({
  item,
  selectedId,
  onClickRecommended
}) {
  if (!item) return null
  return (
    <div className={`${selectedId === item._id ? 'border-[3px] border-black rounded-[20px]' : ''} cursor-pointer`}>
      <div onClick={() => onClickRecommended(item._id)} style={{
        backgroundImage: `url(${item.image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }} className={`w-[334px] h-[340px] rounded-[20px] border-[3px] border-[#fff] smsize:w-[300px] smsize:h-[300px]`}>
        <div className='bg-black/40 text-white w-full h-[100px] px-6 py-5 text-left font-semibold text-[24px] rounded-t-[20px] uppercase leading-7'>
          <h3>{item?.title}</h3>
        </div>
      </div>
    </div>
  )
}
