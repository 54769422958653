/**
 *  Copyright Fitzba Technologies Inc. 2023
 *
 *  A button that toggle the dropdown
 *
 * @author Yang
 * @version May 09, 2023
 */

import React from 'react'
import { FiMenu } from 'react-icons/fi'

/**
 *
 * @param onClick A function to change the state of 'open'
 */
const DropdownButton = ({ onClick }) => (
  <button onClick={onClick} className='pt-2'>
    <FiMenu className='menu-icon' size={32} />
  </button>
)

export default DropdownButton
