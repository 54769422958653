/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * This module exports a React component named 'ItemStoreNameBox'. This component displays information about a store that sells a specific item.
 * It includes the store's logo, name, verification status, phone number, and address. The phone number and address are clickable, 
 * leading to the phone's dialer and Google Maps respectively.
 * 
 * The component takes two props: 'item' and 'isMobile'. 'item' is the item being sold, which includes information about the store.
 * 'isMobile' is a boolean indicating whether the device is mobile or not.
 * 
 * @author Yang Ming, James Nguyen
 * @version November 20th, 2023
 */


import React from 'react'
import { BsPatchCheckFill } from 'react-icons/bs'
import { FiPhoneCall } from "react-icons/fi"
import { GoHome } from "react-icons/go"
import { getAddress, getStoreLink } from '@utils/Helper'
import { initItemsFilter, setFilterStoreOnlyOne } from '@reducers/contentReducer'
import { Link } from 'react-router-dom'

/**
 * A component that displays information about a store that sells a specific item.
 * 
 * @param {Object} props - The props object.
 * @param {Object} props.item - The item being sold, which includes information about the store.
 * @param {boolean} props.isMobile - A boolean indicating whether the device is mobile or not.
 * @returns {JSX.Element} The 'ItemStoreNameBox' component.
 */
export default function ItemStoreNameBox({
    item,
    isMobile,
    dispatch,
}) {

    const link = getStoreLink(item?.business?.shortLink, item?.business?.city, item?.business?.province)

    // Assuming item?.business contains the businessPhone, mapUrl, lat, and lng
    const mapUrl = item?.business?.mapUrl ? item.business.mapUrl : `https://maps.google.com/?q=${item?.business?.lat},${item?.business?.lng}`
    const onClickStore = () => {
        dispatch(initItemsFilter())
        dispatch(setFilterStoreOnlyOne(item?.business?._id))
    }
    return (
        <div className={`flex bg-[#EFEFEF] ${isMobile ? 'px-5' : 'rounded-md px-3'} py-2 gap-4 my-4`}>
            <div className='flex flex-col items-center justify-center w-[110px]'>
                <Link to={link} onClick={onClickStore}>
                    <img src={item?.business?.logo} className='w-[80px] h-[80px] object-fill rounded-full bg-white border border-[#E4B456]' alt={item?.business?.name} />
                </Link>
                <p className='font-light text-[14px] whitespace-nowrap'>{item?.distance} KM</p>
            </div>
            <div className='flex flex-col gap-[1px] pt-1 w-full'>
                <div className='flex gap-3 items-center text-left'>
                    <Link to={link} onClick={onClickStore}>
                        <h3 className='text-[18px] font-[400] uppercase text-left'>
                            {item.business?.name}
                        </h3>
                    </Link>
                </div>
                {item.business?.isVerified ?
                    <span className='text-[#727272] text-[11px] uppercase'>
                        Verified store
                        {' '}
                        <BsPatchCheckFill className='mb-px mr-2 inline-block text-black' size={16} />
                    </span>
                    : <span className='pt-[10px]'></span>
                }
                <p className='text-[#000] text-[14px] uppercase flex items-center gap-2'>
                    <FiPhoneCall size={16} />
                    <a href={`tel:${item?.business?.businessPhone}`} className="hover:underline">{item?.business?.businessPhone}</a>
                </p>
                <p className='text-[#000] text-[14px] flex items-start gap-2'>
                    <GoHome size={16} />
                    <a href={mapUrl} target="_blank" rel="noopener noreferrer" className="hover:underline">{getAddress(item?.business)}</a>
                </p>
            </div>
        </div>
    )
}
